import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRouterStore from '@ngrx/router-store';
import * as fromAppReducer from './app/app.reducers';
import * as fromAuthReducer from './auth/auth.reducers';
import { RouterStateUrl } from './router/router.reducers';
import * as fromTranslationReducer from './translation/translation.reducers';
import * as fromConstantsReducer from './constants/constants.reducers';
import * as fromMessengerReducer from './messenger/messenger.reducer';
import * as fromSearchProfilesReducer from './search-profiles/search-profiles.reducers';
import * as fromDistrictsReducer from './district/district.reducer';

export interface BaseState {
  app: fromAppReducer.AppState;
  auth: fromAuthReducer.AuthState;
  router: fromRouterStore.RouterReducerState<RouterStateUrl>;
  translations: fromTranslationReducer.TranslationState;
  constants: fromConstantsReducer.ConstantsState;
  messenger: fromMessengerReducer.MessengerState;
  searchProfiles: fromSearchProfilesReducer.SearchProfilesState;
  districts: fromDistrictsReducer.DistrictState;
}

export const reducers: ActionReducerMap<BaseState> = {
  app: fromAppReducer.reducer,
  auth: fromAuthReducer.reducer,
  router: fromRouterStore.routerReducer,
  translations: fromTranslationReducer.reducer,
  constants: fromConstantsReducer.reducer,
  messenger: fromMessengerReducer.reducer,
  searchProfiles: fromSearchProfilesReducer.reducer,
  districts: fromDistrictsReducer.reducer
};

export const getBaseState = createFeatureSelector<BaseState>('infrastructure');
