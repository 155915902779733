import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { SocialLoginProvider } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { ButtonComponent } from '../../atoms/button/button.component';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, ButtonComponent, TranslateModule]
})
export class SocialLoginComponent {
  readonly horizontalLayout = input(false);
  readonly showText = input(true);
  readonly login = output<string>();

  public socialLoginProvider = SocialLoginProvider;

  public onSocialLogin(loginMethod: string) {
    this.login.emit(loginMethod);
  }
}
