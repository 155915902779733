<div #wizardScrollAnchor></div>
<div class="wizard__container">
  <app-wizard-progress
    class="wizard__top"
    [steps]="steps()"
    [currentStepNumber]="currentStepNumber()"
    (selectStep)="select($event)"
    appElevation
    [elevation]="floatingTop() ? 3 : null"
  ></app-wizard-progress>

  <div class="wizard__step-container" [class.container]="wrapStepContainer()">
    <ng-content select="app-profile-dk-explanation"></ng-content>
  </div>

  @for (template of stepTemplates(); track template; let stepIndex = $index) {
    <div class="wizard__step-container" [class.container]="wrapStepContainer()">
      @if (currentStepNumber() === stepIndex + 1) {
        <ng-container
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ $implicit: stepChange }"
        ></ng-container>
      }
    </div>
  }
  @if (!hideButtonContainer() && viewLoaded) {
    <div
      class="wizard__actions-container"
      [class.wizard__actions-container--floating]="floatingActions()"
      appElevation
      [elevation]="floatingActions() ? 3 : null"
    >
      <div class="container-fluid wizard__footer">
        <app-wizard-progress
          class="wizard__bottom"
          [steps]="steps()"
          [currentStepNumber]="currentStepNumber()"
          (selectStep)="select($event)"
        ></app-wizard-progress>
        <ng-content select="[wizard-footer]"></ng-content>
        <div
          class="wizard__actions-buttons d-flex justify-content-end align-items-end"
          [class.pe-0]="!floatingActions()"
        >
          @if (
            allowCancel() &&
            (currentStepNumber() === 1 || allowCancelInAnyStep())
          ) {
            <app-button
              [type]="'light-bordered'"
              (clickEvent)="cancel()"
              [disabled]="isProcessing()"
              >{{ 'general.cancel_a' | translate }}
            </app-button>
          }
          @if (allowBack() && currentStepNumber() > 1) {
            <app-button
              class="me-1"
              [type]="'light-bordered'"
              (clickEvent)="back()"
              [disabled]="isProcessing()"
              >{{ 'general.previous_a' | translate }}
            </app-button>
          }
          @if (currentStepNumber() < steps().length && !hideNextButton()) {
            <app-button
              id="next-btn"
              (clickEvent)="next()"
              [loading]="isProcessing()"
              [disabled]="
                alwaysEnableButton() ? false : !isValid || isProcessing()
              "
            >
              {{ 'general.next_a' | translate }}
            </app-button>
          }
          @if (currentStepNumber() === steps().length) {
            <app-button
              id="save-btn"
              (clickEvent)="complete()"
              [loading]="isProcessing()"
              [disabled]="
                alwaysEnableButton() ? false : !isValid || isProcessing()
              "
              >{{ 'general.complete_a' | translate }}
            </app-button>
          }
        </div>
      </div>
    </div>
  }
</div>
