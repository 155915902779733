import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '@ui/shared/models';
import { canViewPropertyApplicants } from '../../utils';

@Pipe({
  standalone: true,
  name: 'canViewApplicantsPipe'
})
export class CanViewApplicantsPipePipe implements PipeTransform {
  transform(property: Property): boolean {
    return canViewPropertyApplicants(property);
  }
}
