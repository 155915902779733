import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { RouteChange } from '../base-state/router';

@Injectable()
export class RoutingDetectorService {
  private router = inject(Router);
  private store = inject(Store);

  private subscription: Subscription;

  public start() {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(([previous, current]) =>
        this.store.dispatch(new RouteChange(previous, current))
      );
  }

  public stop() {
    this.subscription.unsubscribe();
  }
}
