import { Component, OnChanges, input } from '@angular/core';

import { ProjectStatus } from '@ui/shared/models';

import { StatusPipelineBaseComponent } from '../status-pipeline-base.component';
import {
  CurrentStatusConfig,
  PipelineSteps
} from '../status-pipeline-base.config';
import { PROJECT_STATUS_CONFIGS } from '../../../../config';
import { projectSteps } from './project-status-pipeline.config';

@Component({
  selector: 'app-project-status-pipeline',
  templateUrl: './project-status-pipeline.component.html',
  imports: [StatusPipelineBaseComponent]
})
export class ProjectStatusPipelineComponent implements OnChanges {
  readonly status = input<ProjectStatus>(undefined);
  readonly pageView = input<boolean>(undefined);

  public steps: PipelineSteps;
  public currentStatus: CurrentStatusConfig;

  private getCurrentStatus(): CurrentStatusConfig {
    this.steps = projectSteps;
    const config = PROJECT_STATUS_CONFIGS[this.status()];
    return {
      ...config,
      pipeline: {
        ...config.pipeline,
        showAsFullscreen: typeof config.pipeline.stepId === 'undefined'
      }
    };
  }

  ngOnChanges(): void {
    this.currentStatus = this.getCurrentStatus();
  }
}
