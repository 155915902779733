<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body chat-create__body">
        <p class="title-m chat-create__title">
          {{ 'messenger.chat_create_title_l' | translate }}
        </p>
        <p class="default-s chat-create__message">
          {{ 'messenger.chat_create_description_l' | translate }}
        </p>
        @if (!(availableForNewConversationActionState() | async).pending) {
          <div class="chat-create__container">
            @if ((availableForNewConversation() | async)?.length > 0) {
              @for (
                application of availableForNewConversation() | async;
                track application
              ) {
                <div
                  class="application-item d-flex justify-content-center align-content-center"
                  (click)="close(application)"
                >
                  @if (application?.property?.data?.attachments[0]?.url) {
                    <div class="col-2 m-auto p0 flat_image">
                      <img
                        [src]="application?.property?.data?.attachments[0]?.url"
                        alt=""
                      />
                    </div>
                  }
                  <div class="col pr0 flat__information">
                    <p class="m0">{{ application?.property?.data?.name }}</p>
                    <span class="secondary"
                      >{{
                        application?.property?.data?.totalRentGross
                          | currency: 'EUR'
                      }}
                      / {{ 'general.total_rent_l' | translate }}</span
                    >
                  </div>
                </div>
              }
            } @else {
              <div class="no_conversation">
                {{ 'messenger.no_object_for_chat_l' | translate }}
              </div>
            }
          </div>
        } @else {
          <div class="loading-area">
            <app-loading-spinner></app-loading-spinner>
          </div>
        }
        <ng-template #loading>
          <div class="loading-area">
            <app-loading-spinner></app-loading-spinner>
          </div>
        </ng-template>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer chat-create__footer">
        <app-button (clickEvent)="dismiss()" [type]="'light-bordered'">{{
          'general.cancel_a' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
