import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ModalContentComponent } from '../modal/modal-content/modal-content.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-new-update-banner',
  templateUrl: './new-update-banner.component.html',
  styleUrls: ['./new-update-banner.component.scss'],
  imports: [
    ModalComponent,
    ModalContentComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class NewUpdateBannerComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  onUpdate() {
    this.ngbActiveModal.close();
  }
}
