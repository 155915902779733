import { Component, inject, OnInit, input } from '@angular/core';

import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { QesMethods, QESMethodsBadgeText } from '@ui/shared/models';
import { ImageComponent } from '../../../../../atoms/image/image.component';
import { ButtonComponent } from '../../../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../../../modal/modal-footer/modal-footer.component';
import { DateComponent } from '../../../../form/controls/date/date.component';
import { FormFieldLabelComponent } from '../../../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../../form/form-field/form-field.component';
import { BadgeComponent } from '../../../../../atoms/badge/badge.component';
import { ModalContentComponent } from '../../../../modal/modal-content/modal-content.component';
import { ModalV2Component } from '../../../../modal-v2/modal-v2.component';
import { BadgeColorEnum } from '../../../../../atoms/badge';

type QesMethodModalType = {
  header: string;
  label: { color: BadgeColorEnum; text: QESMethodsBadgeText };
  defaultNeededDocumentsText: string;
  defaultHowItWorksText: string;
  notSupportedBanks?: string;
  secondNeededDocumentsText?: string;
  secondHowItWorksText?: string;
};

const METHODS = {
  [QesMethods.ROBO_IDENT]: {
    header: 'digital_contract.qes_method_selfie_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_NECT,
      text: QESMethodsBadgeText.ROBO_IDENT
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_selfie_needed_documents_l',
    defaultHowItWorksText: 'digital_contract.qes_method_selfie_how_it_works_l'
  },
  [QesMethods.BANK]: {
    header: 'digital_contract.qes_method_bank_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_BANK,
      text: QESMethodsBadgeText.BANK
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_bank_needed_documents_l',
    defaultHowItWorksText: 'digital_contract.qes_method_bank_how_it_works_l',
    notSupportedBanks: 'digital_contract.qes_method_bank_not_supported_banks_l'
  },
  [QesMethods.VIDEO]: {
    header: 'digital_contract.qes_method_video_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
      text: QESMethodsBadgeText.VIDEO
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_video_needed_documents_l',
    defaultHowItWorksText: 'digital_contract.qes_method_video_how_it_works_l'
  },
  [QesMethods.E_ID]: {
    header: 'digital_contract.qes_method_id_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
      text: QESMethodsBadgeText.E_ID
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_id_needed_documents_phone_l',
    defaultHowItWorksText:
      'digital_contract.qes_method_id_how_it_works_phone_l',
    secondNeededDocumentsText:
      'digital_contract.qes_method_id_needed_documents_pc_l',
    secondHowItWorksText: 'digital_contract.qes_method_id_how_it_works_pc_l'
  }
};

@Component({
  selector: 'app-qualified-electronic-signature-method-modal',
  templateUrl: './qualified-electronic-signature-method-modal.component.html',
  styleUrls: ['./qualified-electronic-signature-method-modal.component.scss'],
  imports: [
    ModalV2Component,
    ModalContentComponent,
    BadgeComponent,
    SvgIconComponent,
    NgTemplateOutlet,
    FormFieldComponent,
    FormFieldLabelComponent,
    DateComponent,
    FormsModule,
    ReactiveFormsModule,
    ModalFooterComponent,
    ButtonComponent,
    ImageComponent,
    TranslateModule
  ]
})
export class QualifiedElectronicSignatureMethodModalComponent
  implements OnInit
{
  private ngbActiveModal = inject(NgbActiveModal);

  readonly qesMethod = input<QesMethods>(undefined);
  public selectedMethod: QesMethodModalType;
  public qesMethods = QesMethods;
  public dateOfBirthControl: FormControl;

  public maxBirthDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public get isDateOfBirthRequired() {
    const qesMethod = this.qesMethod();
    return qesMethod === QesMethods.BANK || qesMethod === QesMethods.E_ID;
  }

  ngOnInit() {
    this.selectedMethod = METHODS[this.qesMethod()];
    this.dateOfBirthControl = new FormControl('', {
      validators: this.isDateOfBirthRequired ? [Validators.required] : null
    });
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    const payload = {
      provider: this.qesMethod()
    } as any;

    if (this.isDateOfBirthRequired)
      payload.dateOfBirth = this.dateOfBirthControl.value;

    this.ngbActiveModal.close(payload);
  }
}
