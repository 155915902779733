import { Directive, input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Step, WizardStep } from '@ui/legacy-lib';

@Directive()
export class EditLandlordWizard extends WizardStep implements OnInit {
  readonly form = input<FormGroup>(undefined);
  readonly stepIndex = input<number>(undefined);
  readonly steps = input<Step[]>(undefined);

  ngOnInit() {
    super.ngOnInit();
  }

  nextStep() {
    const stepName = this.steps()[this.stepIndex()].name;
    this.onNextStep.emit(stepName);
  }
}
