import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-error-new',
  template: '<p class="block m-0">{{ text() | translate }}</p>',
  styleUrl: './form-error.component.scss',
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorComponent {
  text = input<string>('');
}
