import { Component, inject, input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  CustomQuestion,
  RootQuestionContainerModel,
  ContactTag
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../modal/modal-footer/modal-footer.component';
import { AvailableHierarchicalQuestionComponent } from '../available-hierarchical-question.component/available-hierarchical-question.component';
import { AvailableCustomQuestionComponent } from '../available-custom-question/available-custom-question.component';
import { HintComponent } from '../../hint/hint.component';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { ModalContentComponent } from '../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-attach-custom-question-modal',
  templateUrl: './attach-custom-question-modal.component.html',
  styleUrls: ['./attach-custom-question-modal.component.scss'],
  imports: [
    ModalComponent,
    ModalContentComponent,
    FormFieldLabelComponent,
    HintComponent,
    AvailableCustomQuestionComponent,
    AvailableHierarchicalQuestionComponent,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class AttachCustomQuestionModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly customQuestions = input<CustomQuestion[]>(undefined);
  readonly hierarchicalQuestions =
    input<RootQuestionContainerModel[]>(undefined);
  readonly tags = input<ContactTag[]>(undefined);

  public selectedIds: string[] = [];

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public save() {
    const selectedHierarchicalQuestions = this.hierarchicalQuestions()
      .map(({ id }) => id)
      .filter(id => this.selectedIds.includes(id));

    const selectedCustomQuestions = this.customQuestions()
      .map(({ id }) => id)
      .filter(id => this.selectedIds.includes(id));

    this.ngbActiveModal.close({
      selectedHierarchicalQuestions,
      selectedCustomQuestions
    });
  }

  public isQuestionSelected(questionId: string) {
    return this.selectedIds.includes(questionId);
  }

  public toggle(questionId: string) {
    const index = this.selectedIds.findIndex(id => id === questionId);
    if (index > -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(questionId);
    }
  }
}
