import { StateAfterAuth } from '@ui/shared/models';

export function splitParams(input) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    (input &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      input
        .split('|')
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map(item => (item.length > 0 && item !== 'null' ? item : null))) ||
    null
  );
}

export function getPageSettings(keys: string[]) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (defs: {}, input: {}) =>
    keys.reduce((memo, key, i) => {
      memo[key] = {};
      if (Object.keys(input)) {
        Object.keys(input).map(paramType => {
          const splitInput = splitParams(input[paramType]);
          memo[key][paramType] = parseInt(splitInput[i], 10)
            ? parseInt(splitInput[i], 10)
            : splitInput[i] || defs[paramType];
        });
      } else {
        memo[key] = (input && input[i]) || defs[i];
      }
      return memo;
    }, {});
}

export function setQueryParam(
  // eslint-disable-next-line @typescript-eslint/ban-types
  exisitingSettings: object,
  navOptions: string[],
  activeNav: string,
  param: string,
  defaultVal: string | number,
  value?: string | number
) {
  const paramValue = navOptions
    .map(item => {
      if (item !== activeNav) {
        return ((exisitingSettings &&
          exisitingSettings[item] &&
          exisitingSettings[item][param]) ||
          defaultVal) as string;
      }
      return (value || defaultVal) as string;
    })
    .join('|');
  return {
    [param]: paramValue
  };
}

export function getRedirectUrl(
  currentWindowLocation: string,
  authRoute: string,
  stateAfterAuth?: StateAfterAuth
): string {
  function turnIntoString(queryParamsObject: StateAfterAuth) {
    const pathAfterAuth = `pathAfterAuth=${encodeURIComponent(
      queryParamsObject.pathAfterAuth
    )}`;
    let params = '';
    if (queryParamsObject.queryParams) {
      Object.keys(queryParamsObject.queryParams).forEach(key => {
        params += `&${key}=${encodeURIComponent(
          queryParamsObject.queryParams[key]
        )}`;
      });
    }
    return `${pathAfterAuth}${params}`;
  }

  const urlWithBaseHref = currentWindowLocation
    .split('?')[0]
    .split('/')
    .splice(0, 3)
    .join('/');

  let separator = '?';
  if (authRoute.includes('?')) {
    // For the PS app it can happen that authRoute includes already query params
    // Then we have to adjust the separator
    separator = '&';
  }

  const queryParamsString = stateAfterAuth
    ? `${separator}${turnIntoString(stateAfterAuth)}`
    : separator;

  return `${urlWithBaseHref}${authRoute}${queryParamsString}`;
}
