import { HttpClientModule } from '@angular/common/http';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';

import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';
import { ModuleConfig } from './model';
import { KEYCLOAK_OPTIONS } from './contant';
import { DeepLinkService, KeycloakWebService } from './services';

@NgModule({
  imports: [CommonModule]
})
export class KeycloakAuthenticationModule {
  private keycloakWebService = inject(KeycloakWebService);
  private infrastructure = inject(INFRASTRUCTURE_CONFIG);

  constructor() {
    void this.keycloakWebService.init();
  }

  public static forRoot(
    config = new ModuleConfig()
  ): ModuleWithProviders<KeycloakAuthenticationModule> {
    return {
      ngModule: KeycloakAuthenticationModule,
      providers: [
        HttpClientModule,
        { provide: KEYCLOAK_OPTIONS, useValue: config.keycloakConfig },
        KeycloakWebService,
        DeepLinkService,
        JwtHelperService
      ]
    };
  }
}
