<app-card class="portal" [padding]="'none'">
  <div class="row" (click)="onHeaderClick()">
    <div class="col-3 d-flex right-divider">
      <div class="portal__image">
        <img src="{{ portalConfig()?.imgUrl }}" />
      </div>
    </div>
    <div class="col-7 d-flex align-items-center">
      <div class="portal__info">
        <p class="default-s semibold portal__info-name">
          <span>{{ portalConfig()?.fullName | translate }}</span>
        </p>
      </div>
    </div>
    <div class="col-2 d-flex justify-content-end align-items-center">
      <div class="portal__checkbox">
        <app-check [formControl]="checkControl"></app-check>
      </div>
    </div>
  </div>
  @if (selected()) {
    <div class="portal__credential-details">
      @switch (formType) {
        @case (credentialFormType.IMMOSCOUT) {
          <app-immoscout-credential-details
            [portalConfig]="portalConfig()"
            [credential]="credential"
            [formControl]="credentialDetailsControl"
            (getIs24URL)="onGetIs24URL()"
          >
          </app-immoscout-credential-details>
        }
        @case (credentialFormType.HOMEPAGE_MODULE) {
          <app-homepage-module-credential-details
            [portalConfig]="portalConfig()"
            [credential]="credential"
            [formControl]="credentialDetailsControl"
          >
          </app-homepage-module-credential-details>
        }
        @case (credentialFormType.RESIDENT_APP) {
          <app-resident-credential-details
            [portalConfig]="portalConfig()"
            [credential]="credential"
            [formControl]="credentialDetailsControl"
          >
          </app-resident-credential-details>
        }
        @case (credentialFormType.WILLHABEN) {
          <app-portal-credential-details-with-provider
            [portalConfig]="portalConfig()"
            [credential]="credential"
            [formControl]="credentialDetailsControl"
          >
          </app-portal-credential-details-with-provider>
        }
        @case (credentialFormType.IMMOBILIE1) {
          <app-portal-credential-details-with-provider
            [portalConfig]="portalConfig()"
            [credential]="credential"
            [formControl]="credentialDetailsControl"
          >
          </app-portal-credential-details-with-provider>
        }
        @case (credentialFormType.DEFAULT) {
          <app-portal-credential-details
            [portalConfig]="portalConfig()"
            [credential]="credential"
            [formControl]="credentialDetailsControl"
          >
          </app-portal-credential-details>
        }
      }
    </div>
  }
</app-card>
