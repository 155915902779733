import { Component, inject, OnInit, input } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

const DEFAULT_LINK_PLACEHOLDER = 'link';

@Component({
  selector: 'app-embedded-link',
  templateUrl: './embedded-link.component.html',
  styleUrls: ['./embedded-link.component.scss']
})
export class EmbeddedLinkComponent implements OnInit {
  private sanitizer = inject(DomSanitizer);
  private translate = inject(TranslateService);

  readonly contentKey = input('');
  readonly linkTarget = input('');
  readonly linkTextKey = input('');
  readonly linkPlaceholder = input(DEFAULT_LINK_PLACEHOLDER);

  public value: SafeHtml = '';

  public ngOnInit() {
    const linkTextKey = this.linkTextKey();
    const contentKey = this.contentKey();
    if (!linkTextKey || !contentKey) {
      return;
    }

    const linkText = this.translate.instant(linkTextKey);

    const link = `<a href="${this.linkTarget()}" target="_blank">${String(
      linkText
    )}</a>`;

    const result = this.translate.instant(contentKey, {
      [this.linkPlaceholder()]: link
    });

    this.value = this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
