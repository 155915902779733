<div class="card custom-question__container">
  <div
    class="d-flex align-items-center justify-content-between default-s mb12 custom-question__header"
  >
    <div>{{ customQuestion()?.title }}</div>
    <app-context-menu>
      <div menu-content>
        @if (showPreview()) {
          <app-context-menu-item (clickEvent)="onPreview()">{{
            'custom_questions.preview_a' | translate
          }}</app-context-menu-item>
        }
        <app-context-menu-item (clickEvent)="onDelete()">{{
          'custom_questions.delete_a' | translate
        }}</app-context-menu-item>
      </div>
    </app-context-menu>
  </div>

  <app-custom-question-details
    [customQuestion]="customQuestion()"
  ></app-custom-question-details>
</div>
