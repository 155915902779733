import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';

import { CookiePreference, User } from '@ui/shared/models';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FreshchatWidgetService } from '../../browser/freshworks/widgets';

import * as fromReducers from '../reducers';
import { CookieService, LocalStorageService } from '../../storage';
// Not sure why we explicitly have to go for more specific imports, but if not we get a runtime error
import { WINDOW_REF } from '../../browser/window-ref.token';
import { systemDowntimeQuery, versionQuery } from '../gql-queries';
import { INFRASTRUCTURE_CONFIG } from '../../infrastructure-config.token';
import { ModalService } from '../../../components/legacy/modal';
import { CustomCookieSettingsModalComponent } from '../../../components/legacy/cookie-banner';
import * as fromSelectors from './app.selectors';
import * as fromActions from './app.actions';

@Injectable()
export class AppEffects {
  private apollo = inject(Apollo);
  private actions$ = inject(Actions);
  private storage = inject(LocalStorageService);
  private store = inject<Store<fromReducers.BaseState>>(Store);
  private modalService = inject(ModalService);
  private cookieService = inject(CookieService);
  private freshchatWidgetService = inject(FreshchatWidgetService);
  private infrastructureConfig = inject(INFRASTRUCTURE_CONFIG);
  private windowRef = inject(WINDOW_REF);

  getSystemDowntime = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.GetSystemDowntime>(fromActions.GET_SYSTEM_DOWNTIME),
        switchMap(() =>
          this.apollo
            .query<{ systemDowntime: { isSystemDown: boolean } }>({
              query: systemDowntimeQuery,
              fetchPolicy: 'no-cache'
            })
            .pipe(
              map(response => {
                if (response.data.systemDowntime.isSystemDown)
                  this.windowRef.open(
                    'https://www.mieter.immomio.com/wartungsarbeiten',
                    '_self'
                  );
              })
            )
        )
      ),
    { dispatch: false }
  );

  initCookiesPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.InitCookiesPreference>(
        fromActions.INIT_COOKIES_PREFERENCE
      ),
      map(({ user }) => ({
        cookiePreference: this.storage.getItem<CookiePreference>(
          this.infrastructureConfig.storageKeys.cookiePreference
        ),
        user
      })),
      map(
        ({ cookiePreference, user }) =>
          new fromActions.SetCookiesPreference(cookiePreference, user)
      )
    )
  );

  setCookiesPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetCookiesPreference>(
        fromActions.SET_COOKIES_PREFERENCE
      ),
      mergeMap(({ cookiePreference, user }) => [
        new fromActions.UpdateCookieTracking(cookiePreference, user),
        new fromActions.SetCookiesPreferenceSuccess(cookiePreference)
      ])
    )
  );

  openCustomCookieSettingsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenCookieSettingsModal>(
          fromActions.OPEN_COOKIE_SETTINGS_MODAL
        ),
        tap(({ cookiePreference, isTenant, user }) => {
          this.modalService
            .open(CustomCookieSettingsModalComponent, {
              backdrop: 'static',
              keyboard: false,
              data: { cookiePreference, isTenant }
            })
            .onClose()
            .subscribe((payload: CookiePreference) =>
              this.store.dispatch(
                new fromActions.SetCookiesPreference(payload, user)
              )
            );
        })
      ),
    { dispatch: false }
  );

  getPerformanceWarningConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GetPerformanceWarningConfirmation>(
        fromActions.GET_PERFORMANCE_WARNING_CONFIRMATION
      ),
      map(() => {
        const preference =
          this.storage.getItem<boolean>(
            this.infrastructureConfig.storageKeys
              .hasAcceptedPerformanceWarningConfirmation
          ) || false;

        return new fromActions.SetPerformanceWarningConfirmation(preference);
      })
    )
  );

  setPerformanceWarningConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.SetPerformanceWarningConfirmation>(
          fromActions.SET_PERFORMANCE_WARNING_CONFIRMATION
        ),
        map(action =>
          this.storage.setItem(
            this.infrastructureConfig.storageKeys
              .hasAcceptedPerformanceWarningConfirmation,
            action.confirmation
          )
        )
      ),
    { dispatch: false }
  );

  changeLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.ChangeLocale>(fromActions.CHANGE_LOCALE),
        withLatestFrom(this.store.select(fromSelectors.getCurrentLocale)),
        map(([{ locale }, currentLocale]) => {
          const href = this.windowRef.location.href.replace(
            `/${currentLocale}/`,
            `/${locale}/`
          );
          this.store.dispatch(new fromActions.SetCurrentLocale(locale));

          this.windowRef.location.href = href;
        })
      ),
    { dispatch: false }
  );

  updateApplicationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.UpdateApplicationSuccess>(
          fromActions.UPDATE_APPLICATION_SUCCESS
        ),
        tap(() => this.windowRef.location.reload())
      ),
    { dispatch: false }
  );

  updateCookieTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.UpdateCookieTracking>(
          fromActions.UPDATE_COOKIE_TRACKING
        ),
        tap(({ cookiePreference, user }) =>
          this.updateTracking(cookiePreference, user)
        )
      ),
    { dispatch: false }
  );

  getVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GetVersion>(fromActions.GET_VERSION),
      switchMap(() =>
        this.apollo
          .query<{ version: { version: string; prevVersion: string } }>({
            query: versionQuery,
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              response =>
                new fromActions.GetVersionSuccess(response.data.version)
            )
          )
      )
    )
  );

  private updateTracking(payload: CookiePreference, user: User) {
    this.cookieService.savePreference(payload);
    if (payload?.allowFunctional && !this.isTenantApp) {
      this.freshchatWidgetService.init(user);
    }
  }

  private get isTenantApp() {
    return this.infrastructureConfig.environment.app_name === 'tenant';
  }
}
