import { Component, forwardRef, input } from '@angular/core';

import {
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { BaseControl } from '../../../components/legacy/form/controls/base-control';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';

@Component({
  selector: 'app-self-disclosure-employment',
  templateUrl: './self-disclosure-employment.component.html',
  styleUrls: ['./self-disclosure-employment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelfDisclosureEmploymentComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective
  ]
})
export class SelfDisclosureEmploymentComponent extends BaseControl<any> {
  readonly selfDisclosureQuestion = input<SelfDisclosureQuestion>(undefined);
  readonly form = input<FormGroup>(undefined);

  public writeValue(value: any) {
    this.value = value ? value : [];
  }

  public get getFormControl() {
    return this.form().get('answer') as FormGroup;
  }
}
