import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  input,
  output
} from '@angular/core';

import {
  QesCheckPagesEnum,
  QesMethods,
  QESMethodsBadgeText
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { MobileIdTutorialComponent } from '../mobile-id-tutorial/mobile-id-tutorial.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { MessageComponent } from '../../message/message.component';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import { ModalService } from '../../modal/modal.service';

import { ActionState } from '../../../../state-utils/action-state';

import { BadgeColorEnum } from '../../../atoms/badge';
import { WINDOW_REF } from '../../../../infrastructure/browser/window-ref.token';
import { BaseStep } from '../../wizard/wizard-step';
import { QualifiedElectronicSignatureMethodModalComponent } from './components/qualified-electronic-signature-method-modal/qualified-electronic-signature-method-modal.component';

@Component({
  selector: 'app-qualified-electronic-signature',
  templateUrl: './qualified-electronic-signature.component.html',
  styleUrls: ['./qualified-electronic-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LoadingSpinnerComponent,
    MessageComponent,
    CardComponent,
    BadgeComponent,
    ImageComponent,
    MobileIdTutorialComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class QualifiedElectronicSignatureComponent extends BaseStep {
  private modalService = inject(ModalService);
  private windowRef = inject(WINDOW_REF);

  @Input() set qesActionState(actionState: ActionState) {
    this.actionState = actionState;
    this.handleActionStateChange(actionState);
  }

  readonly qesLink = input<string>(undefined);
  readonly startQesCheck = output<{
    provider: QesMethods;
    dateOfBirth?: string;
  }>();

  qesCheckPagesEnum = QesCheckPagesEnum;
  public actionState: ActionState;
  public qesCheckPagesState: QesCheckPagesEnum;

  public methods = [
    {
      translationKey: 'digital_contract.qes_method_selfie_l',
      imageSrc: QesMethods.ROBO_IDENT,
      label: {
        color: BadgeColorEnum.DIGITAL_CONTRACT_NECT,
        text: QESMethodsBadgeText.ROBO_IDENT
      }
    },
    {
      translationKey: 'digital_contract.qes_method_video_l',
      imageSrc: QesMethods.VIDEO,
      label: {
        color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
        text: QESMethodsBadgeText.VIDEO
      }
    },
    {
      translationKey: 'digital_contract.qes_method_id_l',
      imageSrc: QesMethods.E_ID,
      label: {
        color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
        text: QESMethodsBadgeText.E_ID
      }
    }
  ];

  public openQesMethodModal(index: number) {
    this.modalService
      .open(QualifiedElectronicSignatureMethodModalComponent, {
        size: 'lg',
        data: { qesMethod: this.methods[index].imageSrc }
      })
      .onClose()
      .subscribe(payload => this.startQesCheck.emit(payload));
  }

  public openLink(): void {
    this.windowRef.open(this.qesLink(), '_blank');
    this.qesCheckPagesState = QesCheckPagesEnum.AUTHENTIFICATION_STARTED_PAGE;
  }

  public handleBackClicked(): void {
    this.qesCheckPagesState = QesCheckPagesEnum.AUTH_SELECTION_PAGE;
  }

  private handleActionStateChange(actionState: ActionState) {
    if (actionState.done) {
      this.qesCheckPagesState = QesCheckPagesEnum.START_AUTHENTIFICATION_PAGE;
    } else if (!actionState.error && !actionState.pending) {
      this.qesCheckPagesState = QesCheckPagesEnum.AUTH_SELECTION_PAGE;
    }
  }
}
