import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChild,
  contentChildren,
  HostBinding,
  inject,
  input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { AppClickAreaDirective } from '../controls/input/click-area.directive';
import { AppFormFieldControl } from './form-field-control/form-field-control';

import { FormFieldLabelComponent } from './form-field-label/form-field-label.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';

import { getErrorMessage } from './form-field-error/error-message';

@UntilDestroy()
@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AppClickAreaDirective,
    NgTemplateOutlet,
    FormFieldErrorComponent,
    FormFieldLabelComponent,
    TranslateModule
  ]
})
export class FormFieldComponent implements OnInit, AfterContentInit {
  private changeDetectorRef = inject(ChangeDetectorRef);

  readonly label = input('');
  readonly labelBelowInput = input(false);
  readonly markCompleted = input(false);
  readonly disableErrorMessages = input(false);
  readonly disabled = input(false);
  readonly disabledClickArea = input(false);

  readonly fieldControl = contentChild(AppFormFieldControl);
  projectedLabelChild = contentChild(FormFieldLabelComponent);
  readonly projectedErrorChildren = contentChildren(FormFieldErrorComponent);
  @HostBinding('class.app-form-field') private mainClass = true;
  public errors: string[] = [];
  public showRequiredMarker: boolean;
  public controlId: string;

  ngOnInit() {
    this.validateInputChild();
    this.fieldControl()
      .stateChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.hasProjectedErrorChildren()) {
          const errorsMap = this.fieldControl().errors;
          this.errors = errorsMap
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              Object.keys(errorsMap).map(key => getErrorMessage(key))
            : [];
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngAfterContentInit() {
    this.showRequiredMarker =
      this.fieldControl()?.required() ||
      this.projectedLabelChild()?.showRequiredMarker();
    this.controlId = this.fieldControl()?.id();

    if (this.projectedLabelChild()) {
      this.projectedLabelChild().showRequiredMarker.set(
        this.showRequiredMarker
      );
      this.projectedLabelChild().idFor.set(this.controlId);
    }
  }

  private hasProjectedErrorChildren() {
    return this.projectedErrorChildren()?.length > 0;
  }

  private validateInputChild() {
    if (!this.fieldControl())
      throw Error('fieldControl missing in app-form-field');
  }
}
