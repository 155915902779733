import { Component, inject, OnInit, input } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  CityPacket,
  Constants,
  InternationalizationSettings,
  MarketingType,
  NameValue,
  ObjectType,
  Project,
  PropertyType,
  RegistrationLinkObjectTypes,
  SearchProfile,
  SearchProfileType
} from '@ui/shared/models';
import moment from 'moment';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderDragEvent } from '@angular/material/slider';

import { SearchProfileService } from '../../../../services/search-profile.service';
import { SearchProfilePriceSpaceRoomsSliderComponent } from '../search-profile-price-space-rooms-slider/search-profile-price-space-rooms-slider.component';
import { FormControlPipe } from '../../../../pipes/form-control.pipe';
import { DateComponent } from '../../form/controls/date/date.component';
import { SearchProfilePropertyDetailsComponent } from '../search-profile-property-details/search-profile-property-details.component';
import { SearchProfileAddressComponent } from '../search-profile-address/search-profile-address.component';
import { DropdownMultiselectComponent } from '../../form/controls/dropdown-multiselect/dropdown-multiselect.component';
import { DropdownSelectComponent } from '../../form/controls/dropdown-select/dropdown-select.component';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { ProjectCardComponent } from '../../../molecules/cards/project-card/project-card.component';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage,
  isSalesProperty
} from '../../../../utils';

@UntilDestroy()
@Component({
  selector: 'app-search-profile-details',
  templateUrl: './search-profile-details.component.html',
  styleUrls: ['./search-profile-details.component.scss'],
  providers: [SearchProfileService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ProjectCardComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective,
    DropdownSelectComponent,
    DropdownMultiselectComponent,
    SearchProfileAddressComponent,
    SearchProfilePropertyDetailsComponent,
    DateComponent,
    TranslateModule,
    FormControlPipe,
    SearchProfilePriceSpaceRoomsSliderComponent
  ]
})
export class SearchProfileDetailsComponent implements OnInit {
  private searchProfileService = inject(SearchProfileService);

  readonly form = input<FormGroup>(undefined);
  readonly searchProfile = input<SearchProfile>(undefined);
  readonly constants = input<Constants>(undefined);
  readonly landlordCityPacket = input<CityPacket>(undefined);
  readonly onlyShowConfiguredCityPartsToUser = input<boolean>(undefined);
  readonly showMarketingType = input<boolean>(undefined);
  readonly project = input<Project>(undefined);
  readonly inInternalPool = input<boolean>(undefined);
  readonly internalizationSettings =
    input<InternationalizationSettings>(undefined);
  readonly enableEditingOfSearchingSinceDate = input(false);
  readonly isRegistration = input(false);
  readonly defaultObjectTypes = input<RegistrationLinkObjectTypes>(undefined);

  public locationError: string;
  public garageTypesConstants: NameValue<string>[];
  public commercialSubTypes: NameValue<string>[];
  public objectTypeConstants: NameValue<string>[];
  public propertyTypesConstants: NameValue<string>[];
  public flatTypesConstants: NameValue<string>[];
  public commercialTypesConstants: NameValue<string>[];
  public houseTypesConstants: NameValue<string>[];

  public dateLimit: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  public maxUpperBoundRent = 0;
  public minUpperBoundRent = 0;
  public stepsForUpperBoundRent = 0;

  public upperBoundSizeMax = 150;
  public upperBoundRoomsMax = 10;

  public get isNewSearchProfile() {
    return !this.searchProfile()?.id;
  }

  private get searchProfileTypeControl() {
    return this.form().get('type');
  }

  public get addressForm() {
    return this.form().get('address') as FormGroup;
  }

  public get propertyTypeControl() {
    return this.form().get('propertyType') as FormControl;
  }

  public get garageTypesControl() {
    return this.form().get('garageTypes') as FormControl;
  }

  public get commercialDataGroup() {
    return this.form().get('commercialData') as FormGroup;
  }

  public get commercialTypeForm() {
    return this.commercialDataGroup.get('commercialType');
  }

  public get isProjectSearchProfile() {
    return (
      this.searchProfileTypeControl?.value === SearchProfileType.PROJECT ||
      this.project()?.id
    );
  }

  public get flatDataGroup() {
    return this.form().get('flatData') as FormGroup;
  }

  public get commercialSubTypesForm() {
    return this.commercialDataGroup.get('commercialSubTypes') as FormGroup;
  }

  public get districtsFormArray() {
    return this.form().get('districts') as FormArray;
  }

  public get isFlat() {
    return isPropertyTypeFlat(this.propertyTypeControl.value);
  }

  public get flatDataObjectTypesControl() {
    return this.flatDataGroup.get('objectTypes') as FormControl;
  }

  public get isObjectTypeFlat() {
    return (
      this.isFlat &&
      (this.flatDataObjectTypesControl?.value as string)?.includes(
        ObjectType.FLAT
      )
    );
  }

  public get isObjectTypeHouse() {
    return (
      this.isFlat &&
      (this.flatDataObjectTypesControl?.value as string)?.includes(
        ObjectType.HOUSE
      )
    );
  }

  public get isGarage() {
    return isPropertyTypeGarage(this.propertyTypeControl.value);
  }

  public get isCommercial() {
    return isPropertyTypeCommercial(this.propertyTypeControl.value);
  }

  public get isSalesObject() {
    return isSalesProperty(this.marketingTypeControl.value as MarketingType);
  }

  private get radiusControl() {
    return this.form().get('radius');
  }

  private get marketingTypeControl() {
    return this.form().get('marketingType');
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadGarageTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.garageTypesConstants = this.constants().garageTypes.filter(
        garageType =>
          this.defaultObjectTypes().garageTypes.find(
            defaultType => defaultType === garageType.value
          )
      );
    } else {
      this.garageTypesConstants = this.constants()?.garageTypes?.filter(
        c => c?.value !== 'NO_INFORMATION'
      );
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadObjectTypeConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.objectTypeConstants = this.constants().objectTypes.filter(
        objectTypes =>
          this.defaultObjectTypes().objectTypes.find(
            defaultType => defaultType === objectTypes.value
          )
      );
    } else {
      this.objectTypeConstants = this.constants()?.objectTypes?.filter(
        type =>
          type.value === ObjectType.HOUSE || type.value === ObjectType.FLAT
      );
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadPropertyTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.propertyTypesConstants = this.constants().propertyTypes.filter(
        propertyType =>
          this.defaultObjectTypes().propertyTypes.find(
            defaultType => defaultType === propertyType.value
          )
      );
    } else {
      this.propertyTypesConstants = this.constants().propertyTypes;
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadFlatTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.flatTypesConstants = this.constants().flatTypes.filter(flatType =>
        this.defaultObjectTypes().flatTypes.find(
          defaultType => defaultType === flatType.value
        )
      );
    } else {
      this.flatTypesConstants = this.constants().flatTypes;
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadHouseTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.houseTypesConstants = this.constants().houseTypes.filter(flatType =>
        this.defaultObjectTypes().houseTypes.find(
          defaultType => defaultType === flatType.value
        )
      );
    } else {
      this.houseTypesConstants = this.constants().houseTypes;
    }
  }

  loadDropdownConstants() {
    const defaultObjectTypes = this.defaultObjectTypes();
    this.loadGarageTypesConstants(defaultObjectTypes?.garageTypes?.length > 0);
    this.loadObjectTypeConstants(defaultObjectTypes?.objectTypes?.length > 0);
    this.loadPropertyTypesConstants(
      defaultObjectTypes?.propertyTypes?.length > 0
    );
    this.loadFlatTypesConstants(defaultObjectTypes?.flatTypes?.length > 0);
    this.loadHouseTypesConstants(defaultObjectTypes?.houseTypes?.length > 0);
  }

  checkCommercialConstants() {
    if (this.defaultObjectTypes()?.commercialType) {
      this.commercialTypesConstants = this.constants()?.commercialTypes.filter(
        item => item.value === this.defaultObjectTypes()?.commercialType
      );
    } else {
      this.commercialTypesConstants = this.constants()?.commercialTypes;
    }
  }

  public ngOnInit() {
    this.loadDropdownConstants();
    this.checkCommercialConstants();

    if (this.commercialTypeForm.value) {
      this.updateCommercialSubTypes(this.commercialTypeForm.value);
    }

    this.commercialTypeForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(commercialType => {
        this.commercialSubTypesForm.reset();
        this.updateCommercialSubTypes(commercialType);
      });

    this.propertyTypeControl.valueChanges
      .pipe(
        startWith(this.form().get('propertyType').value),
        untilDestroyed(this)
      )
      .subscribe((type: PropertyType) => {
        // When a default commercialType is set use this one.
        const defaultObjectTypes = this.defaultObjectTypes();
        if (defaultObjectTypes?.commercialType) {
          this.commercialTypeForm.patchValue(defaultObjectTypes.commercialType);
        }

        this.minUpperBoundRent =
          this.searchProfileService.getMinUpperBoundRent(type);

        this.maxUpperBoundRent =
          this.searchProfileService.getMaxUpperBoundRent(type);

        this.stepsForUpperBoundRent =
          this.searchProfileService.getStepsForUpperBoundRent(type);
      });

    this.searchProfileService
      .listenAndApplyDefaultFormChanges(this.form())
      .pipe(untilDestroyed(this))
      .subscribe();

    if (!this.isNewSearchProfile) {
      this.form().patchValue(
        this.searchProfileService.patchForm(
          this.searchProfile(),
          this.districtsFormArray
        )
      );
    } else {
      this.radiusControl.patchValue(this.isFlat ? 2000 : 200);
    }

    if (this.isProjectSearchProfile) {
      this.form().get('projectId').patchValue(this.project()?.id);
      this.searchProfileTypeControl.patchValue(SearchProfileType.PROJECT);
      this.searchProfileService.disableNonProjectRelatedFormFields(this.form());
    }
  }

  public updateCommercialSubTypes(commercialType: string) {
    this.commercialSubTypes = this.constants()?.commercialSubTypes?.reduce(
      (array: NameValue<string>[], subType) => {
        const modifiedSubType =
          subType.value.commercialType === commercialType
            ? [{ value: subType.value.commercialSubType, name: subType.name }]
            : [];

        return [...array, ...modifiedSubType];
      },
      []
    );

    // If there are default commercialSubTypes, then only use them
    if (this.defaultObjectTypes()?.commercialSubTypes?.length > 0) {
      this.commercialSubTypes = this.commercialSubTypes.filter(
        commercialSubType =>
          this.defaultObjectTypes().commercialSubTypes.find(
            defaultType => defaultType === commercialSubType.value
          )
      );
    }
  }

  public addCityGroup() {
    this.districtsFormArray.push(this.searchProfileService.districtsFormGroup);
  }

  public displayRoomsLabel(value: number) {
    return value >= this.upperBoundRoomsMax ? `${value}+` : `${value}`;
  }

  public displaySizeLabel(value: number) {
    return value >= this.upperBoundSizeMax ? `${value}+` : `${value}`;
  }

  /**
   * When the user stops dragging and leaves the nobs with the cursor,
   * the number bubbles should be hidden
   * @param event
   */
  dragEnd(event: MatSliderDragEvent) {
    event.source._isFocused = false;
  }
}
