import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  input,
  output
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PortalCredential } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { PORTALS_CONFIG } from '../../tokens';
import { PortalCredentialComponent } from '../portal-credential/portal-credential.component';
import { ButtonComponent } from '../../../components/atoms/button';

@Component({
  selector: 'app-portal-credential-list',
  templateUrl: './portal-credential-list.component.html',
  styleUrls: ['./portal-credential-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortalCredentialListComponent),
      multi: true
    }
  ],
  imports: [PortalCredentialComponent, TranslateModule, ButtonComponent]
})
export class PortalCredentialListComponent implements ControlValueAccessor {
  private portalsConfig = inject(PORTALS_CONFIG);

  readonly credentials = input<PortalCredential[]>([]);

  /**
   * temporary - for setting deselectables
   */
  readonly existingCredentials = input<string[]>([]);

  readonly allowRemove = input(false);
  readonly allowSelect = input(false);
  readonly allowAdd = input(true);
  readonly disabled = input(false);

  /**
   * temporary - for ReactiveForms use cases
   */
  readonly forceDisabled = input(false);

  readonly add = output();
  readonly remove = output<PortalCredential>();

  public value: PortalCredential[] = [];

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public exists(credential: PortalCredential) {
    return this.existingCredentials().some(id => id === credential.id);
  }

  public isSelected(credential: PortalCredential) {
    return this.value.some(c => c.id === credential.id);
  }

  public onToggle(credential: PortalCredential) {
    if (this.isSelected(credential)) {
      this.value = this.value.filter(c => c.id !== credential.id);
    } else {
      this.value = [...this.value, credential];
    }

    this.onChange(this.value);
    this.onTouch();
  }

  public onAddPortal() {
    this.add.emit();
  }

  public onRemovePortal(credential: PortalCredential) {
    this.remove.emit(credential);
  }

  public getConfig(credential: PortalCredential) {
    return (
      this.portalsConfig.find(
        config => config.portalId === credential.portal
      ) || {}
    );
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: PortalCredential[]) {
    this.value = value;
  }
}
