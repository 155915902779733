import { Component, input, output } from '@angular/core';

import {
  trigger,
  state,
  animate,
  transition,
  style
} from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { SocialsComponent } from '../footer/socials/socials.component';

export type FooterDisplayMode = 'simple' | 'advanced';
export type FooterLinkAppearance = 'default' | 'button';

export interface FooterLinkV2 {
  url: string;
  name: string;
  appearance?: FooterLinkAppearance;
  hideOnMobile?: boolean;
}

@Component({
  selector: 'app-footer-v2',
  templateUrl: './footer-v2.component.html',
  styleUrls: ['./footer-v2.component.scss'],
  animations: [
    trigger('toggleState', [
      state('true', style({ maxHeight: '300px' })),
      state('false', style({ maxHeight: 0, display: 'none' })),
      transition('* => *', animate('300ms ease-in-out'))
    ])
  ],
  imports: [SocialsComponent, TranslateModule]
})
export class FooterV2Component {
  readonly currentYear = input(new Date().getFullYear());
  readonly links = input<FooterLinkV2[]>([]);

  readonly languageChange = output<string>();

  public showLinks = true;

  public toggleLinks() {
    this.showLinks = !this.showLinks;
  }

  public onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }
}
