import { Component, input, model } from '@angular/core';

import { IconTypeEnum } from '@ui/shared/models';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImageComponent } from '../../../atoms/image/image.component';
import { CheckComponent } from '../../../legacy/form/controls/check/check.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { SelectionCardDefaultConfig } from './selection-card.model';

@Component({
  selector: 'app-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss'],
  imports: [CardComponent, CheckComponent, FormsModule, NgClass, ImageComponent]
})
export class SelectionCardComponent {
  readonly imageUrl = input(SelectionCardDefaultConfig.imageUrl);
  readonly title = input(SelectionCardDefaultConfig.title);
  readonly subTitle = input(SelectionCardDefaultConfig.subTitle);
  readonly icon = input<IconTypeEnum | ''>(undefined);
  readonly selected = model(SelectionCardDefaultConfig.selected); // you can control the initial display
  readonly selectable = input(SelectionCardDefaultConfig.selectable); // controls display of check

  get imageUrlWithFallback(): string {
    return this.imageUrl() || '/assets/images/object-image-placeholder.svg';
  }

  public toggleSelection(): void {
    if (this.selectable()) {
      this.selected.update(selected => !selected);
    }
  }
}
