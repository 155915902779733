import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  input,
  output,
  viewChild,
  contentChildren
} from '@angular/core';

import {
  Location,
  NgTemplateOutlet,
  PopStateEvent as LocationEvent,
  ViewportScroller
} from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subject, SubscriptionLike } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../../atoms/button/button.component';
import { ElevationDirective } from '../../../directives';

import {
  BodyModifierClass,
  BodyService
} from '../../../infrastructure/browser/body';
import { WizardStepDirective } from './wizard-step/wizard-step.directive';
import { Step, StepChange } from './models';

import { WizardProgressComponent } from './wizard-progress/wizard-progress.component';

@UntilDestroy()
@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    WizardProgressComponent,
    NgTemplateOutlet,
    ButtonComponent,
    TranslateModule,
    ElevationDirective
  ]
})
export class WizardComponent implements OnDestroy, OnInit, AfterViewInit {
  private location = inject(Location);
  private router = inject(Router);
  private viewportScroller = inject(ViewportScroller);
  private bodyService = inject(BodyService);

  readonly currentForm = input<FormGroup>(undefined);
  readonly steps = input<Step[]>(undefined);
  readonly currentStepNumber = input<number>(undefined);
  readonly currentStepValid = input<boolean>(undefined);
  readonly allowCancel = input(false);
  readonly allowCancelInAnyStep = input(false);
  readonly allowBack = input(true);
  readonly isProcessing = input(false);
  readonly hideButtonContainer = input(false);
  readonly wrapStepContainer = input(false);
  readonly hideNextButton = input(false);
  readonly floatingTop = input(true);
  readonly floatingActions = input(true);
  readonly formElement = input<HTMLFormElement>(undefined);
  readonly alwaysEnableButton = input(false);
  readonly failedContinue = output();
  readonly wizardScrollAnchor = viewChild<ElementRef>('wizardScrollAnchor');

  public viewLoaded = false;

  readonly stepTemplates = contentChildren(WizardStepDirective, {
    read: TemplateRef
  });

  public stepChange: Subject<StepChange>;
  private locationSubscription: SubscriptionLike;

  /**
   * We are passing currentForm from edit-property.component.ts from LL app
   * as a work around for the ExpressionChangedAfterItHasBeenCheckedError.
   * this.currentForm.valid is true, After create property
   * this.currentForm.valid is false.
   */
  get isValid() {
    const currentForm = this.currentForm();
    return (currentForm && currentForm.valid) || this.currentStepValid();
  }

  ngOnInit() {
    this.stepChange = new Subject<StepChange>();

    this.locationSubscription = this.location.subscribe(location =>
      this.handleLocationChange(location)
    );

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => this.viewportScroller.scrollToPosition([0, 0]));

    this.bodyService.setBodyModifierClass(
      BodyModifierClass.WIZARD_ACTIONS_VISIBLE
    );
  }

  ngAfterViewInit() {
    // We wait for the view to be fully loaded before showing the wizard__actions-container,
    // otherwise edit.property.component will always be scrolled to bottom on init
    this.viewLoaded = true;
  }

  ngOnDestroy() {
    this.stepChange?.complete();
    this.locationSubscription?.unsubscribe();
    this.bodyService.unsetBodyModifierClass(
      BodyModifierClass.WIZARD_ACTIONS_VISIBLE
    );
  }

  next() {
    if (!this.isValid) {
      this.formElement()?.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
      this.currentForm().markAllAsTouched();
      this.failedContinue.emit();
    } else {
      this.stepChange.next({ action: 'next' });
      // TODO: check if this causes the invalid jump error
      this.viewportScroller.scrollToPosition([0, 0]);
      (
        this.wizardScrollAnchor().nativeElement as HTMLDivElement
      ).scrollIntoView();
    }
  }

  back() {
    if (!this.allowBack()) return;
    this.stepChange.next({ action: 'back' });
    (
      this.wizardScrollAnchor().nativeElement as HTMLDivElement
    ).scrollIntoView();
  }

  select(step: Step) {
    if (!step.selectable) return;
    this.stepChange.next({ action: 'select', step });
    (
      this.wizardScrollAnchor().nativeElement as HTMLDivElement
    ).scrollIntoView();
  }

  complete() {
    if (!this.isValid) {
      this.formElement().dispatchEvent(
        new Event('submit', { cancelable: true })
      );
      this.currentForm().markAllAsTouched();
      this.failedContinue.emit();
    } else {
      this.stepChange.next({ action: 'complete' });
      (
        this.wizardScrollAnchor().nativeElement as HTMLDivElement
      ).scrollIntoView();
    }
  }

  cancel() {
    if (!this.allowCancel()) return;
    this.stepChange.next({ action: 'cancel' });
    (
      this.wizardScrollAnchor().nativeElement as HTMLDivElement
    ).scrollIntoView();
  }

  private handleLocationChange(location: LocationEvent) {
    const stepName = location.url.split('/').pop();
    const isBeforeCurrent =
      this.steps().findIndex(step => step.name === stepName) <
      this.currentStepNumber() - 1;
    const isAfterCurrent =
      this.steps()[this.currentStepNumber()] &&
      this.steps()[this.currentStepNumber()].name === stepName;
    this.viewportScroller.scrollToPosition([0, 0]);

    if (isBeforeCurrent) {
      this.back();
    }

    if (isAfterCurrent) {
      this.next();
    }
  }
}
