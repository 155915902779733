import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.scss'],
  imports: [CardComponent, TranslateModule]
})
export class InformationBoxComponent {
  readonly title = input('');
}
