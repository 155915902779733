import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  input,
  output,
  viewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter, take } from 'rxjs/operators';

import {
  AgentsListUser,
  CustomerSettings,
  Pagination,
  Property
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { FullNamePipe } from '../../../../../pipes/full-name.pipe';
import { ImageSizePipe } from '../../../../../pipes/image-size.pipe';
import { ChatContactItemComponent } from '../chat-contact-item/chat-contact-item.component';
import { AppInputDirective } from '../../../form/controls/input/input.directive';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';
import { AvatarComponent } from '../../../avatar/avatar.component';
import { DropdownMultiselectComponent } from '../../../form/controls/dropdown-multiselect/dropdown-multiselect.component';
import { ButtonComponent } from '../../../../atoms/button/button.component';
import { MessengerFilterService } from '../../services/messenger-filter.service';

import { Conversation, ConversationDetails } from '../../model';
import { ActionState } from '../../../../../state-utils/action-state';
import { LocalStorageService } from '../../../../../infrastructure/storage/local-storage.service';
import { INFRASTRUCTURE_CONFIG } from '../../../../../infrastructure/infrastructure-config.token';

@UntilDestroy()
@Component({
  selector: 'app-chat-contact-list',
  templateUrl: './chat-contact-list.component.html',
  styleUrls: ['./chat-contact-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    DropdownMultiselectComponent,
    AvatarComponent,
    FormFieldComponent,
    AppInputDirective,
    NgbTooltip,
    NgScrollbar,
    ChatContactItemComponent,
    TranslateModule,
    ImageSizePipe,
    FullNamePipe
  ]
})
export class ChatContactListComponent implements OnInit {
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private messengerFilterService = inject(MessengerFilterService);
  storageService = inject(LocalStorageService);
  private config = inject(INFRASTRUCTURE_CONFIG);
  readonly conversationList = input<Conversation[]>(undefined);
  readonly conversationListPage = input<Pagination>(undefined);
  readonly contactListActionState = input<ActionState>(undefined);
  readonly messagesActionState = input<ActionState>(undefined);
  readonly isLandlord = input<boolean>(undefined);
  readonly isPropertySearcher = input<boolean>(undefined);
  readonly isAgent = input<boolean>(undefined);
  readonly totalMessageCount = input<number>(undefined);
  readonly activeConversation = input<Conversation>(undefined);
  readonly conversationDetails = input<ConversationDetails>(undefined);
  readonly agentsFilter = input<AgentsListUser[]>([]);
  readonly customerSettings = input<CustomerSettings>(undefined);
  readonly loadArchivedConversationsToggle = input<boolean>(undefined);
  readonly searchedProperty = input<Property>(undefined);
  readonly selectConversation = output<Conversation>();
  readonly reloadMessenger = output();
  readonly openChatSettingsModal = output();
  readonly addConversation = output();
  readonly archivedConversations = output();
  readonly activeConversations = output();
  readonly removePropertyFilter = output();
  readonly contacts = viewChild<ElementRef>('contacts');

  public searchForm: FormGroup;
  public propertyId: string;
  public searched: boolean;
  public conversationExists: string;

  get agentsControl() {
    return this.searchForm.get('agents');
  }

  get canFilterAgents() {
    return (
      !this.customerSettings()?.conversationConfig
        ?.agentsAccessOnlyOwnConversations || !this.isAgent()
    );
  }

  get searchControl() {
    return this.searchForm.get('search');
  }

  public ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: [''],
      agents: [[]]
    });

    this.messengerFilterService
      .getFilter()
      .pipe(
        filter(data => data && data.agents && data.agents.length > 0),
        take(1)
      )
      .subscribe(data => {
        this.agentsControl.patchValue(data.agents);
      });

    this.agentsControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.messengerFilterService.updateFilter({ agents: value });
      });

    this.messengerFilterService
      .getFilter()
      .pipe(
        filter(value => !!value),
        untilDestroyed(this)
      )
      .subscribe(messengerFilter => {
        this.propertyId = messengerFilter.propertyId || null;
        this.conversationExists = messengerFilter.conversationExists;
      });

    const activeConversation = this.activeConversation();
    if (this.conversationExists === 'true' && activeConversation) {
      const applicantName = `${activeConversation.mainConversationPartner.firstname} ${activeConversation.mainConversationPartner.name}`;
      const propertyName = `${activeConversation.subject}`;
      const searchData = `${applicantName} - ${propertyName}`;

      this.searchControl.patchValue(searchData, { emitEvent: false });
      this.searched = true;
    }
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
      if (queryParams.agentId) {
        this.agentsControl.patchValue([queryParams.agentId]);
      }
    });
  }

  public onSelectConversation(conversation: Conversation) {
    this.selectConversation.emit(conversation);
  }

  public onReloadMessenger() {
    this.reloadMessenger.emit();
  }

  public onRemovePropertyFilter() {
    this.messengerFilterService.setPropertyId(null);
    this.removePropertyFilter.emit();
  }

  public onOpenSettings() {
    this.openChatSettingsModal.emit();
  }

  public onAddConversation() {
    this.addConversation.emit();
  }

  public onLoadMore() {
    if (!this.contactListActionState().pending) {
      this.messengerFilterService.setPage(this.messengerFilterService.page + 1);
    }
  }

  public onSearch() {
    this.searched = true;
    this.messengerFilterService.updateFilter({
      search: this.searchControl.value,
      page: 0
    });
  }

  public revertSearch() {
    this.searched = false;
    this.messengerFilterService.updateFilter({
      search: '',
      page: 0,
      conversationExists: undefined
    });
    this.searchControl.patchValue('');
  }

  public loadArchivedConversations() {
    this.archivedConversations.emit();
  }

  public loadActiveConversations() {
    this.activeConversations.emit();
  }
}
