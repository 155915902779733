import { Component, forwardRef, OnInit, inject, input } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Appointment } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import { RadioButtonComponent } from '../../form/controls/radio/radio-button/radio-button.component';
import { DateTimePipe } from '../../../../pipes/date-time.pipe';
import { RadioGroupComponent } from '../../form/controls/radio/radio-group/radio-group.component';

@UntilDestroy()
@Component({
  selector: 'app-appointment-selection',
  templateUrl: './appointment-selection.component.html',
  styleUrls: ['./appointment-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppointmentSelectionComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RadioGroupComponent,
    NgClass,
    RadioButtonComponent,
    SvgIconComponent,
    TranslateModule,
    DateTimePipe
  ]
})
export class AppointmentSelectionComponent
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  readonly appointments = input<Appointment[]>(undefined);
  readonly applicationStatus = input<string>(undefined);
  readonly exclusiveAppointments = input<Appointment[]>(undefined);
  readonly showNotFittingOption = input(true);
  public form: FormGroup;
  public value: string;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  get numberOfAppointments() {
    return (
      (this.appointments()?.length || 0) +
      (this.exclusiveAppointments()?.length || 0)
    );
  }

  get hasAppointments() {
    return (
      this.appointments()?.length > 0 ||
      this.exclusiveAppointments()?.length > 0
    );
  }

  get nextActiveAppointmentIdControl() {
    return this.form.get('newActiveAppointmentId');
  }

  ngOnInit() {
    this.form = this.fb.group({
      newActiveAppointmentId: ''
    });
    this.nextActiveAppointmentIdControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(v => {
        this.value = v;
        this.onChange(this.value);
        this.onTouch();
      });
  }

  public getAppointmentCanceled(appointment: Appointment) {
    return appointment.state === 'CANCELED';
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(v: any) {
    this.value = v;
    this.nextActiveAppointmentIdControl.patchValue(v);
  }
}
