import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../atoms/card/card.component';

@Component({
  selector: 'app-mobile-id-tutorial',
  templateUrl: './mobile-id-tutorial.component.html',
  styleUrls: ['./mobile-id-tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CardComponent, TranslateModule]
})
export class MobileIdTutorialComponent {}
