<app-auto-complete-field
  [selector$]="locationSearchSelector$.asObservable()"
  [inputWatcher]="getLocationSearchControlObservable"
  [loading]="loading"
  (onInputChange)="onNewInput()"
  (onSelect)="onSelect($event)"
  (focusout)="onFocusOut()"
  [propertyName]="'name'"
>
  <input
    appInput
    type="text"
    autocomplete="none"
    [disabled]="disabled"
    [required]="required()"
    [placeholder]="placeholder() | translate"
    [(ngModel)]="value"
    class="form-control"
  />

  <ng-template let-item>
    {{ locationSearch.getLocationLabel(item.properties, true) }}
  </ng-template>
</app-auto-complete-field>
