import { Directive, inject } from '@angular/core';
import { shareReplay } from 'rxjs';
import { FormGroupDirective } from '@angular/forms';

/**
 {@link FormSubmitDirective} is used to create an observable (submit$) from form element submit event listener.
 The directive is being injected inside {@link FormControlErrorsDirective}
 it works as follows:
 1. import FormSubmitDirective into the standalone component / the module which declaring the component
 2. add appFormSubmit directive to form element
 Note:
 1. {@link FormControlErrorsDirective} has to be imported in the same component/module in order for {@link FormSubmitDirective} to work
 2. you have to assign type="submit" to the button which should trigger the validation inside form element
 @example:
  <form appFormSubmit>
  <button type="submit">Submit Button</button>
  </form>
 */

@Directive({
  selector: 'form[appFormSubmit]'
})
export class FormSubmitDirective {
  private formGroupDirective = inject(FormGroupDirective);
  public submit$ = this.formGroupDirective.ngSubmit.pipe(shareReplay(1));
}
