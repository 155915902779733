import { Component, input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  imports: [NgbTooltip]
})
export class HintComponent {
  readonly placement = input('top');
  readonly container = input<string>(undefined);
}
