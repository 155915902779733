import moment from 'moment';

export const addDate = (amount: moment.DurationInputArg1, unit: string) =>
  moment()
    .add(amount, unit.toLowerCase() as moment.DurationInputArg2)
    .format('YYYY-MM-DD');

export const dateDiffFromNow = (
  date: Date,
  startUnit: moment.unitOfTime.StartOf,
  unit: moment.unitOfTime.Diff
) => moment(date).startOf(startUnit).diff(moment().startOf(startUnit), unit);
