import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  OnInit,
  input,
  output
} from '@angular/core';

import { combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  BaseNavigationItem as NavigationItem,
  NavigationItemStateEnum
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BadgeComponent } from '../../../../atoms/badge/badge.component';
import { NavigationDrawerListComponent } from '../navigation-drawer-list/navigation-drawer-list.component';
import { innerFadeAnimation } from '../../../../../utils';
import { NavigationService } from '../../navigation.service';
import { NavigationDrawerService } from '../navigation-drawer.service';
import { BadgeColorEnum } from '../../../../atoms/badge';

@UntilDestroy()
@Component({
  selector: 'app-navigation-drawer-item',
  templateUrl: './navigation-drawer-item.component.html',
  styleUrls: ['./navigation-drawer-item.component.scss'],
  animations: [innerFadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLinkActive,
    RouterLink,
    NgTemplateOutlet,
    // forwardRef is added to solve cyclic dependency
    forwardRef(() => NavigationDrawerListComponent),
    BadgeComponent,
    AsyncPipe,
    DecimalPipe,
    TranslateModule
  ]
})
export class NavigationDrawerItemComponent implements OnInit {
  navigation = inject(NavigationService);
  navigationDrawer = inject(NavigationDrawerService);

  readonly item = input<NavigationItem>(undefined);
  readonly narrow = input<boolean>(undefined);
  readonly noTextWrap = input<boolean>(undefined);
  readonly clickEvent = output();

  public navigationItemState = NavigationItemStateEnum;

  private _hoverTimeout: ReturnType<typeof setTimeout>;
  private _overlay: boolean;
  private _openFloatingSubMenu: boolean;
  private _floatingSubMenuClosingDelay = 300; // in ms; recommended: 300-1000
  private _floatingSubMenuOpeningDelay = 300; // in ms
  private _isParentOfLoadedFloatingSubMenu: boolean;
  private _isFloatingSubMenuItem: boolean;

  public get isParentOfOpenFloatingSubMenu$() {
    return combineLatest(
      [
        this.navigation.openFloatingSubMenu$,
        this.navigation.floatingSubMenuParentItem$
      ],
      (openFloatingSubMenu, floatingSubMenuParentItem) => {
        return (
          openFloatingSubMenu &&
          this.areItemsEqual(floatingSubMenuParentItem, this.item())
        );
      }
    );
  }

  public ngOnInit() {
    combineLatest([this.navigation.overlay$, this.navigation.openInMobileView$])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([overlay, openInMobileView]) =>
          (this._overlay = overlay || openInMobileView)
      );

    this.navigation.openFloatingSubMenu$
      .pipe(untilDestroyed(this))
      .subscribe(open => (this._openFloatingSubMenu = open));

    this.navigation.floatingSubMenuParentItem$
      .pipe(untilDestroyed(this))
      .subscribe(
        floatingSubMenuParentItem =>
          (this._isParentOfLoadedFloatingSubMenu = this.areItemsEqual(
            floatingSubMenuParentItem,
            this.item()
          ))
      );

    this.navigationDrawer.isFloatingSubMenu$
      .pipe(untilDestroyed(this))
      .subscribe(
        isFloatingSubMenu => (this._isFloatingSubMenuItem = isFloatingSubMenu)
      );
  }

  public areItemsEqual(item1: NavigationItem, item2: NavigationItem): boolean {
    return item1?.name === item2.name;
  }

  public hasNotificationCount(notificationCount: number): boolean {
    return !isNaN(notificationCount);
  }

  public hasChildren(item: NavigationItem): boolean {
    return !!(item?.children && item?.children?.length);
  }

  public isLink(item: NavigationItem): boolean {
    return this.isRouterLink(item) || !!item.redirectLink;
  }

  public isRouterLink(item: NavigationItem): boolean {
    return item.link && !item.redirectLink;
  }

  public getNotificationCountBackgroundColor(
    notificationCount: number,
    dark: boolean
  ) {
    return notificationCount > 0
      ? BadgeColorEnum.PRIMARY_ACCENT
      : dark
        ? BadgeColorEnum.SECONDARY_TEXT
        : BadgeColorEnum.NEUTRAL;
  }

  private updateFloatingSubMenu(item: NavigationItem): void {
    if (!this.narrow() || this._isFloatingSubMenuItem) return;

    if (this.hasChildren(item)) {
      this.navigation.setFloatingSubMenuParentItem(item);
      this.navigation.openFloatingSubMenu();
    } else {
      this.navigation.closeFloatingSubMenu();
    }
  }

  public onMouseEnter(item: NavigationItem): void {
    if (this._isFloatingSubMenuItem) return;

    if (this._openFloatingSubMenu) {
      if (!this.hasChildren(item)) {
        // => _isParentOfLoadedFloatingSubMenu === false
        // load 'empty' sub menus delayed to prevent menu closing on crossing other main menu items unintentionally:
        this._hoverTimeout = setTimeout(() => {
          this.updateFloatingSubMenu(item);
        }, this._floatingSubMenuClosingDelay);
      } else if (!this._isParentOfLoadedFloatingSubMenu) {
        // load new sub menu immediately if container is already open:
        this.updateFloatingSubMenu(item);
      }
    } else {
      // load new sub menu with short delay if container is currently closed to prevent unintentional sub menu flicks:
      this._hoverTimeout = setTimeout(() => {
        this.updateFloatingSubMenu(item);
      }, this._floatingSubMenuOpeningDelay);
    }
  }

  public onMouseLeave(): void {
    if (this._hoverTimeout) clearTimeout(this._hoverTimeout);
  }

  public onClick(item: NavigationItem) {
    this.clickEvent.emit();

    if (this.isLink(item) || this.hasChildren(item)) {
      const narrow = this.narrow();
      if (narrow) this.updateFloatingSubMenu(item);

      if (this._overlay && !narrow) {
        // always close the whole navigation when links or parent items have been clicked:
        this.navigation.close();
      } else if (!this.hasChildren(item)) {
        this.navigation.closeFloatingSubMenu();
      }
    }
  }
}
