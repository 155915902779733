import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appTextSearch'
})
export class TextSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return items;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return items.filter(item => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return item.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
    });
  }
}
