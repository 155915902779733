import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Address, Coordinates } from '@ui/shared/models';
import { GeoService } from '../../services/geo.service';
import { WINDOW_REF } from './window-ref.token';

export const GEOLOCATION_ERRORS = {
  noSupport: 'general.geolocation.no_support_error',
  fetchingError: 'general.geolocation.fetch_error',
  timeout: 'general.geolocation.took_too_long_error'
};

@UntilDestroy()
@Injectable()
export class NavigatorService {
  private windowRef = inject(WINDOW_REF);
  private geoService = inject(GeoService);

  public locationError: string = null;

  public getCurrentLocation() {
    return this.webLocation();
  }

  private webLocation() {
    const subject = new Subject<Coordinates>();

    if (!this.windowRef.navigator.geolocation) {
      subject.error(GEOLOCATION_ERRORS.noSupport);

      return subject;
    }

    this.windowRef.navigator.geolocation.getCurrentPosition(
      position =>
        subject.next({
          lat: position.coords.latitude,
          lon: position.coords.longitude
        } as Coordinates),
      error => {
        // error.code is returned when navigator.geolocation.getCurrentPosition() returns timeout (code === 3)
        const err =
          error.code && error.code === 3
            ? GEOLOCATION_ERRORS.timeout
            : error.message;
        subject.error(err);
      },
      { timeout: 20000 }
    );

    return subject;
  }

  public onUseCurrentLocation(form: FormGroup) {
    this.locationError = null;
    this.getCurrentLocation()
      .pipe(
        switchMap(coords =>
          this.geoService
            .getReversedGeocoding(coords)
            .pipe(
              catchError(
                () => (this.locationError = GEOLOCATION_ERRORS.fetchingError)
              )
            )
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        catchError(error => (this.locationError = error)),
        untilDestroyed(this)
      )
      .subscribe((address: Address) =>
        form.patchValue({ ...address, country: 'DE' })
      );
  }
}
