<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body rejection-dialog__body">
        <p class="title-m rejection-dialog__title">
          {{ titleMessage() | translate }}
        </p>
        <p class="default-s rejection-dialog__message">
          {{ message() | translate }}
        </p>
      </div>
    </app-modal-content>

    <!-- <div class="rejection_reason">
      <textarea
        appInput
        [required]="isReasonRequired"
        [placeholder]="'REJECTION_REASON_L' | translate"
        [(ngModel)]="reason"
        class="form-control">
      </textarea>
    </div> -->

    <app-modal-footer>
      <div class="modal-footer rejection-dialog__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="cancelAction()">{{
          cancelButtonMessage() | translate
        }}</app-button>
        <app-button
          [disabled]="isReasonRequired && !reason"
          (clickEvent)="okAction()"
          >{{ okButtonMessage() | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
