import {
  BaseNavigationItem as NavigationItem,
  IconTypeEnum,
  NavigationItemStateEnum
} from '@ui/shared/models';
import { NAVIGATION_LINK } from './navigation-links.config';

export const navigationConfig: NavigationItem[] = [
  {
    name: 'tables',
    label: 'navigation.tables_l',
    link: NAVIGATION_LINK.TABLES,
    icon: IconTypeEnum.List
  },
  {
    name: 'download-invoices',
    label: 'navigation.download_invoices_l',
    link: NAVIGATION_LINK.MANAGE_DOWNLOAD_INVOICES,
    icon: IconTypeEnum.Download
  }
];

export const profileSettingsNavigationConfig: NavigationItem[] = [
  {
    name: 'logout',
    label: 'general.logout_a',
    link: NAVIGATION_LINK.LOGOUT,
    icon: IconTypeEnum.LockClosed,
    state: NavigationItemStateEnum.WARNING
  }
];
