import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  input,
  output
} from '@angular/core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PortalConfig, PortalCredential, PortalType } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { CardComponent } from '../../../components/atoms/card';
import { CheckComponent } from '../../../components/legacy/form/controls/check/check.component';
import { ButtonComponent } from '../../../components/atoms/button';

@Component({
  selector: 'app-portal-credential',
  templateUrl: './portal-credential.component.html',
  styleUrls: ['./portal-credential.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    TranslateModule,
    CardComponent,
    CheckComponent,
    ButtonComponent
  ]
})
export class PortalCredentialComponent implements OnInit {
  readonly credential = input<PortalCredential>(undefined);
  readonly config = input<PortalConfig>(undefined);
  readonly selected = input(false);

  // behaviour control inputs
  readonly disabled = input(false);
  readonly selectable = input(true);
  readonly removable = input(false);
  // temporary
  readonly deselectable = input(true);

  readonly togglePortal = output<PortalCredential>();
  readonly remove = output<PortalCredential>();

  public checkControl = new FormControl({
    value: this.selected(),
    disabled: this.isDisabled
  });

  public get isImmoscout() {
    return this.credential()?.portal === PortalType.IMMOBILIENSCOUT24_DE;
  }

  public ngOnInit() {
    this.checkControl.patchValue(this.selected());

    this.checkControl.valueChanges.subscribe(() =>
      this.togglePortal.emit(this.credential())
    );
  }

  public onRemove() {
    this.remove.emit(this.credential());
  }

  public onBoxClick() {
    if (this.isDisabled) return;
    this.checkControl.patchValue(!this.checkControl.value);
  }

  public get isDisabled() {
    return (
      this.disabled() ||
      !this.selectable() ||
      (this.selected() && !this.deselectable())
    );
  }
}
