import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { map, tap } from 'rxjs/operators';
import { FetchConstants, getConstantsLoaded } from '../base-state/constants';

@Injectable()
export class ConstantsGuard {
  private store = inject(Store);

  canActivate() {
    return this.store.select(getConstantsLoaded).pipe(
      tap(loaded => {
        if (!loaded) this.store.dispatch(new FetchConstants());
      }),
      map(() => true)
    );
  }
}
