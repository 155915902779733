<form [formGroup]="form" class="custom-questions__container">
  <div formArrayName="customQuestions" class="custom-questions__list card">
    @for (
      customQuestion of customQuestionsForm.controls;
      track customQuestion;
      let i = $index
    ) {
      <div class="custom-questions__item mb16">
        <div class="custom-questions__item-content" [formGroupName]="i">
          <app-custom-questions-field
            formControlName="answer"
            [customQuestion]="customQuestions()[i]"
            (ngModelChange)="addSelectedRangeOption(i)"
          ></app-custom-questions-field>
          @if (SelectedAnswerIsDesired(i)) {
            <div class="custom-question-field__range-content">
              @if (isRangeDate(i)) {
                <app-form-field>
                  <app-date formControlName="selectedRange"> </app-date>
                </app-form-field>
              }
              @if (isRangeValue(i)) {
                <app-form-field>
                  <input
                    type="number"
                    appInput
                    [placeholder]="'custom_questions.type_answer_l' | translate"
                    formControlName="selectedRange"
                    class="form-control"
                  />
                </app-form-field>
              }
            </div>
          }
          @if (showHint(i)) {
            <div>
              <app-form-field>
                <app-form-field-label>{{
                  customQuestions()[i].commentHint | translate
                }}</app-form-field-label>
                <textarea
                  appInput
                  count="true"
                  maxValue="1000"
                  formControlName="comment"
                  class="form-control"
                ></textarea>
              </app-form-field>
            </div>
          }
        </div>
      </div>
    }
  </div>
</form>
