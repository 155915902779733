<div
  class="slider"
  [ngClass]="{
    'slider--disabled': disabled,
    'slider--hide-active-value': hideActiveValue()
  }"
>
  <div class="slider__input-container">
    <input
      class="slider__input"
      type="range"
      [disabled]="disabled"
      [(ngModel)]="valueShownInUi"
      [max]="max()"
      [min]="min()"
      [step]="step()"
    />
    <div #sliderCurrentValue class="slider__current-value">
      {{ positiveValue | number }} {{ unit() }}
    </div>
    <div
      #sliderFallbackProgress
      class="slider__fallback-progress"
      [ngClass]="{ 'slider__fallback-progress--disabled': disabled }"
    ></div>
  </div>
  <div class="slider__label-container">
    <div
      class="slider__label-min"
      [ngClass]="{ 'slider__label-min--equals-current': currentValueEqualsMin }"
    >
      {{ min() | number }} {{ unitMin() || unit() }}
    </div>
    <div
      class="slider__label-max"
      [ngClass]="{ 'slider__label-max--equals-current': currentValueEqualsMax }"
    >
      {{ max() | number }} {{ unitMax() || unit() }}
    </div>
  </div>
</div>
