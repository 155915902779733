import { AddonType } from '../products';

export enum PortalType {
  WORDPRESS_PLUGIN = 'WORDPRESS_PLUGIN',
  EBAY = 'EBAY',
  IMMOBILIENSCOUT24_DE = 'IMMOBILIENSCOUT24_DE',
  IMMONET_DE = 'IMMONET_DE',
  IMMOWELT_DE = 'IMMOWELT_DE',
  IVD = 'IVD',
  IMMOBILIENSCOUT24_HM_DE = 'IMMOBILIENSCOUT24_HM_DE',
  IMMOBILIENSCOUT24_GC_DE = 'IMMOBILIENSCOUT24_GC_DE',
  RESIDENT_APP = 'RESIDENT_APP',
  WILLHABEN = 'WILLHABEN',
  IMMOBILIE1 = 'IMMOBILIE1'
}

export enum ImmomioPortalType {
  HOMEPAGE_MODULE = 'HOMEPAGE_MODULE'
}

export enum ImmoscoutTypes {
  PORTAL = 'portal',
  CUSTOM = 'custom',
  MODULE = 'module'
}

export interface PortalConfig {
  fullName: string;
  portalId: PortalType | ImmomioPortalType;
  imgUrl: string;
  logoUrl?: string; // in case of limited space we want to show square logo instead full length image
  skipValidation: boolean;
  iframeUrl?: string;
  passwordLabel?: string;
  useImmoscoutForm?: boolean;
  userNameLabel?: string;
  validationControl?: string;
  subType?: ImmoscoutTypes;
  addon?: AddonType;
  state?: string;
  name?: string;
}
