import {
  Component,
  ChangeDetectionStrategy,
  input,
  output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { Step } from '../models';

@Component({
  selector: 'app-wizard-progress',
  templateUrl: './wizard-progress.component.html',
  styleUrls: ['./wizard-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, TranslateModule]
})
export class WizardProgressComponent {
  readonly steps = input<Step[]>(undefined);
  readonly currentStepNumber = input<number>(undefined);
  readonly selectStep = output<Step>();

  public onSelectStep(step: Step) {
    return this.selectStep.emit(step);
  }
}
