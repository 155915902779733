import { Component, inject, Input, input, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { Attachment } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../../../../atoms/button/button.component';
import { HintComponent } from '../../../hint/hint.component';
import { AttachmentsComponent } from '../../../form/controls/attachment/attachments.component';
import { AppInputDirective } from '../../../form/controls/input/input.directive';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';

import { defaultDocumentsConfig } from '../../../../../config';
import { MessageTemplate, MessageTemplateSubstitution } from '../../model';
import { DownloadService } from '../../../../../infrastructure/download/download.service';

@Component({
  selector: 'app-message-template-form',
  templateUrl: './message-template-form.component.html',
  styleUrls: ['./message-template-form.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective,
    AttachmentsComponent,
    HintComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class MessageTemplateFormComponent {
  private fb = inject(FormBuilder);
  private downloadService = inject(DownloadService);

  public form: FormGroup = this.messageTemplateForm;

  public acceptedFileTypes = defaultDocumentsConfig.allAcceptedTypes;
  public documentsMaxSize = defaultDocumentsConfig.acceptedDocumentSize;

  @Input() set activeTemplate(template: MessageTemplate) {
    if (template) this.form.patchValue(template);
  }

  readonly conversationMessageTemplateSubstitutionTags =
    input<MessageTemplateSubstitution[]>(undefined);
  readonly saveTemplate = output<MessageTemplate>();

  public get contentControl() {
    return this.form.get('content');
  }

  public get attachmentControl() {
    return this.form.get('attachments');
  }

  public get idControl() {
    return this.form.get('id');
  }

  public savingTemplate() {
    this.saveTemplate.emit(this.form.value);
  }

  public addSubstitution(substitution: string) {
    let payload = `${String(this.contentControl.value)} ${substitution}`;
    if (this.contentControl?.value?.length === 0) {
      payload = substitution;
    }
    this.contentControl.patchValue(payload);
  }

  public previewAttachment(attachment: Attachment[]) {
    if (!attachment[0]?.url) return;
    this.downloadService.openPdfInNewTab(attachment[0]);
  }

  public downloadAttachment(file: Attachment[]) {
    if (!file[0]?.url) return;
    this.downloadService.downloadByUrl(file[0]);
  }

  private get messageTemplateForm() {
    return this.fb.group({
      id: null,
      title: ['', Validators.required],
      content: ['', Validators.required],
      attachments: null
    });
  }
}
