import { Component, inject, Input, input, output } from '@angular/core';

import { NgClass } from '@angular/common';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { BaseControl } from '../../base-control';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  imports: [NgClass]
})
export class RadioButtonComponent extends BaseControl<string> {
  private radioGroup = inject(RadioGroupComponent, { host: true });

  private _valueRadio = '';
  public currentValue: string = null;
  readonly switchLabelCheck = input(false);

  // Use this equality check when the radio group fromControlName is an object
  readonly equalityFieldName = input<string>(undefined);

  @Input()
  get value() {
    return this._valueRadio;
  }

  set value(value: any) {
    this._valueRadio = value;
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onSelect = output<{
    event: Event;
  }>();

  public check(event: MouseEvent) {
    event.preventDefault();
    if (this.isDisabled) return;
    this.radioGroup.writeValue(this.value);
    this.onSelect.emit({ event });
  }

  public get isChecked() {
    return (
      this.radioGroup.value === this._valueRadio ||
      this.radioGroup.value?.[this.equalityFieldName()] === this._valueRadio
    );
  }

  public get isDisabled() {
    return this.disabled || this.radioGroup.disabled;
  }
}
