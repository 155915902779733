import {
  ChangeDetectionStrategy,
  Component,
  inject,
  output
} from '@angular/core';

import { filter } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import {
  AppInputDirective,
  AutofocusDirective,
  ButtonComponent,
  customEmailValidator,
  FormFieldComponent,
  FormFieldLabelComponent
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    TranslateModule,
    AppInputDirective,
    AutofocusDirective,
    ButtonComponent
  ]
})
export class LoginFormComponent {
  private formBuilder = inject(FormBuilder);

  readonly login = output<{
    email: string;
    loginMethod?: string;
  }>();

  public loginForm = this.formBuilder.group({
    email: new FormControl(null as string, {
      validators: Validators.compose([
        Validators.required,
        customEmailValidator
      ]),
      updateOn: 'submit'
    })
  });

  public loginClick(loginMethod: string) {
    this.loginForm.statusChanges
      .pipe(
        filter(status => status === 'VALID'),
        untilDestroyed(this)
      )
      .subscribe(() =>
        this.login.emit({ ...this.loginForm.getRawValue(), loginMethod })
      );
  }
}
