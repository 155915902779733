import { Directive, inject, TemplateRef, input } from '@angular/core';

@Directive({
  selector: '[appSideSheetContent]'
})
export class SideSheetContentDirective {
  template = inject<TemplateRef<any>>(TemplateRef);

  readonly label = input<string>(undefined);
  readonly required = input<boolean>(undefined);
  readonly disabled = input<boolean>(undefined);

  // Used as reference for the output of currentFormIdChange in the app-side-sheet component
  readonly identifier = input<string>(undefined);
}
