import { Component, ViewEncapsulation, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../modal-footer/modal-footer.component';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-rejection-dialog',
  templateUrl: './rejection-dialog.component.html',
  styleUrls: ['./rejection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class RejectionDialogComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly message = input<string>(undefined);
  readonly titleMessage = input<string>(undefined);
  readonly okButtonMessage = input('REJECT_DIALOG_OK_A');
  readonly cancelButtonMessage = input('REJECT_DIALOG_CANCEL_A');

  public reason: string;
  public isReasonRequired = false;

  okAction() {
    if (this.isReasonRequired && !this.reason) return;

    this.ngbActiveModal.close(this.reason);
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }
}
