import { Directive, input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Step } from '../models';
import { WizardStep } from './wizard-step';

@Directive({})
export class BaseStep extends WizardStep {
  readonly form = input<FormGroup>(undefined);
  readonly formArray = input<FormArray>(undefined);
  readonly stepIndex = input<number>(undefined);
  readonly steps = input<Step[]>(undefined);

  nextStep() {
    const next = this.stepIndex() + 1;
    const stepName = this.steps()[next].name;
    this.onNextStep.emit(stepName);
  }

  public onSubmit() {
    const form = this.form();
    form?.valid ? this.nextStep() : form.markAllAsTouched();
  }

  public onCompleteStep() {
    const form = this.form();
    form?.valid ? this.complete() : form.markAllAsTouched();
  }
}
