import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnInit,
  input,
  output
} from '@angular/core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  District,
  PropertyType,
  SearchProfile,
  SearchProfileType
} from '@ui/shared/models';

import moment from 'moment';

import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, KeyValuePipe } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SortPipe } from '../../../../pipes/sort.pipe';
import { DateTimePipe } from '../../../../pipes/date-time.pipe';
import { AddressPipe } from '../../../../pipes/address.pipe';
import { ContextMenuItemComponent } from '../../../molecules/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../../molecules/context-menu/context-menu.component';
import { SlideToggleComponent } from '../../form/controls/slide-toggle/slide-toggle.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';
import { BoundsPipe } from '../../../../pipes';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isSalesProperty,
  isValueNullOrUndefined
} from '../../../../utils';

import { groupBy } from '../../../../utils/group-by';
import { BadgeColorEnum } from '../../../atoms/badge';

@Component({
  selector: 'app-search-profile-info',
  templateUrl: './search-profile-info.component.html',
  styleUrls: ['./search-profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BadgeComponent,
    SlideToggleComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SvgIconComponent,
    CurrencyPipe,
    KeyValuePipe,
    TranslateModule,
    AddressPipe,
    DateTimePipe,
    SortPipe,
    BoundsPipe
  ]
})
export class SearchProfileInfoComponent implements OnInit, OnChanges {
  readonly searchProfile = input<SearchProfile>(undefined);
  readonly small = input(false);
  readonly showToggleOption = input(true);
  readonly hideMenu = input(false);
  readonly showMarketingTypeBadge = input<boolean>(undefined);
  readonly editProfile = output<SearchProfile>();
  readonly deleteProfile = output<string>();
  readonly toggleProfile = output<string>();

  public isProfileActiveControl: FormControl;
  public districtsMap: Map<string, District[]>;
  public badgeColor = BadgeColorEnum;

  public get typeDistrict() {
    return SearchProfileType.DISTRICT;
  }

  public get typeRadius() {
    return SearchProfileType.RADIUS;
  }

  public get isProfileOutdated() {
    return moment().isAfter(this.searchProfile().durationEndDate);
  }

  public get isFlat() {
    return isPropertyTypeFlat(
      this.searchProfile()?.propertyType as PropertyType
    );
  }

  public get isCommercial() {
    return isPropertyTypeCommercial(
      this.searchProfile()?.propertyType as PropertyType
    );
  }

  public get isSalesObject() {
    return isSalesProperty(this.searchProfile()?.marketingType);
  }

  public get upperBoundRent() {
    const searchProfile = this.searchProfile();
    const searchProfileValue = this.searchProfile();
    return isValueNullOrUndefined(
      searchProfile.upperBoundRent || searchProfile.upperBoundBaseRent
    )
      ? Infinity
      : searchProfileValue.upperBoundRent ||
          searchProfileValue.upperBoundBaseRent;
  }

  public get priceUpperBoundSales() {
    const searchProfile = this.searchProfile();
    return isValueNullOrUndefined(searchProfile.salesData?.priceUpperBound)
      ? Infinity
      : searchProfile.salesData?.priceUpperBound;
  }

  public get searchProfileCities() {
    const searchProfile = this.searchProfile();
    return !searchProfile.districts?.length
      ? searchProfile.address.city
      : [
          ...groupBy(
            searchProfile?.districts || [],
            (d: District) => d.cityName
          )?.keys()
        ].join(' ,');
  }

  public get isInfinity() {
    return (
      (this.isSalesObject ? this.priceUpperBoundSales : this.upperBoundRent) ===
      Infinity
    );
  }

  public get isProjectSearchProfile(): boolean {
    return this.searchProfile()?.type === SearchProfileType.PROJECT;
  }

  public ngOnInit() {
    this.isProfileActiveControl = new FormControl(
      !this.searchProfile().deactivated
    );
  }

  public ngOnChanges() {
    this.computeDistrictMap();
  }

  public getMappedDistrictNames(district: District[]) {
    return district.map(d => d.name)?.join(', ');
  }

  public onEditProfile() {
    this.editProfile.emit(this.searchProfile());
  }

  public onDeleteProfile() {
    this.deleteProfile.emit(this.searchProfile().id);
  }

  public onToggleProfile() {
    this.toggleProfile.emit(this.searchProfile().id);
  }

  public computeDistrictMap() {
    this.districtsMap = groupBy(
      this.searchProfile().districts,
      (d: District) => d.cityName
    );
  }
}
