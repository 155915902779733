import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  input,
  contentChild
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { DataTableColumnDefaultConfig } from '../data-table.model';

@Component({
  selector: 'app-data-table-cell',
  templateUrl: './data-table-cell.component.html',
  styleUrls: ['./data-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgTemplateOutlet, TranslateModule]
})
export class DataTableCellComponent {
  readonly icon = input<string>(undefined);
  readonly hover = input(false);
  readonly alignment = input(DataTableColumnDefaultConfig.alignment);
  readonly withoutPaddingLeft = input(
    DataTableColumnDefaultConfig.withoutPaddingLeft
  );
  readonly clickable = input(DataTableColumnDefaultConfig.clickable);
  readonly withoutPadding = input(false);
  readonly cellData = contentChild<TemplateRef<any>>('cellData');
}
