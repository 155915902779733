import {
  Component,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
  viewChild
} from '@angular/core';
import { Attachment } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import { MessageComponent } from '../../message/message.component';

const isDragEvent = (event: any): event is DragEvent => !!event.dataTransfer;

export type FileUploadChange = Attachment[] | { error: any; message?: string };

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [NgClass, SvgIconComponent, MessageComponent, TranslateModule]
})
export class FileUploadComponent implements OnInit {
  private elementRef = inject(ElementRef);

  readonly fileInput = viewChild<ElementRef>('fileInput');
  readonly disabled = input(false);
  readonly required = input(false);
  readonly multiple = input(false);
  readonly showButton = input(false);
  readonly size = input(1024 * 1024 * 10);
  readonly accept = input<string>(undefined);
  readonly subInformation = input<string>(undefined);

  readonly changeFileUpload = output<FileUploadChange>();

  public dragHover = false;
  private uploadArea: Element;
  public error: string;

  public get acceptImages() {
    return (this.accept() || '').match(/png|jpg|jpeg|gif/g);
  }

  public ngOnInit() {
    this.uploadArea =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.elementRef.nativeElement.querySelector('.file-upload__area');
    this.uploadArea.addEventListener(
      'dragover',
      this.fileDragHover.bind(this),
      true
    );
    this.uploadArea.addEventListener(
      'dragleave',
      this.fileDragHover.bind(this),
      true
    );
    this.uploadArea.addEventListener(
      'drop',
      this.fileSelectHandler.bind(this),
      false
    );
  }

  private fileDragHover(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragHover = event.type === 'dragover';
  }

  private extractFileList(event: Event) {
    if (isDragEvent(event)) {
      return event.dataTransfer.files;
    }
    return (event.target as HTMLInputElement).files;
  }

  public fileSelectHandler(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled()) {
      return;
    }

    this.error = null;
    this.dragHover = false;
    const files = Array.from(this.extractFileList(event));

    return this.validateFiles(files);
  }

  public handleButtonClick() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.fileInput().nativeElement.dispatchEvent('click');
  }

  private castToType(fileType: string) {
    return fileType ? fileType.split('/')[1] : '';
  }

  private getFileType() {
    const accept = this.accept();
    if (accept && accept.includes(',')) {
      return accept.split(',').map(this.castToType);
    }
    return [this.castToType(accept)];
  }

  private emitFiles(files: File[]) {
    this.changeFileUpload.emit(files);
    this.fileInput().nativeElement.value = '';
  }

  private validateFiles(files: File[]) {
    // IE fix
    if (!files.length) return false;

    const fileTypes: string[] = this.getFileType();
    const areAllSizesValid = files.every(file => this.isFileSizeValid(file));

    if ((!this.accept() || fileTypes.includes('*')) && areAllSizesValid) {
      return this.emitFiles(files);
    }

    const validFiles = files.filter(
      file =>
        (!fileTypes[0] || fileTypes.includes(this.castToType(file.type))) &&
        this.isFileSizeValid(file)
    );

    if (!validFiles.length) {
      this.error = !areAllSizesValid
        ? 'NOT_VALID_FILE_SIZE_L'
        : 'NOT_VALID_FILE_TYPE_L';

      return this.changeFileUpload.emit({
        error: true,
        message: this.error
      });
    }

    this.emitFiles(validFiles);
  }

  private isFileSizeValid(file: File) {
    return file.size <= this.size();
  }
}
