import { EnvironmentProviders, Injectable } from '@angular/core';
import { provideAngularSvgIcon, SvgLoader } from 'angular-svg-icon';
import { Observable, of } from 'rxjs';

@Injectable()
class MockSvgLoader implements SvgLoader {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSvg(url: string): Observable<string> {
    // Return a mocked SVG string
    const mockSvg =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><rect width="100" height="100" fill="blue"/></svg>';
    return of(mockSvg);
  }
}

export function provideAngularSvgIconTesting(): EnvironmentProviders {
  return provideAngularSvgIcon({
    loader: {
      provide: SvgLoader,
      useClass: MockSvgLoader
    }
  });
}
