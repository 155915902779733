import { Component, input, output } from '@angular/core';

import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import moment from 'moment';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  Constants,
  SelfDisclosureQuestion,
  SelfDisclosureQuestionType
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';

import { SelfDisclosureDocumentComponent } from '../self-disclosure-document/self-disclosure-document.component';
import { SelfDisclosureAddressComponent } from '../self-disclosure-address/self-disclosure-address.component';
import { SelfDisclosureEmploymentComponent } from '../self-disclosure-employment/self-disclosure-employment.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { SelectComponent } from '../../../components/legacy/form/controls/select/select.component';
import { DateComponent } from '../../../components/legacy/form/controls/date';
import { FlatSelectComponent } from '../../../components/legacy/form/controls/flat-select/flat-select.component';
import { CheckboxComponent } from '../../../components/legacy/form/controls/checkbox/checkbox.component';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';

// TODO MOVE
const BOOLEAN_OPTIONS = [
  { name: 'general.yes_l', value: true },
  { name: 'general.no_l', value: false }
];

@Component({
  selector: 'app-self-disclosure-person',
  templateUrl: './self-disclosure-person.component.html',
  styleUrls: ['./self-disclosure-person.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SvgIconComponent,
    SelfDisclosureEmploymentComponent,
    SelfDisclosureAddressComponent,
    SelfDisclosureDocumentComponent,
    TranslateModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    SelectComponent,
    AppInputDirective,
    DateComponent,
    FlatSelectComponent,
    CheckboxComponent
  ]
})
export class SelfDisclosurePersonComponent {
  readonly selfDisclosureQuestion = input<SelfDisclosureQuestion>(undefined);
  readonly title = input<string, string>(undefined, {
    transform: v => v || this.selfDisclosureQuestion().title
  });
  readonly form = input<FormGroup>(undefined);
  readonly showRemove = input(false);
  readonly constants = input<Constants>(undefined);

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onRemove = output<void>();

  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };

  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };

  public get getAnswerFormControl() {
    return this.form().get('answer') as FormGroup;
  }

  public get getConfirmationsFormControl() {
    return this.form().get('confirmations') as FormArray;
  }

  public get getSubQuestionsFormControl() {
    return this.form().get('subQuestions') as FormArray;
  }

  public getQuestionsForm(index: number): FormGroup {
    return this.getSubQuestionsFormControl.controls[index] as FormGroup;
  }

  public getSubQuestionForm(index: number): FormGroup {
    return this.getQuestionsForm(index);
  }

  public options(question: SelfDisclosureQuestion) {
    const constants = this.constants();
    return this.isTypeBoolean(question)
      ? BOOLEAN_OPTIONS
      : this.isTypeSelect(question) && constants
        ? constants[question.constantName]
        : [];
  }

  public isTypeBooleanOrSelect(subQuestion: SelfDisclosureQuestion) {
    return this.isTypeSelect(subQuestion) || this.isTypeBoolean(subQuestion);
  }

  public isTypeSelect(question: SelfDisclosureQuestion) {
    return question && question.type === SelfDisclosureQuestionType.SELECT;
  }

  public isTypeBoolean(question: SelfDisclosureQuestion) {
    return question && question.type === SelfDisclosureQuestionType.BOOLEAN;
  }

  public isEmployment(subQuestion: SelfDisclosureQuestion) {
    return (
      subQuestion && subQuestion.type === SelfDisclosureQuestionType.EMPLOYMENT
    );
  }

  public isAddress(subQuestion: SelfDisclosureQuestion) {
    return (
      subQuestion && subQuestion.type === SelfDisclosureQuestionType.ADDRESS
    );
  }

  public isDocument(subQuestion: SelfDisclosureQuestion) {
    return (
      subQuestion && subQuestion.type === SelfDisclosureQuestionType.DOCUMENT
    );
  }

  public showSelectTypeQuestion(question: SelfDisclosureQuestion) {
    return (
      question.title === SelfDisclosureQuestionType.SALUTATION &&
      question.showSelfDisclosureQuestions
    );
  }

  public isOtherSubQuestion(question: SelfDisclosureQuestion) {
    return question.title !== SelfDisclosureQuestionType.SALUTATION;
  }

  public getSelectTypeItems(constantName: string) {
    const constants = this.constants();
    return constants ? constants[constantName] : [];
  }

  public removeHandler() {
    this.onRemove.emit();
  }

  public showHint(index: number) {
    const control = this.getSubQuestionsFormControl.value[index];

    const selfDisclosureQuestion = this.selfDisclosureQuestion();
    return (
      selfDisclosureQuestion.subQuestions &&
      selfDisclosureQuestion.subQuestions[index] &&
      selfDisclosureQuestion.subQuestions[index].commentAllowed &&
      control &&
      control.answer === true
    );
  }

  protected readonly SelfDisclosureQuestionType = SelfDisclosureQuestionType;
}
