import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'app-navigation-item-v2',
  templateUrl: './navigation-item-v2.component.html',
  styleUrls: ['./navigation-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLinkActive, RouterLink, TranslateModule]
})
export class NavigationItemV2Component {
  readonly item = input<NavigationItem>(undefined);
  readonly exact = input<true>(undefined);
  readonly trackEvent = output<string>();

  public onTrackEvent(event?: string) {
    this.trackEvent.emit(event);
  }
}
