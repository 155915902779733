import { Directive, inject, TemplateRef, input } from '@angular/core';

@Directive({
  selector: '[appTableCell]'
})
export class DataTableCellDirective {
  template = inject<TemplateRef<HTMLDivElement>>(TemplateRef);

  readonly name = input<string>(undefined, { alias: 'appTableCell' });
}
