<button
  [type]="buttonType()"
  [class]="getClassName()"
  [ngClass]="{
    'h-100': useFullContainerSize(),
    p0: zeroPadding(),
    'button--disable-hover': disableHover()
  }"
  appElevation
  [elevation]="getElevation()"
  [clickable]="!isDisabled() && !isGhost() && elevationHoverEffect()"
  [disabled]="isDisabled()"
  [attr.aria-label]="ariaLabel()"
  (click)="click($event)"
>
  <span [class]="baseClass + '__content'">
    @if (iconLeft() && !loading()) {
      <div [class]="getIconClassName(iconLeft())"></div>
    }
    <!-- TODO: render `inserted-content` only when no content is given (+ remove SCSS interim fix) -->
    <div
      [class]="baseClass + '__inserted-content'"
      [ngClass]="{ 'd-flex': useDisplayFlex() }"
    >
      <ng-content></ng-content>
    </div>
    @if (iconRight() && !loading()) {
      <div [class]="getIconClassName(iconRight())"></div>
    }
    <ng-content select="[slot=after-button-content]"></ng-content>
    @if (loading()) {
      <app-loading-spinner></app-loading-spinner>
    }
  </span>
</button>
