import { Component, input } from '@angular/core';
import { QuotaStatistic } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-quota-info-badge',
  templateUrl: './quota-info-badge.component.html',
  styleUrls: ['./quota-info-badge.component.scss'],
  imports: [RouterLink, TranslateModule]
})
export class QuotaInfoBadgeComponent {
  readonly quotaStatistic = input<QuotaStatistic>({
    totalQuota: null,
    availableQuota: null
  });

  readonly isAdmin = input<boolean>(undefined);
  readonly text = input('general.overview.contingent_l');
  readonly showUnlimited = input(false);
}
