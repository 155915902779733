import {
  ActionState,
  ActionStateCreator
} from '../../../state-utils/action-state';
import * as fromActions from './auth.actions';

export interface AuthState {
  userCredentials: {
    email?: string;
    redirectUri: string;
  };
  loginError: string | null;
  keycloakUrl: string;
  requestPasswordResetActionState: ActionState;
  resetPasswordActionState: ActionState;
  emailVerifiedInCurrentSession: boolean;
  isLoading: boolean;
}

export const initialState: AuthState = {
  loginError: null,
  keycloakUrl: null,
  userCredentials: {
    email: '',
    redirectUri: ''
  },
  requestPasswordResetActionState: ActionStateCreator.create(),
  resetPasswordActionState: ActionStateCreator.create(),
  emailVerifiedInCurrentSession: false,
  isLoading: false
};

export function reducer(
  state = initialState,
  action: fromActions.AuthAction
): AuthState {
  switch (action.type) {
    case fromActions.USER_LOGIN: {
      const { userCredentials } = action;
      return {
        ...state,
        isLoading: true,
        userCredentials
      };
    }

    case fromActions.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        loginError: null,
        isLoading: false
      };
    }

    case fromActions.USER_LOGIN_FAIL: {
      return {
        ...state,
        isLoading: false,
        loginError: action.loginError
      };
    }

    case fromActions.SAVE_KEYCLOAK_URL: {
      return {
        ...state,
        keycloakUrl: action.url
      };
    }

    case fromActions.REQUEST_PASSWORD_RESET: {
      return {
        ...state,
        requestPasswordResetActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.REQUEST_PASSWORD_RESET_FAIL: {
      return {
        ...state,
        requestPasswordResetActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.REQUEST_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        requestPasswordResetActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        resetPasswordActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        emailVerifiedInCurrentSession: true
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoginError = (state: AuthState) => state.loginError;
export const getUserCredentials = (state: AuthState) => state.userCredentials;
export const getKeycloakUrl = (state: AuthState) => state.keycloakUrl;
export const getIsLoading = (state: AuthState) => state.isLoading;

export const getRequestPasswordResetActionState = (state: AuthState) =>
  state.requestPasswordResetActionState;
export const getResetPasswordActionState = (state: AuthState) =>
  state.resetPasswordActionState;

export const getEmailVerifiedInCurrentSession = (state: AuthState) =>
  state.emailVerifiedInCurrentSession;
