<div class="hierarchical-question-container">
  @if (!isMainQuestion()) {
    <div class="row d-flex align-items-center">
      <div class="col-12">
        <span
          class="hierarchical-question-container__sub-question-header uppercase-m"
          >{{
            'hierarchical_questions.display.sub_question_title_l'
              | translate: { index: subQuestionIndex() }
          }}</span
        >
      </div>
    </div>
  }

  <div class="row d-flex align-items-center">
    <div class="col-8">
      @if (question().data.title && currentLanguage()) {
        <span> {{ question().data.title[currentLanguage()] }} </span>
      }
    </div>

    @if (isMainQuestion()) {
      <div class="col-4 d-flex justify-content-end align-items-center">
        @if (editTaskType()) {
          <app-badge
            class="mr5"
            [color]="badgeType"
            [uppercase]="false"
            [tooltipText]="
              'hierarchical_questions.display_badge_' +
                editTaskType().toLowerCase() +
                '_tooltip_l' | translate
            "
            >{{
              'hierarchical_questions.display_badge_' +
                editTaskType().toLowerCase() +
                '_l' | translate
            }}</app-badge
          >
        }
        @if (!appCheckTemplateRef()) {
          <app-context-menu>
            <div menu-content>
              <app-context-menu-item
                (clickEvent)="onEdit()"
                [disabled]="isEditTaskTypeDelete"
              >
                {{ 'custom_questions.edit_a' | translate }}
              </app-context-menu-item>
              @if (!isEditTaskTypeDelete) {
                <app-context-menu-item (clickEvent)="onDelete()">
                  {{ 'custom_questions.delete_a' | translate }}
                </app-context-menu-item>
              }
            </div>
          </app-context-menu>
        }
        <ng-template [ngTemplateOutlet]="appCheckTemplateRef()"></ng-template>
      </div>
    }
  </div>

  <hr />
  <div class="hierarchical-question-container__body">
    @switch (true) {
      @case (isSelection) {
        <div class="row">
          <div class="col-12 hierarchical-question-container__body-title">
            <span>{{
              'hierarchical_questions.display.answers_selection_title_l'
                | translate
            }}</span>
          </div>
        </div>
        @for (answer of sortedAnswers; track answer; let i = $index) {
          <div class="hierarchical-question-container__body-answers">
            <div class="row">
              <div class="col-12">
                <span class="answer-option">{{
                  'hierarchical_questions.display.answers_selection_option_l'
                    | translate
                }}</span>
                <span class="answer-option"
                  >&nbsp;{{ i | appAlphaIndex }}:
                </span>
                @if (answer.data.title && currentLanguage()) {
                  <span class="answer-title">{{
                    answer.data.title[currentLanguage()] | translate
                  }}</span>
                }
                @if (answer.preferredAnswer) {
                  <svg-icon
                    src="/assets/images/icons/star.svg"
                    [applyClass]="true"
                    class="orange-svg-color polygon polygon-fill svg-star"
                  ></svg-icon>
                }
              </div>
            </div>
            @if (answer.questionIds?.length > 0) {
              <div class="row pl12">
                <div class="col-11">
                  <span class="answer-follow-question-label">{{
                    'hierarchical_questions.display.answers_follow_questions_l'
                      | translate
                  }}</span>
                  <span class="answer-follow-question-value"
                    >&nbsp;{{
                      getFollowQuestionTitles(answer.questionIds)
                    }}</span
                  >
                </div>
              </div>
            }
            @if (answer.actions?.length > 0) {
              <div class="d-flex flex-wrap">
                <app-label-list
                  [assignedTags]="getTags(answer.actions)"
                  [isSystemTag]="false"
                  [readonly]="true"
                  [amountBeforeTruncation]="3"
                  [truncateLabels]="true"
                  [multilineMode]="true"
                ></app-label-list>
              </div>
            }
          </div>
        }
      }
      @case (isRanged) {
        <div class="row">
          <div class="col-12 hierarchical-question-container__body-title">
            <span>{{
              'hierarchical_questions.display.answers_ranged_title_l'
                | translate
            }}</span>
          </div>
        </div>
        @for (answer of sortedAnswers; track answer; let i = $index) {
          <div class="hierarchical-question-container__body-answers">
            @if (
              !isRangedMain(answer.data.answerId) ||
              answer.questionIds?.length > 0
            ) {
              <div class="row">
                <div class="col-12">
                  <span class="answer-option">{{
                    'hierarchical_questions.display.answers_ranged_option_' +
                      answer.data.answerId.toLowerCase() +
                      '_l' | translate
                  }}</span>
                  @if (isRangedNumber) {
                    <span class="answer-title">{{
                      getBound(answer.data)
                    }}</span>
                  }
                  @if (isRangedDate) {
                    <span class="answer-title">{{
                      getBound(answer.data).toString() | appDateTime
                    }}</span>
                  }
                  @if (answer.preferredAnswer) {
                    <svg-icon
                      src="/assets/images/icons/star.svg"
                      [applyClass]="true"
                      class="orange-svg-color polygon polygon-fill svg-star"
                    ></svg-icon>
                  }
                </div>
              </div>
            }
            @if (answer.questionIds?.length > 0) {
              <div class="row pl12">
                <div class="col-11">
                  <span class="answer-follow-question-label">{{
                    'hierarchical_questions.display.answers_follow_questions_l'
                      | translate
                  }}</span>
                  <span class="answer-follow-question-value"
                    >&nbsp;{{
                      getFollowQuestionTitles(answer.questionIds)
                    }}</span
                  >
                </div>
              </div>
            }
          </div>
        }
      }
    }
  </div>
</div>
