import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
  output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import moment from 'moment';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  PaymentMethod,
  PaymentMethodType,
  Product,
  SubscriptionPeriod
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';

import { DateTimePipe } from '../../../pipes/date-time.pipe';

import { ADDON_DISCOUNT_CONFIG } from '../../products-module.config';
import { isValueNullOrUndefined } from '../../../utils';
import { BadgeComponent } from '../../../components/atoms/badge';
import { CardComponent } from '../../../components/atoms/card';
import {
  ContextMenuComponent,
  ContextMenuItemComponent,
  ContextMenuTooltipPlacementEnum
} from '../../../components';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { DateComponent } from '../../../components/legacy/form/controls/date';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';

@Component({
  selector: 'app-payment-details-lib',
  templateUrl: './payment-details-lib.component.html',
  styleUrls: ['./payment-details-lib.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentDetailsLibComponent),
      multi: true
    }
  ],
  imports: [
    SvgIconComponent,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CurrencyPipe,
    TranslateModule,
    DateTimePipe,
    BadgeComponent,
    CardComponent,
    ContextMenuComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    DateComponent,
    ContextMenuItemComponent
  ]
})
export class PaymentDetailsLibComponent implements ControlValueAccessor {
  readonly isAdmin = input<boolean>(undefined);
  readonly products = input<Product[]>(undefined);
  readonly totalPrice = input<number>(undefined);
  readonly totalPriceNet = input<number>(undefined);
  readonly totalPricePreDiscount = input<number>(undefined);
  readonly totalPriceNetPreDiscount = input<number>(undefined);
  readonly totalNextPrice = input<number>(undefined);
  readonly totalNextPriceNet = input<number>(undefined);
  readonly totalNextPricePreDiscount = input<number>(undefined);
  readonly totalNextPriceNetPreDiscount = input<number>(undefined);
  readonly currency = input<string>(undefined);
  readonly recalculatingPrice = input(false);
  readonly isYearlySelected = input(false);
  readonly isNextYearlySelected = input(false);
  readonly paymentMethod = input<PaymentMethod>(undefined);
  readonly form = input<FormGroup>(undefined);
  readonly nextProductSubscriptionPeriod = input<SubscriptionPeriod>(undefined);
  readonly currentSubscriptionPeriod = input<SubscriptionPeriod>(undefined);
  readonly renewDate = input<string>(undefined);
  readonly hasActiveProduct = input<boolean>(undefined);
  readonly isTrial = input<boolean>(undefined);
  readonly productChange = output<string>();

  public readonly ADDON_DISCOUNT_CONFIG = ADDON_DISCOUNT_CONFIG;

  ContextMenuTooltipPlacementEnum = ContextMenuTooltipPlacementEnum;
  public value: Product;
  public yearlyDiscount = {
    value: '17%'
  };

  public minDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  /* eslint-disable @typescript-eslint/ban-types */
  private onChange: Function;
  private onTouch: Function;
  /* eslint-enable @typescript-eslint/ban-types */

  readonly periodsMap = {
    [SubscriptionPeriod.MONTHLY]: 'general.monthly_l',
    [SubscriptionPeriod.YEARLY]: 'general.yearly_l'
  };

  get discountEndDate(): string {
    return this.form()?.get('discountEnd')?.value as string;
  }

  get isDisabled() {
    if (
      isValueNullOrUndefined(this.discountEndDate) ||
      this.discountEndDate?.length === 0
    ) {
      return true;
    }
    if (this.discountEndDate?.length > 0) {
      return false;
    }
  }

  get isInvoice() {
    const paymentMethod = this.paymentMethod();
    return paymentMethod && paymentMethod.type === PaymentMethodType.INVOICE;
  }

  get isMultiplierReadonly() {
    return this.hasActiveProduct();
  }

  get isTrialPeriodRequired() {
    return this.hasActiveProduct() && this.isTrial();
  }

  get isTrialPeriodReadOnly() {
    return !this.isTrial();
  }

  onSelectProduct(product: Product) {
    this.productChange.emit(product.id);
  }

  writeValue(value: Product) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }
}
