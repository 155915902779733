import { Component, input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SlideToggleComponent } from '../../../form/controls/slide-toggle/slide-toggle.component';
import { HintComponent } from '../../../hint/hint.component';

@Component({
  selector: 'app-knockout-criteria',
  templateUrl: './knockout-criteria.component.html',
  styleUrls: ['./knockout-criteria.component.scss'],
  imports: [
    HintComponent,
    SlideToggleComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class KnockoutCriteriaComponent {
  readonly formControlInput = input<FormControl>(undefined);
  readonly disabled = input<boolean>(undefined);
}
