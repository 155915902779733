@if (customQuestions()?.length > 0) {
  <app-custom-questions-form
    [formControl]="customQuestionResponsesControl"
    [customQuestions]="customQuestions()"
    (validityChange)="onFormValidityChange($event)"
  ></app-custom-questions-form>
}
@if (showHierarchicalRootQuestions()) {
  <app-hierarchical-root-question-form
    [formControl]="hierarchicalRootQuestionsControl"
    [hierarchicalRootQuestions]="rootQuestions()"
    (validityChange)="onHierarchicalFormValidityChange($event)"
  ></app-hierarchical-root-question-form>
}
@if (
  showErrorMessage() &&
  (customQuestionResponsesControl.errors ||
    hierarchicalRootQuestionsControl.errors)
) {
  <div class="global-custom-questions__error">
    {{ 'forms.errors.field_required' | translate }}
  </div>
}
