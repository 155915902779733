<div class="self-disclosure-document__content">
  <form [formGroup]="form()">
    <div class="d-flex flex-row mt25 mb10">
      {{ selfDisclosureQuestion().title | translate }}
      @if (selfDisclosureQuestion().mandatory) {
        <span class="required-marker ng-star-inserted">&nbsp;*</span>
      }
    </div>

    <app-form-field>
      <div [ngClass]="{ invalid: isDocumentValid }">
        <app-attachments
          [showDownload]="false"
          [showRemove]="true"
          [isDocument]="true"
          [multiple]="false"
          [accept]="acceptedDocumentsTypes"
          [size]="documentsMaxSize"
          formControlName="upload"
        ></app-attachments>
      </div>
    </app-form-field>
  </form>
</div>
