<li
  class="nav-item"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: exact() }"
>
  @if (!item().redirectLink) {
    <a class="nav-link d-flex" routerLink="{{ item().link }}" r>
      {{ item().label | translate }}
    </a>
  } @else {
    <a class="nav-link d-flex" href="{{ item().redirectLink }}" target="_blank">
      {{ item().label | translate }}
    </a>
  }
  <ng-template #redirectLink>
    <a class="nav-link d-flex" href="{{ item().redirectLink }}" target="_blank">
      {{ item().label | translate }}
    </a>
  </ng-template>
</li>
