import { ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';
import {
  InputSignal,
  InputSignalWithTransform,
  ModelSignal
} from '@angular/core';

export abstract class AppFormFieldControl<T> {
  value: T;
  id: InputSignal<string>;
  placeholder: InputSignal<string>;
  required: InputSignalWithTransform<boolean, boolean>;
  touched: boolean;
  count: InputSignal<boolean>;
  counter: ModelSignal<number>;
  errors: ValidationErrors;
  name?: string;
  type?: string;
  element?: { nativeElement: HTMLElement };
  readonly stateChanges: Subject<void>;
}
