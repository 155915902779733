import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  input,
  output
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  HomepageModule,
  ImmomioPortalType,
  PortalConfig
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { PORTALS_CONFIG } from '../../tokens';
import { PortalCardComponent } from '../../../components';
import {
  BadgeColorEnum,
  BadgeComponent
} from '../../../components/atoms/badge';

@Component({
  selector: 'app-portal-homepage-list',
  templateUrl: './portal-homepage-list.component.html',
  styleUrls: ['./portal-homepage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortalHomepageListComponent),
      multi: true
    }
  ],
  imports: [TranslateModule, PortalCardComponent, BadgeComponent]
})
export class PortalHomepageListComponent implements ControlValueAccessor {
  private portalsConfig = inject(PORTALS_CONFIG);

  readonly homepages = input<HomepageModule[]>([]);

  readonly allowSelect = input(false);
  readonly disabled = input(false);

  readonly remove = output();

  public BadgeColorEnum = BadgeColorEnum;

  public value: HomepageModule[] = [];
  public config: PortalConfig; // instead of getConfig() in PortalCredentialList

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  constructor() {
    this.config = this.portalsConfig.find(
      config => config.portalId === ImmomioPortalType.HOMEPAGE_MODULE
    );
  }

  public isSelected(homepage: HomepageModule) {
    return this.value?.some(c => c?.id === homepage.id);
  }

  public onToggle(homepage: HomepageModule) {
    if (this.isSelected(homepage)) {
      this.value = this.value.filter(c => c?.id !== homepage.id);
    } else {
      this.value = [...this.value, homepage];
    }

    this.onChange(this.value);
    this.onTouch();
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: HomepageModule[]) {
    this.value = value;
  }
}
