import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import {
  SetAvailableLanguages,
  SetCurrentLocale,
  SetDefaultLanguageCode
} from '../base-state/app/app.actions';

import { LocalStorageService } from '../storage';

import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';
import { CheckTranslations } from '../base-state/translation/translation.actions';
import { getTranslationLoaded } from '../base-state/translation/translation.selectors';
import { I18nService } from './i18n.service';

@Injectable()
export class TranslationsGuard {
  private store = inject(Store);
  private storage = inject(LocalStorageService);
  private i18nService = inject(I18nService);
  private config = inject(INFRASTRUCTURE_CONFIG);

  /**
   * Loading the page only after translationsState.loaded === true
   * if not true, dispatch action LoadTranslation - executes gql query to fetch translations
   * in future should load language depending on domain used (eg .com - 'en', .de - 'de', .at - 'at')
   */
  canActivate(): Observable<boolean> {
    return this.store.select(getTranslationLoaded).pipe(
      tap((loaded: boolean) => {
        if (!loaded) {
          const localeKey = this.config.storageKeys.localeKey;
          const currentLocale = this.storage.getItem(localeKey);
          const appName = this.config.environment.app_name;
          const languageConfig =
            this.i18nService.getInfrastructureLanguageConfig();

          this.store.dispatch(
            new CheckTranslations(appName, currentLocale, false)
          );
          this.store.dispatch(new SetCurrentLocale(currentLocale));
          this.store.dispatch(
            new SetAvailableLanguages(languageConfig.availableLanguages)
          );
          this.store.dispatch(
            new SetDefaultLanguageCode(languageConfig.defaultLanguageCode)
          );
        }
      }),
      filter((loaded: boolean) => loaded),
      take(1),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
