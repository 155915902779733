import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Attachment } from '@ui/shared/models';
import { ImageSizePipe } from '../../../../pipes/image-size.pipe';
import { AvatarComponent } from '../../../legacy/avatar/avatar.component';

@Component({
  selector: 'app-navigation-user-profile',
  templateUrl: './navigation-user-profile.component.html',
  styleUrls: ['./navigation-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent, ImageSizePipe]
})
export class NavigationUserProfileComponent {
  readonly name = input<string>(undefined);
  readonly email = input<string>(undefined);
  readonly portrait = input<Attachment>(undefined);
  readonly dark = input<boolean>(undefined);
}
