import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
  input,
  viewChild
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownMenu,
  NgbDropdownToggle
} from '@ng-bootstrap/ng-bootstrap';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { KeyValueMaintainMapOrderPipe } from '../../../pipes/key-value-maintain-map-order.pipe';
import { CheckComponent } from '../../legacy/form/controls/check/check.component';
import { FormFieldLabelComponent } from '../../legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../legacy/form/form-field/form-field.component';
import { BadgeComponent } from '../badge/badge.component';
import { BadgeColorEnum } from '../badge';
import { AppFormFieldControl } from '../../legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from '../../legacy/form/controls/base-control';
import {
  MultiSelectGroupedItem,
  MultiSelectGroupedItems
} from './multi-select-grouped-items.model';

interface Value {
  [key: string]: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-multi-select-grouped-dropdown',
  templateUrl: './multi-select-grouped-dropdown.component.html',
  styleUrls: ['./multi-select-grouped-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectGroupedDropdownComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => MultiSelectGroupedDropdownComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgClass,
    BadgeComponent,
    NgTemplateOutlet,
    NgbDropdownMenu,
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    CheckComponent,
    TranslateModule,
    KeyValueMaintainMapOrderPipe
  ]
})
export class MultiSelectGroupedDropdownComponent
  extends BaseControl<any>
  implements OnInit
{
  readonly dropdown = viewChild(NgbDropdown);
  readonly items = input<MultiSelectGroupedItems>(undefined);
  readonly extendedItemsConfig = input<MultiSelectGroupedItems>(undefined);
  readonly itemsSelectedPlaceholder = input(
    'multi_select_dropdown.items_selected_l'
  );
  readonly maxDisplayCountOfSelectedItems = input(2);
  readonly textDelimiterForSelectedItems = input(true);
  readonly placement = input('bottom-left');
  readonly showApplyButton = input(true);

  public isDropdownOpened = false;

  public get selectedItems(): MultiSelectGroupedItem[] {
    const entries = Object.values(this.items()).flat();

    return Object.values(this.value as Value).reduce(
      (acc: MultiSelectGroupedItem[], curr) => {
        const selectedItems = entries.filter(entry =>
          curr.includes(entry.value)
        );
        acc.push(...selectedItems);
        return acc;
      },
      []
    );
  }

  public get itemsLength(): number {
    return Object.keys(this.items()).length;
  }

  public getTranslationKey(index: number, subIndex: number): string {
    const propertyName = Object.keys(this.items())[index];
    return this.items()[propertyName][subIndex].name;
  }

  public getBadgeColor(index: number, subIndex: number): BadgeColorEnum {
    const propertyName = Object.keys(this.items())[index];
    return this.items()[propertyName][subIndex].badgeColor;
  }

  public form = new FormGroup({});

  public writeValue(value: Value): void {
    super.writeValue(value);
    this.updateForm();
    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.initializeForm();
  }

  clear(event?: Event): void {
    // When you don't have this, then the dropdown would be opened/closed
    // It stops the parent element from also receiving the click event
    if (event) event.stopPropagation();
    this.form.reset();

    // If the value is already the default value, don't reset it
    // That would cause another request being made.
    if (this.selectedItems.length) this.applyValues();
  }

  public apply(): void {
    this.dropdown().close();
    this.applyValues();
  }

  public applyValues() {
    this.value = Object.keys(this.form.value).reduce((acc, curr) => {
      acc[curr] = Object.entries(this.form.get(curr).value)
        .filter(([_, value]) => value)
        .map(([key]) => key);
      return acc;
    }, {});
    this.cdr.detectChanges(); // fixes an issue where labels are not updated on manual reset
  }

  private initializeForm(): void {
    Object.entries(this.items()).forEach(([key, value]) => {
      this.form.addControl(key, new FormGroup({}));
      value.forEach(v =>
        (this.form.get(key) as FormGroup).addControl(
          v.value,
          new FormControl(false)
        )
      );
    });

    if (!this.showApplyButton())
      this.form.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => this.applyValues());
  }

  /**
   * Resets every form control. If no values are provided they are reset to false.
   * @private
   */
  private updateForm(): void {
    const value: { [key: string]: Array<any> } = this.value;

    Object.keys(this.form.controls).forEach(key => {
      const patchedValue = {};
      Object.keys(this.form.controls[key].controls).forEach(nestedKey => {
        patchedValue[nestedKey] =
          value && value[key] && value[key].includes(nestedKey);
      });
      this.form.get(key).patchValue(patchedValue);
    });
  }

  public setShowFilters(event: boolean) {
    this.isDropdownOpened = event;
  }
}
