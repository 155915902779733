import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../components/atoms/card';
import { BadgeComponent } from '../../../components/atoms/badge';

@Component({
  selector: 'app-free-features-listing',
  templateUrl: './free-features-listing.component.html',
  styleUrls: ['./free-features-listing.component.scss'],
  imports: [TranslateModule, CardComponent, BadgeComponent]
})
export class FreeFeaturesListingComponent {}
