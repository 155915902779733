<div
  [class]="getClassName()"
  appElevation
  [elevation]="elevation()"
  [clickable]="clickable()"
  [ngbTooltip]="tooltipText()"
  [openDelay]="300"
  [closeDelay]="300"
  [tooltipClass]="baseClass + '__tooltip'"
  (click)="badgeClick($event)"
>
  @if (icon()) {
    <span
      [class]="'me-1 icon icon--' + icon()"
      [class.semibold]="fontBold()"
    ></span>
  }
  <span
    [class]="baseClass + '__inner'"
    [class.uppercase]="uppercase()"
    [class.semibold]="fontBold()"
  >
    <ng-content></ng-content>
  </span>
  @if (withCloseButton()) {
    <button
      [ngClass]="[baseClass + '__button', 'p-0 ps-1']"
      (click)="closeButtonClick($event)"
    >
      ✕
    </button>
  }
</div>
