<label class="checkbox__container" [class.d-flex]="innerHTML()">
  <input
    type="checkbox"
    class="checkbox"
    [class.checkbox--hide-active-value]="hideActiveValue()"
    [ngClass]="{ 'checkbox--large': showLargerCheckboxes() }"
    [disabled]="disabled"
    [required]="required()"
    [(ngModel)]="value"
  />

  <span
    (click)="toggleValue()"
    class="checkbox__label default-s d-flex"
    [ngClass]="{ 'checkbox__label--large': showLargerCheckboxes() }"
  >
    @if (required()) {
      <span class="required-marker">*&nbsp;</span>
    }
    @if (!innerHTML()) {
      <span class="normal">{{ label() }}</span>
    } @else {
      <span [innerHTML]="innerHTML()" class="inner-HTML"></span>
    }
    <span class="checkbox__custom"></span>
  </span>
</label>
