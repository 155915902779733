<form [formGroup]="form">
  <app-form-field>
    <app-form-field-label>
      {{ 'general.international_code_l' | translate }}
      <app-hint class="pl5">{{ 'general.dmv_aes_sms_l' | translate }}</app-hint>
    </app-form-field-label>
    <div class="d-flex align-items-end">
      <div class="col-3 p0">
        <app-select
          [required]="required()"
          [readonly]="readonly()"
          [itemLabelKey]="'value'"
          [itemValueKey]="'value'"
          [items]="internationalOptions()"
          [placeholder]="'forms.select_international_type_l' | translate"
          formControlName="international"
        >
        </app-select>
      </div>
      <div class="col pr0">
        <input
          appInput
          type="text"
          [readonly]="readonly()"
          [required]="required()"
          [placeholder]="'form.type_phone_l' | translate"
          formControlName="number"
          class="form-control w-100"
        />
      </div>
    </div>
  </app-form-field>
</form>
