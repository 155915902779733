import { Component, input } from '@angular/core';

import { CustomQuestion, CustomQuestionType } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { HumanizeBooleanPipe } from '../../../../pipes/humanize-boolean.pipe';

@Component({
  selector: 'app-custom-question-details',
  templateUrl: './custom-question-details.component.html',
  styleUrls: ['./custom-question-details.component.scss'],
  imports: [TranslateModule, HumanizeBooleanPipe]
})
export class CustomQuestionDetailsComponent {
  readonly customQuestion = input<CustomQuestion>(undefined);

  get desiredAnswers() {
    const customQuestion = this.customQuestion();
    return customQuestion?.options
      ? customQuestion?.options
          ?.filter(question => question.desired)
          .map(question => (this.isBoolean ? question.value : question.name))
      : [];
  }

  public get isBoolean() {
    return this.customQuestion()?.type === CustomQuestionType.BOOLEAN;
  }

  public get isSingleSelect() {
    return this.customQuestion()?.type === CustomQuestionType.SELECT;
  }

  public get isMultiSelect() {
    return this.customQuestion()?.type === CustomQuestionType.MULTISELECT;
  }

  public get isRanged() {
    return this.customQuestion()?.type === CustomQuestionType.RANGE;
  }

  public get isSelect() {
    return this.isSingleSelect || this.isMultiSelect || this.isRanged;
  }
}
