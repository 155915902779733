import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import {
  Addon,
  AddonDiscount,
  CreateSubscriptionPayload,
  Customer,
  LandlordUser,
  PaymentMethod,
  Product,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage,
  UpdateSubscriptionsPayload
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { ActionState } from '../state-utils/action-state';
import { LoadingSpinnerComponent } from '../components/legacy/loading-spinner/loading-spinner.component';
import { MessageComponent } from '../components/legacy/message/message.component';
import { SubscriptionFormComponent } from './components/subscription-form/subscription-form.component';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
  imports: [
    SubscriptionFormComponent,
    TranslateModule,
    LoadingSpinnerComponent,
    MessageComponent
  ]
})
export class SubscriptionPageComponent implements OnChanges {
  public isTrial: boolean;

  readonly products = input<Product[]>(undefined);
  readonly addons = input<Addon[]>(undefined);

  readonly customer = input<Customer>(undefined);
  readonly customerProduct = input<ProductOverview>(undefined);
  readonly customerSize = input<string>(undefined);
  readonly nextProductId = input<string>(undefined);
  readonly defaultProductId = input<string>(undefined);

  readonly loaded = input<boolean>(undefined);
  readonly recalculatingPrices = input<boolean>(undefined);

  readonly saving = input<boolean>(undefined);

  readonly loadingError = input<boolean>(undefined);
  readonly form = input<FormGroup>(undefined);

  readonly isAdmin = input<boolean>(undefined);
  readonly paymentMethod = input<PaymentMethod>(undefined);
  readonly userData = input<LandlordUser>(undefined);
  readonly hasActiveProduct = input<boolean>(undefined);
  readonly addonDiscounts = input<AddonDiscount[]>(undefined);
  readonly digitalContractQuotaDiscounts = input<QuotaDiscount[]>([]);
  readonly digitalContractQuotaPackages = input<QuotaPackage[]>([]);
  readonly objectContingentQuotaDiscounts = input<QuotaDiscount[]>([]);
  readonly objectContingentQuotaPackages = input<QuotaPackage[]>([]);
  readonly recalculationActionState$ =
    input<Observable<ActionState>>(undefined);
  readonly saveQuotaDiscounts = output<QuotaDiscount[]>();
  readonly saveObjectContingentQuotaDiscounts = output<QuotaDiscount[]>();
  readonly discountChange = output<AddonDiscount>();
  readonly productChange = output<string>();
  readonly update = output<UpdateSubscriptionsPayload>();
  readonly create = output<CreateSubscriptionPayload>();
  readonly createLandlord = output();

  public currency = 'EUR';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerProduct) {
      const customerProduct = changes.customerProduct.currentValue;
      this.isTrial = customerProduct?.trial ?? true;
    }
  }

  onProductChange(id: string) {
    this.productChange.emit(id);
  }

  onUpdate(data: UpdateSubscriptionsPayload) {
    this.update.emit(data);
  }

  onCreate(data: CreateSubscriptionPayload) {
    this.create.emit(data);
  }

  onDiscountChange(discounts: AddonDiscount) {
    this.discountChange.emit(discounts);
  }

  onSaveQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveQuotaDiscounts.emit(discounts);
  }

  onSaveObjectContingentQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveObjectContingentQuotaDiscounts.emit(discounts);
  }

  onCreateLandlord() {
    this.createLandlord.emit();
  }
}
