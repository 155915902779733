<form [formGroup]="form" class="property-parking-space">
  @for (
    parkingSpaceFormGroup of getParkingSpaceControls;
    track parkingSpaceFormGroup;
    let index = $index
  ) {
    <div>
      <div class="mb15" [formGroup]="parkingSpaceFormGroup">
        <div class="row pb12 property-parking-space__remove">
          <div
            class="col-9 property-parking-space__remove property-parking-space__remove--text"
          >
            {{ index + 1 }}.
            {{ 'general.parking_space_list_item_title_l' | translate }}
          </div>
          <div
            class="col-3 property-parking-space__remove property-parking-space__remove--icon"
          >
            <svg-icon
              src="/assets/images/icons/icon-bin.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="secondary-svg-color path rect"
              (click)="removeParkingSpace(index)"
            ></svg-icon>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-dropdown-select
              [items]="parkingSpaceTypeList()"
              [showSeperator]="true"
              required
              formControlName="type"
            >
              <div dropdown-button>
                {{ 'general.parking_space_types_l' | translate }}
              </div>
              <ng-template let-item
                >{{ 'PARKINGSPACETYPES_' + item.value | translate }}
              </ng-template>
            </app-dropdown-select>
          </div>
        </div>
        <div class="row pt8">
          <div class="col">
            <app-form-field>
              <app-form-field-label
                >{{ 'general.set_number' | translate }}
              </app-form-field-label>
              <div class="input-group">
                <input
                  appInput
                  required
                  type="number"
                  min="1"
                  formControlName="count"
                  class="form-control"
                />
              </div>
            </app-form-field>
          </div>
          @if (!isSalesObject()) {
            <div class="col">
              <app-form-field>
                <app-form-field-label
                  >{{ 'general.parking_places_cost_per_space_l' | translate }}
                </app-form-field-label>
                <div class="input-group">
                  <input
                    appInput
                    required
                    type="number"
                    min="0"
                    formControlName="price"
                    [readonly]="includedInAdditionalCost()"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </app-form-field>
            </div>
          }
          @if (isSalesObject()) {
            <div class="col">
              <app-form-field>
                <app-form-field-label
                  >{{ 'general.parking_places_purchase_price_l' | translate }}
                </app-form-field-label>
                <div class="input-group">
                  <input
                    appInput
                    required
                    type="number"
                    min="0"
                    formControlName="purchasePrice"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </app-form-field>
            </div>
          }
        </div>
      </div>
      <!-- add some space between parking spaces -->
      @if (index < getParkingSpaceControls.length - 1) {
        <div class="pb8"></div>
      }
    </div>
  }
  @if (getParkingSpaceControls.length > 0) {
    <div class="mt40"></div>
  }

  <div>
    <app-button [type]="'light-bordered'" (clickEvent)="addParkingSpace()">
      {{ 'general.parking_space_add_a' | translate }}
    </app-button>
  </div>
</form>
