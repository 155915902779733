import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ImageComponent } from '../../atoms/image/image.component';

import { RegexTypes } from '../../../utils';
import { ThemeService } from '../../../infrastructure/theme';

@Component({
  selector: 'app-landlord-info',
  templateUrl: './landlord-info.component.html',
  styleUrls: ['./landlord-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgTemplateOutlet, ImageComponent, TranslateModule]
})
export class LandlordInfoComponent {
  private themeService = inject(ThemeService);

  readonly name = input<string>(undefined);
  readonly logo = input<string>(undefined);
  readonly logoRedirectUrl = input<string>(undefined);
  readonly showOfferedBy = input(true);
  readonly offeredByTitle = input<string>(undefined);
  readonly showInCooperationWith = input(true);

  public baseClass = 'landlord-info';

  public get withLinkedLogo(): boolean {
    return !!RegexTypes.URL.exec(this.logoRedirectUrl());
  }

  public get showInCooperationWithImmomio(): boolean {
    return !!this.logo();
  }

  public get customerLogo(): string {
    return (
      this.logo() ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }
}
