import { Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuComponent } from '../../context-menu/context-menu.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { isValueNotNullAndUndefined } from '../../../../utils';

@Component({
  selector: 'app-property-group-card',
  templateUrl: './property-group-card.component.html',
  styleUrls: ['./property-group-card.component.scss'],
  imports: [
    CardComponent,
    ImageComponent,
    NgbTooltip,
    ContextMenuComponent,
    TranslateModule
  ]
})
export class PropertyGroupCardComponent {
  readonly imageUrl = input('');
  readonly title = input('');
  readonly subTitle = input('');
  readonly propertiesCount = input(0);
  readonly propertiesCountTotal = input(0);
  readonly propertiesCountAdded = input(0);
  readonly propertiesCountTooltipText = input<string | null>(null);

  public baseClass = 'property-group-card';

  get imageUrlWithFallback(): string {
    return this.imageUrlHasBeenSet()
      ? this.imageUrl()
      : '/assets/images/object-image-placeholder.svg';
  }

  public imageUrlHasBeenSet(): boolean {
    const imageUrl = this.imageUrl();
    return imageUrl !== '' && isValueNotNullAndUndefined(imageUrl);
  }
}
