<div class="gallery__container">
  @if (galleryConfig$ | async; as config) {
    <gallery
      [items]="items"
      [thumbWidth]="config.thumbWidth"
      [thumbHeight]="config.thumbHeight"
      [thumbPosition]="config.thumbPosition"
      [thumb]="config.thumb"
      [dots]="config.dots"
      [ngStyle]="{ 'background-color': 'transparent' }"
      (indexChange)="changeBackgroundImage($event)"
    >
      <div
        *galleryBoxDef="let state = state; let config = config"
        [ngStyle]="styleObject"
      ></div>
    </gallery>
  }
</div>
