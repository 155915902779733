import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'bounds'
})
export class BoundsPipe implements PipeTransform {
  private translate = inject(TranslateService);

  transform(
    bounds: {
      lowerBound?: number | null;
      upperBound?: number | null;
      suffix?: string;
    },
    showPrefix = true
  ): string {
    const { lowerBound, upperBound } = bounds;

    if (!lowerBound && !upperBound) {
      return this.translate.instant('general.no_matter_l') as string; // Neither bound defined
    }

    const { bound, prefix } = this.getRangeOrValue(bounds);
    let val = bound;
    if (showPrefix && prefix) {
      val = `${prefix} ${val}`;
    }

    return val.trim();
  }

  private getRangeOrValue(bounds: {
    lowerBound?: number | null;
    upperBound?: number | null;
    suffix?: string;
  }): { bound: string; prefix?: string } {
    const { lowerBound, upperBound, suffix = '' } = bounds;
    if (lowerBound != null && upperBound == null) {
      return {
        bound: `${lowerBound} ${suffix}`,
        prefix: 'min. '
      };
    } else if (upperBound != null && lowerBound == null) {
      return {
        bound: `${upperBound} ${suffix}`,
        prefix: 'max. '
      };
    } else if (lowerBound != null && upperBound != null) {
      return {
        bound: `${lowerBound} - ${upperBound} ${suffix}`
      };
    }
  }
}
