<app-card
  [padding]="'none'"
  [clickable]="!isDisabled"
  (click)="onBoxClick()"
  class="portal"
  [ngClass]="{ disabled: isDisabled }"
>
  <div class="row">
    <div class="col-3 d-flex right-divider">
      <div class="portal__image">
        <img src="{{ config()?.imgUrl }}" />
      </div>
    </div>
    <div class="col-7">
      <div class="portal__info">
        <p class="default-s semibold portal__info-name">
          <span>{{ config()?.fullName | translate }}</span>
          @if (credential() && credential().portal !== 'EBAY') {
            <span> - {{ credential().name }}</span>
          }
        </p>
        @if (credential()) {
          <p class="default-s portal__info-user">
            {{
              !isImmoscout
                ? ('portals.username_password_used_l' | translate)
                : ('portals.token_used_l' | translate)
            }}
          </p>
        }
      </div>
    </div>
    <div class="col-2 d-flex justify-content-end align-items-center">
      @if (selectable() && deselectable()) {
        <div class="portal__checkbox">
          <app-check
            [formControl]="checkControl"
            [forceDisabled]="disabled()"
          ></app-check>
        </div>
      }
      @if (!deselectable()) {
        <div
          class="portal__checkbox"
          [ngbTooltip]="blockTooltip"
          placement="top"
          [container]="'body'"
          tooltipClass="block-tooltip"
        >
          <app-check
            [formControl]="checkControl"
            [forceDisabled]="disabled() || !deselectable()"
          ></app-check>
        </div>
      }
      @if (removable()) {
        <app-button
          class="portal__remove-button"
          [type]="'context-menu'"
          [iconLeft]="'delete'"
          [elevation]="1"
          (clickEvent)="onRemove()"
          ngbTooltip="{{ 'REMOVE_PORTAL_A' | translate }}"
          [container]="'body'"
          [openDelay]="1000"
        >
        </app-button>
      }
    </div>
  </div>
</app-card>

<ng-template #blockTooltip>
  <span>{{ 'BLOCKED_PORTALS_DESELECT_M' | translate }}</span>
</ng-template>
