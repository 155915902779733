import {
  Component,
  forwardRef,
  inject,
  OnInit,
  input,
  output
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';

import { PortalConfig, PortalCredential, PortalType } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { BACKEND_URL } from '../../tokens';

import { BasePortalCredentialControl } from '../../controls';

import { CredentialValidatorService } from '../../services';
import { ComponentsModule } from '../../../components';

@UntilDestroy()
@Component({
  selector: 'app-immoscout-credential-details',
  templateUrl: './immoscout-credential-details.component.html',
  styleUrls: ['./immoscout-credential-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImmoscoutCredentialsDetailsComponent),
      multi: true
    }
  ],
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class ImmoscoutCredentialsDetailsComponent
  extends BasePortalCredentialControl
  implements OnInit
{
  private fb = inject(FormBuilder);
  private credentialValidatorService = inject(CredentialValidatorService);
  private backendUrl = inject(BACKEND_URL);

  public readonly portalConfig = input<PortalConfig>(undefined);
  public readonly credential = input<PortalCredential>(undefined);
  readonly getIs24URL = output();

  public includeChannel: boolean;

  constructor() {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      properties: this.fb.group({
        verificationCode: ['', Validators.required]
      })
    });
  }

  public ngOnInit() {
    this.includeChannel =
      this.portalConfig().portalId === PortalType.IMMOBILIENSCOUT24_GC_DE;

    if (this.includeChannel) {
      this.propertiesControl.addControl(
        'channel',
        new FormControl('', [Validators.required])
      );
    }

    const credentialValue = this.credential();
    if (credentialValue) {
      this.form.patchValue(credentialValue);
    }

    this.form.valueChanges.subscribe(credential => {
      this.value = { ...credential };

      this.onChange(this.value);
      this.onTouch();
    });

    this.credentialValidatorService.validationError$
      .pipe(
        filter(portal => !!portal),
        untilDestroyed(this)
      )
      .subscribe(portal =>
        this.setCredentialValidationError(
          this.portalConfig().validationControl || 'password',
          portal === this.credential().portal
        )
      );
  }

  public onGetIs24URL() {
    this.getIs24URL.emit();
  }
}
