import { Component, inject, input, output } from '@angular/core';

import { CookiePreference } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';

import { fadeOnEnterLeaveAnimation } from '../../../utils';
import { INFRASTRUCTURE_CONFIG } from '../../../infrastructure/infrastructure-config.token';

@Component({
  animations: [fadeOnEnterLeaveAnimation],
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  imports: [ButtonComponent, TranslateModule]
})
export class CookieBannerComponent {
  private config = inject(INFRASTRUCTURE_CONFIG);

  public get showCookieBanner() {
    const cookiePreference = this.cookiePreference();
    return (
      !cookiePreference ||
      (new Date().getTime() - cookiePreference?.lastUpdated) / 1000 >
        this.ONE_YEAR_IN_SEC
    );
  }

  private get isTenantApp() {
    return this.config.environment.app_name === 'tenant';
  }

  public get isMobile() {
    return this.config?.environment?.mobile;
  }

  readonly cookiePreference = input<CookiePreference>(undefined);
  readonly acceptAll = output<CookiePreference>();
  readonly customSettings = output<CookiePreference>();

  private ONE_YEAR_IN_SEC = 31557600;

  private DEFAULT_PREFERENCE = {
    allowRequired: true,
    allowPerformance: false,
    allowFunctional: false,
    lastUpdated: new Date().getTime()
  };

  onAcceptAll() {
    this.acceptAll.emit({
      allowRequired: true,
      allowPerformance: true,
      allowFunctional: true,
      lastUpdated: new Date().getTime()
    });
  }

  onCustomSettings() {
    this.customSettings.emit(
      this.cookiePreference() || this.DEFAULT_PREFERENCE
    );
  }

  dataPrivacyRedirect() {
    this.isTenantApp
      ? window.open('https://www.mieter.immomio.com/datenschutz', '_blank')
      : window.open('https://www.vermieter.immomio.com/datenschutz', '_blank');
  }
}
