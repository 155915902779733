import { Component, forwardRef, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  HierarchicalQuestionIdType,
  HierarchicalQuestionType
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { AppInputDirective } from '../../../form/controls/input/input.directive';
import { DateComponent } from '../../../form/controls/date/date.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { BaseControl } from '../../../form/controls/base-control';

@Component({
  selector: 'app-hierarchical-question-input',
  templateUrl: './hierarchical-question-input.component.html',
  styleUrls: ['./hierarchical-question-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalQuestionInputComponent),
      multi: true
    }
  ],
  imports: [
    FormFieldLabelComponent,
    FormFieldComponent,
    DateComponent,
    FormsModule,
    AppInputDirective,
    TranslateModule
  ]
})
export class HierarchicalQuestionInputComponent extends BaseControl<any> {
  readonly hierarchicalQuestion = input<HierarchicalQuestion>(undefined);
  readonly currentLanguage = input<AvailableLanguageCodesEnum>(undefined);
  readonly defaultLanguage = input<AvailableLanguageCodesEnum>(undefined);
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onAnswerHasChanged = output<any>();

  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };

  public startDateFutureStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };

  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date() - 1
  };

  public maxDateFutureStruct: NgbDateStruct = {
    year: moment().year() + 100,
    month: moment().month(),
    day: moment().date()
  };

  public get isFutureDate() {
    return (
      this.hierarchicalQuestion().data.idType !==
      HierarchicalQuestionIdType.BIRTHDATE
    );
  }

  public get isRangeDate() {
    return (
      this.hierarchicalQuestion().data?.type ===
      HierarchicalQuestionType.RANGE_DATE
    );
  }

  public get isRangeNumber() {
    return (
      this.hierarchicalQuestion().data?.type ===
      HierarchicalQuestionType.RANGE_NUMBER
    );
  }

  public get isDate() {
    return (
      this.hierarchicalQuestion().data?.type ===
      HierarchicalQuestionType.INPUT_DATE
    );
  }

  public get isText() {
    return (
      this.hierarchicalQuestion().data?.type ===
      HierarchicalQuestionType.INPUT_TEXT
    );
  }

  public get options() {
    return (
      this.hierarchicalQuestion().answers?.map(answer => ({
        name: answer.data.answerId,
        value: answer.id
      })) || []
    );
  }

  public writeValue(value: any) {
    this.value = value;
  }
}
