import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
  input,
  output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AvailableLanguageCodesEnum, IconTypeEnum } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';
import { SelectOption } from '../select/select-option/select-option';
import { ButtonComponent } from '../../../../atoms/button/button.component';
import {
  buildBEMClassNamesByGivenBaseClassAndFlags,
  isObject
} from '../../../../../utils';

@Component({
  selector: 'app-flat-select',
  templateUrl: './flat-select.component.html',
  styleUrls: ['./flat-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatSelectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => FlatSelectComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, ButtonComponent, TranslateModule]
})
export class FlatSelectComponent extends BaseControl<string | string[]> {
  private cd = inject(ChangeDetectorRef);

  readonly icon = input<IconTypeEnum>(undefined);
  readonly itemLabelKey = input('name');
  readonly itemValueKey = input('value');
  readonly multiple = input(false);
  readonly readonly = input(false);
  readonly showAddButton = input(false);
  readonly addButtonText = input('ADD_NEW_A');
  readonly allowUncheck = input(false);
  readonly deletable = input(false);
  readonly currentLanguage = input<AvailableLanguageCodesEnum>(undefined);
  readonly defaultLanguage = input<AvailableLanguageCodesEnum>(undefined);
  readonly stretch = input(true);
  readonly wrapItems = input(false);

  readonly remove = output<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onAdd = output();

  private _touched = false;
  private _errors = null;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  options: SelectOption[];

  @Input()
  set items(items: any[]) {
    this.options = items?.length
      ? items
          .filter(item => !item.hide)
          .map(
            (item: any) =>
              new SelectOption(item, this.itemLabelKey(), this.itemValueKey())
          )
      : [];
  }

  public writeValue(value: string | string[]) {
    super.writeValue(value);
    this.cd.detectChanges();
  }

  selectOption(option: SelectOption) {
    if (option.disabled || this.readonly()) return;
    const multiple = this.multiple();
    if (multiple && !Array.isArray(this.value)) {
      throw new Error('"value" must be an array');
    }

    if (multiple && Array.isArray(this.value)) {
      const foundOption = this.value.find(element => element === option.value);

      this.value = foundOption
        ? this.value.filter(element => element !== option.value)
        : [...this.value, option.value];

      option.selected = !foundOption;
      this.touched = true;
    } else {
      const allowUncheck = this.allowUncheck();
      if (isObject(option.value) && isObject(this.value)) {
        if (
          ((option.value as any) || {}).id === ((this.value as any) || {}).id &&
          !allowUncheck
        )
          return;
      }
      if (this.value === option.value && !allowUncheck) return;

      this.touched = true;

      option.selected = this.value !== option.value;
      this.value = option.selected ? option.value : '';
    }
  }

  isActive(option: SelectOption) {
    if (isObject(option.value) && isObject(this.value)) {
      return (
        ((option.value as any) || {}).id === ((this.value as any) || {}).id
      );
    }
    return (
      option.value === this.value ||
      (this.multiple() && this.value && this.value.indexOf(option.value) !== -1)
    );
  }

  add() {
    this.onAdd.emit();
  }

  public onRemove(index: number) {
    this.remove.emit(this.options[index].value);
  }

  public returnValidLabel(option: SelectOption): string {
    if (
      option.label[this.currentLanguage()] ||
      option.label[this.currentLanguage()] === '' ||
      option.label[this.defaultLanguage()] ||
      option.label[this.defaultLanguage()] === ''
    ) {
      const languageAdjustedLabel: string =
        option.label[this.currentLanguage()] ||
        option.label[this.defaultLanguage()];
      return languageAdjustedLabel;
    } else {
      return option.label;
    }
  }

  public getIconClassName(iconType: IconTypeEnum): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags('icon', {
      [iconType]: !!iconType
    });
  }
}
