import { Address } from './address.model';
import { SearchProfileType } from './enums';
import { ProjectData } from './projects.model';
import { CommercialData, MarketingType } from './property.model';

export interface SearchProfile {
  address?: Address;
  id?: string;
  radius?: number;
  lowerBoundRooms?: number;
  upperBoundRooms?: number;
  lowerBoundSize?: number;
  upperBoundSize?: number;
  manuallyCreated?: boolean;
  name?: string;
  upperBoundRent?: number;
  upperBoundBaseRent?: number;
  type?: SearchProfileType;
  districts?: District[];
  balconyTerrace?: boolean;
  barrierFree?: boolean;
  managedByLandlord?: boolean;
  seniorApartment?: boolean;
  durationEndDate?: Date;
  deactivated?: boolean;
  garageTypes?: string[];
  commercialTypes?: string[];
  propertyType?: string;
  marketingType?: MarketingType;
  createdByPS?: boolean;
  customer?: SearchProfileCustomer;
  landlordDistricts?: string[][];
  salesData?: SalesDataSearchProfile;
  flatData?: FlatData;
  projectId?: string;
  projectLandingPageUrl?: string;
  projectData?: ProjectData;
  searchingSince?: Date;
  elevator?: boolean;
  ignoreFloorIfElevatorExists?: boolean;
  upperBoundFloor?: number;
  lowerBoundFloor?: number;
  commercialData?: CommercialData;
}

export type SearchProfilePatchedData = Omit<SearchProfile, 'districts'> & {
  districts: {
    city: SearchCity;
    districts: District[];
  }[];
};

export interface District {
  id?: string;
  name?: string;
  cityName?: string;
  cityId?: string;
  checked?: boolean;
  lat?: number;
  lng?: number;
}

export interface CityMapTuple {
  name?: string;
  value?: District[];
  lat?: number;
  lng?: number;
  checked?: string;
}

export interface SearchProfileCustomer {
  id: string;
  name?: string;
  logo?: string;
}

export interface SearchCity {
  id?: string;
  name?: string;
  state?: string;
}

export interface SearchDistrict {
  id?: string;
  name?: string;
  cityName?: string;
  city?: SearchCity;
  checked?: boolean;
}

export interface LandlordDistrictGroupUpdateBean {
  id?: string;
  name?: string;
  country?: string;
  districts?: string[];
  children?: LandlordDistrictGroupUpdateBean[];
}

export interface LandlordDistrictGroupBean {
  id?: string;
  name?: string;
  districts: District[];
  children: LandlordDistrictGroupBean[];
  country?: string;
}

export interface CityPacket {
  id: string;
  name: string;
  country?: string;
  citiesMap: Map<string, any[]>;
  citiesDistrictsMap: Map<string, District[]>;
}

export interface SalesDataSearchProfile {
  priceUpperBound: number;
}

export interface FlatData {
  objectTypes: string[];
  flatTypes: string[];
  houseTypes: string[];
}

export const COUNTRIES_AND_DISTRICTS_ERRORS = [
  'DEFAULT_COUNTRY_NOT_PART_OF_SELECTED_COUNTRIES_L',
  'COUNTRY_CODE_DOESNT_MATCH_FOR_SELECTED_DISTRICT_L',
  'CUSTOMER_PROPERTY_CONTAINS_REMOVED_COUNTRY_CODE_L',
  'LANDLORD_DISTRICT_GROUP_CONTAINS_REMOVED_COUNTRY_CODE_L',
  'CUSTOMER_REGISTRATION_TOKEN_CONTAINS_REMOVED_COUNTRY_CODE_L'
];
