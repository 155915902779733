import { Component, input, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomQuestion, RootQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { HierarchicalRootQuestionFormComponent } from '../hierarchical-root-question-form/hierarchical-root-question-form.component';
import { CustomQuestionsFormComponent } from '../custom-questions-form/custom-questions-form.component';

@Component({
  selector: 'app-custom-questions-modal-content',
  templateUrl: './custom-questions-modal-content.component.html',
  styleUrls: ['./custom-questions-modal-content.component.scss'],
  imports: [
    CustomQuestionsFormComponent,
    FormsModule,
    ReactiveFormsModule,
    HierarchicalRootQuestionFormComponent,
    TranslateModule
  ]
})
export class CustomQuestionsModalContentComponent {
  readonly control = input(new FormControl());
  readonly hierarchicalRootQuestionControl = input(new FormControl());
  readonly isProcessing = input<boolean>(undefined);
  readonly hierarchicalRootQuestions = input<RootQuestion[]>(undefined);
  readonly customQuestions = input<CustomQuestion[]>(undefined);
  readonly hierarchicalQuestionErrorMessage = input<string>(undefined);
  readonly formValidityChange = output<boolean>();
  readonly hierarchicalFormValidityChange = output<boolean>();

  public onFormValidityChange(value: boolean) {
    this.formValidityChange.emit(value);
  }

  public onHierarchicalFormValidityChange(value: boolean) {
    this.hierarchicalFormValidityChange.emit(value);
  }
}
