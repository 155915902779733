import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appEnergyClass'
})
export class EnergyClassPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'A_P':
        return 'A+';
      case 'A_P_P':
        return 'A++';
      default:
        return value;
    }
  }
}
