import { inject, Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../services/date-time.service';

@Pipe({
  name: 'appMessageTime'
})
export class MessageTimePipe implements PipeTransform {
  private dateTimeService = inject(DateTimeService);

  transform(date: Date | string) {
    if (!date) return '';
    return this.dateTimeService.getDateCalenderInFormat(date);
  }
}
