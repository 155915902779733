import {
  ComponentRef,
  DestroyRef,
  Directive,
  inject,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl, TouchedChangeEvent } from '@angular/forms';
import { EMPTY, filter, merge, take } from 'rxjs';

import { FormErrorComponent } from '../../components/atoms/form/form-error/form-error.component';
import { ControlErrorContainerDirective } from './form-control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { FORM_ERRORS } from './error-messages';

/**
 {@link FormControlErrorsDirective} is used to inject {@link FormErrorComponent} relative to formControl or formControlName directives.

 The way it works as follows:
 1. the directive is assigned to any element having formControl/formControlName attribute, that also has showError
 2. it listens to value changes (when touched) from the control or submit event from the form element (@see {@link FormSubmitDirective} for submit event)
 3. if there is an error, then {@link FormErrorComponent} is injected into the container of the component/ parent container (@see {@link ControlErrorContainerDirective})

 @example
 <div>
 <input formControlName="control" showError/>
 <--- FormErrorComponent injected here
 </div>

 @param {Record<string, string>} errors - application wide errors, injecting {@link FORM_ERRORS} values
 */

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '([formControlName][showError], [formControl][showError])'
})
export class FormControlErrorsDirective implements OnInit {
  private destroyRef = inject(DestroyRef);
  private errors = inject(FORM_ERRORS);
  private formSubmitDirective = inject(FormSubmitDirective, {
    optional: true,
    host: true
  });
  private vcr = inject(ViewContainerRef);
  private control = inject(NgControl);

  private controlErrorContainer = inject(ControlErrorContainerDirective, {
    optional: true
  });

  private ref!: ComponentRef<FormErrorComponent>;
  private container = this.controlErrorContainer
    ? this.controlErrorContainer.vcr
    : this.vcr;
  private submit$ = this.formSubmitDirective
    ? this.formSubmitDirective.submit$
    : EMPTY;

  ngOnInit() {
    const touchedEvent$ = this.control.control.events.pipe(
      filter(
        event =>
          event instanceof TouchedChangeEvent && !!this.control.control?.dirty
      ),
      take(1)
    );
    const valueChanges$ = this.control.control.valueChanges.pipe(
      filter(() => !!this.control.touched)
    );
    merge(this.submit$, touchedEvent$, valueChanges$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.checkErrors());
  }

  private checkErrors() {
    const controlErrors = this.control.errors;
    if (controlErrors) {
      const [firstKey] = Object.keys(controlErrors);
      const error = this.getError(this.errors[firstKey]);
      this.setError(error);
    } else if (this.ref) {
      this.setError(null);
    }
  }

  private getError(
    error: string | Record<string, string | Record<string, string>>
  ): string {
    if (typeof error === 'string') return error;

    let [value] = Object.values(error);
    while (typeof value === 'object') {
      value = Object.values(value)[0];
    }
    return value;
  }

  private setError(text: string | null) {
    if (!this.ref) {
      this.ref = this.container.createComponent(FormErrorComponent);
    }

    this.ref.setInput('text', text);
  }
}
