import { inject, Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { DecimalPipe } from '@angular/common';
import { isBoolean, isNumber } from '../utils';

@Pipe({
  name: 'appHumanizeBoolean'
})
export class HumanizeBooleanPipe implements PipeTransform {
  private translate = inject(TranslateService);
  private decimalPipe = inject(DecimalPipe);

  transform(value: any, placeholder: string = null): any {
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    // true  false
    if (isBoolean(value))
      return this.translate.instant(value ? 'general.yes_l' : 'general.no_l');
    // numbers (including 0)
    if (isNumber(value) && !isNaN(value))
      return this.decimalPipe.transform(value);
    // null undefined '' (empty string))
    if (!value) return this.translate.instant(placeholder || 'NOT_AVAILABLE_L');
    // complex types
    if (typeof value !== 'string') return value;
    // others
    return this.translate.instant(
      value || (placeholder ? placeholder : 'NOT_AVAILABLE_L')
    );
    /* eslint-enable @typescript-eslint/no-unsafe-return */
  }
}
