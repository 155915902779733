import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';

@Injectable()
export class NewRelicService {
  private doc = inject(DOCUMENT);
  private infrastructureConfig = inject(INFRASTRUCTURE_CONFIG);

  public init() {
    const deploymentEnv = this.infrastructureConfig.environment.deploymentEnv;
    switch (deploymentEnv) {
      case 'integration':
      case 'production': {
        this.injectScript(deploymentEnv);
        break;
      }

      default:
        break;
    }
  }

  private injectScript(path: string) {
    const scriptEl = this.doc.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.src = `new-relic-${path}.js`;
    this.doc.head.appendChild(scriptEl);
  }
}
