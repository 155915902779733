<label
  class="app-label d-flex align-items-center"
  [class.app-label--disabled]="disabled()"
  [class.app-label--small]="smallText()"
  [class.app-label--red]="redText()"
  [class.mb-0]="noBottomMargin()"
  [for]="idFor()"
>
  <ng-content></ng-content>
  @if (showRequiredMarker()) {
    <span class="required-marker">&nbsp;*</span>
  }
</label>
