import {
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
  input,
  viewChild
} from '@angular/core';
import {
  ControlContainer,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

import { SelectOption } from './select-option/select-option';

@UntilDestroy()
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SelectComponent)
    }
  ],
  imports: [FormsModule, NgClass, TranslateModule]
})
export class SelectComponent
  extends BaseControl<string | number | { [key: string]: any }>
  implements OnInit
{
  private controlContainer = inject(ControlContainer, {
    optional: true,
    host: true,
    skipSelf: true
  });

  readonly ngControl = viewChild(NgControl);
  readonly itemLabelKey = input('name');
  readonly itemValueKey = input('value');
  readonly multiple = input(false);
  readonly sharedModel = input<string>(undefined);
  readonly readonly = input(false);
  readonly showAdditionalItem = input(false);

  get valueNotAItem() {
    return !this.options.some(option => option.value === this.value);
  }

  get additionalItem() {
    return (
      this.valueNotAItem &&
      new SelectOption(this.value, this.itemLabelKey(), this.itemValueKey())
    );
  }

  options: SelectOption[];

  @Input()
  set items(items: any[]) {
    this.options =
      items && items.length
        ? items.map(
            (item: any) =>
              new SelectOption(item, this.itemLabelKey(), this.itemValueKey())
          )
        : [];
  }

  ngOnInit() {
    const sharedModel = this.sharedModel();
    if (sharedModel && this.controlContainer) {
      this.controlContainer.control
        .get(sharedModel)
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe(value => {
          this.value = value;
        });
    }
    this.ngControl()
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  shouldAddSharedModelActiveClass() {
    return (
      this.sharedModel() &&
      this.options.find(option => option.value === this.value)
    );
  }
}
