import { Component, input, output } from '@angular/core';
import { AvailableLanguageCodesEnum, Language } from '@ui/shared/models';
import { FormsModule } from '@angular/forms';
import { LocaleFlagComponent } from '../../../../atoms/image/locale-flag/locale-flag.component';
import { LangPickerComponent } from '../../../../molecules/lang-picker/lang-picker.component';

@Component({
  selector: 'app-form-field-lang-picker',
  templateUrl: './form-field-lang-picker.component.html',
  styleUrls: ['./form-field-lang-picker.component.scss'],
  imports: [LangPickerComponent, FormsModule, LocaleFlagComponent]
})
export class FormFieldLangPickerComponent {
  readonly availableLanguages = input<Language[]>(undefined);
  readonly defaultLanguageCode = input<AvailableLanguageCodesEnum>(undefined);
  readonly currentLanguageCode = input<AvailableLanguageCodesEnum>(undefined);
  readonly languageChangeEvent = output<AvailableLanguageCodesEnum>();

  public onLanguageChange(langCode: AvailableLanguageCodesEnum) {
    this.languageChangeEvent.emit(langCode);
  }
}
