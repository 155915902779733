<aside
  *ngrxLet="navigationDrawer.narrowAndNotMobileView$ as narrowAndNotMobileView"
  class="navigation-drawer"
  [class.navigation-drawer--left]="(navigation.alignment$ | async) !== 'right'"
  [class.navigation-drawer--right]="(navigation.alignment$ | async) === 'right'"
  [class.navigation-drawer--overlay]="navigation.overlay$ | async"
  [class.navigation-drawer--floating-sub-menu]="
    navigationDrawer.isFloatingSubMenu$ | async
  "
  [class.navigation-drawer--dark]="navigation.dark$ | async"
  [@slide]="{
    value: slideAnimationState$ | async,
    params: { offset: widthWide }
  }"
  [@collapseX]="{
    value: narrowAndNotMobileView === true ? 'closed' : 'open',
    params: { width: collapseXWidth(narrowAndNotMobileView === true) }
  }"
  [elevation]="(navigationDrawer.isFloatingSubMenu$ | async) ? 2 : 0"
  appElevation
>
  <div
    class="navigation-drawer__scroll-container-inner"
    [class.navigation-drawer__scroll-container-inner--no-bottom-spacing]="
      showNarrowToggleContainer$ | async
    "
  >
    <div
      #header
      class="navigation-drawer__header"
      [class.navigation-drawer__header--visible]="
        (navigationDrawer.narrow$ | async) === false && header.children.length
      "
      [@innerFade]="innerFadeAnimationState(navigationDrawer.open$ | async)"
    >
      <ng-content select="header"></ng-content>
    </div>

    <app-navigation-drawer-list
      class="navigation-drawer__list"
      [items]="items()"
      [@innerFade]="innerFadeAnimationState(navigationDrawer.open$ | async)"
    >
    </app-navigation-drawer-list>

    <div
      #footer
      class="navigation-drawer__footer"
      [class.navigation-drawer__footer--visible]="
        (navigationDrawer.narrow$ | async) === false && footer.children.length
      "
      [@innerFade]="innerFadeAnimationState(navigationDrawer.open$ | async)"
    >
      <ng-content select="footer"></ng-content>
    </div>
  </div>

  @if (showNarrowToggleContainer$ | async) {
    <div class="navigation-drawer__narrow-toggle-container">
      <app-navigation-drawer-list
        [@innerFade]="innerFadeAnimationState(navigationDrawer.open$ | async)"
      >
        <app-navigation-drawer-item
          *ngrxLet="navigationDrawer.narrow$ as narrow"
          [item]="getNarrowToggleItem(navigation.alignment$ | async, narrow)"
          [narrow]="narrow"
          [noTextWrap]="true"
          (clickEvent)="onNarrowToggleClick(narrow)"
        ></app-navigation-drawer-item>
      </app-navigation-drawer-list>
    </div>
  }
</aside>
