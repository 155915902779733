<div class="add-portal-form">
  @for (portal of availablePortals(); track portal.portalId) {
    <app-available-portal
      [portalConfig]="portal"
      [selected]="isSelected(portal)"
      (getIs24URL)="onGetIs24URL()"
      (togglePortal)="onToggle($event)"
      (update)="onUpdate($event)"
    >
    </app-available-portal>
  }
</div>
