import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '@ui/shared/models';
import { canViewPropertyProspects } from '../../utils';

@Pipe({
  standalone: true,
  name: 'canViewProspectsPipe'
})
export class CanViewProspectsPipePipe implements PipeTransform {
  transform(property: Property): boolean {
    return canViewPropertyProspects(property);
  }
}
