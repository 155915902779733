@switch (level()) {
  @case (1) {
    <h1 [ngClass]="classString()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h1>
  }
  @case (2) {
    <h2 [ngClass]="classString()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h2>
  }
  @case (3) {
    <h3 [ngClass]="classString()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h3>
  }
  @case (4) {
    <h4 [ngClass]="classString()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h4>
  }
  @default {
    <span [ngClass]="classString()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
  }
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
