import { Directive, input, OnDestroy, OnInit, output } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { Step, StepChange } from '../models';

@Directive({})
export abstract class WizardStep implements OnInit, OnDestroy {
  readonly stepChange = input<Subject<StepChange>>(undefined);

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  readonly onNextStep = output<string | void>();
  readonly onPreviousStep = output<string | void>();
  readonly onSelectStep = output<Step>();
  readonly onComplete = output();
  readonly onCancel = output();
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  private stepChangeSubscriber: Subscription;

  ngOnInit() {
    this.stepChangeSubscriber = this.stepChange()?.subscribe(
      (change: StepChange) => {
        switch (change.action) {
          case 'next': {
            this.nextStep();
            break;
          }

          case 'back': {
            this.previousStep();
            break;
          }

          case 'complete': {
            this.complete();
            break;
          }

          case 'cancel': {
            this.cancel();
            break;
          }

          case 'select': {
            this.selectStep(change.step);
            break;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.stepChangeSubscriber?.unsubscribe();
  }

  nextStep() {
    this.onNextStep.emit();
  }

  previousStep() {
    this.onPreviousStep.emit();
  }

  selectStep(step: Step) {
    this.onSelectStep.emit(step);
  }

  complete() {
    this.onComplete.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}
