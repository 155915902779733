import {
  Component,
  ElementRef,
  forwardRef,
  OnInit,
  input,
  viewChild
} from '@angular/core';

import { FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DecimalPipe, NgClass } from '@angular/common';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';
import { isValueNullOrUndefined } from '../../../../../utils';

@UntilDestroy()
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SliderComponent)
    }
  ],
  imports: [NgClass, FormsModule, DecimalPipe]
})
export class SliderComponent extends BaseControl<number> implements OnInit {
  readonly min = input(0);
  readonly max = input(100);
  readonly step = input(1);
  readonly unit = input('');
  readonly unitMin = input('');
  readonly unitMax = input('');
  readonly hideActiveValue = input(false);

  // this.value cannot be used, because the slider value needs to be a number
  // if the slider value is the same as this.max, then this.value is Infinity
  public valueShownInUi: number;

  readonly ngControl = viewChild(NgControl);
  readonly sliderCurrentValue = viewChild<ElementRef>('sliderCurrentValue');
  readonly sliderFallbackProgress = viewChild<ElementRef>(
    'sliderFallbackProgress'
  );

  public get positiveValue() {
    this.updateStyles();
    return this.value > -1 ? this.value : this.min();
  }

  public get currentValueEqualsMin() {
    return this.value === this.min();
  }

  public get currentValueEqualsMax() {
    return this.value >= this.max() || isValueNullOrUndefined(this.value);
  }

  ngOnInit() {
    // Listen for changes of the user to the slider
    this.ngControl()
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => {
        // If the max value is selected, then "Infinity" should be saved
        // this.max should be shown in the ui (value of ngControl), because "Infinity" can't be represented
        if (this.isInfinity(value)) {
          this.value = Infinity;
          // We don't need to set this.max here, because value is already this.max
        } else {
          this.value = value;
        }
      });

    this.ngControl()
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  public writeValue(value?: number | null) {
    // If the max value is selected, then "Infinity" should be saved
    if (this.isInfinity(value)) {
      super.writeValue(Infinity);
      // this.max should be shown in the ui (value of ngControl), because "Infinity" can't be represented
      this.valueShownInUi = this.max();
    } else {
      super.writeValue(value);
      this.valueShownInUi = value;
    }
  }

  private isInfinity(value: number | null) {
    // Yes it's correct, that if the value is null it's the max possible value (Infinity)
    return (
      value === null || (value === this.max() && this.unitMax().includes('+'))
    );
  }

  private updateStyles() {
    const sliderFallbackProgress = this.sliderFallbackProgress();
    const sliderCurrentValue = this.sliderCurrentValue();
    if (
      !(
        typeof sliderFallbackProgress !== 'undefined' &&
        typeof sliderCurrentValue !== 'undefined'
      )
    )
      return;
    const isChrome =
      !!window['chrome'] &&
      (!!window['chrome'].runtime || !!window['chrome'].csi);
    const isSafari = !!/Version\/[\d.]+.*Safari/.exec(navigator.userAgent);

    const thumbWidthInPx = 16;

    // chrome & safari do not provide any native way to fill lower art of range input
    const lowerSliderDisplay = isChrome || isSafari ? 'inline-block' : 'none';

    const calcValue = this.currentValueEqualsMax
      ? this.max()
      : this.valueShownInUi;

    const progressDistanceCss = `calc(${
      ((calcValue - this.min()) * 100) / (this.max() - this.min())
    }% - ${(calcValue / this.max()) * thumbWidthInPx}px + ${
      thumbWidthInPx / 2
    }px`;

    const currentValueDisplay =
      calcValue !== this.min() && calcValue !== this.max() ? 'block' : 'none';

    sliderFallbackProgress.nativeElement.style.display = lowerSliderDisplay;
    sliderFallbackProgress.nativeElement.style.width = progressDistanceCss;

    sliderCurrentValue.nativeElement.style.display = currentValueDisplay;
    sliderCurrentValue.nativeElement.style.left = progressDistanceCss;
  }

  public manualValueReset(key: string): void {
    this.valueShownInUi = key === 'max' ? this.max() : this.min();

    if (this.isInfinity(this.valueShownInUi)) {
      this.value = Infinity;
    } else {
      this.value = this.valueShownInUi;
    }
  }
}
