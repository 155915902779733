import {
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  ElementRef,
  inject,
  output,
  viewChild
} from '@angular/core';
import {
  NgbNav,
  NgbNavChangeEvent,
  NgbNavContent,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { SideModalContentDirective } from './side-modal-content.directive';

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    NgTemplateOutlet,
    NgbNavOutlet,
    TranslateModule
  ]
})
export class SideModalComponent {
  private _router = inject(Router);

  readonly navOutlet = viewChild<
    ElementRef<HTMLDivElement>,
    ElementRef<HTMLDivElement>
  >('navOutlet', { read: ElementRef });
  readonly contents = contentChildren(SideModalContentDirective);
  readonly navChanged = output<any>();

  public activeNav: string;

  public dismiss(): void {
    void this._router.navigate(['', { outlets: { side: null } }], {
      queryParamsHandling: 'preserve'
    });
  }

  public onNavChange(event: NgbNavChangeEvent) {
    this.navChanged.emit(event.nextId);
    this.scrollToTop();
  }

  private scrollToTop(): void {
    this.navOutlet().nativeElement.scroll(0, 0);
  }
}
