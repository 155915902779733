import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output
} from '@angular/core';

import moment from 'moment';

import { TranslateModule } from '@ngx-translate/core';

import { FullNamePipe } from '../../../../../pipes/full-name.pipe';
import { DateTimePipe } from '../../../../../pipes/date-time.pipe';
import { BadgeComponent } from '../../../../atoms/badge/badge.component';
import {
  AvatarComponent,
  AvatarSizeEnum
} from '../../../avatar/avatar.component';
import { Conversation } from '../../model';

import { BadgeSizeEnum } from '../../../../atoms/badge';
import { chatBadgeColor } from './chat-information';

@Component({
  selector: 'app-chat-contact-item',
  templateUrl: './chat-contact-item.component.html',
  styleUrls: ['./chat-contact-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent, BadgeComponent, TranslateModule, FullNamePipe]
})
export class ChatContactItemComponent {
  private datePipe = inject(DateTimePipe);

  conversation = input.required<Conversation>();
  readonly isLandlord = input<boolean>(undefined);
  readonly isPropertySearcher = input<boolean>(undefined);
  readonly activeConversation = input<Conversation>(undefined);
  readonly selectConversation = output<Conversation>();

  timeOfLastMessage = computed(() => {
    const lastMessage = this.conversation()?.lastMessageDate;
    if (this.isToday(lastMessage)) {
      return this.datePipe.transform(lastMessage, {
        withDate: false,
        withTime: true
      });
    }
    if (this.isLastSevenDays(lastMessage)) {
      return this.datePipe.transform(lastMessage, { customPattern: 'dd' });
    }
    if (this.isInThePast(lastMessage)) {
      return this.datePipe.transform(lastMessage, { customPattern: 'MMM DD' });
    }
  });

  protected readonly AvatarSizeEnum = AvatarSizeEnum;
  protected readonly chatBadgeColor = chatBadgeColor;

  public removeBreaks(message: string) {
    if (!message) return;
    let replacedMessage = message;
    replacedMessage = replacedMessage.replace(/<br \/>/gi, ' ');
    replacedMessage = replacedMessage.replace(/<br\/>/gi, ' ');
    return replacedMessage;
  }

  public onSelectConversation(conversation: Conversation) {
    this.selectConversation.emit(conversation);
  }

  public isToday(date: string) {
    return moment().isSame(date, 'days');
  }

  public isLastSevenDays(date: string) {
    return moment().diff(date, 'days') <= 7 && !moment().isSame(date, 'days');
  }

  public isInThePast(date: string) {
    return !this.isToday(date) && !this.isLastSevenDays(date);
  }

  protected readonly BadgeSizeEnum = BadgeSizeEnum;
}
