import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  output
} from '@angular/core';
import { BreadcrumbItem } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule]
})
export class BreadcrumbsComponent {
  private _items: BreadcrumbItem[];

  @Input() get items(): BreadcrumbItem[] {
    return this._items;
  }

  set items(value) {
    this._items = this.handleBreadcrumbs(value);
  }

  readonly trim = input(true);
  readonly trimLength = input(3);
  readonly readonly = input<boolean>(undefined);
  readonly navigateToUrl = output<string>();

  public navigate(url: string): void {
    if (this.items[this.items.length - 1].url === url) return;
    this.navigateToUrl.emit(url);
  }

  private handleBreadcrumbs(
    breadcrumbs: BreadcrumbItem[] = []
  ): BreadcrumbItem[] {
    if (!this.trim() || breadcrumbs.length <= this.trimLength())
      return breadcrumbs;

    const indicesToShow = {
      0: true
    };

    for (
      let i = breadcrumbs.length;
      i > breadcrumbs.length - this.trimLength();
      i--
    ) {
      indicesToShow[i] = true;
    }

    return breadcrumbs.map((breadcrumb, index) => {
      if (indicesToShow[index]) return breadcrumb;

      return {
        url: undefined,
        label: '..'
      };
    });
  }
}
