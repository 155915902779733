import { Component, input, output } from '@angular/core';
import { Attachment } from '@ui/shared/models';
import { AttachmentsListComponent } from '../../../attachment/attachments-list/attachments-list.component';

@Component({
  selector: 'app-chat-attachment',
  templateUrl: './chat-attachment.component.html',
  styleUrls: ['./chat-attachment.component.scss'],
  imports: [AttachmentsListComponent]
})
export class ChatAttachmentComponent {
  readonly attachments = input<Attachment[]>(undefined);
  readonly showDownload = input(true);
  readonly disableDownload = input(false);
  readonly blockDownload = input(false);
  readonly isDocument = input(false);

  readonly download = output<Attachment>();
  readonly preview = output<Attachment>();

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }
}
