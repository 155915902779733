<div
  class="single-select-dropdown"
  ngbDropdown
  ngDefaultControl
  [required]="required()"
  [ngModel]="value"
  [class.disabled]="disabled || readOnly()"
>
  <div
    ngbDropdownAnchor
    class="form-control d-flex align-items-center"
    [ngClass]="{
      'bordered-radius-bottom colored-border': dropdown()?.isOpen()
    }"
  >
    <input
      appInput
      class="col-12"
      type="text"
      [placeholder]="
        selectedItem?.[placeholderSelectedItemKey()] ||
        (placeholder() || 'general.search_fulltext_l' | translate)
      "
      [formControl]="searchControl"
      [class.search-disabled]="disableSearch()"
      [class.selected-item-placeholder]="
        selectedItem && !searchControl.value && dropdown()?.isOpen()
      "
      (focus)="dropdown().open()"
      (focusout)="touch()"
    />
    @if (!isLoadingMenuItems()) {
      @if (selectedItem) {
        <svg-icon
          class="icon icon-remove"
          src="/assets/images/icons/icon-close.svg"
          [svgStyle]="{ 'width.px': 10, 'height.px': 10 }"
          (click)="removeSelectedItem()"
        ></svg-icon>
      }
      <svg-icon
        class="icon icon-dropdown"
        src="/assets/images/icons/icon-dropdown.svg"
        [class.rotate]="dropdown()?.isOpen()"
        (click)="dropdown().toggle()"
      ></svg-icon>
    } @else {
      <div class="loading-area-input">
        <app-loading-spinner></app-loading-spinner>
      </div>
    }

    <ng-template #loading>
      <div class="loading-area-input">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </ng-template>
  </div>

  <div
    class="dropdown-menu"
    [class.relative-positioned]="relativelyPositioned()"
    ngbDropdownMenu
  >
    <div class="dropdown__items" [class.d-none]="!dropdown()?.isOpen()">
      @if (items()?.length) {
        <app-infinite-scroll (scrolled)="onScroll()">
          @for (item of items(); track item[itemValueKey()]) {
            <div class="dropdown__item" (click)="apply(item)">
              <ng-template
                [ngTemplateOutlet]="templateRef()"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-template>
            </div>
          }
        </app-infinite-scroll>
      } @else {
        @if (
          searchCharactersLimit() > 0 &&
          searchControl.value.length < searchCharactersLimit()
        ) {
          <div class="no-items-content">
            <span
              >{{
                'single_select_dropdown.minimum_character_l'
                  | translate: { value: searchCharactersLimit() }
              }}
            </span>
          </div>
        } @else {
          <div class="no-items-content">
            <ng-content select="[no-items-content]"></ng-content>
          </div>
        }
      }
    </div>
  </div>
</div>
