import { Component, input } from '@angular/core';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from '../../../utils';

@Component({
  selector: 'app-smart-input-field-set',
  templateUrl: './smart-input-field-set.component.html',
  styleUrls: ['./smart-input-field-set.component.scss']
})
export class SmartInputFieldSetComponent {
  readonly label = input('');
  readonly placeholder = input('');
  readonly edited = input(false);
  readonly error = input(false);
  readonly errorMessage = input('');
  readonly required = input(false);
  readonly disabled = input(false);

  public baseClass = 'smart-input-field-set';
  public baseClassLabel = `${this.baseClass}__label`;

  public getLabelClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClassLabel, {
      disabled: this.disabled()
      // touched: this.touched,
      // errors: !!this.errors
    });
  }
}
