<div class="custom-questions__form">
  @if (customQuestions()?.length > 0) {
    <app-custom-questions-form
      [formControl]="control()"
      [customQuestions]="customQuestions()"
      (validityChange)="onFormValidityChange($event)"
    ></app-custom-questions-form>
  }
  @if (hierarchicalRootQuestions()?.length > 0) {
    <app-hierarchical-root-question-form
      [formControl]="hierarchicalRootQuestionControl()"
      [hierarchicalRootQuestions]="hierarchicalRootQuestions()"
      (validityChange)="onHierarchicalFormValidityChange($event)"
    ></app-hierarchical-root-question-form>
  }
</div>

@if (hierarchicalQuestionErrorMessage()) {
  <div class="custom-questions__error">
    <p>{{ hierarchicalQuestionErrorMessage() | translate }}</p>
  </div>
}
