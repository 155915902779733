import { Component, inject, TemplateRef, input, output } from '@angular/core';
import {
  AvailableLanguageCodesEnum,
  ContactTag,
  EditTaskType,
  HierarchicalQuestionActionType,
  HierarchicalQuestionAnswerId,
  HierarchicalQuestionModel,
  HierarchicalQuestionRangedAnswerData,
  HierarchicalQuestionType,
  QuestionActionModel
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgTemplateOutlet } from '@angular/common';
import { AlphaIndexPipe } from '../../../../pipes/alpha-index.pipe';
import { DateTimePipe } from '../../../../pipes/date-time.pipe';
import { LabelListComponent } from '../../../molecules/label-list/label-list.component';
import { ContextMenuItemComponent } from '../../../molecules/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../../molecules/context-menu/context-menu.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';
import { HierarchicalQuestionService } from '../../../../services/hierarchical-question.service';
import { BadgeColorEnum } from '../../../atoms/badge';

@Component({
  selector: 'app-hierarchical-questions-display-question',
  templateUrl: './hierarchical-questions-display-question.component.html',
  styleUrls: ['./hierarchical-questions-display-question.component.scss'],
  imports: [
    BadgeComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    NgTemplateOutlet,
    SvgIconComponent,
    LabelListComponent,
    TranslateModule,
    DateTimePipe,
    AlphaIndexPipe
  ]
})
export class HierarchicalQuestionsDisplayQuestionComponent {
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);

  readonly question = input<HierarchicalQuestionModel>(undefined);
  readonly questions = input<HierarchicalQuestionModel[]>(undefined);
  readonly tags = input<ContactTag[]>(undefined);
  readonly isMainQuestion = input(false);
  readonly subQuestionIndex = input<number>(undefined);
  readonly appCheckTemplateRef = input<TemplateRef<any>>(undefined);
  readonly editTaskType = input<EditTaskType>(undefined);
  readonly currentLanguage = input<AvailableLanguageCodesEnum>(undefined);

  readonly delete = output();
  readonly edit = output();

  public get isEditTaskTypeDelete(): boolean {
    return this.editTaskType() === EditTaskType.DELETE;
  }

  public get badgeType(): BadgeColorEnum {
    switch (this.editTaskType()) {
      case EditTaskType.CREATE:
        return BadgeColorEnum.NEUTRAL;
      case EditTaskType.EDIT:
        return BadgeColorEnum.PRIMARY_ACCENT_LIGHT;
      case EditTaskType.DELETE:
        return BadgeColorEnum.STATE_900;
    }
  }

  public get sortedAnswers() {
    return [...this.question().answers].sort(
      this.hierarchicalQuestionService.sort
    );
  }

  public get isSelection() {
    return this.question().data.type === HierarchicalQuestionType.SELECTION;
  }

  public get isRanged() {
    return this.isRangedDate || this.isRangedNumber;
  }

  public get isRangedDate() {
    return this.question().data.type === HierarchicalQuestionType.RANGE_DATE;
  }

  public get isRangedNumber() {
    return this.question().data.type === HierarchicalQuestionType.RANGE_NUMBER;
  }

  public getFollowQuestionTitles(ids: string[]) {
    return ids
      .map(id => this.questions().find(question => question.id === id))
      .map(question => question?.data?.title[this.currentLanguage()])
      .join(', ');
  }

  public getTags(actions: QuestionActionModel[]) {
    return actions
      .find(action => action.type === HierarchicalQuestionActionType.TAG)
      ?.tagIds.map(tagId => this.tags()?.find(tag => tag.id === tagId))
      .filter(tag => !!tag);
  }

  public isRangedMain(answerId: HierarchicalQuestionAnswerId) {
    return answerId === HierarchicalQuestionAnswerId.RANGED_MAIN;
  }

  public getBound(answerData: HierarchicalQuestionRangedAnswerData) {
    switch (answerData.answerId) {
      case HierarchicalQuestionAnswerId.RANGED_LOWER_BOUND:
        return answerData.upperBound;
      case HierarchicalQuestionAnswerId.RANGED_UPPER_BOUND:
        return answerData.lowerBound;
      default:
        return '';
    }
  }

  public onDelete() {
    this.delete.emit();
  }

  public onEdit() {
    this.edit.emit();
  }
}
