import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  input
} from '@angular/core';
import { Store } from '@ngrx/store';

import { Attachment } from '@ui/shared/models';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { Theme, ThemeService, ThemeUrls } from '../../../infrastructure/theme';
import { WINDOW_REF } from '../../../infrastructure/browser/window-ref.token';
import { Go } from '../../../infrastructure/base-state/router';

@UntilDestroy()
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {
  private changeDetectorRef = inject(ChangeDetectorRef);
  private store = inject(Store);
  private themeService = inject(ThemeService);
  private windowRef = inject(WINDOW_REF);

  readonly url = input('/');
  readonly alternativeUrl = input<string>(undefined);
  readonly preventClick = input<boolean>(undefined);
  readonly landlordLogo = input<Attachment>(undefined);
  readonly tenantInInternalPool = input<boolean>(undefined);
  readonly targetHandling = input('_blank');

  private theme: Theme;

  ngOnInit() {
    this.themeService.themeChange
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  public get logo() {
    this.theme = this.themeService.getActiveTheme;
    return this.theme.name === ThemeUrls.IMMOMIO && this.tenantInInternalPool()
      ? this.theme.iTPLogo
      : this.theme.logo;
  }

  public get logoExist() {
    return !this.logo && !this.landlordLogo();
  }

  public get isImmomio() {
    return this.logo?.title === 'immomio';
  }

  onClick() {
    if (this.preventClick()) return;
    const url = this.alternativeUrl() || this.theme?.alternativeUrl;
    url
      ? this.windowRef.open(url, this.targetHandling())
      : this.store.dispatch(
          new Go({
            path: [this.url() || 'properties']
          })
        );
  }
}
