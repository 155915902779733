<div class="header-mobile">
  <div class="header-mobile__title title-m">{{ title() | translate }}</div>
  <div class="header-mobile__back">
    @if (showBack()) {
      <app-button
        type="context-menu"
        iconLeft="arrow-left"
        ghost="true"
        (clickEvent)="onBack($event)"
      ></app-button>
    }
  </div>
  <div class="header-mobile__close">
    @if (showClose()) {
      <app-button
        type="context-menu"
        iconLeft="close"
        ghost="true"
        (clickEvent)="onClose($event)"
      ></app-button>
    }
  </div>
</div>
