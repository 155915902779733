import moment from 'moment';

import { inject, Injectable } from '@angular/core';

import { AuthTokenService, DownloadService } from '@ui/legacy-lib';
import { INFRASTRUCTURE_CONFIG } from '@ui/legacy-lib';

@Injectable()
export class InvoiceService {
  private authService = inject(AuthTokenService);
  private downloadService = inject(DownloadService);
  private config = inject(INFRASTRUCTURE_CONFIG);

  downloadInvoice(invoiceId: string) {
    if (!invoiceId.length) return;
    this.downloadService.openWithBearerInNewTab(
      `${this.config.environment.backend_url}/ll-invoices/${invoiceId}/pdf`,
      this.authService.getToken().access_token
    );
  }

  downloadStorno(stornoId: string) {
    if (!stornoId.length) return;
    this.downloadService.openWithBearerInNewTab(
      `${this.config.environment.backend_url}/ll-invoices/${stornoId}/pdf/storno`,
      this.authService.getToken().access_token
    );
  }

  downloadInvoices(dateRangeFormValue, fileFormat: string) {
    const dates = this.calcDates(dateRangeFormValue);
    this.downloadService.openWithBearerInNewTab(
      `${this.config.environment.backend_url}/ll-invoices/download?from=` +
        `${dates.from}&to=${dates.to}`,
      this.authService.getToken().access_token,
      fileFormat
    );
  }

  downloadStornos(dateRangeFormValue, fileFormat: string) {
    const dates = this.calcDates(dateRangeFormValue);
    this.downloadService.openWithBearerInNewTab(
      `${this.config.environment.backend_url}/ll-invoices/download-cancellation?from=` +
        `${dates.from}&to=${dates.to}`,
      this.authService.getToken().access_token,
      fileFormat
    );
  }

  private calcDates(dateForm) {
    return {
      from: moment(dateForm.from).format('DD-MM-YYYY'),
      to: moment(dateForm.to).format('DD-MM-YYYY')
    };
  }
}
