import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  output
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass]
})
export class ContextMenuItemComponent {
  readonly iconLeft = input(undefined);
  readonly iconRight = input(undefined);
  readonly iconSpacing = input<boolean>(undefined);
  readonly withTopSpacing = input<boolean>(undefined);
  // Use iconSpacing="true" if there is items with icon and items without, so there is nice alignment
  readonly active = input(false);
  readonly hover = input(true);
  readonly disabled = input(false);
  readonly highlightTextRed = input(false);
  @HostBinding('class.stick-to-bottom')
  readonly stickToBottom = input(false);

  readonly clickEvent = output<any>();

  click(event: any) {
    if (this.disabled()) return;
    this.clickEvent.emit(event);
  }
}
