import { Component, forwardRef, OnInit, viewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseControl } from '../base-control';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';

@UntilDestroy()
@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SlideToggleComponent)
    }
  ],
  imports: [FormsModule]
})
export class SlideToggleComponent
  extends BaseControl<boolean>
  implements OnInit
{
  readonly ngControl = viewChild(NgControl);

  ngOnInit() {
    this.ngControl()
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }
}
