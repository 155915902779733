<div
  class="no-content-screen"
  [ngClass]="{
    'no-content-screen--transparent': transparent(),
    'no-content-screen--without-vertical-spacing': withoutVerticalSpacing()
  }"
>
  @if (!hideImage()) {
    <div class="no-content-screen__image-container">
      <img
        class="img-fluid"
        src="../../../assets/images/illustration.svg"
        alt="no-content-image"
      />
    </div>
  }
  <h4 class="title-m no-content-screen__title">{{ headline() | translate }}</h4>
  <p class="default-s no-content-screen__message">
    {{ message() | translate }}
  </p>
  <ng-content select="[action-button-container]"></ng-content>
</div>
