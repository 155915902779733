<div class="self-disclosure-person-list__content">
  <form [formGroup]="form()">
    <span>{{ selfDisclosureQuestion.title | translate }}</span>
    @if (selfDisclosureQuestion.mandatory) {
      <span class="required-marker ng-star-inserted">&nbsp;*</span>
    }
    @for (person of getFormControls; track person; let i = $index) {
      <div>
        <app-self-disclosure-person
          [form]="person"
          [title]="
            ('self_disclosure.answer.person_list_subheader' | translate) +
            ' ' +
            (i + 1)
          "
          [selfDisclosureQuestion]="selfDisclosureQuestion"
          [showRemove]="true"
          [constants]="constants()"
          (onRemove)="removePerson(i)"
        ></app-self-disclosure-person>
      </div>
    }

    <div class="row mt20 self-disclosure-person-list__choices">
      @if (selfDisclosureQuestion.mandatory && !getFormControls.length) {
        <div class="col">
          <app-form-field>
            <app-checkbox
              [label]="
                'self_disclosure.answer.person_list_single_tenant_l' | translate
              "
              formControlName="answerUnavailable"
            ></app-checkbox>
          </app-form-field>
        </div>
      }
      <div class="col">
        <app-button
          [disabled]="selfDisclosureQuestion.answerUnavailable"
          (clickEvent)="addPerson()"
          >{{ 'self_disclosure.answers.add_person_a' | translate }}</app-button
        >
      </div>
    </div>
  </form>
</div>
