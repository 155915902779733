import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle } from '@angular/common';
import { ImageComponent } from '../../atoms/image/image.component';

@Component({
  selector: 'app-no-data-disclaimer',
  templateUrl: './no-data-disclaimer.component.html',
  styleUrls: ['./no-data-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, ImageComponent, TranslateModule]
})
export class NoDataDisclaimerComponent {
  // TODO: set fallback translation keys here
  readonly title = input<string>(undefined);
  readonly description = input<string>(undefined);
  readonly imageSrc = input('../../../assets/images/no-data-illustration.svg');
  readonly showImage = input(true);
  readonly showDashedBorder = input(false);
  readonly scale = input(1);
}
