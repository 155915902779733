<label
  class="checkbox__container d-flex flex-row-reverse justify-content-between mb0"
  [class.no-cursor]="disabled"
  (click)="toggleValue()"
>
  <input
    type="checkbox"
    [disabled]="disabled"
    [required]="required()"
    [(ngModel)]="value"
  />
  <span class="checkbox__label" [class.show-checkbox]="showCheckbox()"></span>

  <span class="default-s d-flex" [class.checked]="value">
    <ng-content select="[icon]"></ng-content>
    @if (required()) {
      <span class="required-marker">*&nbsp;</span>
    }
    @if (!innerHTML()) {
      <span>{{ label() }}</span>
    } @else {
      <span [innerHTML]="innerHTML()"></span>
    }
  </span>
</label>
<hr class="mt5 mb10" />
