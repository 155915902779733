import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  input,
  output,
  viewChild
} from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  template: '<ng-content></ng-content><div #anchor></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
  private host = inject<ElementRef<HTMLElement>>(ElementRef);

  readonly options = input<IntersectionObserverInit>({});
  readonly scrolled = output();
  readonly anchor = viewChild<ElementRef<HTMLElement>>('anchor');
  private observer: IntersectionObserver;

  ngOnInit() {
    const options: IntersectionObserverInit = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      rootMargin: '50px',
      threshold: 0,
      ...this.options()
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) this.scrolled.emit();
    }, options);

    this.observer.observe(this.anchor().nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private isHostScrollable() {
    const style = window.getComputedStyle(this.host.nativeElement);

    return (
      style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll'
    );
  }
}
