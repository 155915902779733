import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-lockable-data',
  templateUrl: './lockable-data.component.html',
  styleUrls: ['./lockable-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockableDataComponent {
  readonly locked = input(false);
}
