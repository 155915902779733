<div class="check__container" [ngbTooltip]="tooltip()">
  <input
    type="checkbox"
    [disabled]="disabled || forceDisabled()"
    [(ngModel)]="value"
  />
  <span
    class="checkbox__label"
    [class.checkbox__label--square]="squared()"
    (click)="onClick($event, value)"
  ></span>
</div>
