import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes';

import { ComponentsModule } from '../components';
import {
  DEFAULT_ADDONS_STRATEGY,
  ProductsModuleConfig
} from './products-module.config';

import {
  ADDONS_STRATEGY,
  AddonsHelperService,
  SubscriptionsDiffService
} from './services';

import { SubscriptionPageComponent } from './subscription-page.component';
import { AddonComponent } from './components/addon/addon.component';
import { SubscriptionDetailsComponent } from './components/subscription-details/subscription-details.component';
import { SubscriptionFormComponent } from './components/subscription-form/subscription-form.component';
import { PaymentDetailsLibComponent } from './components/payment-details-lib/payment-details-lib.component';
import { DowngradeModalComponent } from './components/downgrade-modal/downgrade-modal.component';
import { UpdateSubscriptionModalComponent } from './components/update-subscription-modal/update-subscription-modal.component';
import { FreeFeaturesListingComponent } from './components/free-features-listing/free-features-listing.component';

const services = [AddonsHelperService, SubscriptionsDiffService];

const components = [
  AddonComponent,
  SubscriptionDetailsComponent,
  SubscriptionPageComponent,
  SubscriptionFormComponent,
  PaymentDetailsLibComponent,
  DowngradeModalComponent,
  UpdateSubscriptionModalComponent,
  FreeFeaturesListingComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ComponentsModule,
    PipesModule,
    RouterModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    ...components
  ],
  exports: [...components]
})
export class ProductsModule {
  public static forRoot(
    config: ProductsModuleConfig = {}
  ): ModuleWithProviders<ProductsModule> {
    return {
      ngModule: ProductsModule,
      providers: [
        {
          provide: ADDONS_STRATEGY,
          useValue: config.addonsStrategy || DEFAULT_ADDONS_STRATEGY
        },
        ...services
      ]
    };
  }
}
