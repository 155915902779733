import { inject, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FlatSelectType, LandlordUser, Person } from '@ui/shared/models';
import {
  customEmailValidator,
  customPhoneValidator,
  getRequiredValidator
} from '../components/legacy/form/controls/validation';

@Injectable()
export class DigitalContractFormService {
  private fb = inject(FormBuilder);

  public getSignerFormGroup({
    isAddressRequired = false,
    isGenderRequired = true,
    isPhoneRequired = false,
    isReadOnly = false,
    genderTypes = []
  }: {
    isAddressRequired?: boolean;
    isGenderRequired?: boolean;
    isPhoneRequired?: boolean;
    isReadOnly?: boolean;
    genderTypes?: FlatSelectType[];
  }) {
    return this.fb.group(
      this.getDigitalContractSignerControl(
        isAddressRequired,
        isGenderRequired,
        isPhoneRequired,
        isReadOnly,
        genderTypes
      )
    );
  }

  public patchSigners({
    signers,
    formArray,
    isAddressRequired = false,
    isGenderRequired = true,
    isPhoneRequired = false,
    isReadOnly = false,
    genderTypes = []
  }: {
    signers: Person[];
    formArray: FormArray;
    isAddressRequired?: boolean;
    isGenderRequired?: boolean;
    isPhoneRequired?: boolean;
    isReadOnly?: boolean;
    genderTypes?: FlatSelectType[];
  }): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    formArray.value.forEach(() => formArray.removeAt(0));
    signers.forEach(signer => {
      const control = this.getSignerFormGroup({
        isAddressRequired,
        isGenderRequired,
        isPhoneRequired,
        isReadOnly,
        genderTypes
      });
      control.patchValue(signer);
      formArray.push(control);
    });
  }

  public onTemplateChange(
    item,
    templateList: LandlordUser[],
    controlInput: FormGroup,
    isPhoneRequired: boolean
  ) {
    const template = templateList?.filter(a => a.id === item)[0];
    if (template) {
      const removePhoneNumber = {
        ...template,
        // Remove the phone number, when it's not displayed in the Ui
        profile: { ...template.profile, phone: undefined }
      };
      controlInput.patchValue(
        this.getSignerFromUser(isPhoneRequired ? template : removePhoneNumber)
      );
    }
  }

  public getSignerFromUser(template: LandlordUser) {
    return {
      firstname: template.profile.firstname,
      lastname: template.profile.name,
      email: template.email,
      phone: template.profile?.phone,
      address: template.address
    };
  }

  private getDigitalContractSignerControl(
    isAddressRequired: boolean,
    isGenderRequired: boolean,
    isPhoneRequired: boolean,
    isReadOnly: boolean,
    genderTypes: FlatSelectType[]
  ) {
    return {
      selectedTemplate: [null],
      docuSignRecipientClientId: [],
      status: [],
      isTenant: [],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      dateOfBirth: [],
      gender: this.fb.control(
        null,
        getRequiredValidator(
          genderTypes.length > 0 && isGenderRequired && !isReadOnly
        )
      ),
      email: [
        '',
        Validators.compose([Validators.required, customEmailValidator])
      ],
      phone: [
        '',
        Validators.compose([
          getRequiredValidator(isPhoneRequired),
          isPhoneRequired ? customPhoneValidator : null
        ])
      ],
      address: this.fb.group({
        city: ['', isAddressRequired ? Validators.required : undefined],
        houseNumber: ['', isAddressRequired ? Validators.required : undefined],
        street: ['', isAddressRequired ? Validators.required : undefined],
        zipCode: ['', isAddressRequired ? Validators.required : undefined],
        district: [''],
        country: ['', isAddressRequired ? Validators.required : undefined],
        countryName: [{ value: '', disabled: true }],
        region: ['']
      })
    };
  }
}
