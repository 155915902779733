<app-lang-picker
  [availableLanguages]="availableLanguages()"
  [defaultLanguageCode]="defaultLanguageCode()"
  [selectNextLanguageOnClick]="true"
  [ngModel]="currentLanguageCode()"
  [ngModelOptions]="{ standalone: true }"
  (ngModelChange)="onLanguageChange($event)"
>
  <app-locale-flag
    custom-button
    class="form-field-lang-picker__flag"
    [languageCode]="currentLanguageCode()"
  ></app-locale-flag>
</app-lang-picker>
