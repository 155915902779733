import { Component, forwardRef, inject, OnInit, input } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConversationRestrictionConfig, PropertyType } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { CheckComponent } from '../../../form/controls/check/check.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';
import { isPropertyTypeGarage } from '../../../../../utils';
import { restrictionRuleValidator } from '../../../form/controls/validation';

@UntilDestroy()
@Component({
  selector: 'app-conversation-restriction-config-form',
  templateUrl: './conversation-restriction-config-form.component.html',
  styleUrls: ['./conversation-restriction-config-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConversationRestrictionConfigFormComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    CheckComponent,
    FormFieldLabelComponent,
    TranslateModule
  ]
})
export class ConversationRestrictionConfigFormComponent
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  readonly propertyType = input<PropertyType>(undefined);
  public form: FormGroup;
  public value: ConversationRestrictionConfig;

  private controlNames = ['forbidden', 'allowed', 'replyOnly', 'whenInvited'];

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public get forbiddenControl() {
    return this.form.get('forbidden') as FormControl;
  }

  public get allowedControl() {
    return this.form.get('allowed') as FormControl;
  }

  public get replyOnlyControl() {
    return this.form.get('replyOnly') as FormControl;
  }

  public get whenInvitedControl() {
    return this.form.get('whenInvited') as FormControl;
  }

  public get isAllowed() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.allowedControl && this.allowedControl.value;
  }

  public get isReadOnly() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.replyOnlyControl && this.replyOnlyControl.value;
  }

  public get isWhenInvited() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.whenInvitedControl && this.whenInvitedControl.value;
  }

  public get isForbidden() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.forbiddenControl && this.forbiddenControl.value;
  }

  public get isGarage() {
    return isPropertyTypeGarage(this.propertyType());
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      forbidden: [
        false,
        restrictionRuleValidator(
          this.controlNames.filter(cn => cn !== 'forbidden')
        )
      ],
      allowed: [
        false,
        restrictionRuleValidator(
          this.controlNames.filter(cn => cn !== 'allowed')
        )
      ],
      replyOnly: [
        false,
        restrictionRuleValidator(
          this.controlNames.filter(cn => cn !== 'replyOnly')
        )
      ],
      whenInvited: [
        false,
        restrictionRuleValidator(
          this.controlNames.filter(cn => cn !== 'whenInvited')
        )
      ]
    });

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: ConversationRestrictionConfig) => {
        this.value = value;
        this.onChange(this.value);
        this.onTouch();
      });
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: ConversationRestrictionConfig) {
    this.form.patchValue(value);
    this.value = value;
  }
}
