import { Component, input } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  imports: [RouterLink, NgClass, NgTemplateOutlet]
})
export class MessageComponent {
  readonly messageType = input<string>(undefined);
  readonly redirectLink = input<string>(undefined);
}
