import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  viewChild
} from '@angular/core';

import { Store } from '@ngrx/store';
import {
  NgbNav,
  NgbNavChangeEvent,
  NgbNavItem,
  NgbNavLink
} from '@ng-bootstrap/ng-bootstrap';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet
} from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter, switchMap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { Go } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-tables-landlord',
  templateUrl: './landlord.component.html',
  styleUrls: ['./landlord.component.scss'],
  imports: [NgbNav, TranslateModule, RouterOutlet, NgbNavItem, NgbNavLink]
})
export class LandlordComponent implements AfterViewInit {
  private store = inject(Store);
  private cdref = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  readonly nav = viewChild<NgbNav>('nav');

  ngAfterViewInit(): void {
    this.route.firstChild.url
      .pipe(untilDestroyed(this))
      .subscribe(url => this.nav().select(url[0].path));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.route.firstChild.url),
        untilDestroyed(this)
      )
      .subscribe(url => {
        const path = url[0].path;
        this.nav().select(path);
      });

    this.cdref.detectChanges();
  }

  public onTabChange($event: NgbNavChangeEvent) {
    this.store.dispatch(
      new Go({
        path: ['tables/landlord', $event.nextId]
      })
    );
  }
}
