<app-modal>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body update-subscription__body">
        <div class="update-modal__header">
          <div class="d-flex justify-content-between">
            <h2 class="title-xl update-modal__title">
              {{ 'UPDATE_SUBSCRIPTION_A' | translate }}
            </h2>
            <div class="update-modal__close-button">
              <img
                src="/assets/images/icons/icon-close.svg"
                (click)="dismiss()"
              />
            </div>
          </div>
          <h3 class="title-s update-modal__subtitle">
            {{ 'VERIFY_THE_INFORMATION_L' | translate }}
          </h3>
        </div>

        <div class="update-modal__content">
          <h3 class="uppercase-m semibold update-modal__subtitle">
            {{ 'YOUR_PLAN_L' | translate }}
          </h3>

          <div class="update-modal__table">
            <div class="summary-row total-price-row">
              <span class="amount">{{
                summary().totalPrice | currency: currency()
              }}</span>
              <span class="period-indicator">
                /{{
                  (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate
                }}</span
              >
              <div class="total-price-net">
                <span>{{
                  summary().totalPriceNet | currency: currency()
                }}</span>
                <span> {{ 'NET_PRICE_INFORMATION_L' | translate }} </span>
                <span>
                  /{{
                    (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate
                  }}</span
                >
              </div>
            </div>

            <div class="summary-row customer-size-row">
              <span class="default-s amount"
                >{{ sizesMap[summary().customerSize] }}
                {{ 'FLATS_MANAGED_L' | translate }}</span
              >
              <span class="default-s semibold price"
                >{{ 0 | currency: currency }}/{{
                  (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate
                }}</span
              >
            </div>

            <div class="summary-row agents-row">
              <span class="default-s amount">
                {{ agentsAmount }}
                {{
                  agentsAmount === 1
                    ? ('AGENT_L' | translate)
                    : ('AGENTS_L' | translate)
                }}
              </span>
              <span class="default-s semibold price"
                >{{ agentsPrice | currency: currency }}/{{
                  (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate
                }}</span
              >
            </div>

            @if (freeAgentsAmount > 0) {
              <div class="summary-row agents-row">
                <span class="default-s amount">
                  {{ freeAgentsAmount }}
                  {{
                    freeAgentsAmount === 1
                      ? ('FREE_AGENT_L' | translate)
                      : ('FREE_AGENTS_L' | translate)
                  }}
                </span>
              </div>
            }
            @if (homepageAmount) {
              <div class="summary-row homepage-row">
                <span class="default-s amount">
                  {{ homepageAmount }}
                  {{
                    homepageAmount === 1
                      ? ('HOMEPAGE_L' | translate)
                      : ('HOMEPAGES_L' | translate)
                  }}
                </span>
                <span class="default-s semibold price"
                  >{{ homepagePrice | currency: currency }}/{{
                    (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate
                  }}</span
                >
              </div>
            }
            @if (customerCooperationAmount) {
              <div class="summary-row customer-cooperation-row">
                <span class="default-s amount">
                  {{ customerCooperationAmount }}
                  {{
                    customerCooperationAmount === 1
                      ? ('CUSTOMER_COOPERATION_L' | translate)
                      : ('CUSTOMER_COOPERATIONS_L' | translate)
                  }}
                </span>
                <span class="default-s semibold price"
                  >{{ customerCooperationPrice | currency: currency }}/{{
                    (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate
                  }}</span
                >
              </div>
            }
            @for (addon of featureAddons; track addon.id) {
              <div class="summary-row addon-row">
                <span class="default-s name">{{ addon.name | translate }}</span>
                <span class="default-s semibold price">
                  {{
                    (isYearlySelected
                      ? getAddonPriceYearly(addon)
                      : getAddonPriceMonthly(addon)
                    ) | currency: currency
                  }}/{{ (isYearlySelected ? 'YEAR_L' : 'MONTH_L') | translate }}
                </span>
              </div>
            }
          </div>

          <div class="update-modal__payment-details">
            <div class="payment-details__section">
              <h3 class="title-s payment-details__subtitle">
                {{ 'general.renews_l' | translate }}
              </h3>
              @if (summary().renewDate) {
                <h3 class="title-m payment-details__title">
                  {{ summary().renewDate | appDateTime }}
                </h3>
              }
              @if (!summary().renewDate) {
                <h3 class="title-s payment-details__title">...</h3>
              }
              <h3 class="title-s payment-details__data">
                {{ 'BILLED_L' | translate }}
                {{
                  periodsMap[summary()?.product.subscriptionPeriod] | translate
                }}
                @if (isYearlySelected) {
                  <span>
                    ({{
                      'YEARLY_PRODUCT_DISCOUNT_INFO_L'
                        | translate: yearlyDiscount
                    }})</span
                  >
                }
              </h3>
            </div>
            <div class="payment-details__section">
              <h3 class="title-s payment-details__subtitle">
                {{ 'PAYMENT_METHOD_L' | translate }}
              </h3>
              <div class="d-flex">
                <h3 class="title-m payment-details__title pr10">
                  @if (summary().paymentMethod && !isPaymentInvoice) {
                    {{ summary().paymentMethod.name }}
                  }
                  @if (summary().paymentMethod && isPaymentInvoice) {
                    {{ summary().paymentMethod.name | translate }}
                  }
                  @if (!summary().paymentMethod) {
                    {{ 'NONE_ADDED_YET_L' | translate }}
                  }
                </h3>
                <h3 class="title-s payment-details__data">
                  @if (summary().paymentMethod && !isPaymentInvoice) {
                    {{ summary().paymentMethod.shortInfo }}
                  }
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer update-subscription__footer">
        <app-button (clickEvent)="update()">
          {{ 'UPDATE_SUBSCRIPTION_A' | translate }}
        </app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
