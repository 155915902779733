import {
  ComponentRef,
  Directive,
  inject,
  Input,
  input,
  OnInit,
  output,
  ViewContainerRef
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Tooltip, TooltipProgress, TooltipTour } from '@ui/shared/models';
import { TooltipComponent } from './tooltip.component';
import { TooltipPosition } from './tooltip.enum';

@UntilDestroy()
@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit {
  viewContainerRef = inject(ViewContainerRef);

  readonly appTooltipAnchor = input<string>(undefined);

  @Input() set appTooltipProgress(value: TooltipProgress) {
    this.tooltipProgress = value;

    if (this.componentRef) {
      this.componentRef.instance.tooltipProgress = value;
    }
  }

  readonly appTooltipPosition = input<TooltipPosition>(TooltipPosition.BELOW);

  readonly appTooltipContinueEvent = output<Tooltip>();
  readonly appTooltipCloseEvent = output<TooltipTour>();

  protected tooltipProgress: TooltipProgress;
  protected componentRef: ComponentRef<TooltipComponent>;
  private maxTooltipWidth = 300;

  ngOnInit() {
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);

    const host = this.viewContainerRef.element.nativeElement;
    const appTooltipPosition = this.appTooltipPosition();
    if (appTooltipPosition === TooltipPosition.BELOW) {
      // eslint-disable-next-line
      host.appendChild(this.componentRef.location.nativeElement);
    }

    this.componentRef.setInput('tooltipAnchor', this.appTooltipAnchor());
    this.componentRef.setInput(
      'anchorElementRef',
      this.viewContainerRef.element
    );
    this.componentRef.setInput('tooltipProgress', this.tooltipProgress);
    this.componentRef.setInput('tooltipPosition', appTooltipPosition);
    this.componentRef.setInput(
      'outHorizontalBounds',
      this.isTooltipOutOfHorizontalBounds()
    );
    this.componentRef.setInput('outVerticalBounds', false);

    this.componentRef.instance.continueEvent.subscribe(tooltip =>
      this.appTooltipContinueEvent.emit(tooltip)
    );
    this.componentRef.instance.closeEvent.subscribe(tooltipTour =>
      this.appTooltipCloseEvent.emit(tooltipTour)
    );
  }

  public isTooltipOutOfHorizontalBounds(): boolean {
    const boundingClientRect =
      // eslint-disable-next-line
      this.viewContainerRef.element?.nativeElement?.getBoundingClientRect();
    return (
      document.documentElement.scrollWidth -
        boundingClientRect.left -
        window.scrollX <
      this.maxTooltipWidth
    );
  }
}
