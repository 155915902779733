import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  input,
  OnInit,
  output,
  TemplateRef,
  viewChild
} from '@angular/core';

import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IconTypeEnum } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { OffcanvasService } from '../../../services/offcanvas.service';
import { ButtonComponent } from '../../atoms/button/button.component';
import {
  ClickDetectorDirective,
  Elevation,
  ElevationType
} from '../../../directives';
import { ButtonBorderRadiusEnum, ButtonTypeEnum } from '../../atoms/button';
import { mobileBreakingPoints } from '../../legacy/messenger/model/interface';
import { ContextMenuTooltipPlacementEnum } from './context-menu.enum';

@UntilDestroy()
@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbPopover,
    ButtonComponent,
    NgbTooltip,
    NgClass,
    NgTemplateOutlet,
    TranslateModule,
    ClickDetectorDirective
  ]
})
export class ContextMenuComponent implements OnInit {
  private observer = inject(BreakpointObserver);
  private offcanvasService = inject(OffcanvasService);

  readonly buttonElevation = input<ElevationType>(Elevation.ONE);
  readonly buttonMenuIcon = input(IconTypeEnum.Context);
  readonly buttonUseFullContainerSize = input(false);
  readonly ghostButton = input(false);

  readonly tooltipPlacement = input(ContextMenuTooltipPlacementEnum.BOTTOM);
  readonly closeOnItemClick = input(true);
  readonly sideNavMenuTitle = input('');
  readonly allowSideNavForMobileView = input(true);
  readonly disabled = input(false);

  readonly tooltip = input('');
  readonly zeroPadding = input<boolean>(undefined);
  readonly container = input<'body' | null>(null);
  readonly disableHover = input<boolean>(undefined);
  readonly borderRadius = input<ButtonBorderRadiusEnum>(
    ButtonBorderRadiusEnum.BIG
  );

  readonly openMenuEvent = output<Event>();
  readonly clickCloseSideNavEvent = output();
  // ^ will either be triggered, when clicking the close button or the overlay of the side nav view

  readonly popover = viewChild(NgbPopover);

  @HostBinding('class.side-nav-opened') get openSideNav() {
    return this.sideNavOpened;
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IconTypeEnum = IconTypeEnum;

  public openMenuInSideNav = false;
  public sideNavOpened = false;

  public ngOnInit() {
    if (this.allowSideNavForMobileView()) {
      this.observer
        .observe(mobileBreakingPoints)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.openMenuInSideNav = result.matches;
        });
    }
  }

  public handleInsideClick($event: Event) {
    if (!this.closeOnItemClick()) {
      $event.stopPropagation();
    }
  }

  public openContextMenu($event: Event, content: TemplateRef<any>) {
    $event.stopPropagation();
    if (this.disabled()) return;

    const popover = this.popover();
    if (this.openMenuInSideNav) {
      if (this.sideNavOpened) {
        this.closeContextMenu();
        return;
      }

      this.sideNavOpened = true;
      this.openMenuEvent.emit($event);
      this.open(content);
    } else if (popover && !popover.isOpen()) {
      popover.open();
      this.openMenuEvent.emit($event);
    }
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService
      .open(content, {
        ariaLabelledBy: 'offcanvas-context-menu',
        position: 'end'
      })
      .result.then(
        () => {
          this.closeContextMenu();
        },
        () => {
          this.closeContextMenu();
        }
      );
  }

  public closeContextMenu() {
    if (!this.sideNavOpened) {
      return;
    }
    const popover = this.popover();
    if (popover) popover.close();
    this.sideNavOpened = false;
    this.offcanvasService.dismiss();
    this.clickCloseSideNavEvent.emit();
  }
}
