<div [className]="baseClass + '__column'">
  <span [className]="getLabelClassName()">
    {{ label() }}
    @if (required() && !disabled()) {
      <span [className]="baseClass + '__asterisk'"> * </span>
    }
  </span>
</div>
<div [className]="baseClass + '__column'">
  <ng-content></ng-content>
  @if (errorMessage().length && !disabled()) {
    <span [className]="baseClass + '__error-message'">{{
      errorMessage()
    }}</span>
  }
</div>
