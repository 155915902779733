import { Component, ElementRef, OnInit, input, viewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';

import { pairwise, startWith } from 'rxjs/operators';

import { HouseholdType, NameValue } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { FlatSelectComponent } from '../form/controls/flat-select/flat-select.component';
import { SliderComponent } from '../form/controls/slider/slider.component';
import { AppInputDirective } from '../form/controls/input/input.directive';
import { SelectComponent } from '../form/controls/select/select.component';
import { DateComponent } from '../form/controls/date/date.component';
import { FormFieldLabelComponent } from '../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';
import { defaultDocumentsConfig } from '../../../config';
import { BaseStep } from '../wizard/wizard-step';

@UntilDestroy()
@Component({
  selector: 'app-profile-dk-two',
  templateUrl: './profile-dk-two.component.html',
  styleUrls: ['./profile-dk-two.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    DateComponent,
    SelectComponent,
    AppInputDirective,
    SliderComponent,
    FlatSelectComponent,
    TranslateModule
  ]
})
export class ProfileDkTwoComponent extends BaseStep implements OnInit {
  readonly householdTypes = input<NameValue<string>[]>(undefined);
  readonly employmentTypes = input<NameValue<string>[]>(undefined);
  readonly birthDate = input<string>(undefined);
  readonly showFurtherInformation = input(true);
  readonly showMoveInDate = input(true);

  public minNumberOfResidents = 1;
  public maxNumberOfResidents = 10;
  public placeholderResidents = 'general.residents_placeholder_default_l';

  public incomeProofDocumentTypes = defaultDocumentsConfig.acceptedTypes;
  public incomeProofDocumentSize = defaultDocumentsConfig.acceptedDocumentSize;

  readonly incomeEl = viewChild('incomeEl', { read: ElementRef });

  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };

  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };

  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public booleanOptions = [
    { name: 'general.yes_l', value: true },
    { name: 'general.no_l', value: false }
  ];

  public get minDate() {
    const now = moment();

    return {
      year: now.year(),
      month: now.month() + 1,
      day: now.date()
    };
  }

  public ngOnInit() {
    this.form()
      .get('householdType')
      .valueChanges.pipe(
        startWith(this.form().get('householdType').value),
        pairwise(),
        untilDestroyed(this)
      )
      .subscribe(([prev, curr]) => {
        if (prev === curr) return;
        this.updateResidentOptions(curr);
      });
    const birthDate = this.birthDate();
    if (birthDate) this.form().get('dateOfBirth').patchValue(birthDate);
    super.ngOnInit();
  }

  private updateResidentOptions(changes: string) {
    switch (changes) {
      case HouseholdType.SINGLE: {
        this.setResidentsFields(1, 1, 'general.residents_placeholder_single_l');
        break;
      }
      case HouseholdType.FAMILY: {
        this.setResidentsFields(
          3,
          10,
          'general.residents_placeholder_family_l'
        );
        break;
      }
      case HouseholdType.COUPLE_WITHOUT_CHILDREN: {
        this.setResidentsFields(
          2,
          2,
          'general.residents_placeholder_couple_no_kids_l'
        );
        break;
      }
      case HouseholdType.SINGLE_WITH_CHILDREN: {
        this.setResidentsFields(
          2,
          10,
          'general.residents_placeholder_single_kids_l'
        );
        break;
      }
      case HouseholdType.FLATSHARE: {
        this.setResidentsFields(
          2,
          10,
          'general.residents_placeholder_flatshare_l'
        );
        break;
      }
      default: {
        this.setResidentsFields(
          1,
          10,
          'general.residents_placeholder_default_l'
        );
        break;
      }
    }
  }

  private setResidentsFields(min: number, max: number, placeholder: string) {
    this.minNumberOfResidents = min;
    this.maxNumberOfResidents = max;
    this.placeholderResidents = placeholder;
    this.form()
      .get('residents')
      .setValidators(
        Validators.compose([Validators.min(min), Validators.max(max)])
      );
    this.form().get('residents').patchValue(min, { emitEvent: false });
  }

  public get getPlaceholderResidents() {
    return this.placeholderResidents;
  }
}
