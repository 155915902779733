import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NameValue, PaymentMethodType } from '@ui/shared/models';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AddressFormComponent,
  AppInputDirective,
  FormFieldComponent,
  FormFieldLabelComponent,
  SelectComponent
} from '@ui/legacy-lib';
import { EditLandlordWizard } from '../edit-landlord-wizard';

const PAYMENT_METHODS_VALUE = [
  { name: 'payment.invoice_l', value: PaymentMethodType.INVOICE }
];

@Component({
  selector: 'app-payment',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    TranslateModule,
    SelectComponent,
    AddressFormComponent,
    AppInputDirective
  ]
})
export class PaymentMethodComponent
  extends EditLandlordWizard
  implements OnInit
{
  private translate = inject(TranslateService);

  public paymentTypes = PaymentMethodType;
  public addressFormConfig = {
    countryName: false,
    showCountry: true
  };
  paymentMethods: NameValue<string>[] = PAYMENT_METHODS_VALUE;
  pricingPlan: NameValue<string>[];

  get showAddress() {
    return this.form().get('paymentMethod').value === this.paymentTypes.INVOICE;
  }

  ngOnInit() {
    super.ngOnInit();
    this.paymentMethods = PAYMENT_METHODS_VALUE.map(({ name, value }) => ({
      value,
      name: this.translate.instant(name)
    }));
    this.form()
      .get('paymentMethod')
      .valueChanges.subscribe(value => {
        if (value !== this.paymentTypes.INVOICE) {
          this.form().get('address').disable();
        } else {
          this.form().get('address').enable();
        }
      });
  }
}
