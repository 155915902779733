@if (!isPdf(attachment()) && !isDocument()) {
  <div (click)="togglePreview()" (mouseleave)="closePreview()">
    <div class="image-container">
      <img
        [src]="imgSrc() || ''"
        alt=""
        class="attachment__thumbnail"
        [ngClass]="'rotate-' + attachment().rotate"
      />
    </div>
    @if (previewOpen) {
      <div class="attachment-preview">
        <img
          [src]="imgSrc() || ''"
          alt=""
          [ngClass]="'rotate-' + attachment().rotate"
        />
      </div>
    }
  </div>
}
@if (isPdf(attachment()) || isDocument()) {
  <div class="d-flex">
    @if (isPdf(attachment()) && isDocument()) {
      <img
        (click)="clickOnPdf()"
        class="attachment-icon"
        src="/assets/images/icons/icon-pdf-red.svg"
      />
    }
    @if (isDocument() && !isPdf(attachment())) {
      <i
        (click)="clickOnImage()"
        class="attachment-icon icon icon--document"
      ></i>
    }
  </div>
}
