import { gql } from 'apollo-angular';
import { addressGQL } from '@ui/legacy-lib';
import { LandlordUser } from '@ui/shared/models';
import { CustomerImportSettings, ObjectHierarchy } from '../models';

export interface LandlordQueryResult {
  landlord: LandlordUser;
}

export const landlordFields = `
  id
  created
  updated
  lastLogin
  email
  profile
  enabled
  expired
  locked
  userPermissionRole {
    id
    name
    userPermissions
  }
  username
  customer {
    id
    files
    name
    priceMultiplier
    nextPriceMultiplier
    paymentMethods {
      method
      preferred
    }
    invoiceEmail
    hasActiveProduct
    customerSize
    customerType
    paymentDetails
    preferences
    ${addressGQL}
  }
`;

export const objectHierarchyFields = `
  id
  operativeType
  data {
    name
    fieldTypes
  }
  hierarchyLevel
`;

const customerImportSettingsFields = `
  importDelimiter
  activated
`;

export const landlordQuery = gql`
  query landlord($id: ID!) {
    landlord(id: $id) {
      ${landlordFields}
    }
  }
`;

export const createLandlordMutation = gql`
    mutation createLandlord($input: LandlordData!) {
      createLandlord(input: $input) {
        ${landlordFields}
      }
    }
`;

export interface UpdateLandlordResponse {
  updateLandlord: LandlordUser;
}

export const updateLandlordMutation = gql`
  mutation updateLandlord($input: LandlordData!, $id: ID) {
    updateLandlord(input: $input, id: $id) {
      ${landlordFields}
    }
  }
`;

export interface CreateObjectHierarchyResponse {
  createObjectHierarchy: ObjectHierarchy;
}

export interface UpdateObjectHierarchyResponse {
  updateObjectHierarchy: ObjectHierarchy;
}

export interface GetObjectHierarchyResponse {
  getObjectHierarchy: ObjectHierarchy[];
}

export interface GetCustomerImportSettingsResponse {
  getCustomerImportSettings: CustomerImportSettings;
}

export interface UpdateCustomerImportSettingsResponse {
  updateCustomerImportSettings: {
    status: number;
    statusText: string;
  };
}

export const getObjectHierarchyQuery = gql`
  query getObjectHierarchy($customerId: ID) {
    getObjectHierarchy(customerId: $customerId) {
      ${objectHierarchyFields}
    }
  }
`;

export const getCustomerImportSettingsQuery = gql`
  query getCustomerImportSettings($customer: ID) {
    getCustomerImportSettings(customer: $customer) {
      ${customerImportSettingsFields}
    }
  }
`;

export const createObjectHierarchyMutation = gql`
  mutation createObjectHierarchy(
    $customerId: ID
    $objectHierarchy: AdministrationUnitTypeInput
  ) {
    createObjectHierarchy(
      customerId: $customerId
      objectHierarchy: $objectHierarchy
    ) {
      ${objectHierarchyFields}
    }
  }
`;

export const updateObjectHierarchyMutation = gql`
  mutation updateObjectHierarchy(
    $customerId: ID
    $administrationUnitType: ID
    $objectHierarchy: AdministrationUnitTypeInput
  ) {
    updateObjectHierarchy(
      customerId: $customerId
      administrationUnitType: $administrationUnitType
      objectHierarchy: $objectHierarchy
    ) {
      ${objectHierarchyFields}
    }
  }
`;

export const deleteObjectHierarchyMutation = gql`
  mutation deleteObjectHierarchy($customerId: ID, $administrationUnitType: ID) {
    deleteObjectHierarchy(
      customerId: $customerId
      administrationUnitType: $administrationUnitType
    ) {
      status
      statusText
    }
  }
`;

export const updateCustomerImportSettingsMutation = gql`
  mutation updateCustomerImportSettings(
    $customer: ID
    $importSettings: CustomerImportSettingsInput
  ) {
    updateCustomerImportSettings(
      customer: $customer
      importSettings: $importSettings
    ) {
      status
      statusText
    }
  }
`;
