import { Component, OnInit, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Appointment, PropertyApplicationStatus } from '@ui/shared/models';
import {
  AppointmentAcceptanceShortInfo,
  AppointmentPropertyShortInfo,
  AppointmentSelection
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ImageSizePipe } from '../../../../pipes/image-size.pipe';
import { AddressPipe } from '../../../../pipes/address.pipe';
import { ModalV2Component } from '../../modal-v2/modal-v2.component';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../modal/modal-footer/modal-footer.component';
import { AppointmentSelectionComponent } from '../appointment-selection/appointment-selection.component';
import { ModalContentComponent } from '../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-appointments-modal',
  templateUrl: './appointments-modal.component.html',
  styleUrls: ['./appointments-modal.component.scss'],
  imports: [
    ModalComponent,
    ModalContentComponent,
    AppointmentSelectionComponent,
    FormsModule,
    ModalFooterComponent,
    ButtonComponent,
    ModalV2Component,
    TranslateModule,
    AddressPipe,
    ImageSizePipe
  ]
})
export class AppointmentsModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);

  public cancelButtonMessage = 'appointment.cancel_a';
  public okButtonMessage = 'property.select_appointment_a';
  public applicationId = input<string>(undefined);
  public applicationStatus = input<string>(undefined);
  public property = input<AppointmentPropertyShortInfo>(undefined);
  public appointments = input<Appointment[]>(undefined);
  public exclusiveAppointments = input<Appointment[]>(undefined);
  public appointmentAcceptances =
    input<AppointmentAcceptanceShortInfo[]>(undefined);
  public activeAppointment = input<Appointment>(undefined);
  public activeAppointmentAcceptance =
    input<AppointmentAcceptanceShortInfo>(undefined);
  public currentActiveAppointmentId: string;
  public newActiveAppointmentId: string;
  public showV2 = input(true);
  public showNotFittingOption = input(undefined);

  get picture() {
    return this.property()?.image;
  }

  get numberOfAppointments() {
    return (
      (this.appointments()?.length || 0) +
      (this.exclusiveAppointments?.length || 0)
    );
  }

  get hasAppointments() {
    return (
      this.appointments()?.length > 0 || this.exclusiveAppointments?.length > 0
    );
  }

  ngOnInit() {
    this.newActiveAppointmentId =
      this.activeAppointment()?.id ||
      (this.applicationStatus() ===
      PropertyApplicationStatus.ALL_APPOINTMENTS_DECLINED
        ? 'non_fitting'
        : '');
    this.currentActiveAppointmentId = this.activeAppointment()?.id;
  }

  close() {
    if (this.newActiveAppointmentId === 'non_fitting') {
      return this.ngbActiveModal.close({
        applicationId: this.applicationId(),
        appointmentAcceptanceId: this.newActiveAppointmentId
      });
    }
    const appointment = [
      ...this.exclusiveAppointments(),
      ...this.appointments()
    ].find(({ id }) => id === this.newActiveAppointmentId);

    this.ngbActiveModal.close({
      applicationId: this.applicationId(),
      appointmentAcceptanceId: this.activeAppointmentAcceptance()?.id,
      property: this.property(),
      appointment
    } as AppointmentSelection);
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public getAppointmentCanceled(appointment: Appointment) {
    return appointment.state === 'CANCELED';
  }
}
