import {
  Component,
  inject,
  input,
  model,
  OnInit,
  viewChild
} from '@angular/core';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionHeader,
  NgbAccordionItem
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import { Attachment, Pagination } from '@ui/shared/models';
import { AsyncPipe } from '@angular/common';

import { ListControlsComponent } from '../../../list-controls/list-controls.component';
import { LoadingSpinnerComponent } from '../../../loading-spinner/loading-spinner.component';
import { MessageTemplateFormComponent } from '../message-template-form/message-template-form.component';
import { ButtonComponent } from '../../../../atoms/button/button.component';
import { MessageTemplate, MessageTemplateSubstitution } from '../../model';
import { ActionState } from '../../../../../state-utils/action-state';
import {
  CreateTemplate,
  DeleteTemplate,
  getCreateTemplateActionState,
  getDeleteTemplateActionState,
  getMessageTemplates,
  getMessageTemplatesActionState,
  getMessageTemplatesPage,
  getUpdateTemplateActionState,
  LoadTemplate,
  UpdateTemplate
} from '../../../../../infrastructure/base-state/messenger';

@UntilDestroy()
@Component({
  selector: 'app-message-templates',
  templateUrl: './message-templates.component.html',
  styleUrls: ['./message-templates.component.scss'],
  imports: [
    ButtonComponent,
    MessageTemplateFormComponent,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionBody,
    LoadingSpinnerComponent,
    ListControlsComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class MessageTemplatesComponent implements OnInit {
  private store = inject(Store);
  private translateService = inject(TranslateService);

  public templates: MessageTemplate[] = [];
  public activeTemplate: MessageTemplate;
  public showCreateTemplate = false;
  public loadedTemplatesActionState$: Observable<ActionState>;
  public createActionState$: Observable<ActionState>;
  public updateActionState$: Observable<ActionState>;
  public deleteActionState$: Observable<ActionState>;
  public loadedTemplatePagination: Pagination;
  readonly conversationMessageTemplateSubstitutionTags =
    input<MessageTemplateSubstitution[]>(undefined);
  readonly newTemplateData = model<MessageTemplate>(undefined);
  readonly accordion = viewChild(NgbAccordionDirective);

  public get processingAction$() {
    return combineLatest([
      this.createActionState$,
      this.updateActionState$,
      this.deleteActionState$
    ]).pipe(map(array => array.some(pending => pending.pending)));
  }

  public ngOnInit() {
    this.loadedTemplatesActionState$ = this.store.select(
      getMessageTemplatesActionState
    );
    this.store
      .select(getMessageTemplatesPage)
      .pipe(untilDestroyed(this))
      .subscribe(pagination => (this.loadedTemplatePagination = pagination));
    this.createActionState$ = this.store.select(getCreateTemplateActionState);
    this.updateActionState$ = this.store.select(getUpdateTemplateActionState);
    this.deleteActionState$ = this.store.select(getDeleteTemplateActionState);
    this.store.dispatch(new LoadTemplate({ page: 0 }));

    this.store
      .select(getMessageTemplates)
      .pipe(untilDestroyed(this))
      .subscribe(templates => {
        this.templates = templates;
        const newTemplateData = this.newTemplateData();
        if (newTemplateData) {
          this.activeTemplate = { ...newTemplateData };
        }
      });
  }

  public saveTemplate(template: MessageTemplate) {
    const { attachments, ...payload } = template;
    if ((attachments as Attachment)?.file) {
      (payload as MessageTemplate).attachments = (
        attachments as Attachment
      ).file;
    }
    if ((attachments as Attachment)?.url) {
      (payload as MessageTemplate).attachments = attachments;
    }
    this.newTemplateData.set(null);
    if (!template.id) {
      this.cancelCreate();
      return this.store.dispatch(new CreateTemplate(payload));
    }
    return this.store.dispatch(new UpdateTemplate(payload));
  }

  public panelChanged(panelId: string) {
    const id = panelId.slice(-1);
    this.cancelCreate();
    this.activeTemplate = this.templates[id];
  }

  public createTemplate() {
    this.accordion().collapseAll();
    this.showCreateTemplate = true;
    this.activeTemplate = {
      id: null,
      title: this.translateService.instant('messenger.new_template_title_l'),
      content: '',
      attachments: null
    };
  }

  public deleteTemplate(event: MouseEvent, templateId: string, index: number) {
    if (!templateId) return this.templates.splice(index, 1);
    this.store.dispatch(new DeleteTemplate(templateId));
    this.accordion().collapseAll();
    event.preventDefault();
    event.stopPropagation();
  }

  public onPageChange(page: number) {
    this.store.dispatch(
      new LoadTemplate({
        page: page - 1,
        size: this.loadedTemplatePagination.size
      })
    );
  }

  public onSizeChange(size: number) {
    this.store.dispatch(new LoadTemplate({ page: 0, size }));
  }

  public cancelCreate() {
    this.showCreateTemplate = false;
  }
}
