import { inject, Injectable } from '@angular/core';
import Keycloak, { KeycloakConfig, KeycloakLoginOptions } from 'keycloak-js';

import { AuthToken, KeycloakLoginResponse } from '../model';
import { AuthTokenService } from '../../authentication';
import { WINDOW_REF } from '../../browser';

import { INFRASTRUCTURE_CONFIG } from '../../infrastructure-config.token';
import { KeycloakService } from './keycloak.service';

// Workaround from https://github.com/ng-packagr/ng-packagr/issues/343#issuecomment-350965445

@Injectable()
export class KeycloakWebService {
  private authTokenService = inject(AuthTokenService);
  private keycloakService = inject(KeycloakService);
  private windowRef = inject(WINDOW_REF);
  private infrastructure = inject(INFRASTRUCTURE_CONFIG);

  private keycloakInstance: Keycloak.KeycloakInstance;

  public async init() {
    await this.initKeycloak();
    void this.refresh(
      `${this.infrastructure.environment.property_searcher_home_url}/login`
    );
  }

  public login(options: KeycloakLoginOptions) {
    try {
      this.beginLogin(options);
    } catch (err) {
      const context = { messageError: 'Keycloak Error: error by login' };
      Object.assign(err, { context });
      throw err;
    }
  }

  public exchangeToken(token: string, realm: string, clientId: string) {
    void this.keycloakService.getExchangeToken(token, realm, clientId);
  }

  public logout(redirectUrl: string) {
    const url = this.getLogoutUrl(redirectUrl);
    this.windowRef.location.href = url;
  }

  private getLogoutUrl(redirectUrl: string): string {
    return this.keycloakInstance.createLogoutUrl({
      redirectUri:
        this.infrastructure.environment.property_searcher_home_url +
        '/' +
        encodeURIComponent(redirectUrl)
    });
  }

  private async initKeycloakInstance() {
    const keycloakConfig = await this.keycloakService.getKcJsonStructure();
    this.keycloakInstance = Keycloak(keycloakConfig as KeycloakConfig);
  }

  private async initKeycloak() {
    await this.initKeycloakInstance();
    this.keycloakService.initKeycloak(this.keycloakInstance);
    void this.keycloakInstance.init({
      adapter: 'default',
      redirectUri:
        this.infrastructure.environment.property_searcher_home_url + '/'
    });
  }

  public refresh(path: string): Promise<AuthToken> {
    try {
      void this.keycloakService.refresh();
    } catch (e) {
      this.logout(path);
      return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async continueLoginWithCode({
    code,
    redirectUri: redirectUrl
  }: KeycloakLoginResponse) {
    const uri = this.keycloakService.getTokenUrl();
    const body = this.keycloakService.getAccessTokenParams(code, redirectUrl);
    const headers = this.keycloakService.getTokenRequestHeaders();
    // eslint-disable-next-line @typescript-eslint/require-await
    return this.keycloakService.createPostRequest(uri, body, { headers }).then(
      // eslint-disable-next-line @typescript-eslint/require-await
      async authToken => {
        this.authTokenService.setToken(authToken);
        return authToken;
      }
    );
  }

  private beginLogin(options: KeycloakLoginOptions) {
    this.authTokenService.setToken(null);
    const url = this.keycloakInstance.createLoginUrl(options);
    this.windowRef.location.href = url;
  }
}
