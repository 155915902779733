<div class="label-list-wrapper" [ngClass]="{ 'no-wrap': multilineMode() }">
  @for (
    tag of truncateLabels()
      ? (assignedTags() | slice: 0 : amountBeforeTruncation())
      : assignedTags();
    track tag
  ) {
    <app-label-list-tag
      [tag]="tag"
      [name]="isSystemTag() ? tag : tag?.title"
      [isSystemTag]="isSystemTag()"
      [tagType]="isSystemTag() ? undefined : tag?.ownershipType"
      [readonly]="readonly()"
      (unassignTag)="onUnassignTag($event)"
    >
    </app-label-list-tag>
  }
  @if (truncateLabels() && assignedTags()?.length > amountBeforeTruncation()) {
    <app-context-menu class="align-self-center me-2" [container]="'body'">
      <app-badge menu-button class="c-pointer">
        ... {{ assignedTags()?.length - amountBeforeTruncation() }}
      </app-badge>
      <!-- style attribute is used because the tooltip is applied to the body
      and class styles defined in this component will not affect it -->
      <div style="overflow-y: scroll; max-height: 275px" menu-content>
        @for (
          tag of assignedTags()
            | slice: amountBeforeTruncation() : assignedTags()?.length;
          track tag
        ) {
          <app-context-menu-item>
            <app-label-list-tag
              [tag]="tag"
              [name]="isSystemTag() ? tag : tag?.title"
              [isSystemTag]="isSystemTag()"
              [tagOwnershipType]="tag?.ownershipType"
              [tagType]="isSystemTag() ? undefined : tag?.ownershipType"
              [readonly]="readonly()"
              (unassignTag)="unassignTag.emit($event)"
            >
            </app-label-list-tag>
          </app-context-menu-item>
        }
      </div>
    </app-context-menu>
  }
  @if (!readonly() && assignableTags()?.length) {
    <app-context-menu
      class="align-self-center"
      [tooltipPlacement]="ContextMenuTooltipPlacementEnum.BOTTOM"
      [container]="'body'"
    >
      <span
        menu-button
        class="add-tag__icon"
        [ngbTooltip]="
          (isAddLabelObject()
            ? 'landlord.add_tag_object_tooltip_l'
            : 'property_searcher.add_tag_tooltip_l'
          ) | translate
        "
      >
        <i class="icon icon--plus" style="font-size: 13px"></i>
      </span>
      <!-- style attribute is used because the tooltip is applied to the body
      and class styles defined in this component will not affect it -->
      <div style="overflow-y: scroll; max-height: 275px" menu-content>
        @for (tag of assignableTags(); track tag) {
          <app-context-menu-item (clickEvent)="onAssignTag(tag)">
            <app-tag
              class="labels-tag labels-tag--clickable"
              [name]="isSystemTag() ? tag : tag?.title"
              [isSystemTag]="isSystemTag()"
              [tagType]="isSystemTag() ? undefined : tag?.ownershipType"
            ></app-tag>
          </app-context-menu-item>
        }
      </div>
    </app-context-menu>
  }
</div>
