import {
  Component,
  forwardRef,
  inject,
  OnInit,
  input,
  output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  HierarchicalQuestionType,
  IconTypeEnum
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';
import { HierarchicalQuestionInputComponent } from '../hierarchical-question-field/input-field/hierarchical-question-input.component';
import { HierarchicalQuestionSelectionComponent } from '../hierarchical-question-field/selection-field/hierarchical-question-selection.component';
import { HierarchicalQuestionService } from '../../../../services/hierarchical-question.service';
import { BadgeColorEnum } from '../../../atoms/badge';

@UntilDestroy()
@Component({
  selector: 'app-hierarchical-question-form',
  templateUrl: './hierarchical-question-form.component.html',
  styleUrls: ['./hierarchical-question-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalQuestionFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HierarchicalQuestionFormComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HierarchicalQuestionSelectionComponent,
    HierarchicalQuestionInputComponent,
    BadgeComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective,
    TranslateModule
  ]
})
export class HierarchicalQuestionFormComponent
  implements OnInit, ControlValueAccessor
{
  readonly hierarchicalQuestion = input<HierarchicalQuestion>(undefined);
  readonly hierarchicalQuestions = input<HierarchicalQuestion[]>(undefined);
  readonly currentLanguage = input<AvailableLanguageCodesEnum>(undefined);
  readonly defaultLanguage = input<AvailableLanguageCodesEnum>(undefined);
  readonly isProcessing = input<boolean>(undefined);
  readonly validityChange = output<boolean>();
  readonly answerChange = output<any>();
  public form: FormGroup;
  public value: HierarchicalQuestion;
  private fb = inject(FormBuilder);
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);

  public get responseControl() {
    return this.form.get('userResponse').get('response') as FormControl;
  }

  public get answerIdsControl() {
    return this.form.get('userResponse').get('answerIds');
  }

  public get commentControl() {
    return this.form.get('userResponse').get('comment');
  }

  public ngOnInit() {
    this.form = this.fb.group(
      this.hierarchicalQuestionService.questionControlConfig
    );

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: HierarchicalQuestion) => {
        this.value = value;
        this.onChange(this.value);
        this.onTouch();
      });

    const hierarchicalQuestion = this.hierarchicalQuestion();
    if (hierarchicalQuestion.data.type === HierarchicalQuestionType.SELECTION) {
      this.responseControl.disable();
    } else {
      const validator =
        hierarchicalQuestion.data.type === HierarchicalQuestionType.RANGE_NUMBER
          ? Validators.pattern(/^\d+$/)
          : Validators.nullValidator;
      this.responseControl.setValidators([Validators.required, validator]);
      this.answerIdsControl.disable();
    }
  }

  public validate() {
    this.validityChange.emit(this.form.valid);
    return this.form.valid ? null : { missingFields: true };
  }

  public onAnswerChanged(value: any) {
    this.answerChange.emit(value);
  }

  public writeValue(value: HierarchicalQuestion): void {
    this.value = value || ({} as HierarchicalQuestion);
    const valueToPatch = {
      ...value,
      userResponse: {
        response: value?.userResponse?.response || null,
        answerIds: value?.userResponse?.answerIds || null,
        comment: value?.userResponse?.comment || null
      }
    };
    this.form.patchValue(valueToPatch);
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  private onChange = (value: unknown) => value;

  private onTouch = () => null;

  public registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  protected readonly BadgeColorEnum = BadgeColorEnum;
  protected readonly IconTypeEnum = IconTypeEnum;
}
