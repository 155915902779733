<input appInput #textInput type="text" [(ngModel)]="value" />
@if (!hideIcon()) {
  <svg-icon
    src="/assets/images/icons/icon-edit.svg"
    [applyClass]="true"
    [svgStyle]="{ 'width.px': 30 }"
    class="secondary-svg-color polygon polyline edit-btn"
    (click)="edit()"
  ></svg-icon>
}
