import { Component, input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appFieldsetDisabled]',
  template: `
    <fieldset [class.disable-pointer]="disabled()" [disabled]="disabled()">
      <ng-content></ng-content>
    </fieldset>
  `,
  styleUrls: ['fieldset-disabled.component.scss']
})
export class FieldsetDisabledComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  readonly disabled = input<boolean>(undefined, {
    alias: 'appFieldsetDisabled'
  });
}
