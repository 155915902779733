import { Prioset } from '@ui/shared/models';

import { ActionState, ActionStateCreator } from '@ui/legacy-lib';
import * as fromActions from './priosets.actions';

export interface PriosetsState {
  loadPropertyActionState: ActionState;
  latestPriosetSaved: Prioset;
}

export const initialState: PriosetsState = {
  loadPropertyActionState: ActionStateCreator.create(),
  latestPriosetSaved: null
};

export function reducer(
  state = initialState,
  action: fromActions.PriosetAction
): PriosetsState {
  switch (action.type) {
    case fromActions.UPDATE_PRIOSET: {
      return {
        ...state,
        loadPropertyActionState: ActionStateCreator.onStart(),
        latestPriosetSaved: null
      };
    }

    case fromActions.UPDATE_PRIOSET_SUCCESS: {
      return {
        ...state,
        loadPropertyActionState: ActionStateCreator.onSuccess(),
        latestPriosetSaved: action.prioset
      };
    }

    case fromActions.UPDATE_PRIOSET_FAIL: {
      return {
        ...state,
        loadPropertyActionState: ActionStateCreator.onError(
          new Error(action.error)
        ),
        latestPriosetSaved: null
      };
    }

    case fromActions.CLEAR_LATEST_PRIOSET_SAVED: {
      return {
        ...state,
        latestPriosetSaved: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoadPropertyActionState = (state: PriosetsState) =>
  state.loadPropertyActionState;
export const getLatestPriosetSaved = (state: PriosetsState) =>
  state.latestPriosetSaved;
