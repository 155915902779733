import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  output
} from '@angular/core';

@Directive({
  selector: '[appClickDetector]'
})
export class ClickDetectorDirective {
  private elementRef = inject(ElementRef);

  readonly enabled = input(false);
  readonly insideClick = output<void>();
  readonly outsideClick = output<void>();

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (this.enabled()) {
      const clickedInside = (
        this.elementRef.nativeElement as HTMLElement
      ).contains(target);

      if (!clickedInside) {
        this.outsideClick.emit();
      } else {
        this.insideClick.emit();
      }
    }
  }
}
