import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-landlord',
  templateUrl: './landlord.component.html',
  styleUrls: [],
  imports: [RouterOutlet]
})
export class LandlordComponent {}
