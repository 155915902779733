<div class="date__container">
  <input
    class="form-control"
    ngbDatepicker
    #d="ngbDatepicker"
    [(ngModel)]="value"
    [required]="required()"
    [minDate]="minDate()"
    [maxDate]="maxDate()"
    [startDate]="calendarStartDate$ | async"
    name="dp"
    [placeholder]="'date.format_l' | translate"
    [disabled]="disabled"
    [readonly]="readonly()"
    [container]="container()"
  />
  <svg-icon
    src="/assets/images/icons/calendar.svg"
    [applyClass]="true"
    [svgStyle]="{ 'width.px': 15 }"
    class="secondary-svg-color path rect"
    (click)="!readonly() && d.toggle()"
  ></svg-icon>
</div>
