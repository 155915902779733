import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { BaseStep } from '../../../../wizard/wizard-step';

@Component({
  selector: 'app-qualified-electroinic-signature-confirm-terms-and-conditions',
  templateUrl:
    './qualified-electronic-signature-confirm-terms-and-conditions.component.html',
  styleUrls: [
    './qualified-electronic-signature-confirm-terms-and-conditions.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule]
})
export class QualifiedElectronicSignatureConfirmTermsAndConditionsComponent extends BaseStep {}
