import { Component, inject, input } from '@angular/core';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import {
  NameValue,
  PropertyApplicationStatus,
  PropertyType
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from '../modal/modal.component';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ModalFooterComponent } from '../modal/modal-footer/modal-footer.component';
import { RadioButtonComponent } from '../form/controls/radio/radio-button/radio-button.component';
import { RadioGroupComponent } from '../form/controls/radio/radio-group/radio-group.component';
import { InfoBoxComponent } from '../info-box/info-box.component';
import { ConfirmReasonComponent } from '../confirm-reason-modal/confirm-reason/confirm-reason.component';
import { ModalContentComponent } from '../modal/modal-content/modal-content.component';
import { ModalV2Component } from '../modal-v2/modal-v2.component';

@Component({
  selector: 'app-property-interest',
  templateUrl: './property-interest.component.html',
  styleUrls: ['./property-interest.component.scss'],
  imports: [
    ModalV2Component,
    ModalContentComponent,
    ConfirmReasonComponent,
    InfoBoxComponent,
    RadioGroupComponent,
    FormsModule,
    RadioButtonComponent,
    ModalFooterComponent,
    ButtonComponent,
    NgbTooltip,
    ModalComponent,
    TranslateModule
  ]
})
export class PropertyInterestComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly propertyName = input<string>(undefined);
  readonly intent = input<string>(undefined);
  readonly fromEmail = input<boolean>(undefined);
  readonly profileComplete = input<boolean>(undefined);
  readonly reasons = input<NameValue[]>(undefined);
  readonly editProfile = input<() => void>(undefined);
  readonly isOfflineUser = input<boolean>(undefined);
  readonly propertyType = input<PropertyType>(undefined);
  readonly showV2 = input(true);
  readonly askForViewingAppointmentAttendance = input<boolean>(undefined);

  public hasVisitedViewingAppointment: string;
  public reasonTypeModel: string;
  public otherReasonText: string;
  public applicationStates = PropertyApplicationStatus;

  public get isInterested() {
    return this.intent() === PropertyApplicationStatus.INTENT;
  }

  close() {
    this.ngbActiveModal.close();
  }

  public onEditProfile() {
    this.ngbActiveModal.dismiss(true);
    return this.editProfile()();
  }

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public confirmIntent() {
    this.ngbActiveModal.close({
      intent: true
    });
  }

  public denyIntent() {
    this.ngbActiveModal.close({
      intent: false,
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    });
  }
}
