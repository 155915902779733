import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  input
} from '@angular/core';
import { Editor, Toolbar, NgxEditorModule } from 'ngx-editor';
import {
  ControlContainer,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldLabelComponent } from '../../legacy/form/form-field/form-field-label/form-field-label.component';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ],
  imports: [
    FormFieldLabelComponent,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class TextEditorComponent implements OnInit, OnDestroy {
  readonly controlKey = input.required<string>();
  readonly required = input(false);
  public editor: Editor;
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'link'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear']
  ];

  public ngOnInit(): void {
    this.editor = new Editor();
  }

  public ngOnDestroy(): void {
    this.editor.destroy();
  }
}
