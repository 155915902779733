import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  input,
  output
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  AvailableLanguageCodesEnum,
  BreakPointsMaxInPx,
  Language
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LangPickerComponent } from '../../molecules/lang-picker/lang-picker.component';
import { SocialsComponent } from './socials/socials.component';

export type FooterLinkAppearance = 'default' | 'button';

export interface FooterLink {
  link: string;
  name: string;
  appearance?: FooterLinkAppearance;
  hideOnMobile?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LangPickerComponent,
    FormsModule,
    SocialsComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class FooterComponent implements OnInit, OnDestroy {
  private observer = inject(BreakpointObserver);

  readonly currentYear = input(new Date().getFullYear());
  readonly links = input<FooterLink[]>([]);
  readonly showLanguagePicker = input(false);
  readonly availableLanguages = input<Language[]>(undefined);
  readonly currentLanguageCode = input<AvailableLanguageCodesEnum>(undefined);
  readonly sideNavMode = input<boolean>(undefined);
  readonly dark = input<boolean>(undefined);

  readonly languageChange = output<string>();

  private _mobileView: BehaviorSubject<boolean>;

  public get mobileView$() {
    return this._mobileView.asObservable().pipe(distinctUntilChanged());
  }

  public ngOnInit() {
    this._mobileView = new BehaviorSubject<boolean>(null);

    this.observer
      .observe(`(max-width: ${BreakPointsMaxInPx.SM}px)`)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.setMobileView(result.matches);
      });
  }

  public ngOnDestroy() {
    this._mobileView.complete();
  }

  private setMobileView(mobileView: boolean) {
    this._mobileView.next(mobileView);
  }

  public onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }
}
