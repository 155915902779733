<div class="custom-question-field__content">
  <app-form-field>
    <app-form-field-label>{{
      customQuestion().title | translate
    }}</app-form-field-label>
    <app-flat-select
      [items]="options"
      [(ngModel)]="value"
      required
      [multiple]="isMultiple"
    ></app-flat-select>
  </app-form-field>
</div>
