<div class="whole-container d-flex justify-content-between" appClickArea>
  <div class="text-label" [class.greyed-text]="value === null">
    <ng-content select="app-form-field-label"></ng-content>
  </div>

  <div
    class="checkboxes-container d-flex"
    [class.visible]="disabled || forceDisabled()"
  >
    <div
      class="check__container"
      [ngClass]="{ 'always-visible': alwaysShow(), 'd-lg-block': trueValue }"
    >
      <input
        type="checkbox"
        [disabled]="disabled || forceDisabled()"
        [(ngModel)]="trueValue"
        (ngModelChange)="onTrueChange($event)"
      />
      <span class="checkbox__label true-value"></span>
    </div>

    <div
      class="check__container ml5"
      [ngClass]="{ 'always-visible': alwaysShow(), 'd-lg-block': falseValue }"
    >
      <input
        type="checkbox"
        [disabled]="disabled || forceDisabled()"
        [(ngModel)]="falseValue"
        (ngModelChange)="onFalseChange($event)"
      />
      <span class="checkbox__label false-value"></span>
    </div>
  </div>
</div>
