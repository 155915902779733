import { PortalsEffects } from './portals';
import { PriosetsEffects } from './priosets';
import { ProductsEffects } from './products';
import { LandlordEffects } from './landlord';
import { PropertySearcherEffects } from './property-searcher';

export const effects = [
  PortalsEffects,
  PriosetsEffects,
  LandlordEffects,
  PropertySearcherEffects,
  ProductsEffects
];

export * from './reducers';
export * from './queries/gql-queries';
export * from './portals';
export * from './priosets';
export * from './products';
export * from './landlord';
export * from './property-searcher';
