<div class="self-disclosure-child-list__content">
  <form [formGroup]="form()">
    <span>{{ selfDisclosureQuestion.title | translate }}</span>
    @if (selfDisclosureQuestion.mandatory) {
      <span class="required-marker ng-star-inserted">&nbsp;*</span>
    }
    @for (person of getFormControls; track person; let i = $index) {
      <div class="mb20">
        <app-self-disclosure-child
          [form]="person"
          [title]="
            ('self_disclosure.answer.child_list_subheader' | translate) +
            ' ' +
            (i + 1)
          "
          [selfDisclosureQuestion]="selfDisclosureQuestion"
          [showRemove]="true"
          (onRemove)="removeChild(i)"
        ></app-self-disclosure-child>
      </div>
    }

    <div class="row mt20 self-disclosure-child-list__choices">
      @if (selfDisclosureQuestion.mandatory && !getFormControls.length) {
        <div class="col justify-content-center">
          <app-form-field>
            <app-checkbox
              [label]="'general.no_l' | translate"
              formControlName="answerUnavailable"
            ></app-checkbox>
          </app-form-field>
        </div>
      }
      <div class="col">
        <app-button
          [disabled]="selfDisclosureQuestion.answerUnavailable"
          (clickEvent)="addChild()"
          >{{ 'self_disclosure.answers.add_person_a' | translate }}</app-button
        >
      </div>
    </div>
  </form>
</div>
