import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChildren,
  ElementRef,
  inject,
  input,
  model,
  output,
  viewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase
} from '@ng-bootstrap/ng-bootstrap';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { ButtonComponent } from '../../atoms/button/button.component';
import { slideAnimation, SlideAnimationStateEnum } from '../../../utils';
import { ElevationDirective } from '../../../directives';
import { SideSheetContentDirective } from './side-sheet-content.directive';

@UntilDestroy()
@Component({
  selector: 'app-side-sheet',
  templateUrl: './side-sheet.component.html',
  styleUrls: ['./side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideAnimation],
  imports: [
    NgClass,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgTemplateOutlet,
    ButtonComponent,
    TranslateModule,
    ElevationDirective
  ]
})
export class SideSheetComponent implements AfterViewInit {
  private _router = inject(Router);
  private cd = inject(ChangeDetectorRef);

  readonly isPending = input<boolean>(undefined);
  readonly isFormValid = input(true);
  readonly singleSubmitButton = input<string>(undefined);
  readonly hasClosingCheck = input<boolean>(undefined);
  readonly isLayer = model(false);
  readonly disableActions = input<boolean>(undefined);
  currentFormIndex = model(0);
  readonly showDefaultFooterButtons = input(true);
  readonly showCustomFooterButtons = input(false);
  readonly showEditButton = input(false);
  readonly disableNext = input(false);
  readonly preventNext = input(false);
  readonly useFullWidth = input(false);

  readonly nav = viewChild<NgbNav>('nav');
  readonly el = viewChild<ElementRef>('navigation');

  readonly currentFormIdChange = output<string>();
  readonly completeFlow = output();
  readonly closeSideSheet = output();
  readonly editEntry = output();
  readonly nextStepClicked = output<number>();
  readonly contents = contentChildren(SideSheetContentDirective);

  public leftFade = false;
  public rightFade = true; // right fade set to true initially to prevent delay before setting to false

  public activeNav = 'nav-0';

  public slideAnimationStateEnum = SlideAnimationStateEnum;

  constructor() {
    toObservable(this.currentFormIndex)
      .pipe(untilDestroyed(this))
      .subscribe(currentFormIndex => {
        this.selectSpecificTab(currentFormIndex);
        this.scrollIntoView(currentFormIndex);
      });
  }

  public get activeNavIndex(): number {
    return parseInt(this.activeNav.slice(-1));
  }

  public ngAfterViewInit(): void {
    this.nav()
      .activeIdChange.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.currentFormIndex.set(this.activeNavIndex);
        this.currentFormIdChange.emit(
          this.contents().at(this.activeNavIndex).identifier()
        );
        this.scrollIntoView(this.activeNavIndex);
      });

    this.currentFormIndex() ? this.switchToTab(this.currentFormIndex()) : null;

    this.focusFirstTabForKeyNavigation();

    // check whether nav has overflow and add fade if it does
    const target = this.el().nativeElement as HTMLElement;
    if (target.scrollWidth > target.offsetWidth) {
      this.rightFade = true;
      this.leftFade = false;
    } else {
      this.leftFade = false;
      this.rightFade = false;
    }
  }

  public get isNextStepDisabled() {
    return (
      !this.isFormValid() ||
      this.isPending() ||
      this.disableActions() ||
      this.disableNext()
    );
  }

  public get hasSteps(): boolean {
    return this.singleSubmitButton() ? false : true;
  }

  public dismiss(): void {
    if (!this.hasClosingCheck()) {
      if (!this.isLayer()) {
        void this._router.navigate(['', { outlets: { side: null } }]);
      } else {
        this.isLayer.set(false);
      }
    }
    this.closeSideSheet.emit();
  }

  public save(): void {
    this.completeFlow.emit();
  }

  public editEntryClicked() {
    this.editEntry.emit();
  }

  /**
   * Used for navigating back and forth between the tabs
   * @param index
   */
  public switchToTab(index: number): void {
    const navIndex = index + this.activeNavIndex;
    this.nextStepClicked.emit(navIndex);
    if (!this.disableActions()) {
      const preventNext = this.preventNext();
      if (!preventNext || (preventNext && index === -1)) {
        this.nav().select(`nav-${navIndex}`);
        this.scrollIntoView(index);
        this.cd.detectChanges();
      }
    }
  }

  public selectSpecificTabByIdentifier(identifier: string): void {
    if (!this.disableActions()) {
      let index = 0;
      // no findIndex() possible for queryLists, loop through
      for (let i = 0; i < this.contents().length; i++) {
        index = i;
        if (this.contents().at(i).identifier() === identifier) {
          break;
        }
      }
      this.selectSpecificTab(index);
    }
  }

  /**
   * Used to set a specific tab index
   * @param index
   */
  public selectSpecificTab(index: number): void {
    if (!this.disableActions()) {
      this.nav().select(`nav-${index}`);
      this.cd.detectChanges();
    }
  }

  private focusFirstTabForKeyNavigation() {
    const firstTab = this.el().nativeElement.childNodes[0] as HTMLElement;
    firstTab ? firstTab.focus() : null;
  }

  public scrollIntoView(index: number): void {
    const target = this.el().nativeElement.children[index] as HTMLElement;
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  public onHorizontalScroll(): void {
    const target = this.el().nativeElement as HTMLElement;
    const edgeTolerance = 10;
    // remove fade if scroll is near edges
    if (target.scrollLeft < edgeTolerance) {
      this.leftFade = false;
    } else if (
      target.scrollLeft >=
      target.scrollWidth - target.offsetWidth - edgeTolerance
    ) {
      this.rightFade = false;
    } else {
      this.leftFade = true;
      this.rightFade = true;
    }
  }

  public onWheelScroll(event: WheelEvent): void {
    const target = this.el().nativeElement as HTMLElement;

    if (!event.deltaY) {
      return; // keep horizontal scrolling same
    }

    // transform vertical scroll to horizontal scroll
    target.scrollLeft += event.deltaY + event.deltaX;
  }
}
