<form [formGroup]="this.form()">
  @if (!isSalesObject()) {
    <app-form-field>
      <app-form-field-label>
        <i class="mb-0 icon icon--coin me-2"></i>
        {{ 'general.max_rental_price_l' | translate }}
      </app-form-field-label>
      <app-slider
        [min]="minUpperBoundRent()"
        [max]="maxUpperBoundRent()"
        [unit]="'€'"
        [unitMax]="'€ +'"
        [step]="stepsForUpperBoundRent()"
        [required]
        formControlName="upperBoundRent"
      >
      </app-slider>
    </app-form-field>
  } @else {
    <ng-container [formGroup]="form().controls.salesData">
      <app-form-field>
        <app-form-field-label>
          <i class="mb-0 icon icon--coin me-2"></i>
          {{ 'general.max_price_l' | translate }}
        </app-form-field-label>
        <app-slider
          [min]="5000"
          [max]="5000000"
          [unit]="'€'"
          [unitMax]="'€ +'"
          [step]="5000"
          [required]
          formControlName="priceUpperBound"
        >
        </app-slider>
      </app-form-field>
    </ng-container>
  }

  @if (isFlat() || isCommercial()) {
    <app-form-field-label class="mt-3 d-block">
      <i class="mb-0 icon icon--area-size me-2"></i>
      {{ 'general.flat_area_l' | translate }}</app-form-field-label
    >
    @let lowerBoundSize = form().controls.lowerBoundSize.value;
    @let upperBoundSize = form().controls.upperBoundSize.value;
    <mat-slider
      [max]="upperBoundSizeMax"
      [min]="0"
      [step]="5"
      discrete
      [displayWith]="displaySizeLabel"
    >
      <input
        matSliderStartThumb
        (dragEnd)="dragEnd($event)"
        formControlName="lowerBoundSize"
      />
      <input
        matSliderEndThumb
        (dragEnd)="dragEnd($event)"
        formControlName="upperBoundSize"
      />
      <div class="slider__label-container">
        <div class="slider__label-min">{{ lowerBoundSize }}</div>
        <div class="slider__label-max">
          {{ upperBoundSize }}
          @if (upperBoundSize === upperBoundSizeMax) {
            +
          }
        </div>
      </div>
    </mat-slider>
  }
  @if (isFlat()) {
    <app-form-field-label class="mt-4 d-block">
      <i class="mb-0 icon icon--rooms me-2"></i>
      {{ 'general.number_of_rooms_l' | translate }}</app-form-field-label
    >
    @let lowerBoundRooms = form().controls.lowerBoundRooms.value;
    @let upperBoundRooms = form().controls.upperBoundRooms.value;
    <mat-slider
      [max]="upperBoundRoomsMax"
      [min]="1"
      [step]="1"
      discrete
      [displayWith]="displayRoomsLabel"
    >
      <input
        matSliderStartThumb
        (dragEnd)="dragEnd($event)"
        formControlName="lowerBoundRooms"
      />
      <input
        matSliderEndThumb
        (dragEnd)="dragEnd($event)"
        formControlName="upperBoundRooms"
      />
      <div class="slider__label-container">
        <div class="slider__label-min">{{ lowerBoundRooms }}</div>
        <div class="slider__label-max">
          {{ upperBoundRooms }}
          @if (upperBoundRooms === upperBoundRoomsMax) {
            +
          }
        </div>
      </div>
    </mat-slider>
  }
</form>
