import { createSelector } from '@ngrx/store';
import { getBaseState } from '../reducers';

export const getRouterState = createSelector(
  getBaseState,
  state => state.router
);

export const getRouterParams = createSelector(
  getRouterState,
  routerState => routerState?.state?.params
);

export const getRouterQueryParams = createSelector(
  getRouterState,
  routerState => routerState?.state?.queryParams
);

export const getRouterUrl = createSelector(
  getRouterState,
  routerState => routerState?.state?.url
);
