import {
  COUNTRIES_AND_DISTRICTS_ERRORS,
  CUSTOMER_COOPERATION_ERRORS
} from '@ui/shared/models';

import { isString } from '../../utils';
import { ExtendedGraphQLError } from './interfaces';

const MEANINGFUL_STATUS_CODES = [400, 500];
const MEANINGFUL_STATUS_MESSAGE = 'INTERNAL_SERVER_ERROR';
const DEFAULT_MESSAGE = 'UNEXPECTED_ERROR_OCCURRED_M';
const BAD_REQUEST = 'BAD_REQUEST';
const UNIFIED_CONTACTS = [
  'ONLY_USER_OWNED_CONTACTS_CAN_BE_CONVERTED_TO_LANDLORD_OWNED_L'
];
const MEANINGFUL_ERRORS = [
  'REGION_NOT_SET_L',
  'MEMBERSHIP_NUMBER_NOT_POSSIBLE_L',
  'APPLICATION_ALREADY_EXISTS_L',
  'EMAIL_ALREADY_VERIFIED_L',
  'CONVERSATION_IS_BLOCKED_L',
  'Could not find valid SCHUFA credentials',
  'MEMBER_PROFILE_WITH_EMAIL_ALREADY_EXISTING_L',
  ...CUSTOMER_COOPERATION_ERRORS,
  ...COUNTRIES_AND_DISTRICTS_ERRORS,
  ...UNIFIED_CONTACTS
];

export function errorMessageParser(err: any, defaultMessage = '') {
  const errors = Array.isArray(err) ? err : [err];
  const errorsArray = [
    ...errors,
    ...errors.reduce(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
      (array, error) => array.concat(error.graphQLErrors || []),
      []
    )
  ];
  let errorMessage =
    defaultMessage || errorsArray[0]?.message || DEFAULT_MESSAGE;

  errorsArray.some((error: ExtendedGraphQLError) => {
    if (error?.message && MEANINGFUL_ERRORS.includes(error.message)) {
      errorMessage = error.message;
      return true;
    }

    if (
      error &&
      Array.isArray(error.extra) &&
      isString(error.extra[0]) &&
      MEANINGFUL_ERRORS.includes(error.extra[0])
    ) {
      errorMessage = error.extra[0];
      return true;
    }

    if (error?.extra && Array.isArray(error.extra)) {
      error.extra.some(extra => {
        if (extra.status === BAD_REQUEST && !!extra.message) {
          errorMessage = extra.message;
          return true;
        }
      });
    }

    return (
      error?.extra &&
      Array.isArray(error.extra) &&
      error.extra.some(extra => {
        if (
          (MEANINGFUL_STATUS_CODES.includes(extra.status) ||
            extra.status === MEANINGFUL_STATUS_MESSAGE) &&
          !!extra.message
        ) {
          errorMessage = extra.message;

          if (Array.isArray(extra.errors)) {
            errorMessage += ` ${String(extra.errors[0])}`;
          }

          return true;
        }
      })
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return errorMessage;
}
