import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  input
} from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';

import { AsyncPipe } from '@angular/common';
import { NavigationDrawerItemComponent } from '../navigation-drawer-item/navigation-drawer-item.component';
import { NavigationDrawerService } from '../navigation-drawer.service';

@Component({
  selector: 'app-navigation-drawer-list',
  templateUrl: './navigation-drawer-list.component.html',
  styleUrls: ['./navigation-drawer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    // forwardRef is added to solve cyclic dependency
    forwardRef(() => NavigationDrawerItemComponent),
    AsyncPipe
  ]
})
export class NavigationDrawerListComponent {
  navigationDrawer = inject(NavigationDrawerService);

  readonly items = input<NavigationItem[]>(undefined);
  readonly isSubList = input(false);
}
