import { Component, forwardRef, ViewEncapsulation, input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControl } from '../../base-control';
import { AppFormFieldControl } from '../../../form-field/form-field-control/form-field-control';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => RadioGroupComponent)
    }
  ]
})
export class RadioGroupComponent extends BaseControl<string> {
  readonly isUnselectable = input(true);

  public writeValue(value: string): void {
    this.value = this.value === value && this.isUnselectable() ? null : value;
  }
}
