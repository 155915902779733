<form appFormSubmit class="desired-tenant-form" [formGroup]="profileForm">
  <p class="preset-profile__subtitle uppercase-m semibold">
    {{ 'tenant_profile.preset_tenantprofile_l' | translate }}
  </p>
  <div class="row">
    <div [appFieldsetDisabled]="readonly()" class="col-12 mb30">
      <app-form-field>
        <app-form-field-label
          >{{ 'tenant_profile.name_l' | translate }}
        </app-form-field-label>
        <input
          appInput
          type="text"
          required
          [placeholder]="'forms.type_profile_name' | translate"
          formControlName="name"
          class="form-control"
        />
      </app-form-field>
    </div>

    <div [appFieldsetDisabled]="readonly()" class="col-12 mb30">
      <app-form-field>
        <app-form-field-label
          >{{ 'tenant_profile.description_l' | translate }}
        </app-form-field-label>
        <input
          appInput
          type="text"
          required
          [placeholder]="'forms.type_profile_description' | translate"
          formControlName="description"
          class="form-control"
        />
      </app-form-field>
    </div>

    @if (changeOnlyByTemplateAllowed()) {
      <div class="col-12 mb30">
        <app-form-field [disabledClickArea]="true">
          <app-form-field-label>
            {{
              'tenant_profile.conditions_agent_allowed_to_change_l' | translate
            }}
          </app-form-field-label>
          <app-hint class="pl5"
            >{{
              'tenant_profile.conditions_agent_allowed_to_change_hint_m'
                | translate
            }}
          </app-hint>
          <app-dropdown-multiselect
            [items]="conditionIds"
            [formControl]="editableConditionsControl"
          >
            <div dropdown-button>{{ 'general.select_l' | translate }}</div>
            <div dropdown-button-multi>
              {{ editableConditionsControl?.value?.length }}
              {{ 'housing_permission.types_selected' | translate }}
            </div>
            <ng-template let-item> {{ item.name | translate }}</ng-template>
          </app-dropdown-multiselect>
        </app-form-field>
      </div>
    }

    <div formGroupName="data">
      <div formArrayName="conditions" class="col-12">
        @for (
          condition of conditionsFormArray.controls;
          track condition;
          let i = $index
        ) {
          <div
            class="tenant-profile-form__form-group mb30"
            [formGroup]="condition"
            [appFieldsetDisabled]="
              isConditionDisabled(condition?.value?.data?.conditionId)
            "
          >
            @switch (condition?.value?.data?.conditionId) {
              @case (conditionId.HOUSING_PERMISSION) {
                <app-form-field>
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                      <app-form-field-label
                        >{{ 'tenant_profile.is_wbs_required_l' | translate }}
                      </app-form-field-label>
                      <app-hint class="pl5"
                        >{{
                          'tenant_profile.is_wbs_required_hint_m' | translate
                        }}
                      </app-hint>
                    </div>
                  </div>
                  <app-flat-select
                    [items]="yesNoOptions"
                    [readonly]="
                      isConditionDisabled(conditionId.HOUSING_PERMISSION)
                    "
                    formControlName="value"
                    (ngModelChange)="onWBSValueChange($event)"
                    class="radio-buttons"
                  >
                  </app-flat-select>
                </app-form-field>
                @if (condition.value.value) {
                  <ng-container formGroupName="data">
                    <app-form-field
                      [disabledClickArea]="true"
                      class="d-block mb10"
                    >
                      <app-form-field-label [showRequiredMarker]="true">
                        {{ 'general.housing_permission_type_l' | translate }}
                      </app-form-field-label>
                      <app-multi-select-dropdown-stepper
                        showError
                        formControlName="housingPermissionTypes"
                        [items]="WBS_STEPPER_OPTIONS"
                        [dropdownTitle]="
                          'tenant_profile.wbs_dropdown_title_m' | translate
                        "
                        [placeholderText]="
                          'tenant_profile.wbs_dropdown_placeholder_l'
                            | translate
                        "
                        [resetChildrenOnNewParentSelection]="true"
                        [multipleItemsSelectedText]="
                          'housing_permission.types_selected' | translate
                        "
                      >
                        <ng-template let-item>
                          {{ item.name | translate }}
                        </ng-template>
                      </app-multi-select-dropdown-stepper>
                    </app-form-field>
                    <div class="row">
                      <div class="col-12 col-md-6 mb10">
                        <app-form-field>
                          <app-form-field-label [showRequiredMarker]="true">
                            {{
                              'general.housing_permission_amount_people_lower_bound_l'
                                | translate
                            }}
                          </app-form-field-label>
                          <input
                            appInput
                            [placeholder]="
                              'forms.type_housing_permission_amount_people'
                                | translate
                            "
                            formControlName="amountPeopleLowerBound"
                            class="form-control"
                            type="number"
                            min="0"
                          />
                        </app-form-field>
                      </div>
                      <div class="col-12 col-md-6 mb10">
                        <app-form-field>
                          <app-form-field-label [showRequiredMarker]="true">
                            {{
                              'general.housing_permission_amount_people_upper_bound_l'
                                | translate
                            }}
                          </app-form-field-label>
                          <input
                            appInput
                            [placeholder]="
                              'forms.type_housing_permission_amount_people'
                                | translate
                            "
                            formControlName="amountPeopleUpperBound"
                            class="form-control"
                            type="number"
                            min="0"
                          />
                        </app-form-field>
                      </div>
                    </div>
                  </ng-container>
                }
              }
              @case (conditionId.SEARCH_PERIOD) {
                @if (condition.value.value) {
                  <app-knockout-criteria
                    [formControlInput]="getConditionKnockoutControl(i)"
                  >
                  </app-knockout-criteria>
                }
                <app-form-field>
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                      <app-form-field-label
                        >{{
                          'tenant_profile.prefer_search_duration_l' | translate
                        }}
                      </app-form-field-label>
                      <app-hint class="pl5"
                        >{{
                          'tenant_profile.prefer_search_duration_hint_m'
                            | translate
                        }}
                      </app-hint>
                    </div>
                  </div>
                  <app-flat-select
                    [items]="yesNoOptions"
                    [readonly]="isConditionDisabled(conditionId.SEARCH_PERIOD)"
                    formControlName="value"
                    class="radio-buttons"
                  >
                  </app-flat-select>
                </app-form-field>
                @if (condition.value.value) {
                  <app-form-field>
                    <app-form-field-label
                      >{{
                        'tenant_profile.search_duration_importance_l'
                          | translate
                      }}
                    </app-form-field-label>
                    <app-slider
                      [min]="0"
                      [max]="10"
                      formControlName="importance"
                    >
                    </app-slider>
                  </app-form-field>
                  <app-form-field formGroupName="data" class="inline">
                    <app-form-field-label
                      >{{
                        'tenant_profile.search_duration_months_l' | translate
                      }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="number"
                      [min]="0"
                      step="1"
                      formControlName="months"
                      class="form-control"
                    />
                  </app-form-field>
                }
              }
              @case (conditionId.RESIDENTS) {
                @if (condition.value.value) {
                  <app-knockout-criteria
                    [formControlInput]="getConditionKnockoutControl(i)"
                  >
                  </app-knockout-criteria>
                }
                <app-form-field>
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                      <app-form-field-label
                        >{{ 'tenant_profile.prefer_residents_l' | translate }}
                      </app-form-field-label>
                      <app-hint class="pl5"
                        >{{
                          'tenant_profile.prefer_residents_hint_m' | translate
                        }}
                      </app-hint>
                    </div>
                  </div>
                  <app-flat-select
                    [items]="yesNoOptions"
                    [readonly]="isConditionDisabled(conditionId.RESIDENTS)"
                    formControlName="value"
                    class="radio-buttons"
                  >
                  </app-flat-select>
                </app-form-field>
                @if (condition.value.value) {
                  <app-form-field>
                    <app-form-field-label
                      >{{
                        'tenant_profile.inhabitants_importance_l' | translate
                      }}
                    </app-form-field-label>
                    <app-slider
                      [min]="0"
                      [max]="10"
                      formControlName="importance"
                    >
                    </app-slider>
                  </app-form-field>
                  <div formGroupName="data" class="row">
                    <div class="col-12 col-md-6 mb10">
                      <app-form-field>
                        <app-form-field-label>
                          {{
                            'tenant_profile.preferred_min_inhabitants_l'
                              | translate
                          }}
                        </app-form-field-label>
                        <input
                          appInput
                          formControlName="amountPeopleLowerBound"
                          class="form-control"
                          type="number"
                          min="0"
                        />
                      </app-form-field>
                    </div>
                    <div class="col-12 col-md-6 mb10">
                      <app-form-field>
                        <app-form-field-label>
                          {{
                            'tenant_profile.preferred_max_inhabitants_l'
                              | translate
                          }}
                        </app-form-field-label>
                        <input
                          appInput
                          formControlName="amountPeopleUpperBound"
                          class="form-control"
                          type="number"
                          min="0"
                        />
                      </app-form-field>
                    </div>
                  </div>
                }
              }
            }
          </div>
        }
      </div>

      <div [appFieldsetDisabled]="readonly()">
        <div formGroupName="monthlyIncome" class="col-12 mb30">
          <div class="tenant-profile-form__form-group">
            <app-knockout-criteria
              [formControlInput]="getKnockoutControl('monthlyIncome')"
            ></app-knockout-criteria>

            <ng-container formGroupName="value">
              <app-form-field>
                <div class="d-flex flex-row">
                  <app-form-field-label
                    >{{ 'tenant_profile.income_importance_l' | translate }}
                  </app-form-field-label>
                  <app-hint class="pl5"
                    >{{ 'tenant_profile.income_importance_hint_m' | translate }}
                  </app-hint>
                </div>
                <app-slider [min]="0" [max]="10" formControlName="value">
                </app-slider>
              </app-form-field>
              <div class="row">
                <div class="col-12 col-md-6 mb10">
                  <app-form-field>
                    <app-form-field-label
                      >{{ 'tenant_profile.min_salary_l' | translate }}
                    </app-form-field-label>
                    <app-select
                      [items]="minSalaryOptions"
                      formControlName="lowerBound"
                    >
                    </app-select>
                  </app-form-field>
                </div>
                <div class="col-12 col-md-6 mb10">
                  <app-form-field class="inline">
                    <app-form-field-label
                      >{{ 'tenant_profile.max_salary_l' | translate }}
                    </app-form-field-label>
                    <app-select
                      [items]="maxSalaryOptions"
                      formControlName="upperBound"
                    >
                    </app-select>
                  </app-form-field>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div formGroupName="employmentType" class="col-12 mb30">
          <div class="tenant-profile-form__form-group">
            <app-knockout-criteria
              [formControlInput]="getKnockoutControl('employmentType')"
            ></app-knockout-criteria>
            <ng-container formGroupName="value">
              <app-form-field>
                <div class="d-flex flex-row">
                  <app-form-field-label
                    >{{ 'tenant_profile.employment_type_l' | translate }}
                  </app-form-field-label>
                  <app-hint class="pl5"
                    >{{ 'tenant_profile.employment_type_hint_m' | translate }}
                  </app-hint>
                </div>
                <app-flat-select
                  [items]="constants()?.employmentTypes"
                  [readonly]="readonly()"
                  [multiple]="true"
                  formControlName="choice"
                >
                </app-flat-select>
              </app-form-field>
              <app-form-field>
                <app-form-field-label
                  >{{ 'tenant_profile.employment_importance_l' | translate }}
                </app-form-field-label>
                <app-slider [min]="0" [max]="10" formControlName="value">
                </app-slider>
              </app-form-field>
            </ng-container>
          </div>
        </div>

        <div formGroupName="householdType" class="col-12 mb30">
          <div class="tenant-profile-form__form-group">
            <app-knockout-criteria
              [formControlInput]="getKnockoutControl('householdType')"
            ></app-knockout-criteria>
            <ng-container formGroupName="value">
              <app-form-field>
                <div class="d-flex flex-row">
                  <app-form-field-label
                    >{{ 'tenant_profile.household_type_l' | translate }}
                  </app-form-field-label>
                  <app-hint class="pl5"
                    >{{ 'tenant_profile.household_type_hint_m' | translate }}
                  </app-hint>
                </div>
                <app-flat-select
                  [items]="constants()?.householdTypes"
                  [readonly]="readonly()"
                  [multiple]="true"
                  formControlName="choice"
                >
                </app-flat-select>
              </app-form-field>
              <app-form-field>
                <app-form-field-label
                  >{{ 'tenant_profile.household_importance_l' | translate }}
                </app-form-field-label>
                <app-slider [min]="0" [max]="10" formControlName="value">
                </app-slider>
              </app-form-field>
            </ng-container>
          </div>
        </div>

        <div formGroupName="age" class="col-12 mb30">
          <div class="tenant-profile-form__form-group">
            <app-knockout-criteria
              [formControlInput]="getKnockoutControl('age')"
            ></app-knockout-criteria>

            <div formGroupName="value" class="row">
              <div class="col-12 col-md-6 mb10">
                <app-form-field class="inline">
                  <app-form-field-label
                    >{{ 'tenant_profile.preferred_min_age_l' | translate }}
                  </app-form-field-label>
                  <input
                    appInput
                    type="number"
                    [min]="0"
                    formControlName="lowerBound"
                    class="form-control"
                  />
                </app-form-field>
              </div>
              <div class="col-12 col-md-6 mb10">
                <app-form-field class="inline">
                  <app-form-field-label
                    >{{ 'tenant_profile.preferred_max_age_l' | translate }}
                  </app-form-field-label>
                  <input
                    appInput
                    type="number"
                    [min]="0"
                    formControlName="upperBound"
                    class="form-control"
                  />
                </app-form-field>
              </div>

              <div class="col-12 mb30">
                <app-form-field>
                  <app-form-field-label
                    >{{
                      'tenant_profile.preferred_age_importance_l' | translate
                    }}
                  </app-form-field-label>
                  <app-slider [min]="0" [max]="10" formControlName="value">
                  </app-slider>
                </app-form-field>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="children" class="col-12 mb30">
          <div class="tenant-profile-form__form-group">
            <app-knockout-criteria
              [formControlInput]="getKnockoutControl('children')"
            ></app-knockout-criteria>

            <app-form-field>
              <app-form-field-label
                >{{ 'tenant_profile.childless_importance_l' | translate }}
              </app-form-field-label>
              <app-slider [min]="0" [max]="10" formControlName="value">
              </app-slider>
            </app-form-field>
          </div>
        </div>

        <div formGroupName="animals" class="col-12 mb30">
          <div class="tenant-profile-form__form-group">
            <app-knockout-criteria
              [formControlInput]="getKnockoutControl('animals')"
            ></app-knockout-criteria>

            <app-form-field>
              <app-form-field-label
                >{{ 'tenant_profile.petless_importance_l' | translate }}
              </app-form-field-label>
              <app-slider [min]="0" [max]="10" formControlName="value">
              </app-slider>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if (hasCustomQuestionsAccess()) {
    <div [appFieldsetDisabled]="readonly()" class="custom-questions__container">
      <ng-container formArrayName="customQuestions">
        @for (
          customQuestion of attachedCustomQuestions;
          track customQuestion;
          let i = $index
        ) {
          <div
            class="mb16 custom-questions__list-item"
            [formGroup]="getCustomQuestionFormGroup(i)"
          >
            <app-knockout-criteria
              [formControlInput]="getCustomQuestionKnockoutControl(i)"
            ></app-knockout-criteria>
            <app-custom-question
              [customQuestion]="customQuestion"
              (preview)="onPreviewCustomQuestion($event)"
              (delete)="onDeleteCustomQuestion($event)"
            ></app-custom-question>
            <div class="mt12">
              <app-form-field>
                <app-form-field-label
                  >{{ 'custom_question.importance_l' | translate }}
                </app-form-field-label>
                <app-slider
                  [min]="0"
                  [max]="10"
                  required
                  formControlName="importance"
                >
                </app-slider>
              </app-form-field>
            </div>
          </div>
        }
      </ng-container>
      <ng-container formArrayName="hierarchicalQuestions">
        @for (
          hierarchicalQuestion of attachedHierarchicalQuestionsForm;
          track hierarchicalQuestion;
          let i = $index
        ) {
          <div
            class="mb16 custom-questions__list-item"
            [formGroup]="getHierarchicalQuestionFormGroup(i)"
          >
            <app-hierarchical-questions-display-root
              [hierarchicalQuestionContainer]="hierarchicalQuestion"
              [tags]="tags()"
              (delete)="onDeleteHierarchicalQuestion($event)"
            ></app-hierarchical-questions-display-root>
            <div class="mt12">
              <app-form-field>
                <app-form-field-label
                  >{{ 'custom_question.importance_l' | translate }}
                </app-form-field-label>
                <app-slider
                  [min]="0"
                  [max]="10"
                  required
                  formControlName="importance"
                >
                </app-slider>
              </app-form-field>
            </div>
          </div>
        }
      </ng-container>
      @if (
        availableCustomQuestions.length || availableHierarchicalQuestions.length
      ) {
        <div class="card custom-questions__create">
          <div
            class="d-flex align-items-center default-s mb12 pt12 pb12 pl16 pr16 custom-questions__create-header"
          >
            <span>{{ 'custom_questions.add_new_question_l' | translate }}</span>
            <app-hint class="pl6"
              >{{
                'custom_questions.add_new_question_to_property_hint' | translate
              }}
            </app-hint>
          </div>
          <app-button
            [type]="'light-bordered'"
            [iconLeft]="'add'"
            [disabled]="readonly()"
            (clickEvent)="onCustomQuestionAdd()"
          >
            {{ 'custom_questions.add_a' | translate }}
          </app-button>
        </div>
      }
    </div>
  }
</form>
