import {
  AfterViewInit,
  Component,
  contentChild,
  input,
  model,
  OnInit,
  output,
  TemplateRef,
  viewChild
} from '@angular/core';

import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../legacy/loading-spinner/loading-spinner.component';

@UntilDestroy()
@Component({
  selector: 'app-auto-complete-field',
  templateUrl: './auto-complete-field.component.html',
  styleUrls: ['./auto-complete-field.component.scss'],
  imports: [
    NgbDropdown,
    NgClass,
    LoadingSpinnerComponent,
    NgbDropdownMenu,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule
  ]
})
export class AutoCompleteFieldComponent implements OnInit, AfterViewInit {
  public isAutoCompleteOpen = true;

  readonly selector$ = input<Observable<any[]>>(undefined);
  readonly inputWatcher = input<Observable<string>>(undefined);
  readonly propertyName = input<string>(undefined);
  readonly debounceValue = input(500);
  readonly typeOffset = input(2);
  readonly withoutOffset = input(false);
  readonly readonly = input(false);
  readonly scrollDownForMore = input(false);
  readonly loading = input(false);
  readonly showClearButton = input(false);
  readonly hasUserInteractedWithComponent = model(false);
  readonly trackId = input<string>(undefined);

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  readonly onInputChange = output<string>();
  readonly onSelect = output<any>();
  readonly clearValue = output();
  readonly onScrollDownForMore = output();
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  readonly itemTemplate = contentChild(TemplateRef);
  readonly dropdown = viewChild(NgbDropdown);

  public setFormControlValue(value: any) {
    this.isAutoCompleteOpen = false;
    this.dropdown().close();
    this.onSelect.emit(value);
  }

  ngOnInit(): void {
    this.inputWatcher()
      ?.pipe(
        filter(
          term =>
            (this.withoutOffset() && !!term) ||
            term?.length >= this.typeOffset()
        ),
        debounceTime(this.debounceValue()),
        tap(term => this.onInputChange.emit(term)),
        switchMap(() => this.selector$()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        // Only open the dropdown when the user already clicked on it
        // If we don't check this, then the dropdown will automatically open on page load,
        // when data is already selected
        this.dropdown().open();
      });
  }

  public ngAfterViewInit() {
    this.dropdown().autoClose = true;
  }

  public onScroll(event: Event) {
    if (this.scrollDownForMore()) {
      const target = event.target as HTMLElement;
      const pos = target.scrollTop + target.offsetHeight;
      const threshold = 150;
      if (pos >= target.scrollHeight - threshold) {
        this.onScrollDownForMore.emit();
      }
    }
  }

  public handleDropdownClick() {
    const dropdown = this.dropdown();
    dropdown._open ? dropdown.close() : dropdown.open();
  }

  trackByValue = (value: unknown): unknown => {
    return this.trackId() ? value[this.trackId()] : value;
  };
}
