import { Component, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { PropertySearcher } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { ButtonComponent } from '../../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../../modal/modal-footer/modal-footer.component';
import { LoadingSpinnerComponent } from '../../../loading-spinner/loading-spinner.component';
import { ModalContentComponent } from '../../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../../modal/modal.component';
import { ActionState } from '../../../../../state-utils/action-state';

@Component({
  selector: 'app-chat-create-modal',
  templateUrl: './chat-create-modal.component.html',
  styleUrls: ['./chat-create-modal.component.scss'],
  imports: [
    ModalComponent,
    ModalContentComponent,
    LoadingSpinnerComponent,
    ModalFooterComponent,
    ButtonComponent,
    AsyncPipe,
    CurrencyPipe,
    TranslateModule
  ]
})
export class ChatCreateModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly availableForNewConversation =
    input<Observable<PropertySearcher[]>>(undefined);
  readonly availableForNewConversationActionState =
    input<Observable<ActionState>>(undefined);

  public close(application: PropertySearcher) {
    this.ngbActiveModal.close(application);
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
