import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslateModule]
})
export class HeaderMobileComponent {
  readonly title = input<string>(undefined);
  readonly showBack = input(false);
  readonly showClose = input(false);

  readonly backEvent = output<Event>();
  readonly closeEvent = output<Event>();

  onBack(e: Event) {
    this.backEvent.emit(e);
  }

  onClose(e: Event) {
    this.closeEvent.emit(e);
  }
}
