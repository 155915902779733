import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';
import { QuotaNumberPipe } from './pipes/quota-number.pipe';
import { QuotaTranslationPipe } from './pipes/quota-translation.pipe';
import { QuotaIconPipe } from './pipes/quota-icon.pipe';
import { QuotaStylePipe } from './pipes/quota-button.pipe';

@Component({
  selector: 'app-quota-button',
  templateUrl: './quota-button.component.html',
  styleUrls: ['./quota-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    TranslateModule,
    QuotaStylePipe,
    QuotaIconPipe,
    QuotaTranslationPipe,
    QuotaNumberPipe
  ]
})
export class QuotaButtonComponent {
  readonly currentAmount = input<number>(undefined);
  readonly useDefaultCursor = input<boolean>(undefined);

  readonly positiveQuota = input<string>(undefined);
  readonly emptyQuota = input<string>(undefined);
  readonly negativeQuota = input<string>(undefined);

  readonly alwaysShowNumber = input<boolean>(undefined);

  readonly clickEvent = output();

  public onClick() {
    this.clickEvent.emit();
  }
}
