import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  output,
  model
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import {
  Addon,
  AddonDiscount,
  AddonType,
  CreateSubscriptionPayload,
  Customer,
  GenericFormControl,
  PaymentMethod,
  PriceTypes,
  Product,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage,
  SubscriptionPeriod,
  SubscriptionsForm,
  SubscriptionsSummary,
  UpdateSubscriptionsPayload
} from '@ui/shared/models';

import { filter } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

import { UpdateSubscriptionModalComponent } from '../update-subscription-modal/update-subscription-modal.component';
import { FreeFeaturesListingComponent } from '../free-features-listing/free-features-listing.component';
import { SubscriptionDetailsComponent } from '../subscription-details/subscription-details.component';
import { PaymentDetailsLibComponent } from '../payment-details-lib/payment-details-lib.component';

import { AddonsHelperService, SubscriptionsDiffService } from '../../services';
import { BasketCreatorService } from '../../../services';
import { ActionState } from '../../../state-utils/action-state';
import { ButtonComponent } from '../../../components/atoms/button';
import { ModalService } from '../../../components/legacy/modal';

@UntilDestroy()
@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PaymentDetailsLibComponent,
    SubscriptionDetailsComponent,
    FreeFeaturesListingComponent,
    TranslateModule,
    ButtonComponent
  ]
})
export class SubscriptionFormComponent implements OnChanges, OnInit {
  private modalService = inject(ModalService);
  private subscriptionsDiffService = inject(SubscriptionsDiffService);
  private addonsHelperService = inject(AddonsHelperService);
  private basketCreatorService = inject(BasketCreatorService);

  readonly isAdmin = input<boolean>(undefined);
  readonly isTrial = input<boolean>(undefined);
  readonly products = input<Product[]>(undefined);
  readonly addons = input<Addon[]>([]);
  readonly addonDiscounts = input<AddonDiscount[]>([]);
  readonly customer = input<Customer>(undefined);
  readonly customerProduct = input<ProductOverview>(undefined);
  readonly customerSize = input<string>(undefined);
  readonly nextProductId = input<string>(undefined);
  readonly currency = input<string>(undefined);
  readonly defaultProductId = input<string>(undefined);
  readonly recalculatingPrice = input(false);
  readonly hasActiveProduct = input(true);
  readonly saving = model(false);
  readonly paymentMethod = input<PaymentMethod>(undefined);
  readonly digitalContractQuotaDiscounts = input<QuotaDiscount[]>([]);
  readonly digitalContractQuotaPackages = input<QuotaPackage[]>([]);
  readonly objectContingentQuotaDiscounts = input<QuotaDiscount[]>([]);
  readonly objectContingentQuotaPackages = input<QuotaPackage[]>([]);
  readonly form = input<FormGroup>(undefined);
  readonly recalculationActionState$ =
    input<Observable<ActionState>>(undefined);

  readonly productChange = output<string>();
  readonly updateSubscription = output<UpdateSubscriptionsPayload>();
  readonly createSubscription = output<CreateSubscriptionPayload>();
  readonly discountChange = output<AddonDiscount>();
  readonly saveQuotaDiscounts = output<QuotaDiscount[]>();
  readonly saveObjectContingentQuotaDiscounts = output<QuotaDiscount[]>();
  readonly createLandlord = output();

  public totalPrice = 0;
  public totalPriceNet = 0;
  public totalPricePreDiscount = 0;
  public totalPriceNetPreDiscount = 0;
  public totalNextPrice = 0;
  public totalNextPriceNet = 0;
  public totalNextPricePreDiscount = 0;
  public totalNextPriceNetPreDiscount = 0;
  public addonDiscountsFilled: AddonDiscount[] = [];

  public get productControl() {
    return this.form().get('product') as GenericFormControl<Product>;
  }

  public get addonsControl() {
    return this.form().get('addons') as GenericFormControl<Addon[]>;
  }

  public get paymentDetailsForm() {
    return this.form().get('paymentDetails') as FormGroup;
  }

  get disableSubmit() {
    return (
      (this.paymentDetailsForm && !this.paymentDetailsForm.valid) ||
      !this.productControl.value ||
      !this.productControl.value.id
    );
  }

  get dueDate() {
    return this.customerProduct()?.dueDate;
  }

  get productSubscriptionPeriod() {
    return this.customerProduct()?.product?.subscriptionPeriod;
  }

  get nextProductSubscriptionPeriod() {
    return this.customerProduct()?.nextProduct?.subscriptionPeriod;
  }

  get isYearlySelected() {
    return this.productSubscriptionPeriod === SubscriptionPeriod.YEARLY;
  }

  get isNextYearlySelected() {
    return (
      (this.nextProductSubscriptionPeriod || this.productSubscriptionPeriod) ===
      SubscriptionPeriod.YEARLY
    );
  }

  ngOnInit() {
    this.form()
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value =>
        this.recalculateTotalPrice(value, this.customerProduct()?.addons)
      );

    const customerProductValue = this.customerProduct();
    if (customerProductValue?.product && customerProductValue?.addons) {
      const customerProduct = JSON.parse(JSON.stringify(customerProductValue));
      this.productControl.patchValue(customerProduct.product);
      this.addonsControl.patchValue(
        this.getActiveAddons(customerProduct.addons)
      );
    } else {
      this.productControl.patchValue({
        id: this.defaultProductId(),
        subscriptionPeriod: SubscriptionPeriod.MONTHLY
      });
    }

    if (this.addonsControl.value?.length === 0) {
      const agentAddon = this.addonsHelperService.extractAgent(this.addons());
      const freeAgentAddon = this.addonsHelperService.extractFreeAgent(
        this.addons()
      );
      this.addonsControl.patchValue([
        {
          ...agentAddon,
          amounts: {
            renew: 1,
            expire: 0
          }
        },
        {
          ...freeAgentAddon,
          amounts: {
            renew: 0,
            expire: 0
          }
        }
      ]);
    }

    // check if homepageModuleAddon has been booked, add empty addon for gui
    if (
      !this.addonsHelperService.extractHomepageModule(this.addonsControl.value)
    ) {
      const homepageModuleAddon =
        this.addonsHelperService.extractHomepageModule(this.addons());
      this.addonsControl.patchValue([
        ...this.addonsControl.value,
        {
          ...homepageModuleAddon,
          amounts: {
            renew: 0,
            expire: 0
          }
        }
      ]);
    }

    if (
      !this.addonsHelperService.extractCustomerCooperation(
        this.addonsControl.value
      )
    ) {
      const customerCooperationAddon =
        this.addonsHelperService.extractCustomerCooperation(this.addons());
      this.addonsControl.patchValue([
        ...this.addonsControl.value,
        {
          ...customerCooperationAddon,
          amounts: {
            renew: 0,
            expire: 0
          }
        }
      ]);
    }

    this.addonDiscountsFilled = [];
    this.addons().forEach(addon => {
      const newAddon = {
        addonId: addon.id,
        value: 0,
        endDate: null
      };
      const found =
        this.addonDiscounts()?.find(d => d.addonId === addon.id) || newAddon;
      this.addonDiscountsFilled.push(found);
    });
    this.recalculateTotalPrice(
      this.form().getRawValue(),
      customerProductValue?.addons
    );
    this.recalculationActionState$()
      ?.pipe(
        filter(state => !state.pending),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.recalculateTotalPrice(
          this.form().getRawValue(),
          this.customerProduct()?.addons
        );
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.defaultProductId?.currentValue &&
      changes.products?.currentValue
    ) {
      const productToPatch = (changes.products.currentValue as Product[]).find(
        p => p.id === changes.defaultProductId.currentValue
      );
      this.productControl.patchValue(productToPatch);
    }
    if (changes?.products?.currentValue) {
      const currentId = this.productControl?.value?.id;
      let product = {};
      if (currentId) {
        product = (changes.products.currentValue as Product[]).find(
          p => p.id === currentId
        );
      } else {
        product = (changes.products.currentValue as Product[]).find(
          p => p.id === this.defaultProductId()
        );
      }
      this.productControl.patchValue({ ...product });
    }
    if (changes?.addons?.currentValue?.length > 0) {
      if (
        this.addonsControl?.value?.length === 0 ||
        this.addonsControl?.value?.length === 1
      ) {
        this.addonsControl.patchValue([]);
        const agentAddon = this.addonsHelperService.extractAgent(
          changes?.addons?.currentValue
        );
        this.addonsControl.patchValue([
          {
            ...agentAddon,
            amounts: {
              renew: 1,
              expire: 0
            }
          }
        ]);
      }
    }

    if (changes?.customerProduct?.currentValue) {
      const changedAddons =
        (changes.customerProduct.currentValue as ProductOverview).addons || [];
      const newAddons = changedAddons?.filter(
        ({ id }) => !this.addonsControl.value?.some(({ id: id2 }) => id === id2)
      );
      const addons = (this.addonsControl.value || []).map(addon => {
        const index = changedAddons.findIndex(a => addon.id === a.id);
        if (index > -1) {
          return {
            ...addon,
            price: changedAddons[index].price,
            nextPrice: changedAddons[index].nextPrice
          };
        }
        return addon;
      });
      this.addonsControl.patchValue([...addons, ...newAddons]);
    }
    this.recalculateTotalPrice(
      this.form().getRawValue(),
      this.customerProduct()?.addons
    );
  }

  public onUpdate() {
    const customer = this.customer();
    if (!customer.id) {
      return this.createLandlord.emit();
    }
    this.saving.set(true);
    const product = this.productControl.value;
    const addons = this.addonsControl.value.filter(a => {
      if (![AddonType.AGENT].includes(a.type)) {
        return a?.amounts?.renew;
      }
      return true;
    });
    const customerProduct = { ...this.customerProduct() };
    const trialPeriodEndDate =
      this.paymentDetailsForm?.value?.trialPeriodEndDate;
    const trialPeriodEndDateChanged =
      this.paymentDetailsForm != null &&
      this.isTrial() &&
      trialPeriodEndDate != this.customerProduct()?.dueDate;
    const summary: SubscriptionsSummary = {
      product,
      addons,
      customerSize: this.customerSize(),
      totalPrice: this.totalPrice,
      totalPriceNet: this.totalPriceNet,
      renewDate:
        this.isTrial() && trialPeriodEndDate
          ? trialPeriodEndDate
          : customerProduct?.product?.id === product.id
            ? customerProduct.dueDate
            : null,
      paymentMethod: this.paymentMethod()
    };

    const diff = this.subscriptionsDiffService.getProductsDiff(
      product,
      addons,
      this.customerProduct()
    );
    const getBasketValuePayload = {
      customerID: customer.id,
      purchasedProduct: diff.purchasedProduct,
      purchasedAddons: diff.purchasedAddons
    };

    this.basketCreatorService
      .getBasketValue(getBasketValuePayload)
      .subscribe(basketValue => {
        const modal = this.modalService.open(UpdateSubscriptionModalComponent, {
          data: {
            summary,
            currency: this.currency()
          }
        });

        modal.onClose().subscribe(() => {
          if (!this.hasActiveProduct() && this.isAdmin()) {
            this.createSubscription.emit({
              product: diff.purchasedProduct,
              addons: diff.purchasedAddons,
              trialPeriodEndDate,
              customerId: this.customer().id
            });
          } else {
            this.updateSubscription.emit({
              purchasedProduct: diff.purchasedProduct,
              purchasedAddons: diff.purchasedAddons,
              discardedAddons: diff.discardedAddons,
              newCustomerSize: this.customerSize(),
              basketID: basketValue.basketID,
              addonTypesDiff: {
                purchasedAddonTypes: diff.purchasedAddonTypes,
                discardedAddonTypes: diff.discardedAddonTypes
              },
              trialPeriodEndDate,
              trialPeriodEndDateChanged: trialPeriodEndDateChanged,
              customerID: this.customer().id
            });
          }
        });

        modal.onDismiss().subscribe(() => this.saving.set(false));
      });
  }

  public onDiscountChange(discounts: AddonDiscount) {
    this.discountChange.emit(discounts);
  }

  public onSaveQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveQuotaDiscounts.emit(discounts);
  }

  public onSaveObjectContingentQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveObjectContingentQuotaDiscounts.emit(discounts);
  }

  public onProductChange(id: string) {
    this.productChange.emit(id);
  }

  private getActiveAddons(addons: Addon[]) {
    return (addons || []).filter(customerAddon => customerAddon.booked);
  }

  private recalculateTotalPrice(
    formValue: SubscriptionsForm,
    customerProductAddons: Addon[]
  ) {
    this.totalPrice =
      this.addonsHelperService.getPrice(
        formValue.addons,
        customerProductAddons,
        PriceTypes.GROSS,
        true,
        this.isYearlySelected
      ) || 0;
    this.totalPriceNet =
      this.addonsHelperService.getPrice(
        formValue.addons,
        customerProductAddons,
        PriceTypes.NET,
        true,
        this.isYearlySelected
      ) || 0;
    this.totalPricePreDiscount = this.addonsHelperService.getPrice(
      formValue.addons,
      customerProductAddons,
      PriceTypes.GROSS,
      false,
      this.isYearlySelected
    );
    this.totalPriceNetPreDiscount = this.addonsHelperService.getPrice(
      formValue.addons,
      customerProductAddons,
      PriceTypes.NET,
      false,
      this.isYearlySelected
    );
    this.totalNextPrice =
      this.addonsHelperService.getPrice(
        formValue.addons,
        customerProductAddons,
        PriceTypes.GROSS,
        true,
        this.isNextYearlySelected,
        true
      ) || 0;
    this.totalNextPriceNet =
      this.addonsHelperService.getPrice(
        formValue.addons,
        customerProductAddons,
        PriceTypes.NET,
        true,
        this.isNextYearlySelected,
        true
      ) || 0;
    this.totalNextPricePreDiscount = this.addonsHelperService.getPrice(
      formValue.addons,
      customerProductAddons,
      PriceTypes.GROSS,
      false,
      this.isNextYearlySelected,
      true
    );
    this.totalNextPriceNetPreDiscount = this.addonsHelperService.getPrice(
      formValue.addons,
      customerProductAddons,
      PriceTypes.NET,
      false,
      this.isNextYearlySelected,
      true
    );
  }
}
