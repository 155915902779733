<div
  class="navigation-user-profile d-flex flex-column align-items-center"
  [class.navigation-user-profile--dark]="dark()"
>
  <app-avatar
    [imgSrc]="portrait() | appImageSize: 'S'"
    [name]="name()"
    size="large"
  ></app-avatar>
  <div
    class="navigation-user-profile__labels d-flex flex-column align-items-stretch text-center"
  >
    <span class="navigation-user-profile__name" [title]="name()">{{
      name()
    }}</span>
    <span class="navigation-user-profile__email" [title]="email()">{{
      email()
    }}</span>
  </div>
</div>
