import { ComponentRef, inject, Injectable } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasOptions } from '@ng-bootstrap/ng-bootstrap';

type SideSheetOptions<
  DataOptions extends Record<string, unknown> = Record<string, never>
> = NgbOffcanvasOptions & {
  data?: DataOptions;
};

@Injectable({
  providedIn: 'root'
})
export class OffcanvasService {
  #offCanvasOptions: NgbOffcanvasOptions = {
    position: 'end'
  };
  private ngbOffCanvasService = inject(NgbOffcanvas);

  open<T, DataOptions extends Record<string, unknown>>(
    content: T,
    options?: SideSheetOptions<DataOptions>
  ) {
    const { data, ...offCanvasOptions } = options || {};
    const ref = this.ngbOffCanvasService.open(content, {
      ...this.#offCanvasOptions,
      ...offCanvasOptions
    });
    if (data) {
      /**
       * TODO: This is a hack to get the ComponentRef because NgbOffcanvasRef does not expose it.
       * See https://github.com/ng-bootstrap/ng-bootstrap/issues/4688
       */
      const componentRef: ComponentRef<T> = ref['_contentRef'].componentRef;

      for (const property in options.data) {
        componentRef.setInput(property, options.data[property]);
      }
    }

    return ref;
  }

  dismiss(reason?: any) {
    this.ngbOffCanvasService.dismiss(reason);
  }
}
