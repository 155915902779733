<div
  ngbDropdown
  [placement]="placement()"
  [autoClose]="'outside'"
  class="dropdown-multiselect__container"
  (openChange)="setShowFilters($event)"
>
  <button
    type="button"
    class="btn d-flex justify-content-between align-items-center"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <span class="dropdown-toggle__inner">
      @if (!selectedItems.length) {
        {{ placeholder() | translate }}
      }
      @if (selectedItems.length > maxDisplayCountOfSelectedItems()) {
        {{
          itemsSelectedPlaceholder()
            | translate: { count: selectedItems.length }
        }}
      }
      @if (
        selectedItems.length > 0 &&
        selectedItems.length <= maxDisplayCountOfSelectedItems()
      ) {
        @for (
          selectedItem of selectedItems;
          track selectedItem.name;
          let selectedItemIndex = $index
        ) {
          @if (selectedItemIndex > 0 && textDelimiterForSelectedItems()) {
            {{ ', ' }}
          }
          <span
            [ngClass]="{
              'ms-1': selectedItemIndex > 0 && !textDelimiterForSelectedItems()
            }"
          >
            @if (selectedItems[selectedItemIndex].badgeColor) {
              <app-badge
                [color]="selectedItems[selectedItemIndex].badgeColor"
                class="d-inline-block"
              >
                <ng-container
                  *ngTemplateOutlet="plainTextSelectedItem"
                ></ng-container>
              </app-badge>
            } @else {
              {{ selectedItems[selectedItemIndex].name | translate }}
            }
            <ng-template #plainTextSelectedItem>{{
              selectedItems[selectedItemIndex].name | translate
            }}</ng-template>
          </span>
        }
      }
    </span>
    <div class="d-flex align-items-center">
      @if (selectedItems.length || !form.pristine) {
        <i class="icon icon--close ms-2" (click)="clear($event)"></i>
      }
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>

  <div ngbDropdownMenu>
    <div class="dropdown__items" [formGroup]="form">
      @for (
        group of form.controls | appKeyValueMaintainOrder;
        track group;
        let i = $index
      ) {
        <div>
          @for (
            control of group.value.controls | appKeyValueMaintainOrder;
            track control.key;
            let j = $index
          ) {
            <ng-container [formGroup]="group.value">
              <app-form-field>
                <div class="dropdown__item">
                  <app-form-field-label>
                    @if (getBadgeColor(i, j)) {
                      <app-badge [color]="getBadgeColor(i, j)">
                        <ng-container
                          *ngTemplateOutlet="plainTextItem"
                        ></ng-container>
                      </app-badge>
                    } @else {
                      {{ getTranslationKey(i, j) | translate }}
                    }
                    <ng-template #plainTextItem
                      >{{ getTranslationKey(i, j) | translate }}
                    </ng-template>
                  </app-form-field-label>
                  <app-check
                    class="dropdown__check"
                    [formControlName]="control.key"
                  ></app-check>
                </div>
              </app-form-field>
            </ng-container>
          }
          @if (i < itemsLength - 1) {
            <hr />
          }
        </div>
      }
    </div>
    @if (showApplyButton()) {
      <div class="dropdown__actions">
        <button
          class="btn btn-apply"
          [disabled]="!itemsLength"
          (click)="apply()"
        >
          {{ 'APPLY_A' | translate }}
        </button>
      </div>
    }
  </div>
</div>
