import { inject, Pipe, PipeTransform } from '@angular/core';

import { Address } from '@ui/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'appAddress'
})
export class AddressPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(source: Address, showDistrict = true, showCountry = true): string {
    if (!source) return '';
    const streetPart =
      source.street && source.houseNumber
        ? `${source.street} ${source.houseNumber}`
        : source.street;
    const cityPart =
      source.zipCode && source.city
        ? `${source.zipCode} ${source.city}`
        : source.zipCode || source.city;

    const addressArray = [streetPart, cityPart];
    if (showDistrict && source.district) addressArray.push(source.district);

    if (source.country && showCountry)
      addressArray.push(
        this.translateService.instant(`RUVNATIONALITIES_${source.country}`)
      );

    return addressArray.filter(it => !!it).join(', ');
  }
}
