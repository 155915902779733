import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Prioset } from '@ui/shared/models';

import { ShowError, ShowInfo } from '@ui/legacy-lib';
import { UpdatePriosetInput } from '../queries/prioset.mutation';

import { PriosetFacade } from '../service';
import { notificationConfig } from '../../config';
import * as fromActions from './priosets.actions';

@Injectable()
export class PriosetsEffects {
  private actions$ = inject(Actions);
  priosetUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UPDATE_PRIOSET_SUCCESS),
      mergeMap(() => [
        new ShowInfo(notificationConfig.prioset.update.success),
        new fromActions.ClearLatestSavedPrioset()
      ])
    )
  );
  priosetFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.UpdatePriosetFail>(fromActions.UPDATE_PRIOSET_FAIL),
      map(action => new ShowError(action.error))
    )
  );
  private store = inject(Store);
  private priosetFacade = inject(PriosetFacade);
  priosetUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UPDATE_PRIOSET),
      map((action: fromActions.UpdatePrioset) =>
        this.getUpdatePriosetInput(action.prioset)
      ),
      switchMap(input =>
        this.priosetFacade.updatePrioset(input).pipe(
          map(result => new fromActions.UpdatePriosetSuccess(result)),
          catchError(() =>
            of(
              new fromActions.UpdatePriosetFail(
                notificationConfig.prioset.update.error
              )
            )
          )
        )
      )
    )
  );

  private getUpdatePriosetInput(prioset: Prioset): UpdatePriosetInput {
    const {
      name,
      description,
      data,
      id: priosetID,
      template = false
    } = prioset;
    return { name, description, data, template, locked: false, priosetID };
  }
}
