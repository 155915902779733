import { AvailableLanguageCodesEnum } from '@ui/shared/models';

type MultiLanguageStringFromGroupControlsConfig = {
  [value in AvailableLanguageCodesEnum]?: unknown;
};

/**
 * Returns a FormGroup's controlsConfig containing one control config each language code
 * provided as value in AvailableLanguageCodesEnum.
 *
 * Example usage:
 *
 *    this.fb.group(
 *      getMultiLanguageStringFormGroupControlsConfig(controlConfig)
 *    )
 *
 * Expected result:
 *
 *    this.fb.group({
 *      'de': controlConfig,
 *      'en': controlConfig
 *    })
 *
 * Please note:
 * The given controlConfig will be used for all controls within this group.
 *
 * @param controlConfig
 * @returns {MultiLanguageStringFromGroupControlsConfig}
 */
export const getMultiLanguageStringFormGroupControlsConfig = (
  controlConfig: unknown
): MultiLanguageStringFromGroupControlsConfig => {
  const controlsConfig: MultiLanguageStringFromGroupControlsConfig = {};
  for (const key in AvailableLanguageCodesEnum) {
    const languageCode = AvailableLanguageCodesEnum[key];
    controlsConfig[languageCode] = controlConfig;
  }
  return controlsConfig;
};
