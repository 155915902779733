import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { KeyValue } from '@angular/common';
import { GroupedDistrictsDetailComponent } from '../grouped-districts-detail/grouped-districts-detail.component';

@Component({
  selector: 'app-grouped-city-map-component',
  templateUrl: './grouped-city-map.component.html',
  styleUrls: ['./grouped-city-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GroupedDistrictsDetailComponent]
})
export class GroupedCityMapComponent {
  readonly cityMap = input<KeyValue<string, any[]>>(undefined);
}
