import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  input,
  output,
  viewChild
} from '@angular/core';

import { FormsModule, NgControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  Addon,
  AddonDiscount,
  AddonPriceType,
  CalculatedPrices
} from '@ui/shared/models';

import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgClass } from '@angular/common';

import { DateTimePipe } from '../../../pipes/date-time.pipe';

import { ADDON_DISCOUNT_CONFIG } from '../../products-module.config';
import { BadgeComponent } from '../../../components/atoms/badge';
import { CheckComponent } from '../../../components/legacy/form/controls/check/check.component';
import { DateComponent } from '../../../components/legacy/form/controls/date';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    FormsModule,
    CurrencyPipe,
    TranslateModule,
    DateTimePipe,
    AppInputDirective,
    BadgeComponent,
    CheckComponent,
    DateComponent
  ]
})
export class AddonComponent implements OnInit {
  readonly isAdmin = input<boolean>(undefined);
  readonly addon = input<Addon>(undefined);
  readonly addonDiscount = input<AddonDiscount>(undefined);
  readonly price = input<CalculatedPrices>(undefined);
  readonly currency = input<string>(undefined);
  readonly dueDate = input<string>(undefined);
  readonly isSelected = input(false);
  readonly recalculatingPrice = input(false);
  readonly disabled = input(false);
  readonly isYearlySelected = input(false);
  readonly tooltip = input('');

  readonly toggleAddon = output<Addon>();
  readonly discountChange = output<AddonDiscount>();

  readonly checkControl = viewChild(NgControl);

  public readonly ADDON_DISCOUNT_CONFIG = ADDON_DISCOUNT_CONFIG;
  public readonly addonPriceTypes = AddonPriceType;

  public minDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  get priceNet() {
    return this.isYearlySelected()
      ? this.price()?.yearlyPriceNet
      : this.price()?.monthlyPriceNet;
  }

  get priceDiscountNet() {
    return this.isYearlySelected()
      ? this.price()?.postDiscountYearlyPriceNet
      : this.price()?.postDiscountMonthlyPriceNet;
  }

  get setupPrice() {
    return this.price()?.setup;
  }

  ngOnInit() {
    this.checkControl().control.patchValue(this.isSelected());
  }

  onChange(_value: boolean) {
    this.toggleAddon.emit(this.addon());
  }

  public onDiscountValueChange(value: number, addonDiscount: AddonDiscount) {
    this.discountChange.emit({
      ...addonDiscount,
      value
    });
  }

  public onDiscountDateChange(endDate, addonDiscount: AddonDiscount) {
    this.discountChange.emit({
      ...addonDiscount,
      endDate
    });
  }

  public isExpired(discount: AddonDiscount) {
    return (
      discount.value > 0 &&
      new Date(discount.endDate).getTime() < new Date().getTime()
    );
  }
}
