<app-modal>
  <div class="modal-xl">
    <app-modal-content>
      <div class="modal-header">
        {{ 'messenger.settings_header_l' | translate }}
      </div>
      <div class="modal-body chat-settings__body">
        <nav ngbNav #nav="ngbNav" [activeId]="activeTab()" class="nav-pills">
          <ng-container ngbNavItem="settings">
            <a ngbNavLink>{{ 'messenger.settings_tab_l' | translate }}</a>
            <ng-template ngbNavContent>
              <div class="chat-settings__settings">
                <section class="mb30">
                  <app-conversation-general-config-form
                    [formControl]="generalConfigFormControl"
                  >
                    <p class="title-m chat-settings__title">
                      {{ 'messenger.chat_notifications_title_l' | translate }}
                    </p>
                  </app-conversation-general-config-form>
                </section>
                <section>
                  <app-conversation-restriction-config-form
                    [formControl]="restrictionConfigFormControl"
                  >
                    <p class="title-m chat-settings__title">
                      {{ 'messenger.chat_settings_title_l' | translate }}
                    </p>
                    <p class="default-s chat-settings__message">
                      {{ 'messenger.chat_settings_description_l' | translate }}
                    </p>
                  </app-conversation-restriction-config-form>
                </section>
                <div class="chat-settings_save-btn">
                  <app-button
                    (clickEvent)="saveControlValues()"
                    [disabled]="controlsNeverTouched()"
                    >{{ 'messenger.save_a' | translate }}</app-button
                  >
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem="templates">
            <a ngbNavLink>{{ 'messenger.templates_tab_l' | translate }}</a>
            <ng-template ngbNavContent>
              <app-message-templates
                [newTemplateData]="newTemplateData()"
                [conversationMessageTemplateSubstitutionTags]="
                  conversationMessageTemplateSubstitutionTags()
                "
              ></app-message-templates>
            </ng-template>
          </ng-container>
        </nav>

        <div [ngbNavOutlet]="nav"></div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer chat-settings__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="close()">{{
          'general.close_a' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
