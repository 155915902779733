import { computed, Injectable, signal } from '@angular/core';
import { Property } from '@ui/shared/models';
import {
  canPropertyBePublished,
  isPropertyTaskRunning,
  isPropertyDelegated,
  isPropertyReceived,
  isPropertyForRent,
  isPropertyForSale,
  isPropertyImported,
  isPropertyOffline,
  isPropertyRentedOut,
  isPropertyReserved,
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeFlatOrGarage,
  isPropertyTypeGarage,
  canViewPropertyProspects,
  canViewPropertyApplicants,
  hasPropertyPublishingPortalsSet,
  isContractAssignedToProperty,
  isPropertyWithoutDelegation
} from '../utils';

@Injectable()
export class PropertyConditionService {
  private property = signal<Property | null>(null);

  isPropertyTypeFlat = computed(() =>
    isPropertyTypeFlat(this.property()?.type)
  );
  isPropertyTypeGarage = computed(() =>
    isPropertyTypeGarage(this.property()?.type)
  );
  isPropertyTypeFlatOrGarage = computed(() =>
    isPropertyTypeFlatOrGarage(this.property()?.type)
  );
  isPropertyTypeCommercial = computed(() =>
    isPropertyTypeCommercial(this.property()?.type)
  );
  isPropertyForRent = computed(() => isPropertyForRent(this.property()));
  isPropertyForSale = computed(() => isPropertyForSale(this.property()));
  isPropertyReceived = computed(() => isPropertyReceived(this.property()));
  isPropertyDelegated = computed(() => isPropertyDelegated(this.property()));
  isPropertyWithoutDelegation = computed(() =>
    isPropertyWithoutDelegation(this.property())
  );
  isPropertyImported = computed(() => isPropertyImported(this.property()));
  isPropertyReserved = computed(() => isPropertyReserved(this.property()));
  isPropertyRentedOut = computed(() => isPropertyRentedOut(this.property()));

  isPropertyOffline = computed(() => isPropertyOffline(this.property()));
  isPropertyTaskRunning = computed(() =>
    isPropertyTaskRunning(this.property())
  );
  isContractAssignedToProperty = computed(() =>
    isContractAssignedToProperty(this.property())
  );
  canPropertyBePublished = computed(() =>
    canPropertyBePublished(this.property())
  );
  canViewPropertyProspects = computed(() =>
    canViewPropertyProspects(this.property())
  );
  canViewPropertyApplicants = computed(() =>
    canViewPropertyApplicants(this.property())
  );
  hasPropertyPublishingPortalsSet = computed(() =>
    hasPropertyPublishingPortalsSet(this.property())
  );

  setProperty(property: Property) {
    this.property.set(property);
  }
}
