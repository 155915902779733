import { Component, input, output } from '@angular/core';
import { PerformanceBannerConfig } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-performance-warning-banner',
  templateUrl: './performance-warning-banner.component.html',
  styleUrls: ['./performance-warning-banner.component.scss'],
  imports: [TranslateModule]
})
export class PerformanceWarningBannerComponent {
  readonly confirmed = input<boolean>(undefined);
  readonly config = input<PerformanceBannerConfig>(undefined);
  readonly confirm = output<void>();

  onClose() {
    this.confirm.emit();
  }
}
