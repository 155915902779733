import { Component, inject, ViewEncapsulation, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemTag } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../modal-footer/modal-footer.component';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class ConfirmationDialogComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly message = input<string>(undefined);
  readonly acknowledge = input(false);
  readonly innerHTML = input(false);
  readonly titleMessage = input<string>(undefined);
  readonly okButtonMessage = input('general.ok');
  readonly cancelButtonMessage = input('general.cancel_a');
  readonly titleValue = input(null);
  readonly messageValue = input(null);
  readonly systemTags = input<SystemTag[]>(undefined);

  okAction() {
    this.ngbActiveModal.close();
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }

  public get isProspectOfflineSales() {
    return this.systemTags()?.includes(
      SystemTag.OFFLINE && SystemTag.SALES_PROSPECT
    );
  }
}
