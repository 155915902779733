<form [formGroup]="form" class="self-disclosure__container">
  <div class="self-disclosure__description mb24">
    {{ 'self_disclosure.answers_description' | translate }}
  </div>

  <div formArrayName="questions" class="self-disclosure__list">
    @for (
      question of questionsFormArray.controls;
      track question;
      let i = $index
    ) {
      <div
        class="self-disclosure__item mb16"
        [ngStyle]="{
          display: selfDisclosureModel().questions[i]
            .showSelfDisclosureQuestions
            ? 'block'
            : 'none'
        }"
      >
        <div
          class="self-disclosure__item-content"
          [formGroup]="getQuestionsForm(i)"
        >
          @if (showBooleanOrSelect(selfDisclosureModel().questions[i])) {
            <div>
              <app-form-field>
                <app-form-field-label
                  >{{ selfDisclosureModel().questions[i].title | translate }}
                </app-form-field-label>
                <app-flat-select
                  [items]="
                    options(
                      selfDisclosureModel().questions[i],
                      isBoolean(selfDisclosureModel().questions[i])
                    )
                  "
                  formControlName="answer"
                  [required]="selfDisclosureModel().questions[i].mandatory"
                  [multiple]="false"
                ></app-flat-select>
              </app-form-field>
              @if (showHint(i)) {
                <div>
                  <app-form-field>
                    <app-form-field-label
                      >{{
                        selfDisclosureModel().questions[i].commentHint
                          | translate
                      }}
                    </app-form-field-label>
                    <textarea
                      appInput
                      count="true"
                      maxValue="1000"
                      formControlName="comment"
                      class="form-control"
                    ></textarea>
                  </app-form-field>
                </div>
              }
              @if (showUpload(i)) {
                <div>
                  <app-form-field>
                    <app-form-field-label
                      >{{
                        selfDisclosureModel().questions[i].uploadHint
                          | translate
                      }}
                    </app-form-field-label>
                    <app-attachments
                      [showDownload]="false"
                      [showRemove]="true"
                      [isDocument]="true"
                      [multiple]="false"
                      [accept]="acceptedDocumentsTypes"
                      [size]="documentsMaxSize"
                      formControlName="upload"
                    ></app-attachments>
                  </app-form-field>
                </div>
              }
            </div>
          }
        </div>
        @if (showFlat(selfDisclosureModel().questions[i])) {
          <div>
            <app-self-disclosure-flat
              [form]="getQuestionsForm(i)"
              [selfDisclosureQuestion]="selfDisclosureModel().questions[i]"
            ></app-self-disclosure-flat>
          </div>
        }
        @if (showPerson(selfDisclosureModel().questions[i])) {
          <div>
            <app-self-disclosure-person
              [form]="getQuestionsForm(i)"
              [selfDisclosureQuestion]="selfDisclosureModel().questions[i]"
              [constants]="constants()"
            ></app-self-disclosure-person>
          </div>
        }
        @if (showPersonList(selfDisclosureModel().questions[i])) {
          <div>
            <!-- show border for invalid field -->
            <div [ngClass]="{ invalid: isValid(getQuestionsForm(i)) }">
              <app-self-disclosure-person-list
                [form]="getQuestionsForm(i)"
                [selfDisclosureQuestion]="selfDisclosureModel().questions[i]"
                [confirmations]="selfDisclosureModel().confirmations"
                [constants]="constants()"
              ></app-self-disclosure-person-list>
            </div>
          </div>
        }
        @if (showChildren(selfDisclosureModel().questions[i])) {
          <div>
            <!-- show border for invalid field -->
            <div [ngClass]="{ 'invalid-text': isValid(getQuestionsForm(i)) }">
              <app-self-disclosure-child-list
                [form]="getQuestionsForm(i)"
                [selfDisclosureQuestion]="selfDisclosureModel().questions[i]"
              ></app-self-disclosure-child-list>
            </div>
          </div>
        }
        @if (showAddress(selfDisclosureModel().questions[i])) {
          <div>
            <app-self-disclosure-address
              [form]="getQuestionsForm(i)"
              [selfDisclosureQuestion]="selfDisclosureModel().questions[i]"
            ></app-self-disclosure-address>
          </div>
        }
        @if (showEmployment(selfDisclosureModel().questions[i])) {
          <div>
            <app-self-disclosure-employment
              [form]="getQuestionsForm(i)"
              [selfDisclosureQuestion]="selfDisclosureModel().questions[i]"
            ></app-self-disclosure-employment>
          </div>
        }
      </div>
    }
  </div>

  @if (
    selfDisclosureModel().documents &&
    selfDisclosureModel().documents.length > 0
  ) {
    <div class="self-disclosure__documents">
      <app-form-field>
        <div class="row">
          <div class="col-12 self-disclosure__documents-title">
            <app-form-field-label
              >{{ 'self_disclosure.answers.upload_documents_l' | translate }}
            </app-form-field-label>
          </div>
          <div class="col-12 self-disclosure__documents-list">
            <ul>
              @for (
                documentName of selfDisclosureModel().documents;
                track documentName
              ) {
                <li>{{ documentName }}</li>
              }
            </ul>
          </div>
        </div>
        <app-attachments
          [showDownload]="false"
          [showRemove]="true"
          [isDocument]="true"
          [multiple]="true"
          [accept]="acceptedDocumentsTypes"
          [size]="documentsMaxSize"
          formControlName="uploadedDocuments"
        ></app-attachments>
      </app-form-field>
    </div>
  }

  <div class="self-disclosure__documents">
    <app-form-field>
      <app-form-field-label
        >{{ 'self_disclosure.answers.property_searcher_comment' | translate }}
      </app-form-field-label>
      <textarea
        appInput
        count="true"
        maxValue="1000"
        formControlName="propertySearcherComment"
        class="form-control"
      ></textarea>
    </app-form-field>
  </div>
</form>
