<form [formGroup]="form()" class="register__subform">
  <div class="form__section">
    <app-form-field class="mb10">
      <app-form-field-label>
        <svg-icon
          src="/assets/images/icons/icon-balcony-or-terrace.svg"
          [applyClass]="true"
          [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
          class="secondary-svg-color path rect line me-1"
        ></svg-icon>
        {{ 'general.balcony_or_terrace_l' | translate }}
      </app-form-field-label>
      <app-flat-select [items]="yesNoOptions" formControlName="balconyTerrace">
      </app-flat-select>
    </app-form-field>

    <app-form-field class="mb10">
      <app-form-field-label>
        <svg-icon
          src="/assets/images/icons/icon-barrier-free.svg"
          [applyClass]="true"
          [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
          class="secondary-svg-color polyline line circle-fill path me-1"
        ></svg-icon>
        {{ 'general.apartment_barrier_free_l' | translate }}
      </app-form-field-label>
      <app-flat-select [items]="yesNoOptions" formControlName="barrierFree">
      </app-flat-select>
    </app-form-field>

    @if (showSeniorApartment()) {
      <app-form-field class="mt10">
        <app-form-field-label>
          <svg-icon
            src="/assets/images/icons/icon-seniors.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
            class="secondary-svg-color polyline line circle-fill path me-1"
          ></svg-icon>
          {{ 'general.seniors_l' | translate }}
        </app-form-field-label>
        <app-flat-select
          [items]="yesNoOptions"
          formControlName="seniorApartment"
        >
        </app-flat-select>
      </app-form-field>
    }
    @if (considerElevator()) {
      <app-form-field class="mt10">
        <app-form-field-label>
          <svg-icon
            src="/assets/images/icons/icon-elevator.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
            class="secondary-svg-color polyline line circle-fill path me-1"
          ></svg-icon>
          {{ 'general.elevator' | translate }}
        </app-form-field-label>
        <app-flat-select [items]="yesNoOptions" formControlName="elevator">
        </app-flat-select>
      </app-form-field>
    }

    <div class="form__section">
      <app-form-field-label>
        <i class="mb-0 icon icon--stairwell me-2"></i>
        {{ 'search_profile.floor_slider_l' | translate }}
      </app-form-field-label>
      <mat-slider
        [max]="10"
        [min]="0"
        [step]="1"
        discrete
        [displayWith]="displayFloorLabel"
      >
        <input
          matSliderStartThumb
          (dragEnd)="dragEnd($event)"
          formControlName="lowerBoundFloor"
        />
        <input
          matSliderEndThumb
          (dragEnd)="dragEnd($event)"
          formControlName="upperBoundFloor"
        />
        <div class="slider__label-container">
          <div class="slider__label-min">{{ lowerBoundFloorSet }}</div>
          <div class="slider__label-max">
            {{ upperBoundFloorSet }}
            @if (upperBoundFloorSet === 10) {
              +
            }
          </div>
        </div>
      </mat-slider>
    </div>

    @if (form().value.elevator === null && considerElevator()) {
      <app-form-field class="mt10">
        <app-item-check
          [label]="'general.higher_floor_descr' | translate"
          [showCheckbox]="true"
          formControlName="ignoreFloorIfElevatorExists"
        >
        </app-item-check>
      </app-form-field>
    }
  </div>
</form>

@if (
  elevatorSelected +
    balconyTerraceSelected +
    barrierFreeSelected +
    seniorApartmentSet +
    (upperBoundFloorSet === 10 && lowerBoundFloorSet === 0 ? 0 : 1) >=
  3
) {
  <div class="mt10 mb-1">
    <div class="d-flex align-items-center info info--message">
      <span class="icon icon--info me-2 info"></span>
      Info
    </div>
    <p class="ms-4 info--message">
      {{ 'search.profile_many.attributes.selected' | translate }}
    </p>
  </div>
}
