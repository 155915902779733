import { Pipe, PipeTransform } from '@angular/core';

import { Location } from '@ui/shared/models';
import { isNumber } from '../utils';

@Pipe({
  name: 'appLocation'
})
export class LocationPipe implements PipeTransform {
  transform(location: Location): string {
    if (!location || !isNumber(location.lat) || !isNumber(location.lng))
      return '';
    return `${location.lat.toFixed(5)}, ${location.lng.toFixed(5)}`;
  }
}
