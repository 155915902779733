@if (message()?.showSeparator) {
  <div class="day-separator">
    <span>{{
      message()?.messageSent | appDateTime: { withFullNameOfDay: true }
    }}</span>
  </div>
}
@if (sender() !== message()?.sender?.type) {
  <div class="incoming_msg" [ngClass]="groupCssClass">
    @if (showImage) {
      <div class="incoming_msg_img">
        <app-avatar
          [imgSrc]="{ url: message()?.sender?.portrait } | appImageSize: 'S'"
          [name]="message()?.sender | appFullName"
          [size]="'small'"
        ></app-avatar>
      </div>
    }
    <div class="received_msg">
      @if (showImage) {
        <span class="title-grey-xs d-block">{{
          message()?.sender | appFullName
        }}</span>
      }
      <div
        [ngClass]="{ email_message: message().source === 'EMAIL' }"
        class="received_width_msg"
      >
        @if (message()?.message?.length) {
          <p [innerHTML]="onCheckMessage(message()?.message)"></p>
        }
        @if (message()?.attachments) {
          <app-chat-attachment
            [attachments]="message()?.attachments"
            [isDocument]="true"
            (download)="onDownload($event)"
            (preview)="onPreview($event)"
          ></app-chat-attachment>
        }
        <span class="time_date">{{
          message()?.messageSent | appMessageTime
        }}</span>
      </div>
    </div>
  </div>
}
@if (sender() === message()?.sender?.type) {
  <div class="outgoing_msg" [ngClass]="groupCssClass">
    <div class="sent_msg p0">
      @if (isLandlord()) {
        <div class="template-action">
          <app-button
            ngbTooltip="{{
              'messenger.save_message_as_template_l' | translate
            }}"
            (clickEvent)="creatingMessageAsTemplate(message())"
          >
            <div class="icon icon--add"></div>
          </app-button>
        </div>
      }
      @if (message()?.message?.length) {
        <p [innerHTML]="onCheckMessage(message()?.message)"></p>
      }
      @if (message()?.attachments) {
        <app-chat-attachment
          [attachments]="message()?.attachments"
          [isDocument]="true"
          (download)="onDownload($event)"
          (preview)="onPreview($event)"
        ></app-chat-attachment>
      }
      <span class="time_date">{{
        message()?.messageSent | appMessageTime
      }}</span>
    </div>
    @if (showImage) {
      <div class="outgoing_msg_img">
        <app-avatar
          [imgSrc]="{ url: message()?.sender?.portrait } | appImageSize: 'S'"
          [name]="message()?.sender | appFullName"
          [size]="'small'"
        ></app-avatar>
      </div>
    }
  </div>
}
