import {
  Directive,
  inject,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
  input
} from '@angular/core';

@Directive({
  selector: '[appHide]',
  exportAs: 'appHide'
})
export class HideDirective implements OnChanges {
  private view = inject(ViewContainerRef);
  private template = inject<TemplateRef<any>>(TemplateRef);

  readonly appHide = input<boolean>(undefined);

  ngOnChanges() {
    if (this.appHide()) {
      this.view.clear();
    } else {
      this.view.createEmbeddedView(this.template);
    }
  }
}
