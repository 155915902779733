@if (!selectNextLanguageOnClick()) {
  <app-context-menu [allowSideNavForMobileView]="false">
    <div menu-button>
      <ng-container *ngTemplateOutlet="button"></ng-container>
    </div>
    <div menu-content>
      @for (lang of availableLanguages(); track lang.label) {
        <app-context-menu-item
          [active]="selectedLanguage.code === lang.code"
          [iconRight]="selectedLanguage.code === lang.code ? 'check' : null"
          (clickEvent)="onLanguageChange(lang.code)"
        >
          <div class="d-flex align-items-center">
            {{ lang.nativeLabel | translate }}
          </div>
        </app-context-menu-item>
      }
    </div>
  </app-context-menu>
} @else {
  @if (!customButton.children.length) {
    <app-button
      [ghost]="ghostButton()"
      [type]="'context-menu'"
      [iconRight]="!selectNextLanguageOnClick() ? 'arrow-dropdown' : 'check'"
      [elevation]="1"
      (clickEvent)="onSelectNextLanguage(value)"
    >
      @if (selectedLanguage) {
        <div class="d-flex align-items-center">
          <app-locale-flag
            class="lang-picker__flag flex-shrink-0"
            [languageCode]="selectedLanguage.code"
          ></app-locale-flag>
          {{
            showFullLanguageNames()
              ? (selectedLanguage.nativeLabel | translate)
              : (selectedLanguage.code | uppercase)
          }}
        </div>
      }
    </app-button>
  }
  <div #customButton (click)="onSelectNextLanguage(value)">
    <ng-content select="[custom-button]"></ng-content>
  </div>
}

<ng-template #button>
  @if (!customButton.children.length) {
    <app-button
      [ghost]="ghostButton()"
      [type]="'context-menu'"
      [iconRight]="!selectNextLanguageOnClick() ? 'arrow-dropdown' : 'check'"
      [elevation]="1"
      (clickEvent)="onSelectNextLanguage(value)"
    >
      @if (selectedLanguage) {
        <div class="d-flex align-items-center">
          <app-locale-flag
            class="lang-picker__flag flex-shrink-0"
            [languageCode]="selectedLanguage.code"
          ></app-locale-flag>
          {{
            showFullLanguageNames()
              ? (selectedLanguage.nativeLabel | translate)
              : (selectedLanguage.code | uppercase)
          }}
        </div>
      }
    </app-button>
  }

  <div #customButton (click)="onSelectNextLanguage(value)">
    <ng-content select="[custom-button]"></ng-content>
  </div>
</ng-template>
