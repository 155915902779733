import {
  Component,
  forwardRef,
  inject,
  input,
  OnInit,
  output
} from '@angular/core';

import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { delay } from 'rxjs/operators';

import moment from 'moment';

import {
  CustomQuestion,
  CustomQuestionSubType,
  CustomQuestionType
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { DateComponent } from '../../form/controls/date/date.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { CustomQuestionsFieldComponent } from '../custom-questions-field/custom-questions-field.component';
import { isValidDateValidator } from '../../form/controls/validation';

@UntilDestroy()
@Component({
  selector: 'app-custom-questions-form',
  templateUrl: './custom-questions-form.component.html',
  styleUrls: ['./custom-questions-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomQuestionsFormComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CustomQuestionsFieldComponent,
    FormFieldComponent,
    DateComponent,
    AppInputDirective,
    FormFieldLabelComponent,
    TranslateModule
  ]
})
export class CustomQuestionsFormComponent
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  readonly customQuestions = input<CustomQuestion[]>(undefined);

  readonly validityChange = output<boolean>();

  public form: FormGroup;
  public value: CustomQuestion[];

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public get customQuestionsForm() {
    return this.form.get('customQuestions') as FormArray;
  }

  public ngOnInit() {
    this.form = this.fb.group({
      customQuestions: this.fb.array([])
    });

    this.customQuestionsForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: CustomQuestion[]) => {
        this.value = value;
        this.onChange(this.value);
        this.onTouch();
      });

    this.customQuestionsForm.statusChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(value => this.validityChange.emit(value === 'VALID'));
  }

  public writeValue(value: CustomQuestion[]) {
    this.value = value || ([] as CustomQuestion[]);
    const controlConfig = {
      answer: [null],
      comment: [null],
      id: [null],
      key: [null],
      selectedRange: [null]
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.customQuestionsForm.value.forEach(() =>
      this.customQuestionsForm.removeAt(0)
    );
    value.forEach(data => {
      const group = this.fb.group(controlConfig);
      let patchedData = { ...data };
      if (
        patchedData.type === CustomQuestionType.RANGE &&
        !!patchedData.options.find(
          option => option.desired && option?.value === patchedData?.answer
        )
      ) {
        group.addControl(
          'selectedRange',
          new FormControl(
            patchedData.selectedRange,
            Validators.compose([Validators.required, isValidDateValidator])
          )
        );
        if (patchedData.subType === CustomQuestionSubType.RANGE_DATE) {
          const selectedRange = data.selectedRange
            ? moment(data.selectedRange).format('YYYY-MM-DD').toString()
            : null;
          patchedData = {
            ...patchedData,
            selectedRange
          };
        }
      }
      group.patchValue(patchedData);
      this.customQuestionsForm.push(group);
    });
    this.form.patchValue({ customQuestions: this.value });
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public showHint(index: number) {
    const control = this.customQuestionsForm.value[index];
    const customQuestions = this.customQuestions();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      customQuestions[index] &&
      customQuestions[index].commentAllowed &&
      control &&
      (control.answer || control.answer === false) &&
      (!Array.isArray(control.answer) || control.answer.length)
    );
  }

  public addSelectedRangeOption(index: number) {
    const group = this.customQuestionsForm.get([index]) as FormGroup;
    const customQuestions = this.customQuestions();
    if (
      customQuestions[index].type === CustomQuestionType.RANGE &&
      !!customQuestions[index].options.find(
        option => option.desired && option?.value === group?.value?.answer
      )
    ) {
      group.addControl(
        'selectedRange',
        new FormControl(
          null,
          Validators.compose([Validators.required, isValidDateValidator])
        )
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    } else if (
      Object.prototype.hasOwnProperty.call(group.value, 'selectedRange')
    ) {
      group.removeControl('selectedRange');
    }
  }

  public SelectedAnswerIsDesired(index: number) {
    const group = this.customQuestionsForm.get([index]) as FormGroup;
    const customQuestions = this.customQuestions();
    return (
      this.isRange(index) &&
      group.value.id === customQuestions[index].id &&
      !!customQuestions[index].options.find(
        option => option.desired && option?.value === group?.value?.answer
      )
    );
  }

  public isRange(index: number) {
    return this.customQuestions()[index].type === CustomQuestionType.RANGE;
  }

  public isRangeValue(index: number) {
    return (
      this.customQuestions()[index].subType ===
      CustomQuestionSubType.RANGE_VALUE
    );
  }

  public isRangeDate(index: number) {
    return (
      this.customQuestions()[index].subType === CustomQuestionSubType.RANGE_DATE
    );
  }
}
