<div class="portal-homepage-list__container">
  @for (homepage of homepages(); track homepage.id) {
    <app-portal-card
      [identification]="homepage"
      [imgUrl]="config.imgUrl"
      [title]="homepage.name"
      [disabled]="disabled()"
      [selected]="isSelected(homepage)"
      [selectable]="allowSelect()"
      (toggleEvent)="onToggle($event)"
    >
      @if (homepage.svgCount > 0) {
        <div class="d-flex align-items-center" description>
          <span class="default-s">{{
            'homepage_module.with_svg_animation_l' | translate
          }}</span>
          <app-badge
            [color]="BadgeColorEnum.SECONDARY_ACCENT"
            [size]="'small'"
            [borderRadius]="'big'"
            [className]="'ms-1'"
            [tooltipText]="'homepage_module.svg_count_l' | translate"
          >
            {{ homepage.svgCount }}
          </app-badge>
        </div>
      }
    </app-portal-card>
  }
</div>
