import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';

import { IconTypeEnum } from '@ui/shared/models';
import { NgClass } from '@angular/common';
import { LoadingSpinnerComponent } from '../../legacy/loading-spinner/loading-spinner.component';
import {
  Elevation,
  ElevationDirective,
  ElevationType
} from '../../../directives';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from '../../../utils';
import {
  ButtonBorderRadiusEnum,
  ButtonSizeEnum,
  ButtonTypeEnum
} from './button.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, LoadingSpinnerComponent, ElevationDirective]
})
export class ButtonComponent {
  readonly type = input<ButtonTypeEnum>(ButtonTypeEnum.PRIMARY);
  readonly size = input<ButtonSizeEnum>(ButtonSizeEnum.LARGE);
  readonly iconLeft = input<IconTypeEnum>(undefined);
  readonly iconRight = input<IconTypeEnum>(undefined);
  readonly spaceBetweenIcons = input<false>(undefined);
  readonly borderRadius = input<ButtonBorderRadiusEnum>(
    ButtonBorderRadiusEnum.BIG
  );
  readonly elevation = input<ElevationType>(Elevation.ZERO);
  readonly elevationHoverEffect = input(true);
  readonly ghost = input(false);
  readonly loading = input(false);
  readonly disabled = input(false);
  readonly useFullContainerSize = input(false); // e.g. used in DataTable for taking whole cell size
  readonly useDisplayFlex = input(false);
  readonly zeroPadding = input<boolean>(undefined);
  readonly disableHover = input<boolean>(undefined);
  readonly useDefaultCursor = input<boolean>(undefined);
  readonly buttonType = input<'button' | 'submit'>('button'); // html button type
  readonly ariaLabel = input<string>(undefined);

  readonly clickEvent = output<MouseEvent>();

  public baseClass = 'button';

  public isGhost(): boolean {
    return this.type() === ButtonTypeEnum.PRIMARY ? false : this.ghost();
  }

  public isDisabled(): boolean {
    return this.disabled() || this.loading();
  }

  public getClassName(): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`type-${this.type()}`]: !!this.type(),
      [`border-radius-${this.borderRadius()}`]: !!this.borderRadius(),
      [`ghost`]: this.isGhost(),
      [`space-between-icons`]: !!this.spaceBetweenIcons(),
      [`size-${this.size()}`]: !!this.size(),
      ['use-default-cursor']: !!this.useDefaultCursor()
    });
  }

  public getIconClassName(iconType: IconTypeEnum): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags('icon', {
      [iconType]: !!iconType
    });
  }

  public getElevation(): ElevationType {
    return this.isGhost() || this.type() === ButtonTypeEnum.LINK
      ? Elevation.ZERO
      : this.elevation();
  }

  public click(event: MouseEvent): void {
    if (!this.isDisabled()) {
      this.clickEvent.emit(event);
    }
  }
}
