import { inject, Pipe, PipeTransform } from '@angular/core';
import { ConstantsTypeTranslatorService } from '../services/constants-type-translator.service';

@Pipe({
  name: 'appConstantsTypePipe'
})
export class ConstantsTypePipe implements PipeTransform {
  private constantsTypeService = inject(ConstantsTypeTranslatorService);

  transform(value: any, types?: any): any {
    return this.constantsTypeService.getTranslation(value, types);
  }
}
