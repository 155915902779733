import {
  Component,
  ChangeDetectionStrategy,
  input,
  output
} from '@angular/core';

import { Attachment } from '@ui/shared/models';
import { AttachmentItemV2Component } from '../attachment-item-v2/attachment-item-v2.component';

@Component({
  selector: 'app-attachments-list-v2',
  templateUrl: './attachments-list-v2.component.html',
  styleUrls: ['./attachments-list-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AttachmentItemV2Component]
})
export class AttachmentsListV2Component {
  public readonly attachments = input<Attachment[]>([]);
  public readonly showDownload = input(true);
  public readonly disableDownload = input(false);
  public readonly blockDownload = input(false);
  public readonly showRemove = input(false);
  public readonly isDocument = input(false);
  public readonly orderable = input(false);
  public readonly editable = input(false);

  public readonly remove = output<number>();
  public readonly download = output<Attachment>();
  public readonly preview = output<Attachment>();
  public readonly moveUp = output<Attachment>();
  public readonly moveDown = output<Attachment>();

  public onRemove(index: number) {
    this.remove.emit(index);
  }

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }

  public onMoveUp(attachment: Attachment) {
    this.moveUp.emit(attachment);
  }

  public onMoveDown(attachment: Attachment) {
    this.moveDown.emit(attachment);
  }
}
