import { Component, forwardRef, input, output } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Attachment } from '@ui/shared/models';

import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';
import { FileUploadComponent } from '../../../attachment/file-upload/file-upload.component';
import { AttachmentsListComponent } from '../../../attachment/attachments-list/attachments-list.component';
import { documentsTypes, imagesTypes } from '../../../../../config';
import { arrayMove } from '../../../../../utils';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttachmentsComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => AttachmentsComponent)
    }
  ],
  imports: [AttachmentsListComponent, FileUploadComponent]
})
export class AttachmentsComponent extends BaseControl<
  Attachment | Attachment[]
> {
  readonly multiple = input(false);

  // List Inputs
  readonly showDownload = input(true);
  readonly showPreview = input(true);
  readonly disableDownload = input(false);
  readonly blockDownload = input(false);
  readonly showRemove = input(false);
  readonly isDocument = input(false);
  readonly orderable = input(false);
  readonly editable = input(false);

  // FileUpload Inputs
  readonly hideFileUpload = input(false);
  readonly size = input(1024 * 1024 * 10);
  readonly accept = input<string>(undefined);
  readonly subInformation = input<string>(undefined);

  readonly attachmentType = input<string>(undefined);

  readonly download = output<Attachment>();
  readonly preview = output<Attachment>();
  readonly boundaryMoveUp = output<Attachment>();
  readonly boundaryMoveDown = output<Attachment>();
  readonly remove = output<Attachment>();

  private _touched = false;
  private _errors = null;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  get attachmentsArray() {
    return this.value instanceof Array
      ? this.value
      : this.value
        ? [this.value]
        : [];
  }

  get showAddButton() {
    return Array.isArray(this.value) && this.value.length > 0;
  }

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }

  public onRemove(indexToRemove: number) {
    const multiple = this.multiple();
    if (multiple) {
      const removed = ((this.value as Attachment[]) || []).find(
        (_, i) => i === indexToRemove
      );
      const attachments = ((this.value as Attachment[]) || []).filter(
        (_, i) => i !== indexToRemove
      );
      this.value = [...attachments];

      this.remove.emit(removed);
    }

    if (!multiple) {
      const removed = { ...this.value };
      this.value = null;

      /**
       * The order here is important!
       * This event musts emit after value is set to null,
       * otherwise when we want to replace it with something else,
       * value will be nulled after callback's execution.
       */
      this.remove.emit(removed as Attachment);
    }

    this.markAsTouched();
  }

  private getFileType(type: string): string {
    const attachmentType = this.attachmentType();
    if (attachmentType) return attachmentType;
    if (documentsTypes.includes(type)) return 'PDF';
    if (imagesTypes.includes(type)) return 'IMG';
    return undefined;
  }

  public onChange(files: File[]) {
    if (!files || !files[0]) return;

    const newAttachments = files.map(
      file =>
        ({
          title: file.name,
          name: file.name,
          size: file.size,
          file: file as Blob,
          type: this.getFileType(file.type)
        }) as Attachment
    );

    const multiple = this.multiple();
    if (multiple) {
      const currentFiles = (this.value as Attachment[]) || [];
      this.value = [...currentFiles, ...newAttachments];
    }

    if (!multiple) {
      this.value = newAttachments && newAttachments[0];
    }

    this.markAsTouched();
  }

  public onMoveUp(attachment: Attachment) {
    const index = this.attachmentsArray.findIndex(a => a === attachment);

    if (index <= 0) {
      this.boundaryMoveUp.emit(attachment);

      /**
       * If the event's callback alters the value somehow,
       * we want to be sure that indexes are still correct.
       */
      if (!this.multiple()) {
        this.value = { ...this.value, index: 0 };
      } else {
        this.value = this.updateIndexes(this.attachmentsArray);
      }
      return;
    }

    this.value = this.updateIndexes(
      arrayMove(this.attachmentsArray, index, index - 1)
    );
  }

  public onMoveDown(attachment: Attachment) {
    const index = this.attachmentsArray.findIndex(a => a === attachment);
    const length = this.attachmentsArray.length;

    if (index >= length - 1) {
      this.boundaryMoveDown.emit(attachment);

      /**
       * If the event's callback alters the value somehow,
       * we want to be sure that indexes are still correct.
       */
      if (!this.multiple()) {
        this.value = { ...this.value, index: 0 };
      } else {
        this.value = this.updateIndexes(this.attachmentsArray);
      }
      return;
    }

    this.value = this.updateIndexes(
      arrayMove(this.attachmentsArray, index, index + 1)
    );
  }

  public get hideUpload() {
    const hasValue =
      (Array.isArray(this.value) && this.value.length) ||
      (!Array.isArray(this.value) && this.value);

    return this.hideFileUpload() || (!this.multiple() && hasValue);
  }

  private markAsTouched() {
    this.touched = true;
  }

  private updateIndexes(attachments: Attachment[]) {
    return attachments.map((attachment, index) => ({ ...attachment, index }));
  }

  public onUpdateAttachment(input: { attachment: Attachment; index: number }) {
    const isArray = Array.isArray(this.value);

    isArray
      ? (this.value[input.index] = {
          ...this.value[input.index],
          ...input.attachment
        })
      : (this.value = { ...this.value, ...input.attachment });
  }
}
