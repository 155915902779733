import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  output
} from '@angular/core';
import { QuotaPackage } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgClass } from '@angular/common';
import { ButtonComponent } from '../../atoms/button/button.component';
import { QuotaTableCheckout } from './quota-table.models';

@Component({
  selector: 'app-quota-table',
  templateUrl: './quota-table.component.html',
  styleUrls: ['./quota-table.component.scss'],
  imports: [NgClass, ButtonComponent, CurrencyPipe, TranslateModule]
})
export class QuotaTableComponent implements OnChanges {
  readonly quotaPackageCart = input<Map<string, number>>(undefined);
  readonly quotaPackages = input<QuotaPackage[]>(undefined);
  readonly availableQuota = input<number | string>(undefined);
  readonly isLoading = input<boolean>(undefined);

  readonly availableQuotaLabel = input('quota_table.current_quota_l');
  readonly subTotalLabel = input('quota_table.sub_total_l');
  readonly checkoutLabel = input('quota_table.checkout_a');
  readonly amountColumnLabel = input('quota_table.amount_column_l');
  readonly priceColumnLabel = input('quota_table.price_column_l');
  readonly cartColumnLabel = input('quota_table.cart_column_l');

  readonly checkout = output<QuotaTableCheckout>();

  public totalAmount: number;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.quotaPackageCart || changes.quotaPackages) {
      this.refreshTotalAmount();
    }
  }

  public addToCart(id: string) {
    const currentQuantity = this.quotaPackageCart().get(id) || 0;
    this.quotaPackageCart().set(id, currentQuantity + 1);
    this.refreshTotalAmount();
  }

  public removeFromCart(id: string) {
    const newQuantity = (this.quotaPackageCart().get(id) || 0) - 1;
    if (newQuantity <= 0) {
      this.quotaPackageCart().delete(id);
    } else {
      this.quotaPackageCart().set(id, newQuantity);
    }
    this.refreshTotalAmount();
  }

  private refreshTotalAmount() {
    this.totalAmount = Array.from(this.quotaPackageCart().entries())
      .map(([id, quantity]) => {
        const quotaPackage = this.quotaPackages().find(
          quotaPackage => quotaPackage.id === id
        );
        return quotaPackage.postDiscountPrice * quantity;
      })
      .reduce((a, b) => a + b, 0);
  }

  public onCheckout() {
    const quotaPackageCart = Array.from(this.quotaPackageCart().entries()).map(
      ([quotaPackageId, quantity]) => ({ quotaPackageId, quantity })
    );
    this.checkout.emit(quotaPackageCart);
  }
}
