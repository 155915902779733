import { Component, input } from '@angular/core';

import { NgClass } from '@angular/common';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from '../../../utils';
import {
  ImageBorderRadiusEnum,
  ImageBorderStyleEnum,
  ImageDefaultConfig,
  ImageObjectFitEnum,
  ImageSources,
  ImageSrcSet
} from './image.model';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  imports: [NgClass]
})
export class ImageComponent {
  readonly sources = input<ImageSources>(ImageDefaultConfig.sources);
  readonly defaultSrc = input<string>(undefined);
  readonly alt = input<string>(undefined);
  readonly lazyLoading = input(ImageDefaultConfig.lazyLoading);
  readonly maxHeightInPx = input<number>(undefined);
  readonly objectFit = input<ImageObjectFitEnum>(ImageDefaultConfig.objectFit);
  readonly borderRadius = input<ImageBorderRadiusEnum>(
    ImageDefaultConfig.borderRadius
  );
  readonly borderStyle = input<ImageBorderStyleEnum>(
    ImageDefaultConfig.borderStyle
  );
  readonly centerImage = input(false);
  readonly dataTableCellPicture = input(false);

  public get setMaxHeight(): boolean {
    const maxHeightInPx = this.maxHeightInPx();
    return !isNaN(maxHeightInPx) && Number.isInteger(+maxHeightInPx);
  }

  public baseClass = 'image';

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`object-fit-${this.objectFit()}`]: !!this.objectFit(),
      [`border-radius-${this.borderRadius()}`]: !!this.borderRadius(),
      [`border-style-${this.borderStyle()}`]: !!this.borderStyle()
    });
  }

  public getMediaQuery(
    minScreenWidth?: number,
    maxScreenWidth?: number
  ): string | null {
    let ret = '';
    if (minScreenWidth !== undefined) {
      ret += `(min-width: ${minScreenWidth}px)`;
    }
    if (maxScreenWidth !== undefined) {
      if (ret !== '') ret += ' and ';
      ret += `(max-width: ${maxScreenWidth}px)`;
    }
    return ret || null;
  }

  public getSrcSet(srcSet: ImageSrcSet): string | null {
    if (!srcSet) return null;
    let ret = '';
    let value;
    for (let i = 1; i <= 3; i++) {
      value = srcSet[`x${i}`];
      if (value) {
        if (ret !== '') ret += ', ';
        ret += `${String(value)} ${i}x`;
      }
    }
    return ret || null;
  }
}
