import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Apollo } from 'apollo-angular';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  findDistrictByAddressQuery,
  FindDistrictByAddressResult
} from '../gql-queries';
import { errorMessageParser, getResponseValidator } from '../../gql-client';
import { ShowError } from '../notification';
import * as fromActions from './district.actions';

@Injectable()
export class DistrictEffects {
  private actions$ = inject(Actions);
  private apollo = inject(Apollo);

  fetchLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDistrictsByAddress),
      switchMap(({ address }) =>
        this.apollo
          .query({
            query: findDistrictByAddressQuery,
            variables: {
              address
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<FindDistrictByAddressResult>()),
            map(result =>
              fromActions.loadDistrictsByAddressSuccess({
                data: result.data.districtForAddress
              })
            ),
            catchError(err => [
              fromActions.loadDistrictsByAddressFailure({
                error: new Error(err.message)
              }),
              new ShowError(errorMessageParser(err))
            ])
          )
      )
    )
  );
}
