@if (customerId()) {
  <div class="object-hierarchy mt-4">
    <app-info-collapse [type]="'default'">
      <div info-header class="semibold">
        {{ 'admin.object_hierarchy.explanation_title' | translate }}
      </div>
      <div info-content>
        <div>{{ 'admin.object_hierarchy.explanation_body' | translate }}</div>
      </div>
    </app-info-collapse>
    <form [formGroup]="objectHierarchyForm" class="mx-2">
      <div
        class="object-hierarchy__settings"
        formGroupName="customerImportSettings"
      >
        <app-form-field>
          <app-checkbox
            [label]="
              'admin.customer_import_settings.use_imported_external_id_l'
                | translate
            "
            formControlName="activated"
          >
          </app-checkbox>
        </app-form-field>
        <div class="justify-content-center">
          <app-form-field>
            <app-dropdown-select
              [required]="true"
              [items]="separators"
              [placeholder]="
                'admin.customer_import_settings.delimiter_l' | translate
              "
              formControlName="importDelimiter"
            >
              <div dropdown-button>
                {{ 'admin.customer_import_settings.delimiter_l' | translate }}
              </div>
              <ng-template let-item>{{ item.name | translate }}</ng-template>
            </app-dropdown-select>
          </app-form-field>
        </div>
        <div class="justify-content-end">
          <app-button
            [type]="'primary'"
            [borderRadius]="'none'"
            (clickEvent)="saveCustomerImportSettings()"
            [disabled]="customerImportSettingsControl.invalid"
            >{{ 'admin.customer_import_settings.save_a' | translate }}
          </app-button>
        </div>
      </div>
      @if ((loading$ | async) === false) {
        <table
          class="table table-bordered table-striped object-hierarchy__table"
        >
          <thead>
            <tr>
              @for (col of headerCols; track col) {
                <th scope="col" class="text-center">{{ col | translate }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              field of objectHierarchyForm.controls.items.controls;
              track field;
              let i = $index
            ) {
              <tr formArrayName="items">
                <ng-container [formGroup]="field">
                  <ng-container [formGroupName]="'data'">
                    <td
                      class="text-center semibold object-hierarchy__table-cell"
                    >
                      {{
                        field.get('hierarchyLevel')
                          ? field.get('hierarchyLevel').value
                          : i
                      }}
                    </td>
                    <td class="object-hierarchy__table-cell">
                      <app-form-field [markCompleted]="true">
                        <input
                          [readonly]="objectHierarchyActive"
                          appInput
                          type="text"
                          required
                          [placeholder]="
                            'admin.object_hierarchy.enter_name_l' | translate
                          "
                          class="form-control"
                          formControlName="name"
                        />
                      </app-form-field>
                    </td>
                    <td class="text-center object-hierarchy__table-cell">
                      <app-form-field [disabledClickArea]="true">
                        <app-dropdown-multiselect
                          [items]="fieldTypes"
                          formControlName="fieldTypes"
                          [showToggleAll]="true"
                        >
                          <div dropdown-button>
                            {{
                              'admin.object_hierarchy_select_field_types_l'
                                | translate
                            }}
                          </div>
                          <ng-template let-item>
                            {{
                              getFieldTypeTranslationKey(item.name) | translate
                            }}
                          </ng-template>
                          <div dropdown-button-multi>
                            {{ field.value.data?.fieldTypes }}
                          </div>
                        </app-dropdown-multiselect>
                      </app-form-field>
                    </td>
                  </ng-container>
                  <td class="text-center object-hierarchy__table-cell">
                    <app-form-field
                      class="object-hierarchy__checkbox-margin-fix"
                    >
                      <app-checkbox
                        formControlName="operativeType"
                      ></app-checkbox>
                    </app-form-field>
                  </td>
                  <td class="text-center object-hierarchy__table-cell">
                    <div class="d-flex justify-content-center">
                      <app-button
                        [type]="'context-menu'"
                        [borderRadius]="'none'"
                        [iconLeft]="'close'"
                        class="me-3"
                        [ghost]="true"
                        [disabled]="objectHierarchyActive"
                        (clickEvent)="deleteHierarchy(field.value, i)"
                      ></app-button>
                      <app-button
                        [type]="'context-menu'"
                        [iconLeft]="'save'"
                        [borderRadius]="'none'"
                        [ghost]="true"
                        (clickEvent)="saveHierarchy(field, i)"
                        [disabled]="!field.valid || objectHierarchyActive"
                      ></app-button>
                    </div>
                  </td>
                </ng-container>
              </tr>
            }
          </tbody>
        </table>
      } @else {
        <div class="loading-area">
          <app-loading-spinner></app-loading-spinner>
        </div>
      }
      <div class="d-flex justify-content-end">
        <app-button
          [type]="'primary'"
          [borderRadius]="'none'"
          class="mx-2"
          (clickEvent)="addLevel()"
          [disabled]="
            formLength > 3 || objectHierarchyActive || disableAddLevel
          "
          >{{ 'admin.object_hierarchy.add_level_a' | translate }}
        </app-button>
      </div>
    </form>
  </div>
}

<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
