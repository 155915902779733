import { Component, inject, input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  NgbActiveModal,
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';

import { CookiePreference } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../modal/modal-footer/modal-footer.component';
import { CheckboxComponent } from '../../form/controls/checkbox/checkbox.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { ModalContentComponent } from '../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../modal/modal.component';
import { ThemeService } from '../../../../infrastructure/theme';

@Component({
  selector: 'app-custom-cookie-settings-modal',
  templateUrl: './custom-cookie-settings-modal.component.html',
  styleUrls: ['./custom-cookie-settings-modal.component.scss'],
  imports: [
    ModalComponent,
    ModalContentComponent,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    FormFieldComponent,
    CheckboxComponent,
    NgbNavOutlet,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class CustomCookieSettingsModalComponent implements OnInit {
  private fb = inject(FormBuilder);
  private ngbActiveModal = inject(NgbActiveModal);
  private themeService = inject(ThemeService);

  public form: FormGroup;
  public cookiePreference = input<CookiePreference>(undefined);
  public isTenant = input<boolean>(true);
  public showMoreRequired = false;
  public showMorePerformance = false;
  public showMoreFunctional = false;

  public get logo() {
    return (
      this.theme?.logo?.url || '/assets/images/logos/logo-immomio-main.svg'
    );
  }

  public get theme() {
    return this.themeService.getActiveTheme;
  }

  public ngOnInit() {
    this.form = this.fb.group({
      allowRequired: [true],
      allowFunctional: [false],
      allowPerformance: [false],
      lastUpdated: [null]
    });
    this.form.get('allowRequired').disable();
    if (this.cookiePreference()) {
      this.form.patchValue(this.cookiePreference());
    }
  }

  public save() {
    this.ngbActiveModal.close({
      ...this.form.value,
      lastUpdated: new Date().getTime()
    });
  }

  public acceptAll() {
    this.ngbActiveModal.close({
      allowRequired: true,
      allowFunctional: true,
      allowPerformance: true,
      lastUpdated: new Date().getTime()
    });
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public toggleMoreRequired() {
    this.showMoreRequired = !this.showMoreRequired;
  }

  public toggleMorePerformance() {
    this.showMorePerformance = !this.showMorePerformance;
  }

  public toggleMoreFunctional() {
    this.showMoreFunctional = !this.showMoreFunctional;
  }
}
