import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CityPacket } from '@ui/shared/models';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KeyValuePipe } from '@angular/common';
import {
  NgbAccordionDirective,
  NgbAccordionItem,
  NgbAccordionHeader,
  NgbAccordionToggle,
  NgbCollapse,
  NgbAccordionCollapse,
  NgbAccordionBody
} from '@ng-bootstrap/ng-bootstrap';
import { DropdownMultiselectComponent } from '../../form/controls/dropdown-multiselect/dropdown-multiselect.component';

@Component({
  selector: 'app-search-profile-landlord-districts',
  templateUrl: './search-profile-landlord-districts.component.html',
  styleUrls: ['./search-profile-landlord-districts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    NgbCollapse,
    NgbAccordionCollapse,
    NgbAccordionBody,
    DropdownMultiselectComponent,
    FormsModule,
    ReactiveFormsModule,
    KeyValuePipe,
    TranslateModule
  ]
})
export class SearchProfileLandlordDistrictsComponent {
  readonly landlordCityPacket = input<CityPacket>(undefined);
  readonly form = input<FormGroup>(undefined);
  readonly onlyShowConfiguredCityPartsToUser = input<boolean>(undefined);
  readonly isNewSP = input<boolean>(undefined);
}
