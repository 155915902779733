<div class="self-disclosure-person__content">
  <form [formGroup]="form()">
    <div class="d-flex flex-row mb10" [ngClass]="{ mt10: showRemove() }">
      <span>{{ this.title() | translate }}</span>
      @if (showRemove()) {
        <div class="remove-attachment-button">
          <svg-icon
            src="/assets/images/icons/icon-bin.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="secondary-svg-color path rect"
            (click)="removeHandler()"
          ></svg-icon>
        </div>
      }
    </div>

    <div [formGroup]="getAnswerFormControl">
      <div class="row">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label>{{
              'general.first_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              type="text"
              [placeholder]="'forms.type_firstname' | translate"
              formControlName="firstName"
              [required]="selfDisclosureQuestion().mandatory"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-6">
          <app-form-field>
            <app-form-field-label>{{
              'general.last_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              type="text"
              [required]="selfDisclosureQuestion().mandatory"
              [placeholder]="'forms.type_lastname' | translate"
              formControlName="lastName"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label>{{
              'general.birthdate_l' | translate
            }}</app-form-field-label>
            <app-date
              [required]="selfDisclosureQuestion().mandatory"
              formControlName="birthDate"
              [startDate]="startDateStruct"
              [maxDate]="maxDateStruct"
              [minDate]="minDateStruct"
            ></app-date>
          </app-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
