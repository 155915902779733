<div
  [appRipple]="clickable()"
  [rippleColor]="rippleColor"
  [class]="getClassName()"
  appElevation
  [elevation]="elevation()"
  [clickable]="clickable()"
  (click)="click()"
>
  <ng-content></ng-content>
</div>
