import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  inject,
  Injector,
  input,
  OnChanges,
  output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as Leaflet from 'leaflet';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.vectorgrid';
import { GestureHandling } from 'leaflet-gesture-handling';

import { MarketingType } from '@ui/shared/models';

import { NgStyle } from '@angular/common';

import { PropertyCardComponent, PropertyCardPropertyData } from '../cards';
import { Elevation } from '../../../directives';
import { ThemePropertyKeys } from '../../../infrastructure/theme';
import { MapPropertyData, MapRadiusDrawInfo } from './map.model';

const iconDefault = Leaflet.divIcon({
  className: 'leaflet-custom-icon icon icon--location',
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28]
});
Leaflet.Marker.prototype.options.icon = iconDefault;

Leaflet.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

interface ExtendedMapOptions extends Leaflet.MapOptions {
  gestureHandling?: boolean;
}

@Component({
  selector: 'app-property-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [NgStyle]
})
export class MapComponent implements OnChanges, AfterViewInit {
  private resolver = inject(ComponentFactoryResolver);
  private injector = inject(Injector);
  private translate = inject(TranslateService);

  readonly tileServerUrl = input<string>(undefined);
  readonly propertiesData = input<MapPropertyData[]>(undefined);
  readonly radiusDrawInfo = input<MapRadiusDrawInfo>(undefined);
  readonly mapHeight = input('100vh');
  readonly popupCardClick = output<string>();

  // Set initial view and bounds (Germany):
  INITIAL_ZOOM = 6;
  INITIAL_POSITION: Leaflet.LatLngTuple = [51.095123, 10.423383]; // = close to "Germany" label
  COUNTRY_BOUNDS = [
    [Math.max(55.05320258537114, 49.0219), Math.max(25.42236328125, 9.5215)], // Germany and Austria - Northeast coordinates
    [Math.min(46.76996843356982, 46.372), Math.min(-4.899902343750001, 16.1965)] // Germany and Austria - Southwest coordinates
  ];

  MAX_MARKER_BOUNDS_AUTO_ZOOM = 15;
  MARKER_BOUNDS_DEFAULT_VALUE: Leaflet.LatLngExpression[] = [];
  RADIUS_CIRCLE_DEFAULT_VALUE: Leaflet.LatLngTuple = [0, 0];

  markerClusterGroup = L.markerClusterGroup({
    spiderfyDistanceMultiplier: 1.8,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    spiderLegPolylineOptions: {
      weight: 1.5,
      opacity: 0.1,
      color: `var(${ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT})`
    }
  });
  markerBounds = Leaflet.latLngBounds(this.MARKER_BOUNDS_DEFAULT_VALUE);
  radiusCircle: Leaflet.Circle = Leaflet.circle(
    this.RADIUS_CIRCLE_DEFAULT_VALUE,
    {
      radius: 0
    }
  );
  radiusCircleOptions: Leaflet.CircleMarkerOptions = {
    color: `var(${ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT})`,
    opacity: 0.1,
    radius: 0
  };

  private map: Leaflet.Map;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map && !changes.mapHeight) {
      if (changes.propertiesData || changes.radiusDrawInfo) {
        this.generateMarker(this.map);
      }
      if (changes.radiusDrawInfo) {
        this.drawRadiusCircle(this.radiusDrawInfo());
      }
      this.setMapBounds();
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.map = Leaflet.map('leaflet-map', {
      center: this.INITIAL_POSITION,
      zoom: this.INITIAL_ZOOM,
      maxBounds: this.COUNTRY_BOUNDS as unknown, // limit the map to germany
      maxBoundsViscosity: 0.1, // set elasticity when panning out of the map
      minZoom: this.INITIAL_ZOOM, // avoid zooming out of bounds too much
      attributionControl: false, // prevents linked Leaflet badge showing up on map
      gestureHandling: true,
      gestureHandlingOptions: {
        text: {
          touch: this.translate.instant('leaflet.gesture_handling.touch'),
          scroll: this.translate.instant('leaflet.gesture_handling.scroll'),
          scrollMac: this.translate.instant(
            'leaflet.gesture_handling.scroll_mac'
          )
        },

        /* Defining gestureHandlingOptions without duration would brake the gesture handling:
         * https://github.com/elmarquis/Leaflet.GestureHandling/issues/47#issuecomment-775158618
         */
        duration: 1000 // = default
      }
    } as ExtendedMapOptions);
    // TODO: switch to vector map tiles
    //  => https://immomio.atlassian.net/browse/ART-945
    const layer = Leaflet.tileLayer(this.tileServerUrl());

    layer.addTo(this.map);
    if (this.propertiesData()?.length) {
      this.generateMarker(this.map);
    }

    this.drawRadiusCircle(this.radiusDrawInfo());
    this.setMapBounds();
  }

  private generateMarker(map: Leaflet.Map): void {
    this.removeMarkerLayers();
    this.markerBounds = Leaflet.latLngBounds(this.MARKER_BOUNDS_DEFAULT_VALUE);

    for (const propertyData of this.propertiesData()) {
      const { address, showAddress } = propertyData;
      if (
        (address?.coordinates?.lat && address?.coordinates?.lon) ||
        !showAddress
      ) {
        const component = this.resolver
          .resolveComponentFactory(PropertyCardComponent)
          .create(this.injector);

        const { lat, lon } = address?.coordinates;
        const markerCoordinates = this.getMarkerCoordinates(
          lat,
          lon,
          showAddress
        );
        const marker = Leaflet.marker(markerCoordinates);
        this.handleZoomOnMarkerClick(marker);
        this.setInitialCardData(component, propertyData);
        marker.bindPopup(component.location.nativeElement);
        this.markerClusterGroup.addLayer(marker);
        this.markerBounds.extend(markerCoordinates);
        component.changeDetectorRef.detectChanges();
      }
    }
    map.addLayer(this.markerClusterGroup);
  }

  private getMarkerCoordinates(
    lat: number,
    lon: number,
    showAddress: boolean
  ): Leaflet.LatLngTuple {
    const radiusDrawInfo = this.radiusDrawInfo();
    if (showAddress && lat && lon) {
      return [lat, lon];
    } else if (radiusDrawInfo) {
      return [radiusDrawInfo[0], radiusDrawInfo[1]];
    } else {
      return this.INITIAL_POSITION;
    }
  }

  private drawRadiusCircle(drawInfo: MapRadiusDrawInfo): void {
    this.removeRadiusCircleLayer();

    if (drawInfo) {
      const coordinates: Leaflet.LatLngTuple = [drawInfo[0], drawInfo[1]];
      const radius: number = drawInfo[2];
      this.radiusCircle = Leaflet.circle(
        coordinates,
        radius,
        this.radiusCircleOptions
      );
      this.radiusCircle.addTo(this.map);
    } else {
      this.radiusCircle = Leaflet.circle(this.RADIUS_CIRCLE_DEFAULT_VALUE, {
        radius: 0
      });
    }
  }

  private setMapBounds() {
    const radiusCircleCoords = this.radiusCircle.getLatLng();
    if (
      radiusCircleCoords.lat !== this.RADIUS_CIRCLE_DEFAULT_VALUE[0] &&
      radiusCircleCoords.lng !== this.RADIUS_CIRCLE_DEFAULT_VALUE[1]
    ) {
      // update map view to fit in radius circle bounds:
      try {
        this.map.fitBounds(this.radiusCircle.getBounds());
      } catch {
        this.setMapBoundsToDefault();
      }
    } else if (this.markerBounds.isValid()) {
      // update map view to fit in bounds of all markers:
      this.map.fitBounds(this.markerBounds, {
        maxZoom: this.MAX_MARKER_BOUNDS_AUTO_ZOOM,
        padding: [50, 50]
      });
    } else {
      // update map view to default:
      this.setMapBoundsToDefault();
    }
  }

  private setMapBoundsToDefault(): void {
    this.map.setView(this.INITIAL_POSITION, this.INITIAL_ZOOM);
  }

  private removeMarkerLayers(): void {
    this.markerClusterGroup.clearLayers();
    this.map.removeLayer(this.markerClusterGroup);
  }

  private removeRadiusCircleLayer(): void {
    this.map.removeLayer(this.radiusCircle);
  }

  private handleZoomOnMarkerClick(marker: Leaflet.Marker): void {
    marker.on('click', (e: Leaflet.LeafletMouseEvent) => {
      const mapZoom = this.map.getZoom();
      // clicking on a non-spider marker will zoom to marker area
      // if mapZoom > default it will stay the same
      if (!e.sourceTarget._preSpiderfyLatlng) {
        this.map.setView(
          e.latlng,
          Math.max(this.MAX_MARKER_BOUNDS_AUTO_ZOOM, mapZoom)
        );
      }
    });
  }

  private setInitialCardData(
    component: ComponentRef<PropertyCardComponent>,
    data: MapPropertyData
  ): void {
    component.setInput('elevation', Elevation.ONE); // ⚠️ keep in sync with elevation SCSS of .leaflet-popup-tip
    component.setInput('withoutBorder', true);
    component.setInput('showLandlordInfo', false);
    component.setInput('enableContextMenu', false);
    component.setInput('showAddress', data.showAddress);
    component.setInput('propertyData', this.normalizePropertyData(data));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    component.location.nativeElement.addEventListener('click', () => {
      this.emitCardClick(data);
    });
  }

  private emitCardClick(data: MapPropertyData): void {
    this.popupCardClick.emit(data.applicationLink);
  }

  private normalizePropertyData(
    property: MapPropertyData
  ): PropertyCardPropertyData {
    const propertyData: PropertyCardPropertyData = {
      marketingType: MarketingType.RENT, // added to show price on cards
      type: property.propertyType,
      housingPermissionNeeded: property.wbs,
      attachments: property.titleImage ? [property.titleImage] : [],
      rooms: property.totalRooms,
      availableFrom: property.availableFrom,
      ...property
    };
    return propertyData;
  }
}
