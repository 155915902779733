import {
  AgentInfo,
  DkApprovalLevel,
  Pagination,
  Property,
  PropertySearcher,
  PropertySearcherBase,
  SelectedTicket,
  TicketIssueType
} from '@ui/shared/models';

import moment from 'moment';

import {
  ActionState,
  ActionStateCreator
} from '../../../state-utils/action-state';
import { stripTypenameProperty } from '../../../utils';
import {
  Conversation,
  ConversationDetails,
  ConversationLabel,
  ConversationMessage,
  ConversationSenderTypes,
  ConversationType,
  MessageTemplate
} from '../../../components/legacy/messenger/model';
import * as fromActions from './messenger.actions';

export interface MessengerState {
  sender: ConversationSenderTypes;

  conversationList: Conversation[];
  conversationListPage: Pagination;
  conversationListActionState: ActionState;
  activeConversation: string;
  blockConversationActionState: ActionState;
  countUnread: number;

  conversationMessages: ConversationMessage[];
  conversationMessagesPage: Pagination;
  conversationDetails: ConversationDetails;
  conversationMessagesActionState: ActionState;
  conversationDetailsActionState: ActionState;
  conversationMessagesAttach: boolean;
  bulkSendMessageActionState: ActionState;

  participatedAgents: AgentInfo[];
  participatedAgentsActionState: ActionState;

  loadedTemplate: MessageTemplate[];
  loadedTemplateActionState: ActionState;
  loadedTemplatePage: Pagination;
  loadedParsedTemplates: MessageTemplate[];
  loadedParsedTemplatesActionState: ActionState;
  loadedParsedTemplatesPage: Pagination;
  createTemplateActionState: ActionState;
  updateTemplateActionState: ActionState;
  deleteTemplateActionState: ActionState;

  archivedByCustomer: boolean;

  searchedProperty: Property;
}

export const initialState: MessengerState = {
  sender: null,

  conversationList: [],
  conversationListPage: {
    page: 0
  },
  conversationListActionState: ActionStateCreator.create(),
  activeConversation: null,
  blockConversationActionState: ActionStateCreator.create(),
  countUnread: 0,

  conversationMessages: [],
  conversationMessagesPage: {
    page: 0
  },
  conversationDetails: undefined,
  conversationMessagesActionState: ActionStateCreator.create(),
  conversationDetailsActionState: ActionStateCreator.create(),
  bulkSendMessageActionState: ActionStateCreator.create(),
  conversationMessagesAttach: false,

  participatedAgents: null,
  participatedAgentsActionState: ActionStateCreator.create(),

  loadedTemplate: [],
  loadedTemplateActionState: ActionStateCreator.create(),
  loadedTemplatePage: {
    page: 0,
    size: 10
  },
  loadedParsedTemplates: [],
  loadedParsedTemplatesActionState: ActionStateCreator.create(),
  loadedParsedTemplatesPage: {
    page: 0,
    size: 10
  },
  createTemplateActionState: ActionStateCreator.create(),
  updateTemplateActionState: ActionStateCreator.create(),
  deleteTemplateActionState: ActionStateCreator.create(),

  archivedByCustomer: false,

  searchedProperty: undefined
};

export function reducer(
  state = initialState,
  action: fromActions.MessengerActions
): MessengerState {
  switch (action.type) {
    case fromActions.SET_SENDER: {
      return {
        ...state,
        sender: action.sender
      };
    }

    case fromActions.LOAD_CONVERSATIONS: {
      let conversationList = [...state.conversationList];
      const newConversation = state.conversationList.find(c => c.id === 'new');

      if (action.data?.page === 0) {
        conversationList = [];

        if (newConversation) {
          conversationList.push(newConversation);
        }
      }

      return {
        ...state,
        conversationList,
        conversationListActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_CONVERSATIONS_SUCCESS: {
      const activeConversation =
        state.activeConversation || action.activeConversationId;
      let conversationList = [];

      if (
        action.conversations.page.page === 0 &&
        activeConversation !== 'new'
      ) {
        conversationList = action.conversations.nodes;
      } else {
        conversationList = [
          ...state.conversationList,
          ...action.conversations.nodes
        ];
      }

      return {
        ...state,
        conversationListActionState: ActionStateCreator.onSuccess(),
        conversationList,
        activeConversation,
        conversationListPage: action.conversations.page,
        archivedByCustomer: action.archivedByCustomer
      };
    }

    case fromActions.LOAD_CONVERSATION_MESSAGES: {
      let conversationMessages: ConversationMessage[] = [];
      if (
        state.conversationList.find(
          c => c.id === action.input.conversationId
        ) &&
        state.conversationMessages
      ) {
        conversationMessages = state.conversationMessages;
      }
      return {
        ...state,
        conversationMessagesPage: {
          ...state.conversationMessagesPage,
          page: action.input.page
        },
        conversationMessagesActionState: ActionStateCreator.onStart(),
        conversationMessages
      };
    }

    case fromActions.LOAD_CONVERSATION_MESSAGES_AND_DETAILS: {
      let conversationMessages: ConversationMessage[] = [];
      if (
        state.conversationList.find(
          c => c.id === action.input.conversationId
        ) &&
        state.conversationMessages
      ) {
        conversationMessages = state.conversationMessages;
      }
      return {
        ...state,
        conversationMessagesPage: {
          ...state.conversationMessagesPage,
          page: 0
        },
        conversationMessagesActionState: ActionStateCreator.onStart(),
        conversationDetailsActionState: ActionStateCreator.onStart(),
        conversationMessages
      };
    }

    case fromActions.LOAD_CONVERSATION_MESSAGES_FAIL:
    case fromActions.LOAD_CONVERSATION_MESSAGES_AND_DETAILS_FAIL: {
      return {
        ...state,
        conversationMessagesActionState: ActionStateCreator.onError(
          action.error
        ),
        conversationDetailsActionState: ActionStateCreator.onError(
          action.error
        ),
        conversationMessages: [],
        conversationDetails: undefined
      };
    }

    case fromActions.LOAD_CONVERSATION_MESSAGES_SUCCESS: {
      let conversationMessages = state.conversationMessages
        ? [...state.conversationMessages]
        : [];

      if (state.conversationMessagesAttach) {
        conversationMessages = [
          ...action.conversationsMessages,
          ...conversationMessages
        ];
      } else {
        conversationMessages = action.conversationsMessages;
      }
      conversationMessages = setSeparators(conversationMessages);

      return {
        ...state,
        conversationMessagesActionState: ActionStateCreator.onSuccess(),
        conversationMessages,
        conversationMessagesPage: action.page
      };
    }

    case fromActions.LOAD_CONVERSATION_MESSAGES_AND_DETAILS_SUCCESS: {
      return {
        ...state,
        conversationMessagesActionState: ActionStateCreator.onSuccess(),
        conversationMessages: setSeparators(
          action.conversationMessagesAndDetails.nodes
        ),
        conversationMessagesPage: action.conversationMessagesAndDetails.page,
        conversationDetails: action.conversationMessagesAndDetails.details,
        conversationDetailsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SEND_MESSAGE: {
      return {
        ...state,
        conversationMessagesAttach: false
      };
    }

    case fromActions.SEND_MESSAGE_SUCCESS: {
      const conversationMessages = [
        ...state.conversationMessages,
        action.conversationMessage
      ];
      let conversationList = [...state.conversationList];
      const index = conversationList.findIndex(
        item => item.id === state.activeConversation
      );
      conversationList = conversationList.map((item, i) =>
        index === i
          ? { ...item, lastMessageText: action.conversationMessage.message }
          : item
      );

      /*
       * Moves the conversation to the top of the list if it is not already at the top
       */
      if (state.activeConversation !== conversationList[0]?.id) {
        const movedItem = conversationList.splice(index, 1)[0];
        conversationList = [movedItem, ...conversationList];
      }

      return {
        ...state,
        conversationList,
        conversationMessages
      };
    }

    case fromActions.SEND_MESSAGE_IN_NEW_CONVERSATION_SUCCESS: {
      let newConversation;

      const conversationList = state.conversationList.filter(conversation => {
        const notFound = conversation.id !== 'new';
        if (!notFound) newConversation = { ...conversation };
        return notFound;
      });
      newConversation.id = action.conversation.conversationId;

      return {
        ...state,
        conversationList: [newConversation, ...conversationList],
        activeConversation: newConversation.id
      };
    }

    case fromActions.BULK_SEND_MESSAGE: {
      return {
        ...state,
        bulkSendMessageActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.BULK_SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        bulkSendMessageActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.BULK_SEND_MESSAGE_FAIL: {
      return {
        ...state,
        bulkSendMessageActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.CREATE_CONVERSATION: {
      const newConversation: Partial<Conversation> = generateConversation(
        action.data.property,
        action.data.applicationId,
        action.data.ticketDetails
      );

      // Create conversationDetails ONLY if the sender is LL
      // AND it is conversation over application
      const conversationDetails =
        state.sender === ConversationSenderTypes.LANDLORD &&
        action.data.applicationId
          ? generateConversationDetailsForNewConversationLL(
              action.data.property,
              action.data.propertySearcher
            )
          : generateConversationDetailsForNewConversationPS(
              action.data.property
            );

      return {
        ...state,
        conversationList: [newConversation, ...state.conversationList],
        activeConversation: 'new',
        conversationMessages: [],
        conversationDetails,
        conversationMessagesPage: {
          ...state.conversationMessagesPage,
          page: 0
        },
        conversationMessagesAttach: false
      };
    }

    case fromActions.REMOVE_CONVERSATION: {
      const conversationList = [...state.conversationList].filter(
        c => c && c.id !== action.conversationId
      );

      return {
        ...state,
        conversationList,
        conversationMessagesAttach: false,
        conversationMessagesPage: {
          ...state.conversationMessagesPage,
          page: 0
        }
      };
    }

    case fromActions.SELECT_CONVERSATION: {
      const conversationList = [...state.conversationList].map(c => {
        if (c.id === action.conversationId) {
          return {
            ...c,
            unreadMessages: 0
          };
        }
        return c;
      });

      return {
        ...state,
        activeConversation: action.conversationId,
        conversationMessages: [],
        conversationList,
        conversationMessagesPage: {
          ...state.conversationMessagesPage,
          page: 0
        },
        conversationMessagesAttach: false
      };
    }

    case fromActions.DESELECT_CONVERSATION: {
      return {
        ...state,
        activeConversation: null
      };
    }

    case fromActions.INCREASE_CHAT_PAGE: {
      const conversationMessagesPage = state.conversationMessagesPage;
      return {
        ...state,
        conversationMessagesPage: {
          ...conversationMessagesPage,
          page: conversationMessagesPage.page + 1
        },
        conversationMessagesAttach: true
      };
    }

    case fromActions.BLOCK_CONVERSATIONS: {
      return {
        ...state,
        blockConversationActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.BLOCK_CONVERSATIONS_SUCCESS: {
      const conversationDetails: ConversationDetails = {
        ...state.conversationDetails,
        blocked: action.blocked
      };

      return {
        ...state,
        blockConversationActionState: ActionStateCreator.onSuccess(),
        conversationDetails
      };
    }

    case fromActions.COUNT_UNREAD_SUCCESS: {
      return {
        ...state,
        countUnread: action.count
      };
    }

    case fromActions.FIND_UNREAD_MESSAGES_SUCCESS: {
      const conversationMessages = [
        ...state.conversationMessages,
        ...action.conversationMessages
      ];
      let conversationList = state.conversationList;
      if (action.conversationMessages.length > 0) {
        const lastMessage =
          action.conversationMessages[action.conversationMessages.length - 1];
        conversationList = conversationList.map(c => {
          if (c.id === state.activeConversation) {
            return {
              ...c,
              lastMessageDate: lastMessage.messageSent,
              lastMessageText: lastMessage.message
            };
          }
          return c;
        });
      }
      return {
        ...state,
        conversationList,
        conversationMessages
      };
    }

    case fromActions.LOAD_PARTICIPATED_AGENTS: {
      return {
        ...state,
        participatedAgents: null,
        participatedAgentsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_PARTICIPATED_AGENTS_SUCCESS: {
      return {
        ...state,
        participatedAgents: action.participatedAgents,
        participatedAgentsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_PARTICIPATED_AGENTS_FAIL: {
      return {
        ...state,
        participatedAgents: null,
        participatedAgentsActionState: ActionStateCreator.onError(action.error)
      };
    }
    case fromActions.LOAD_TEMPLATE: {
      return {
        ...state,
        loadedTemplateActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_TEMPLATE_FAIL: {
      return {
        ...state,
        loadedTemplateActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loadedTemplateActionState: ActionStateCreator.onSuccess(),
        loadedTemplate: action.templates.nodes,
        loadedTemplatePage: stripTypenameProperty(action.templates.page)
      };
    }

    case fromActions.LOAD_PARSED_TEMPLATES: {
      return {
        ...state,
        loadedParsedTemplatesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_PARSED_TEMPLATES_FAIL: {
      return {
        ...state,
        loadedParsedTemplatesActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.LOAD_PARSED_TEMPLATES_SUCCESS: {
      const loadedParsedTemplates =
        action.templates.page.page === 0
          ? action.templates.nodes
          : state.loadedParsedTemplates.concat(action.templates.nodes);

      return {
        ...state,
        loadedParsedTemplatesActionState: ActionStateCreator.onSuccess(),
        loadedParsedTemplates,
        loadedParsedTemplatesPage: stripTypenameProperty(action.templates.page)
      };
    }

    case fromActions.CREATE_TEMPLATE: {
      return {
        ...state,
        createTemplateActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.CREATE_TEMPLATE_FAIL: {
      return {
        ...state,
        createTemplateActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.CREATE_TEMPLATE_SUCCESS: {
      const loadedTemplate = [...state.loadedTemplate, action.template];
      return {
        ...state,
        createTemplateActionState: ActionStateCreator.onSuccess(),
        loadedTemplate
      };
    }

    case fromActions.UPDATE_TEMPLATE: {
      return {
        ...state,
        updateTemplateActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.UPDATE_TEMPLATE_FAIL: {
      return {
        ...state,
        updateTemplateActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.UPDATE_TEMPLATE_SUCCESS: {
      const loadedTemplate = [...state.loadedTemplate].map(template =>
        template.id === action.template.id ? action.template : template
      );
      return {
        ...state,
        updateTemplateActionState: ActionStateCreator.onSuccess(),
        loadedTemplate
      };
    }

    case fromActions.DELETE_TEMPLATE: {
      return {
        ...state,
        deleteTemplateActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.DELETE_TEMPLATE_FAIL: {
      return {
        ...state,
        deleteTemplateActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.DELETE_TEMPLATE_SUCCESS: {
      const loadedTemplate = [...state.loadedTemplate].filter(
        template => template.id !== action.templateId
      );
      return {
        ...state,
        deleteTemplateActionState: ActionStateCreator.onSuccess(),
        loadedTemplate
      };
    }

    case fromActions.ARCHIVE_CONVERSATION_SUCCESS: {
      const conversationList = [...state.conversationList].filter(
        conversation => conversation.id !== action.conversationId
      );
      return {
        ...state,
        conversationList: conversationList
      };
    }

    case fromActions.UN_ARCHIVE_CONVERSATION_SUCCESS: {
      const conversationList = [...state.conversationList].filter(
        conversation => conversation.id !== action.conversationId
      );
      return {
        ...state,
        conversationList: conversationList
      };
    }

    case fromActions.MARK_CONVERSATION_AS_UNREAD_SUCCESS: {
      const conversationList = state.conversationList.map(conversation => {
        if (conversation.id === action.id) {
          return {
            ...conversation,
            unreadMessages: 1
          };
        }
        return conversation;
      });
      return {
        ...state,
        conversationList,
        activeConversation: null
      };
    }

    case fromActions.SEARCHED_PROPERTY: {
      return {
        ...state,
        searchedProperty: action.property
      };
    }

    default:
      return state;
  }
}

export const getSender = (state: MessengerState) => state.sender;
export const getConversationMessagesAttach = (state: MessengerState) =>
  state.conversationMessagesAttach;
export const getConversationList = (state: MessengerState) =>
  state.conversationList;
export const getConversationListPage = (state: MessengerState) =>
  state.conversationListPage;
export const getConversationListActionState = (state: MessengerState) =>
  state.conversationListActionState;
export const getActiveConversationId = (state: MessengerState) =>
  state.activeConversation;
export const getConversationMessages = (state: MessengerState) =>
  state.conversationMessages;
export const getConversationMessagesPage = (state: MessengerState) =>
  state.conversationMessagesPage;
export const getConversationDetails = (state: MessengerState) =>
  state.conversationDetails;
export const getConversationMessagesActionState = (state: MessengerState) =>
  state.conversationMessagesActionState;

export const getConversationDetailsActionState = (state: MessengerState) =>
  state.conversationDetailsActionState;
export const getBulkSendMessageActionState = (state: MessengerState) =>
  state.bulkSendMessageActionState;
export const getUnreadMessageCount = (state: MessengerState) =>
  state.countUnread;
export const getParticipatedAgents = (state: MessengerState) =>
  state.participatedAgents;
export const getParticipatedAgentsActionState = (state: MessengerState) =>
  state.participatedAgentsActionState;
export const getMessageTemplates = (state: MessengerState) =>
  state.loadedTemplate;
export const getMessageTemplatesActionState = (state: MessengerState) =>
  state.loadedTemplateActionState;
export const getMessageTemplatesPage = (state: MessengerState) =>
  state.loadedTemplatePage;
export const getParsedMessageTemplates = (state: MessengerState) =>
  state.loadedParsedTemplates;
export const getParsedMessageTemplatesActionState = (state: MessengerState) =>
  state.loadedParsedTemplatesActionState;
export const getParsedMessageTemplatesPage = (state: MessengerState) =>
  state.loadedParsedTemplatesPage;
export const getCreateTemplateActionState = (state: MessengerState) =>
  state.createTemplateActionState;
export const getUpdateTemplateActionState = (state: MessengerState) =>
  state.updateTemplateActionState;
export const getDeleteTemplateActionState = (state: MessengerState) =>
  state.deleteTemplateActionState;
export const getConversationArchived = (state: MessengerState) =>
  state.archivedByCustomer;

export const getSearchedProperty = (state: MessengerState) =>
  state.searchedProperty;

function generateConversation(
  property: Property,
  applicationId: string,
  ticketDetails: SelectedTicket
): Partial<Conversation> {
  let payload: Partial<Conversation> = {
    id: 'new',
    titleImage: property?.attachments[0]?.url || '',
    subject: property?.name
  };
  if (applicationId) payload.applicationId = applicationId;

  if (ticketDetails) {
    const name = ticketDetails.requester.fullName.split(' ');
    const label = [
      TicketIssueType.GENERAL_DAMAGE,
      TicketIssueType.PROPERTY_DAMAGE
    ].includes(ticketDetails.issueType)
      ? ConversationLabel.DAMAGE
      : ConversationLabel.CONCERN;

    payload = {
      id: 'new',
      subject: ticketDetails.title,
      labels: [label],
      otherParticipants: 1,
      mainConversationPartner: {
        dkApprovalLevel: DkApprovalLevel.DK1,
        firstname: name[0],
        name: name[1],
        portrait: null,
        type: null,
        id: ticketDetails.assignee.id
      }
    };
  }

  return payload;
}

function setSeparators(conversationMessages: ConversationMessage[]) {
  return conversationMessages.map((messages, i) => {
    let showSeparator = null;
    const dateToCheck = moment(messages.messageSent).format('YYYY.MM.DD');
    let nextDate = moment(new Date()).format('YYYY.MM.DD');

    if (i === 0) showSeparator = true;
    if (i > 0 && conversationMessages.length - 1 >= i) {
      nextDate = moment(conversationMessages[i - 1].messageSent).format(
        'YYYY.MM.DD'
      );
      showSeparator = moment(dateToCheck).isAfter(nextDate, 'd');
    }

    return {
      ...messages,
      showSeparator
    };
  });
}

const generateConversationDetailsForNewConversationPS = (
  property: Property
): ConversationDetails => {
  return {
    id: null,
    participants: [],
    propertyDetailsPS: {
      property
    },
    type: ConversationType.APPLICATION,
    blocked: false,
    userAllowedToSendMessage: true
  };
};

const generateConversationDetailsForNewConversationLL = (
  property: Property,
  propertySearcher: PropertySearcher
): ConversationDetails => {
  return {
    id: null,
    participants: [],
    applicationDetails: propertySearcher as PropertySearcherBase,
    propertyDetailsLL: property,
    type: ConversationType.APPLICATION,
    blocked: false,
    userAllowedToSendMessage: true
  };
};
