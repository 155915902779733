import { LanguageConfig } from '@ui/legacy-lib';
import { AvailableLanguageCodesEnum } from '@ui/shared/models';

export const languageConfig: LanguageConfig = {
  defaultLanguageCode: AvailableLanguageCodesEnum.DE,
  availableLanguagesByCode: [
    AvailableLanguageCodesEnum.DE,
    AvailableLanguageCodesEnum.EN
  ]
};
