import {
  Component,
  forwardRef,
  OnInit,
  ElementRef,
  input,
  viewChild
} from '@angular/core';

import { NgControl, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { SvgIconComponent } from 'angular-svg-icon';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';
import { AppInputDirective } from '../input/input.directive';

@Component({
  selector: 'app-simple-text-input',
  templateUrl: './simple-text-input.component.html',
  styleUrls: ['./simple-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleTextInputComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SimpleTextInputComponent)
    }
  ],
  imports: [AppInputDirective, FormsModule, SvgIconComponent]
})
export class SimpleTextInputComponent
  extends BaseControl<boolean>
  implements OnInit
{
  readonly ngControl = viewChild(NgControl);
  readonly inputEl = viewChild<ElementRef<HTMLInputElement>>('textInput');
  readonly hideIcon = input(false);

  public ngOnInit() {
    this.ngControl().statusChanges.subscribe(() => this.stateChanges.next());

    if (this.disabled || this.forceDisabled()) {
      this.ngControl().control.disable();
    }
  }

  public edit() {
    this.inputEl().nativeElement.focus();
  }
}
