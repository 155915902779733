<app-subscription-page
  [isAdmin]="true"
  [userData]="userData()"
  [loaded]="loaded"
  [hasActiveProduct]="hasActiveProduct()"
  [products]="products"
  [addons]="addons"
  [defaultProductId]="defaultProductId"
  [customerProduct]="customerProduct$ | async"
  [customerSize]="userData()?.customer?.customerSize"
  [addonDiscounts]="addonDiscounts"
  [digitalContractQuotaDiscounts]="digitalContractQuotaDiscounts$ | async"
  [digitalContractQuotaPackages]="digitalContractQuotaPackages$ | async"
  [objectContingentQuotaDiscounts]="objectContingentQuotaDiscounts$ | async"
  [objectContingentQuotaPackages]="objectContingentQuotaPackages$ | async"
  [paymentMethod]="paymentMethod"
  [customer]="userData()?.customer"
  [nextProductId]="nextProductId$ | async"
  [recalculatingPrices]="(recalculatingPrices$ | async)?.pending"
  [saving]="
    (subscriptionActionState$ | async)?.pending ||
    (updateLandlordActionState$ | async).pending
  "
  [form]="form()"
  [loadingError]="!!(productActionState$ | async)?.error"
  [recalculationActionState$]="recalculationActionState$()"
  (productChange)="onProductChange($event)"
  (update)="onUpdate($event)"
  (create)="onCreate($event)"
  (createLandlord)="onCreateLandlord()"
  (discountChange)="onDiscountChange($event)"
  (saveQuotaDiscounts)="onSaveQuotaDiscounts($event)"
  (saveObjectContingentQuotaDiscounts)="
    onSaveObjectContingentQuotaDiscounts($event)
  "
>
</app-subscription-page>
