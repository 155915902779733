import { inject, Injectable } from '@angular/core';

import { DOCUMENT } from '@angular/common';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { take } from 'rxjs/operators';
import { WINDOW_REF } from '../../window-ref.token';
import { AuthTokenService } from '../../../authentication';
import { INFRASTRUCTURE_CONFIG } from '../../../infrastructure-config.token';

@Injectable()
export class KnowledgeBaseWidgetService {
  private windowRef = inject(WINDOW_REF);
  private doc = inject(DOCUMENT);
  private http = inject(HttpClient);
  private authTokenService = inject(AuthTokenService);
  private infrastructureConfig = inject(INFRASTRUCTURE_CONFIG);

  private widget: any;

  constructor() {
    this.widget = (this.windowRef as any).FreshworksWidget;
  }

  public hideWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('hide', 'launcher');
  }

  public loadWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('boot');
  }

  public destroyWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('destroy');
  }

  public authenticateWidget() {
    const token = this.authTokenService.getToken().access_token;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    const path =
      this.infrastructureConfig.environment.graphql_host +
      '/freshdesk/widget/token';

    this.http
      .post(
        path,
        {
          token
        },
        { headers, responseType: 'text' }
      )
      .pipe(take(1))
      .subscribe(token => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.widget('authenticate', {
          token
        });
      });
  }

  public showWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('show', 'launcher');
  }
}
