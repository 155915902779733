import { Component, forwardRef, OnInit, input, viewChild } from '@angular/core';

import { FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';

import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { BaseControl } from '../base-control';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';

import { DateStringAdapter } from './date-string-adapter.service';
import { DateParserFormatter } from './date-parser-formatter.service';

@UntilDestroy()
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => DateComponent)
    },
    {
      provide: NgbDateAdapter,
      useClass: DateStringAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: DateParserFormatter
    }
  ],
  imports: [
    NgbInputDatepicker,
    FormsModule,
    SvgIconComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class DateComponent extends BaseControl<string> implements OnInit {
  public calendarStartDate$: Observable<NgbDateStruct>;

  readonly ngControl = viewChild(NgControl);
  readonly minDate = input<NgbDateStruct>({
    year: moment().year() - 100,
    month: 1,
    day: 1
  });
  readonly maxDate = input<NgbDateStruct>(undefined);
  readonly startDate = input<NgbDateStruct>(undefined);
  readonly readonly = input<boolean>(undefined);
  readonly container = input<'body' | null>(null);

  get errors() {
    const ngControl = this.ngControl();
    if (!ngControl.errors) return null;
    if (ngControl.errors.ngbDate) {
      const { ngbDate, invalid, ...rest } = ngControl.errors;
      let errorMsg;
      if (new Date(ngbDate?.minDate?.actual).getTime() <= Date.now()) {
        new Date().toDateString() ==
        new Date(ngbDate?.minDate?.actual).toDateString()
          ? (errorMsg = { dateInPresentOrToday: true })
          : (errorMsg = { dateInPast: true });
      } else {
        errorMsg = { invalidDate: invalid };
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...rest,
        ...errorMsg,
        ...ngbDate
      };
    }

    return ngControl.errors;
  }

  public ngOnInit() {
    this.ngControl()
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());

    this.calendarStartDate$ = this.ngControl().valueChanges.pipe(
      map(value => {
        const startDate = this.startDate();
        return !startDate || !!value ? undefined : startDate;
      })
    );
  }
}
