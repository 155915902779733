import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';

@Component({
  selector: 'app-wizard-footer',
  templateUrl: './wizard-footer.component.html',
  styleUrls: ['./wizard-footer.component.scss'],
  imports: [ButtonComponent, TranslateModule]
})
export class WizardFooterComponent {
  readonly disabled = input<boolean>(undefined);
  readonly hasNext = input<boolean>(undefined);
  readonly hasPrevious = input<boolean>(undefined);
  readonly hasFinish = input<boolean>(undefined);
  readonly hideRequiredHint = input<boolean>(undefined);
  readonly completeActionText = input<string>(undefined);

  readonly nextStep = output();
  readonly previousStep = output();
  readonly completeStep = output();

  public onNextStep() {
    this.nextStep.emit();
  }

  public onPreviousStep() {
    this.previousStep.emit();
  }

  public onCompleteStep() {
    this.completeStep.emit();
  }
}
