import {
  Component,
  ElementRef,
  OnChanges,
  signal,
  SimpleChanges,
  input,
  output,
  viewChild
} from '@angular/core';

import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { BadgeComponent } from '../../atoms/badge/badge.component';
import { BadgeColorEnum } from '../../atoms/badge';

export interface FunnelItemConfig<T> {
  label?: string;
  value?: T;
  count?: number;
  disabled?: boolean;
  active?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.scss'],
  imports: [BadgeComponent, NgClass, TranslateModule]
})
export class FunnelComponent<T> implements OnChanges {
  readonly items = input<FunnelItemConfig<T>[]>(undefined);
  readonly total = input<number>(undefined);
  readonly allowMultiselect = input(true);
  readonly loading = input(true);
  readonly allIsEmptySelection = input(false);
  readonly selectEvent = output<FunnelItemConfig<T>[]>();
  readonly el = viewChild<ElementRef>('funnelContainer');
  public allSelected = false;
  public badgeColor = BadgeColorEnum;

  // void subject, since we don't actually need any event info, just a subscribable event for when the faders should be re-evaluated
  private evaluateScrollFaders$ = new Subject<void>();
  public fadeLeft = signal(false);
  public fadeRight = signal(false);

  private debounceSelectEvent = new Subject<FunnelItemConfig<T>[]>();

  constructor() {
    this.debounceSelectEvent
      .pipe(
        debounceTime(500), // Adjust the debounce time as needed
        untilDestroyed(this)
      )
      .subscribe(debouncedItems => {
        this.selectEvent.emit(debouncedItems);
      });

    merge(this.evaluateScrollFaders$, fromEvent(window, 'resize'))
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(() => {
        const el = this.el();
        if (el.nativeElement) {
          const target = el.nativeElement as HTMLElement;
          if (target.scrollWidth > target.offsetWidth) {
            if (target.scrollLeft === target.scrollWidth - target.clientWidth) {
              this.fadeRight.set(false);
              this.fadeLeft.set(true);
            } else if (target.scrollLeft === 0) {
              this.fadeRight.set(true);
              this.fadeLeft.set(false);
            } else {
              this.fadeRight.set(true);
              this.fadeLeft.set(true);
            }
          } else {
            this.fadeLeft.set(false);
            this.fadeRight.set(false);
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      const itemDisabledCount = this.items().filter(
        item => !item.active
      ).length;
      const allIsEmptySelection = this.allIsEmptySelection();
      this.allSelected =
        (!allIsEmptySelection && !itemDisabledCount) ||
        (allIsEmptySelection && itemDisabledCount === this.items().length);

      // Since the width changed, we may want to re-evaluate the scroll faders
      // This should also deal with initial evaluation of faders
      this.evaluateScrollFaders$.next();
    }
  }

  public onHorizontalScroll() {
    // All we need is a re-evaluation of the scroll faders (happens centrally)
    this.evaluateScrollFaders$.next();
  }

  public selectValue(selectedItem: FunnelItemConfig<T> | null) {
    // If the user clicked on any funnel item, except for the "Select All" button
    if (selectedItem) {
      // If previously all items we're selected, we need to unselect all and only activate the one which the user selected
      const prevAllSelected = this.allSelected;
      if (this.allSelected) {
        this.items().forEach(item => {
          item.active = false;
        });
        this.allSelected = false;
      }
      if (!this.allowMultiselect()) {
        // If single select, deselect every active item and set selectedItem.active to it's inverted value
        this.items().forEach(item => {
          if (item.value === selectedItem.value) {
            selectedItem.active = !selectedItem.active;
          } else {
            item.active = false;
          }
        });
      } else {
        selectedItem.active = !selectedItem.active;
      }

      if (this.allIsEmptySelection() && !prevAllSelected) {
        const allOptionsDisabled = this.items().every(item => !item.active);
        if (allOptionsDisabled) {
          this.allSelected = true;
        }
      }
    } else {
      if (!this.allIsEmptySelection()) {
        this.allSelected = !this.allSelected;

        this.items().forEach(item => {
          item.active = this.allSelected;
        });
      } else {
        // If the user clicked on  all selected and it was previously false
        if (!this.allSelected) {
          this.allSelected = true;
          this.items().forEach(item => {
            item.active = false;
          });
        } else {
          // If the user clicked on  all selected, and it was previously true, then nothing should happen.
          // If we would not catch this case, then debounceSelectEvent would emit and trigger a new request
          return;
        }
      }
    }

    this.debounceSelectEvent.next(this.items());
  }
}
