import { Component, OnChanges, input } from '@angular/core';

import { ApplicantStatus, PropertyType } from '@ui/shared/models';
import { StatusPipelineBaseComponent } from '../status-pipeline-base.component';
import {
  CurrentStatusConfig,
  PipelineSteps
} from '../status-pipeline-base.config';
import {
  APPLICATION_STATUS_CONFIGS,
  unknownApplicationStatusConfig
} from '../../../../config';
import { applicationSteps } from './application-status-pipeline.config';

@Component({
  selector: 'app-application-status-pipeline',
  templateUrl: './application-status-pipeline.component.html',
  imports: [StatusPipelineBaseComponent]
})
export class ApplicationStatusPipelineComponent implements OnChanges {
  readonly status = input<ApplicantStatus | null>(undefined);
  readonly pageView = input<boolean>(undefined);
  readonly propertyType = input<PropertyType>(undefined);

  public steps: PipelineSteps;
  public currentStatus: CurrentStatusConfig;

  private getCurrentStatus(): CurrentStatusConfig {
    this.steps = applicationSteps;
    const config =
      APPLICATION_STATUS_CONFIGS[this.status()] ||
      unknownApplicationStatusConfig;
    return {
      ...config,
      pipeline: {
        ...config.pipeline,
        showAsFullscreen: typeof config.pipeline.stepId === 'undefined'
      }
    };
  }

  ngOnChanges(): void {
    this.currentStatus = this.getCurrentStatus();
  }
}
