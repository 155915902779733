import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  inject,
  input,
  signal,
  TemplateRef,
  WritableSignal,
  output,
  contentChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { BaseControl } from '../base-control';
import { CheckComponent } from '../check/check.component';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { isFunction } from '../../../../../utils';

type Item = any;

// Copy of DropdownMultiselectComponent
@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrl: './multiselect.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => MultiselectComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CheckComponent,
    NgTemplateOutlet,
    TranslateModule
  ]
})
export class MultiselectComponent extends BaseControl<any[]> {
  private fb = inject(FormBuilder);

  readonly templateRef = contentChild(TemplateRef);
  readonly itemValueKey = input('value');
  items = input<Item[]>();
  readonly emptyPlaceholder = input<string>(undefined);
  readonly showBadges = input<boolean>(undefined);

  readonly selectionChange = output<Item[]>();

  selectedItems: WritableSignal<Item[]> = signal([]);
  public dropdownForm: FormGroup;

  toggle = computed(
    () => this.items()?.length === this.selectedItems()?.length
  );

  writeValue(value: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const valueToWrite =
      typeof value === 'string'
        ? value
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          value?.map(v => (typeof v === 'string' ? v : v.id));
    super.writeValue(valueToWrite);
    const values = this.getFormGroupValues(item => this.isSelected(item));
    this.dropdownForm.patchValue(values);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems.set(this.items().filter(item => this.isSelected(item)));
  }

  ngOnChanges(): void {
    this.dropdownForm = this.fb.group(
      this.getFormGroupValues(
        this.dropdownForm ? item => this.isSelected(item) : false
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems.set(this.items().filter(item => this.isSelected(item)));
  }

  onOptionClick(index: number) {
    const key = this.items()[index][this.itemValueKey()];

    this.dropdownForm.patchValue({
      [key]: !this.dropdownForm.value[key]
    });
    this.apply();
  }

  clear() {
    this.dropdownForm.patchValue(this.getFormGroupValues(false));
  }

  apply() {
    this.value = Object.keys(this.dropdownForm.value).filter(
      key => this.dropdownForm.value[key]
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems.set(
      this.items().filter(option => this.isSelected(option))
    );
    this.selectionChange.emit(this.selectedItems());
    this.cdr.detectChanges(); // fixes an issue where labels are not updated on manual reset
  }

  toggleAll(value: boolean) {
    this.dropdownForm.patchValue(this.getFormGroupValues(value));
    this.apply();
  }

  removeItem(event: MouseEvent, item: any) {
    this.dropdownForm.get(item[this.itemValueKey()]).patchValue(false);
    this.value = Object.keys(this.dropdownForm.value).filter(
      key => this.dropdownForm.value[key]
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems.set(
      this.items().filter(option => this.isSelected(option))
    );
    event.stopPropagation();
  }

  private getFormGroupValues(value: ((item) => boolean) | boolean) {
    const groupValues = {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.items().forEach(option => {
      groupValues[option[this.itemValueKey()]] = isFunction(value)
        ? value(option)
        : value;
    });

    return groupValues;
  }

  private isSelected(option: any) {
    if (!this.value) return false;

    const values = Array.isArray(this.value) ? this.value : [this.value];

    return (
      values.findIndex(value => value === option[this.itemValueKey()]) > -1
    );
  }
}
