import { Directive, inject, TemplateRef, input } from '@angular/core';

@Directive({
  selector: '[appTableHeader]'
})
export class DataTableHeaderDirective {
  template = inject<TemplateRef<HTMLDivElement>>(TemplateRef);

  readonly name = input<string>(undefined, { alias: 'appTableHeader' });
}
