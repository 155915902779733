import { Component, inject, Input, OnInit, input } from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';

import { SelfDisclosureService } from '../../self-disclosure.service';
import { SelfDisclosureChildComponent } from '../self-disclosure-child/self-disclosure-child.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { CheckboxComponent } from '../../../components/legacy/form/controls/checkbox/checkbox.component';
import { ButtonComponent } from '../../../components/atoms/button';
import { getArrayListValidator } from '../../../components/legacy/form/controls/validation';

@UntilDestroy()
@Component({
  selector: 'app-self-disclosure-child-list',
  templateUrl: './self-disclosure-child-list.component.html',
  styleUrls: ['./self-disclosure-child-list.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelfDisclosureChildComponent,
    TranslateModule,
    FormFieldComponent,
    CheckboxComponent,
    ButtonComponent
  ]
})
export class SelfDisclosureChildListComponent implements OnInit {
  private fb = inject(FormBuilder);
  private selfDisclosureService = inject(SelfDisclosureService);

  private _selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input()
  get selfDisclosureQuestion() {
    return this._selfDisclosureQuestion;
  }

  set selfDisclosureQuestion(value) {
    this._selfDisclosureQuestion = { ...value };
  }

  readonly form = input<FormGroup>(undefined);

  public get getFormControls() {
    return (this.getFormArray.controls as FormGroup[]) || [];
  }

  private get getAnswerUnavailable() {
    return this.form().get('answerUnavailable');
  }

  private get getFormArray() {
    return this.form().get('answer') as FormArray;
  }

  public ngOnInit() {
    this.getAnswerUnavailable.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selfDisclosureQuestion.answerUnavailable =
          this.getAnswerUnavailable.value;
        this.updateValidity(value);
      });
    this.updateValidity(this.selfDisclosureQuestion.answerUnavailable);
  }

  public removeChild(index: number) {
    this.getFormArray.removeAt(index);
  }

  public addChild() {
    const control = this.selfDisclosureService.getChildConfig(
      this.selfDisclosureQuestion.mandatory &&
        this.selfDisclosureQuestion.showSelfDisclosureQuestions
    );
    this.getFormArray.push(this.fb.group(control));
  }

  private updateValidity(value: boolean) {
    /**
     * Here we add or remove the validators to show the red border only if field is invalid
     */
    if (value) {
      this.getFormArray.clearValidators();
    } else {
      this.getFormArray.setValidators(
        getArrayListValidator(
          this.selfDisclosureQuestion.mandatory &&
            this.selfDisclosureQuestion.showSelfDisclosureQuestions
        )
      );
    }
    this.getFormArray.updateValueAndValidity();
  }
}
