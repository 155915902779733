<div
  class="dropdown"
  (openChange)="handleDropdownOpenChange($event)"
  ngbDropdown
>
  <button
    type="button"
    class="btn d-flex justify-content-between align-items-center dropdown-toggle"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    @if (selectedChildren()?.length) {
      <div class="truncate-text">
        @switch (selectedChildren()?.length) {
          @case (1) {
            <ng-template
              [ngTemplateOutlet]="templateRef()"
              [ngTemplateOutletContext]="{
                $implicit: selectedChildren()[0] || {}
              }"
            ></ng-template>
          }
          @default {
            {{ selectedChildren().length }} {{ multipleItemsSelectedText() }}
          }
        }
      </div>
    } @else {
      {{ placeholderText() | translate }}
    }

    <div class="d-flex align-items-center">
      @if (selectedChildren()?.length) {
        <i class="icon icon--close ms-2" (click)="clear($event)"></i>
      }
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>
  <div ngbDropdownMenu class="dropdown-menu-custom">
    <div
      class="animation-container"
      [ngClass]="
        animationState === 'showParents' ? 'show-parent' : 'show-child'
      "
    >
      <div class="view-container parents">
        <p class="back-button semibold">{{ dropdownTitle() }}</p>
        @for (parent of items(); track parent.name) {
          <div (click)="selectParent(parent)">
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0">{{ parent.name | translate }}</p>
              <i class="icon icon--chevron-right"></i>
            </div>
          </div>
        }
      </div>
      <div class="view-container children">
        <app-button
          class="back-button"
          [type]="ButtonTypeEnum.LINK"
          [iconLeft]="IconTypeEnum.ArrowLeft"
          (clickEvent)="backToParents()"
        >
          {{ 'general.previous_a' | translate }}
        </app-button>
        @if (selectedParent()) {
          @for (child of selectedParent().items; track child.name) {
            <div
              class="d-flex justify-content-between"
              (click)="selectChild(child)"
            >
              <p class="m-0 truncate-text">{{ child.name | translate }}</p>
              <app-check
                [ngModel]="
                  child.value
                    | appIsValueSelectedInDropdownStepper: selectedChildren()
                "
                [squared]="true"
                class="ms-3"
              ></app-check>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
