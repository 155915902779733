import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '@ui/shared/models';
import { isPropertyTaskRunning } from '../../utils';

@Pipe({
  standalone: true,
  name: 'isPropertyTaskRunningPipe'
})
export class IsPropertyTaskRunningPipePipe implements PipeTransform {
  transform(property: Property): boolean {
    return isPropertyTaskRunning(property);
  }
}
