import { Component, input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { CustomQuestion, RootQuestion } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { HierarchicalRootQuestionFormComponent } from '../custom-questions/hierarchical-root-question-form/hierarchical-root-question-form.component';
import { CustomQuestionsFormComponent } from '../custom-questions/custom-questions-form/custom-questions-form.component';
import { BaseStep } from '../wizard/wizard-step';

@Component({
  selector: 'app-global-custom-questions',
  templateUrl: './global-custom-questions.component.html',
  styleUrls: ['./global-custom-questions.component.scss'],
  imports: [
    CustomQuestionsFormComponent,
    FormsModule,
    ReactiveFormsModule,
    HierarchicalRootQuestionFormComponent,
    TranslateModule
  ]
})
export class GlobalCustomQuestionsComponent extends BaseStep {
  readonly form = input<FormGroup>(undefined);
  readonly customQuestions = input<CustomQuestion[]>(undefined);
  readonly rootQuestions = input<RootQuestion[]>(undefined);
  readonly isProcessing = input<boolean>(undefined);
  readonly showHierarchicalRootQuestions = input(true);
  readonly showErrorMessage = input(false);

  public get customQuestionResponsesControl() {
    return this.form().get('customQuestionResponses') as FormControl;
  }

  public get hierarchicalRootQuestionsControl() {
    return this.form().get('hierarchicalRootQuestions') as FormControl;
  }

  public onFormValidityChange(isValid: boolean) {
    this.customQuestionResponsesControl.setErrors(
      isValid ? null : { missingFields: true }
    );
  }

  public onHierarchicalFormValidityChange(isValid: boolean) {
    this.hierarchicalRootQuestionsControl.setErrors(
      isValid ? null : { missingFields: true }
    );
  }
}
