import { IconTypeEnum } from '@ui/shared/models';
import { PipelineSteps } from '../status-pipeline-base.config';

export const projectSteps: PipelineSteps = [
  {
    label: 'project.status_pipeline.planning.label',
    iconType: IconTypeEnum.Planning
  },
  {
    label: 'project.status_pipeline.announcement.label',
    iconType: IconTypeEnum.Marketing
  },
  {
    label: 'project.status_pipeline.marketing.label',
    iconType: IconTypeEnum.Offer
  },
  {
    label: 'project.status_pipeline.rented.label',
    iconType: IconTypeEnum.Key
  }
];
