import { StorageKeys } from '@ui/legacy-lib';

export interface AdminStorageKeys extends StorageKeys {
  sovendusSessionKey: string;
  pathAfterAuth: string;
  navigation: string;
}

export const storageKeys: AdminStorageKeys = {
  tokenKey: 'token',
  localeKey: 'locale',
  sovendusSessionKey: 'sessionID',
  pathAfterAuth: 'path-after-auth',
  navigation: 'immomio-navigation'
};
