<div class="text-editor">
  <div class="text-editor-form-field">
    <app-form-field-label>
      <ng-content></ng-content>
    </app-form-field-label>
    <div class="card">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
      <ngx-editor
        [placeholder]="'forms.text_editor' | translate"
        [editor]="editor"
        [required]="required()"
        [outputFormat]="'html'"
        [formControlName]="controlKey()"
      ></ngx-editor>
    </div>
  </div>
</div>
