import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import {
  AuthTokenService,
  SessionStorageService,
  UserRedirect
} from '@ui/legacy-lib';
import { storageKeys } from '../../config';

@Injectable()
export class AuthGuard {
  private store = inject(Store);
  private authService = inject(AuthTokenService);
  private sessionStorage = inject(SessionStorageService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const token: string = this.authService.getToken()?.access_token;

    this.sessionStorage.setItem(storageKeys.pathAfterAuth, {
      pathAfterAuth: state.url.split('?')[0],
      queryParams: route.queryParams
    });

    if (!token) {
      this.store.dispatch(new UserRedirect(state.url));
      return of(false);
    }

    return of(true);
  }
}
