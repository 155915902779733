@if (actionState?.pending) {
  <div class="d-flex justify-content-center mt10">
    <app-loading-spinner></app-loading-spinner>
  </div>
} @else {
  @if (actionState?.error) {
    <app-message [messageType]="'error'">
      <div>{{ 'forms.errors.unknown_error' | translate }}</div>
    </app-message>
  } @else {
    @switch (qesCheckPagesState) {
      @case (qesCheckPagesEnum.AUTH_SELECTION_PAGE) {
        <p>{{ 'digital_contract.qes_header_l' | translate }}</p>
        <p>{{ 'digital_contract.qes_methods_l' | translate }}</p>
        <div class="d-flex flex-wrap mb20">
          @for (method of methods; track method; let i = $index) {
            <ng-container
              [ngTemplateOutlet]="qesMethod"
              [ngTemplateOutletContext]="{
                translationKey: method.translationKey,
                imageSrc: method.imageSrc,
                label: method.label,
                index: i
              }"
            ></ng-container>
          }
        </div>
        <ng-template
          #qesMethod
          let-translationKey="translationKey"
          let-imageSrc="imageSrc"
          let-label="label"
          let-index="index"
        >
          <app-card
            class="w-50 p5 text-center"
            [clickable]="true"
            (click)="openQesMethodModal(index)"
          >
            <app-badge [color]="label.color">{{
              label.text | translate
            }}</app-badge>
            <div class="d-flex flex-column">
              <app-image
                [defaultSrc]="
                  '/assets/images/digitalContract/qes/qes_' +
                  imageSrc.toLowerCase() +
                  '.png'
                "
              ></app-image>
              <span>{{ translationKey | translate }}</span>
            </div>
          </app-card>
        </ng-template>
      }
      @case (qesCheckPagesEnum.START_AUTHENTIFICATION_PAGE) {
        <p>{{ 'digital_contract.qes_done_header_l' | translate }}</p>
        <app-mobile-id-tutorial class="mt-3 mb-4"></app-mobile-id-tutorial>
        @if (qesLink()) {
          <app-button [size]="'small'" (clickEvent)="openLink()">
            {{ 'qes.open_link_a' | translate }}
          </app-button>
        }
      }
      @case (qesCheckPagesEnum.AUTHENTIFICATION_STARTED_PAGE) {
        <p>{{ 'digital_contract.qes_pending_header_l' | translate }}</p>
        <app-button [size]="'small'" (clickEvent)="handleBackClicked()">
          {{ 'qes.back_to_selection_a' | translate }}
        </app-button>
      }
    }
  }
}
