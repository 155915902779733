import { Component, forwardRef, inject, OnInit, input } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';

import { PortalConfig, PortalCredential } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { BasePortalCredentialControl } from '../../controls';
import { ComponentsModule } from '../../../components';

@UntilDestroy()
@Component({
  selector: 'app-resident-credential-details',
  templateUrl: './resident-credential-details.component.html',
  styleUrls: ['./resident-credential-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResidentCredentialsDetailsComponent),
      multi: true
    }
  ],
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class ResidentCredentialsDetailsComponent
  extends BasePortalCredentialControl
  implements OnInit
{
  private fb = inject(FormBuilder);

  public readonly portalConfig = input<PortalConfig>(undefined);
  public readonly credential = input<PortalCredential>(undefined);

  constructor() {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      properties: {}
    });
  }

  public ngOnInit() {
    const credentialValue = this.credential();
    if (credentialValue) {
      this.form.patchValue(credentialValue);
    }

    this.form.valueChanges.subscribe(credential => {
      this.value = { ...credential };

      this.onChange(this.value);
      this.onTouch();
    });
  }
}
