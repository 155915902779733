<div class="row m-0">
  <form [formGroup]="form" class="col-12 col-md-8 p0">
    <app-form-field class="col-12 p0">
      <app-form-field-label>{{
        'messenger.template_title_l' | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        required
        formControlName="title"
        class="form-control"
      />
    </app-form-field>
    <app-form-field class="col-12 p0">
      <app-form-field-label>{{
        'messenger.template_content_l' | translate
      }}</app-form-field-label>
      <textarea
        appInput
        type="text"
        required
        formControlName="content"
        class="form-control"
      >
      </textarea>
    </app-form-field>
    <app-form-field class="col-12 p0">
      <app-form-field-label>{{
        'messenger.attachment_l' | translate
      }}</app-form-field-label>
      <app-attachments
        [showRemove]="true"
        [showDownload]="true"
        [accept]="acceptedFileTypes"
        [size]="documentsMaxSize"
        formControlName="attachments"
        (preview)="previewAttachment(attachmentControl.value)"
        (download)="downloadAttachment(attachmentControl.value)"
      ></app-attachments>
    </app-form-field>
  </form>
  <div class="col-12 col-md-4 pr0">
    <div>
      {{ 'messenger.templates_substitution_title_l' | translate }}
      <app-hint>
        {{ 'messenger.templates_substitution_description_l' | translate }}
      </app-hint>
    </div>
    @for (tag of conversationMessageTemplateSubstitutionTags(); track tag) {
      <div class="placeholder__item d-flex align-items-center py-1">
        <div class="col-10 p0">{{ tag?.label }}</div>
        <app-button
          class="col-1 p0"
          [iconLeft]="'add'"
          [type]="'context-menu'"
          (clickEvent)="addSubstitution(tag?.substitutionTag)"
        >
        </app-button>
      </div>
    }
  </div>
</div>
<div class="template-button__container d-flex justify-content-end mt20">
  <ng-content select="[cancel-button]"></ng-content>
  <app-button [disabled]="this.form.invalid" (clickEvent)="savingTemplate()">
    {{ 'messenger.template_save_a' | translate }}
  </app-button>
</div>
