<div [formGroup]="form()">
  <div class="row card__form">
    <div class="col-12 mb15 card__name">
      <app-form-field>
        <app-form-field-label>{{
          'general.tax_nr_l' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="text"
          [placeholder]="'3012034567890'"
          formControlName="taxId"
          class="form-control"
        />
      </app-form-field>
    </div>

    <div class="col-12 mb15 card__number">
      <app-form-field>
        <app-form-field-label>{{
          'INVOICE_EMAIL_L' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="email"
          required
          [placeholder]="'example@gmail.de'"
          formControlName="invoiceEmail"
          class="form-control"
        />
      </app-form-field>
    </div>
  </div>

  @if (error) {
    <app-message [messageType]="'error'"> {{ error | translate }} </app-message>
  }
</div>
