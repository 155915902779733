@if (redirectLink()) {
  <a
    [routerLink]="redirectLink()"
    [ngClass]="messageType()"
    class="message clickable"
  >
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </a>
} @else {
  <div [ngClass]="messageType()" class="message">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>
}

<ng-template #content>
  @if (messageType() === 'info') {
    <i placement="top" class="info-icon">
      <img src="/assets/images/icons/icon-question.svg" class="question-mark" />
    </i>
  }
  <p><ng-content></ng-content></p>
</ng-template>
