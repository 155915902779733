import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, TranslateModule]
})
export class NoContentComponent {
  readonly headline = input<string>(undefined);
  readonly message = input<string>(undefined);
  readonly hideImage = input<boolean>(undefined);
  readonly transparent = input<boolean>(undefined);
  readonly withoutVerticalSpacing = input<false>(undefined);
}
