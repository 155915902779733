import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgbPagination]
})
export class PaginationComponent {
  readonly pageSize = input<number>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly collectionSize = input<number>(undefined);
  readonly page = input<number>(undefined);
  readonly forceDisplay = input<boolean>(undefined);
  readonly pageChange = output<number>();

  onPageChange(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }
}
