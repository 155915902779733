import { Component, forwardRef, inject, OnInit, input } from '@angular/core';

import {
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';

import { PortalConfig, PortalCredential } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';

import { BasePortalCredentialControl } from '../../controls';
import { CredentialValidatorService } from '../../services';
import { ComponentsModule } from '../../../components';

@UntilDestroy()
@Component({
  selector: 'app-portal-credential-details',
  templateUrl: './portal-credential-details.component.html',
  styleUrls: ['./portal-credential-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortalCredentialDetailsComponent),
      multi: true
    }
  ],
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class PortalCredentialDetailsComponent
  extends BasePortalCredentialControl
  implements OnInit
{
  private fb = inject(FormBuilder);
  private credentialValidatorService = inject(CredentialValidatorService);

  public readonly portalConfig = input<PortalConfig>(undefined);
  public readonly credential = input<PortalCredential>(undefined);

  constructor() {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      properties: this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      })
    });
  }

  public ngOnInit() {
    const credentialValue = this.credential();
    if (credentialValue) {
      this.form.patchValue(credentialValue);
    }

    this.form.valueChanges.subscribe(credential => {
      this.value = { ...credential };

      this.onChange(this.value);
      this.onTouch();
    });

    this.credentialValidatorService.validationError$
      .pipe(
        filter(portal => !!portal),
        untilDestroyed(this)
      )
      .subscribe(portal =>
        this.setCredentialValidationError(
          this.portalConfig().validationControl || 'password',
          portal === this.credential().portal
        )
      );
  }
}
