import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  input,
  output
} from '@angular/core';

import {
  BodyModifierClass,
  BodyService
} from '../../../infrastructure/browser/body';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PageSizeSelectionComponent } from './components/page-size-selection/page-size-selection.component';

@Component({
  selector: 'app-list-controls',
  templateUrl: './list-controls.component.html',
  styleUrls: ['./list-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PaginationComponent, PageSizeSelectionComponent]
})
export class ListControlsComponent implements OnInit, OnDestroy {
  private bodyService = inject(BodyService);

  readonly pageSize = input(25);
  readonly disabled = input(false);
  readonly forceDisplay = input(false);
  readonly totalCount = input<number>(undefined);
  readonly page = input<number>(undefined);
  readonly selectableSizes = input([10, 25, 50, 100]);
  readonly hidePageSizeSelection = input<boolean>(undefined);
  readonly pageChange = output<number>();
  readonly sizeChange = output<number>();

  ngOnInit(): void {
    this.bodyService.setBodyModifierClass(BodyModifierClass.PAGINATION_VISIBLE);
  }

  ngOnDestroy(): void {
    this.bodyService.unsetBodyModifierClass(
      BodyModifierClass.PAGINATION_VISIBLE
    );
  }

  public onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  public onSizeChange(size: number) {
    this.sizeChange.emit(size);
  }
}
