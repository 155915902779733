<div class="status-info">
  @if (!loading()) {
    <div class="status-info__wrapper">
      @if (subject()) {
        <div class="status-info__subject">{{ subject() | translate }}</div>
      }
      <div class="status-info__header">
        <ng-content select="[status-info-header]"></ng-content>
      </div>
      <div [style.width]="imageSize()">
        @if (imageSrc()) {
          <app-image
            class="status-info__image"
            [defaultSrc]="imageSrc()"
          ></app-image>
        }
        @if (svgXML) {
          <div class="status-info__svg" [innerHTML]="svgXML"></div>
        }
      </div>
      @if (title()) {
        <div class="status-info__title title-xxl">
          {{ title() | translate }}
        </div>
      }
      <div class="status-info__text">
        <ng-content select="[status-info-description]"></ng-content>
      </div>
      <div class="status-info__content">
        <ng-content></ng-content>
      </div>
      <div class="status-info__button">
        <ng-content select="[status-info-button]"></ng-content>
      </div>
    </div>
  }
  @if (loading()) {
    <div class="status-info__spinner">
      <app-loading-spinner></app-loading-spinner>
    </div>
  }
</div>
