import { Component, input } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldLabelComponent } from '../../../components/legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../components/legacy/form/form-field/form-field.component';
import { PhoneInputComponent } from '../../../components';
import { AppInputDirective } from '../../../components/legacy/form/controls/input';

@Component({
  selector: 'app-self-disclosure-address',
  templateUrl: './self-disclosure-address.component.html',
  styleUrls: ['./self-disclosure-address.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AppInputDirective,
    FormFieldLabelComponent,
    FormFieldComponent,
    PhoneInputComponent
  ]
})
export class SelfDisclosureAddressComponent {
  readonly selfDisclosureQuestion = input<SelfDisclosureQuestion>(undefined);
  readonly form = input<FormGroup>(undefined);

  public get getFormControl() {
    return this.form().get('answer') as FormGroup;
  }
}
