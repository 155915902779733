import { Pipe, PipeTransform } from '@angular/core';
import { SystemTag } from '@ui/shared/models';

@Pipe({
  name: 'appGetAssignableSystemTags'
})
export class GetAssignableSystemTagsPipe implements PipeTransform {
  // It's only possible to assign those tags
  private readonly defaultSystemTags = [SystemTag.BUYER, SystemTag.TENANT];

  transform(systemTags: SystemTag[]): SystemTag[] {
    const tagsSet = new Set(systemTags);
    // Only return a defaultSystemTag when it's not included in the systemTags array
    return this.defaultSystemTags.filter(item => !tagsSet.has(item));
  }
}
