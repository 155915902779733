<div class="breadcrumbs d-flex">
  @for (item of items; track item.label; let i = $index) {
    <span
      class="breadcrumbs__label"
      [class.breadcrumbs__label--active]="i + 1 === items.length"
      [class.breadcrumbs__label--clickable]="item.url"
      [class.readonly]="readonly()"
      (click)="item.url && navigate(item.url)"
    >
      {{ item.label | translate }}
    </span>
    @if (i + 1 !== items.length) {
      <div class="breadcrumbs__separator" [class.readonly]="readonly()">/</div>
    }
  }
</div>
