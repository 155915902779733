import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';
import { Icons } from '@ui/shared/models';

// TODO: remove
export const messengerNavigation: NavigationItem = {
  name: 'messenger',
  label: 'navigation.messenger_l',
  link: '/messenger',
  icon: Icons.Messenger
};
