import { Component, forwardRef, input } from '@angular/core';

import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomQuestion, CustomQuestionType } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { FlatSelectComponent } from '../../form/controls/flat-select/flat-select.component';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { BaseControl } from '../../form/controls/base-control';

const BOOLEAN_OPTIONS = [
  { name: 'general.yes_l', value: true },
  { name: 'general.no_l', value: false }
];

@Component({
  selector: 'app-custom-questions-field',
  templateUrl: './custom-questions-field.component.html',
  styleUrls: ['./custom-questions-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomQuestionsFieldComponent),
      multi: true
    }
  ],
  imports: [
    FormFieldComponent,
    FormFieldLabelComponent,
    FlatSelectComponent,
    FormsModule,
    TranslateModule
  ]
})
export class CustomQuestionsFieldComponent extends BaseControl<any> {
  readonly customQuestion = input<CustomQuestion>(undefined);

  public writeValue(value: any) {
    if (this.isMultiple) {
      this.value = value ? value : [];
      return;
    }

    this.value = value;
  }

  public get isMultiple() {
    return this.customQuestion().type === CustomQuestionType.MULTISELECT;
  }

  public get isBoolean() {
    return this.customQuestion().type === CustomQuestionType.BOOLEAN;
  }

  public get options() {
    return this.isBoolean ? BOOLEAN_OPTIONS : this.customQuestion().options;
  }
}
