import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  viewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NgbNav,
  NgbNavChangeEvent,
  NgbNavItem,
  NgbNavLink
} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { Go } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-tables-propertysearcher',
  templateUrl: './propertysearcher.component.html',
  styleUrls: ['./propertysearcher.component.scss'],
  imports: [NgbNav, RouterOutlet, NgbNavItem, NgbNavLink, TranslateModule]
})
export class PropertySearcherComponent implements AfterViewInit {
  readonly nav = viewChild<NgbNav>('nav');
  private store = inject(Store);
  private cdref = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);

  ngAfterViewInit(): void {
    if (this.route?.firstChild) {
      this.route.firstChild.url
        .pipe(untilDestroyed(this))
        .subscribe(url => this.nav()?.select(url[0].path));
    } else {
      this.store.dispatch(new Go({ path: ['tables/landlord/customers'] }));
    }
    this.cdref.detectChanges();
  }

  public onTabChange($event: NgbNavChangeEvent) {
    this.store.dispatch(
      new Go({
        path: ['tables/property-searcher', $event.nextId]
      })
    );
  }
}
