import { Component, input } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class TableHeaderComponent {
  readonly headerArray = input<string[]>(undefined);
}
