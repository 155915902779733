import { Component, inject, input, output } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Property, PropertyBean, SocialLogin } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SocialLoginComponent } from '../../social-login/social-login.component';
import { MessageComponent } from '../../../legacy/message/message.component';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { CheckboxComponent } from '../../../legacy/form/controls/checkbox/checkbox.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { PasswordComponent } from '../../../legacy/form/controls/password/password.component';
import { AppInputDirective } from '../../../legacy/form/controls/input/input.directive';
import { FormFieldComponent } from '../../../legacy/form/form-field/form-field.component';
import { ThemeService } from '../../../../infrastructure/theme';

@Component({
  selector: 'app-register-card',
  templateUrl: './register-card.component.html',
  styleUrls: ['./register-card.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    AppInputDirective,
    PasswordComponent,
    CardComponent,
    CheckboxComponent,
    ButtonComponent,
    MessageComponent,
    SocialLoginComponent,
    RouterLink,
    TitleCasePipe,
    TranslateModule
  ]
})
export class RegisterCardComponent {
  private themeService = inject(ThemeService);

  readonly form = input<FormGroup>(undefined);
  readonly property = input<Property & PropertyBean>(undefined);
  readonly logoRedirectUrl = input<string>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly errorMessage = input<string>(undefined);
  readonly socialLogin = input<SocialLogin>(undefined);
  readonly guestLoginRedirectUrl = input<string>(undefined);
  readonly showLoginAsGuest = input<boolean>(undefined);

  readonly registerEvent = output();
  readonly loginRedirectEvent = output();
  readonly socialLoginEvent = output<string>();
  readonly loginAsGuestEvent = output();

  public get customerName() {
    const property = this.property();
    return property?.customerName || property?.customer?.name;
  }

  public get logo() {
    const property = this.property();
    return property?.customerLogo || property?.customer?.logo;
  }

  public get customerLogo() {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  public onRegister() {
    this.registerEvent.emit();
  }

  public onLoginRedirect() {
    this.loginRedirectEvent.emit();
  }

  public onSocialLogin($event: string) {
    this.socialLoginEvent.emit($event);
  }

  public onLoginAsGuest() {
    this.loginAsGuestEvent.emit();
  }
}
