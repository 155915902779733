import { ModuleWithProviders, NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  NgbDropdownModule,
  NgbModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEditorModule } from 'ngx-editor';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { MatSliderModule } from '@angular/material/slider';

import { PipesModule } from '../pipes';
import {
  AutofocusDirective,
  ElevationDirective,
  FocusFormErrorDirective
} from '../directives';
import { GeoService, GOOGLE_MAPS_API_CONFIG } from '../services/geo.service';
import { DateTimeService } from '../services/date-time.service';

import { IsValueSelectedInDropdownStepperPipe } from './atoms/multi-select-dropdown-stepper/is-value-selected.pipe';
import { MultiSelectGroupedIntermediateDropdownComponent } from './atoms/multi-select-grouped-intermediate-dropdown/multi-select-grouped-intermediate-dropdown.component';
import { SingleSelectDropdownStepperComponent } from './atoms/single-select-dropdown-stepper/single-select-dropdown-stepper.component';
import { MultiSelectDropdownStepperComponent } from './atoms/multi-select-dropdown-stepper/multi-select-dropdown-stepper.component';
import {
  BasicTableComponent,
  DataTableCellComponent,
  DataTableCellDirective,
  DataTableComponent,
  DataTableHeaderDirective,
  NavigationComponent,
  NavigationDrawerComponent,
  NavigationDrawerItemComponent,
  NavigationDrawerListComponent,
  NavigationUserProfileComponent,
  QuotaTableComponent
} from './organisms';
import {
  ApplicationStatusPipelineComponent,
  AutoCompleteFieldComponent,
  ContextMenuComponent,
  ContextMenuItemComponent,
  FileUploadInputComponent,
  FunnelComponent,
  GetAssignableContactTagsPipe,
  GetAssignablePropertyTagsPipe,
  GetAssignableSystemTagsPipe,
  HeaderMobileComponent,
  LabelListComponent,
  LabelListTagComponent,
  LandlordInfoComponent,
  LangPickerComponent,
  LocationSearchFieldComponent,
  MapComponent,
  NoDataDisclaimerComponent,
  PortalCardComponent,
  ProjectCardComponent,
  ProjectStatusPipelineComponent,
  PropertyCardComponent,
  PropertyGroupCardComponent,
  QuotaButtonComponent,
  QuotaIconPipe,
  QuotaNumberPipe,
  QuotaStylePipe,
  QuotaTranslationPipe,
  RegisterCardComponent,
  SegmentComponent,
  SelectionCardComponent,
  ServiceCardComponent,
  SideModalComponent,
  SideModalContentDirective,
  SideSheetComponent,
  SideSheetContentDirective,
  SmartInputFieldSetComponent,
  SocialLoginComponent,
  StatusInfoComponent,
  StatusPipelineBaseComponent,
  SwitchComponent,
  TabNavigationComponent,
  TagComponent,
  TooltipComponent,
  TooltipDirective
} from './molecules';
import { PhoneInputComponent } from './molecules/form/controls/phone-input/phone-input.component';
import { BadgeComponent } from './atoms/badge';
import { CardComponent } from './atoms/card';
import { ButtonComponent } from './atoms/button';
import { BreadcrumbsComponent } from './atoms/breadcrumbs/breadcrumbs.component';
import { MultiSelectGroupedDropdownComponent } from './atoms/multi-select-grouped-dropdown';
import { SalesLegalCheckboxesPreviewComponent } from './atoms/sales-legal-checkboxes-preview/sales-legal-checkboxes-preview.component';
import { InfoCollapseComponent } from './atoms/info-collapse';
import { ApplicationConfirmationComponent } from './atoms/application-confirmation/application-confirmation.component';
import { InfiniteScrollComponent } from './atoms/infinite-scroll/infinite-scroll.component';
import { GroupedDistrictsDetailComponent } from './atoms/grouped-districts-detail/grouped-districts-detail.component';
import { GroupedCityMapComponent } from './atoms/grouped-city-map/grouped-city-map.component';
import { InformationBoxComponent } from './atoms/information-box/information-box.component';
import { LocaleFlagComponent } from './atoms/image/locale-flag/locale-flag.component';
import { QuotaInfoBadgeComponent } from './atoms/quota-info-badge/quota-info-badge.component';
import { MultiSelectDropdownV2Component } from './atoms/multi-select-dropdown-v2/multi-select-dropdown-v2.component';
import { SingleSelectDropdownComponent } from './atoms/single-select-dropdown/single-select-dropdown.component';
import { TextEditorComponent } from './atoms/text-editor/text-editor.component';
import { LoadMoreComponent } from './atoms/load-more/load-more.component';
import { TextFieldComponent } from './atoms/input-fields/text-field/text-field.component';
import { ImageComponent } from './atoms/image';
import { CityAutocompleteComponent } from './legacy/search-profile/city-autocomplete/city-autocomplete.component';
import { DigitalContractSignerComponent } from './legacy/digital-contract/digital-contract-signer/digital-contract-signer.component';
import { MobileIdTutorialComponent } from './legacy/digital-contract/mobile-id-tutorial/mobile-id-tutorial.component';
import { QualifiedElectronicSignatureComponent } from './legacy/digital-contract/qualified-electronic-signature/qualified-electronic-signature.component';

import { SearchProfileAddressComponent } from './legacy/search-profile/search-profile-address/search-profile-address.component';
import { SearchProfileDetailsComponent } from './legacy/search-profile/search-profile-details/search-profile-details.component';
import { SearchProfileInfoComponent } from './legacy/search-profile/search-profile-info/search-profile-info.component';
import { SearchProfileLandlordDistrictsComponent } from './legacy/search-profile/search-profile-landlord-districts/search-profile-landlord-districts.component';
import { SearchProfilePropertyDetailsComponent } from './legacy/search-profile/search-profile-property-details/search-profile-property-details.component';

import { AddressFormComponent } from './legacy/address-form/address-form.component';
import { MessageComponent } from './legacy/message/message.component';
import { LogoComponent } from './legacy/logo/logo.component';
import { ListNavigationComponent } from './legacy/list-navigation/list-navigation.component';
import { HightlightedBgComponent } from './legacy/hightlighted-bg/hightlighted-bg.component';
import { FileUploadComponent } from './legacy/attachment/file-upload/file-upload.component';
import { WizardStepDirective } from './legacy/wizard/wizard-step';
import { WizardProgressComponent } from './legacy/wizard/wizard-progress';
import { WizardComponent } from './legacy/wizard/wizard.component';
import { WizardFooterComponent } from './legacy/wizard/wizard-footer';
import { CommentsComponent } from './legacy/comments/comments.component';
import { FooterComponent } from './legacy/footer/footer.component';
import { SocialsComponent } from './legacy/footer/socials/socials.component';
import { HeaderComponent } from './legacy/header/header.component';
import { NoContentComponent } from './legacy/no-content/no-content.component';
import { MatchControlValidator } from './legacy/form/controls/validation';
import { HintComponent } from './legacy/hint/hint.component';

import { AttachmentsListComponent } from './legacy/attachment/attachments-list/attachments-list.component';
import { AttachmentItemComponent } from './legacy/attachment/attachment-item/attachment-item.component';
import { AttachmentPreviewComponent } from './legacy/attachment/attachment-preview/attachment-preview.component';
import { AttachmentItemV2Component } from './legacy/attachment-v2/attachment-item-v2/attachment-item-v2.component';
import { AttachmentPreviewV2Component } from './legacy/attachment-v2/attachment-preview-v2/attachment-preview-v2.component';
import { AttachmentsListV2Component } from './legacy/attachment-v2/attachments-list-v2/attachments-list-v2.component';
import { AttachmentsComponent } from './legacy/form/controls/attachment/attachments.component';
import { CalendarComponent } from './legacy/calendar/calendar.component';
import { ToastContainerComponent, ToastService } from './legacy/toast';
import { AdBlockInfoComponent } from './legacy/ad-block-info/ad-block-info.component';
import { InvoicePaymentMethodComponent } from './legacy/invoice-payment-method/invoice-payment-method.component';
import { SetPasswordFormComponent } from './legacy/set-password-form/set-password-form.component';
import { LoadingSpinnerComponent } from './legacy/loading-spinner/loading-spinner.component';
import {
  CookieBannerComponent,
  CustomCookieSettingsModalComponent
} from './legacy/cookie-banner';
import { VerifyEmailBannerComponent } from './legacy/verify-email-banner/verify-email-banner.component';
import { PerformanceWarningBannerComponent } from './legacy/performance-warning-banner/performance-warning-banner.component';
import { InfoBoxComponent } from './legacy/info-box/info-box.component';
import {
  AppClickAreaDirective,
  AppInputDirective
} from './legacy/form/controls/input';
import { AvatarComponent } from './legacy/avatar/avatar.component';
import { ListControlsComponent } from './legacy/list-controls/list-controls.component';

import { EmbeddedLinkComponent } from './legacy/embedded-link/embedded-link.component';
import { MessengerComponent } from './legacy/messenger';
import { PropertyBannerComponent } from './legacy/property-banner/property-banner.component';
import { PropertyParkingSpaceComponent } from './legacy/parking-spaces/property-parking-space.component';
import { NewUpdateBannerComponent } from './legacy/new-update-banner/new-update-banner.component';
import { ProfileDkTwoComponent } from './legacy/profile-dk-two/profile-dk-two.component';
import { GlobalCustomQuestionsComponent } from './legacy/global-custom-questions/global-custom-questions.component';
import { ConfirmReasonComponent } from './legacy/confirm-reason-modal/confirm-reason/confirm-reason.component';
import { AppointmentSelectionComponent } from './legacy/appointments/appointment-selection/appointment-selection.component';
import { PropertyInterestComponent } from './legacy/property-interest/property-interest.component';
import { HeadingComponent } from './legacy/heading/heading.component';
import { UpdateModalComponent } from './legacy/update-modal/update-modal.component';
import { HeaderV2Component } from './legacy/header-v2/header-v2.component';
import { FooterV2Component } from './legacy/footer-v2/footer-v2.component';
import { TenantProfileFormComponent } from './legacy/tenant-profile-form/tenant-profile-form.component';

import { FieldsetDisabledComponent } from './legacy/fieldset-disabled/fieldset-disabled.component';
import { LockableDataComponent } from './legacy/lockable-data/lockable-data.component';
import { CheckComponent } from './legacy/form/controls/check/check.component';
import { CheckIntermediateComponent } from './legacy/form/controls/check-intermediate/check-intermediate.component';
import { CheckboxComponent } from './legacy/form/controls/checkbox/checkbox.component';
import { DateComponent } from './legacy/form/controls/date';
import { DropdownMultiselectComponent } from './legacy/form/controls/dropdown-multiselect/dropdown-multiselect.component';
import { DropdownSelectComponent } from './legacy/form/controls/dropdown-select/dropdown-select.component';
import { FlatSelectComponent } from './legacy/form/controls/flat-select/flat-select.component';
import { FormFieldComponent } from './legacy/form/form-field/form-field.component';
import { FormFieldLabelComponent } from './legacy/form/form-field/form-field-label/form-field-label.component';
import { FormFieldErrorComponent } from './legacy/form/form-field/form-field-error/form-field-error.component';
import { FormFieldInfoComponent } from './legacy/form/form-field/form-field-info/form-field-info.component';
import { FormFieldLangPickerComponent } from './legacy/form/form-field/form-field-lang-picker/form-field-lang-picker.component';
import { ScoreCircleComponent } from './legacy/score-circle/score-circle.component';
import { SimpleTextInputComponent } from './legacy/form/controls/simple-text-input/simple-text-input.component';
import { SlideToggleComponent } from './legacy/form/controls/slide-toggle/slide-toggle.component';
import { ItemCheckComponent } from './legacy/form/controls/item-check/item-check.component';
import { ListNavigationV2Component } from './legacy/list-navigation-v2/list-navigation-v2.component';
import { NavigationItemV2Component } from './legacy/navigation-item-v2/navigation-item-v2.component';
import { PasswordComponent } from './legacy/form/controls/password';
import { RadioButtonComponent } from './legacy/form/controls/radio/radio-button/radio-button.component';
import { RadioGroupComponent } from './legacy/form/controls/radio/radio-group/radio-group.component';
import { SelectComponent } from './legacy/form/controls/select/select.component';
import { SliderComponent } from './legacy/form/controls/slider/slider.component';
import { TimeComponent } from './legacy/form/controls/time/time.component';
import { InternationalPhoneComponent } from './legacy/form/controls/international-phone/international-phone.component';
import { MultiselectComponent } from './legacy/form/controls/multiselect/multiselect.component';
import { RangeSliderComponent } from './legacy/form/controls/range-slider/range-slider.component';
import { ModalFooterComponent } from './legacy/modal/modal-footer/modal-footer.component';
import { ModalContentComponent } from './legacy/modal/modal-content/modal-content.component';
import { ConfirmationDialogComponent } from './legacy/modal/confirmation-dialog/confirmation-dialog.component';
import { RejectionDialogComponent } from './legacy/modal/rejection-dialog/rejection-dialog.component';
import { ModalComponent, ModalService } from './legacy/modal';
import { ModalV2Component } from './legacy/modal-v2/modal-v2.component';
import { AppointmentsModalComponent } from './legacy/appointments/appointments-modal/appointments-modal.component';
import { ConfirmReasonModalComponent } from './legacy/confirm-reason-modal/confirm-reason-modal.component';
import { AttachCustomQuestionModalComponent } from './legacy/custom-questions/attach-custom-question-modal/attach-custom-question-modal.component';
import { AvailableCustomQuestionComponent } from './legacy/custom-questions/available-custom-question/available-custom-question.component';
import { CustomQuestionComponent } from './legacy/custom-questions/custom-question/custom-question.component';
import { CustomQuestionDetailsComponent } from './legacy/custom-questions/custom-question-details/custom-question-details.component';
import { CustomQuestionPreviewModalComponent } from './legacy/custom-questions/custom-question-preview-modal/custom-question-preview-modal.component';
import { CustomQuestionFormComponent } from './legacy/custom-questions/custom-question-form/custom-question-form.component';
import { CustomQuestionsFieldComponent } from './legacy/custom-questions/custom-questions-field/custom-questions-field.component';
import { CustomQuestionsFormComponent } from './legacy/custom-questions/custom-questions-form/custom-questions-form.component';
import { HierarchicalQuestionFormComponent } from './legacy/custom-questions/hierarchical-question-form/hierarchical-question-form.component';
import { HierarchicalRootQuestionFormComponent } from './legacy/custom-questions/hierarchical-root-question-form/hierarchical-root-question-form.component';
import { CustomQuestionsModalContentComponent } from './legacy/custom-questions/custom-questions-modal-content/custom-questions-modal-content.component';
import { AvailableHierarchicalQuestionComponent } from './legacy/custom-questions/available-hierarchical-question.component/available-hierarchical-question.component';
import { HierarchicalQuestionsDisplayRootComponent } from './legacy/custom-questions/hierarchical-questions-display-root/hierarchical-questions-display-root.component';
import { HierarchicalQuestionsDisplayQuestionComponent } from './legacy/custom-questions/hierarchical-questions-display-question/hierarchical-questions-display-question.component';
import { HierarchicalQuestionSelectionComponent } from './legacy/custom-questions/hierarchical-question-field/selection-field/hierarchical-question-selection.component';
import { HierarchicalQuestionInputComponent } from './legacy/custom-questions/hierarchical-question-field/input-field/hierarchical-question-input.component';
import { EditAttachmentComponent } from './legacy/attachment/edit-attachment/edit-attachment.component';
import { ColorPickerComponent } from './legacy/form/controls/color-picker/color-picker.component';

import {
  MessengerFilterService,
  MessengerNotificationService
} from './legacy/messenger/services';
import { QualifiedElectronicSignatureConfirmTermsAndConditionsComponent } from './legacy/digital-contract/qualified-electronic-signature/components/qualified-electronic-signature-confirm-terms-and-conditions/qualified-electronic-signature-confirm-terms-and-conditions.component';
import { QualifiedElectronicSignatureMethodModalComponent } from './legacy/digital-contract/qualified-electronic-signature/components/qualified-electronic-signature-method-modal/qualified-electronic-signature-method-modal.component';
import { PageSizeSelectionComponent } from './legacy/list-controls/components/page-size-selection/page-size-selection.component';
import { PaginationComponent } from './legacy/list-controls/components/pagination/pagination.component';
import { TableHeaderComponent } from './legacy/table/components/header/header.component';
import { ChatComponent } from './legacy/messenger/components/chat/chat.component';
import { ChatContactItemComponent } from './legacy/messenger/components/chat-contact-item';
import { ChatContactListComponent } from './legacy/messenger/components/chat-contact-list/chat-contact-list.component';
import { ChatTextMessageComponent } from './legacy/messenger/components/chat-text-message/chat-text-message.component';
import { ChatAttachmentComponent } from './legacy/messenger/components/chat-attachment/chat-attachment.component';
import { ChatCreateModalComponent } from './legacy/messenger/components/chat-create-modal/chat-create-modal.component';
import { ChatSettingsModalComponent } from './legacy/messenger/components/chat-settings-modal/chat-settings-modal.component';
import { MessageTemplatesComponent } from './legacy/messenger/components/message-templates/message-templates.component';
import { MessageTemplateFormComponent } from './legacy/messenger/components/message-template-form/message-template-form.component';
import { KnockoutCriteriaComponent } from './legacy/tenant-profile-form/components/knockout-criteria/knockout-criteria.component';
import { ConversationRestrictionConfigFormComponent } from './legacy/messenger/components/conversation-restriction-config-form/conversation-restriction-config-form.component';
import { ConversationGeneralConfigFormComponent } from './legacy/messenger/components/chat-settings-modal/conversation-general-config-form/conversation-general-config-form.component';
import { ComponentsModuleConfig } from './components.config';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    NgbPopoverModule,
    NgbDropdownModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    LayoutModule,
    PipesModule,
    ColorPickerModule,
    NgScrollbarModule,
    AngularSvgIconModule,
    DragDropModule,
    LetDirective,
    NgxEditorModule,
    MatSliderModule,
    IsValueSelectedInDropdownStepperPipe,
    CityAutocompleteComponent,
    DigitalContractSignerComponent,
    MobileIdTutorialComponent,
    QualifiedElectronicSignatureComponent,
    QualifiedElectronicSignatureConfirmTermsAndConditionsComponent,
    QualifiedElectronicSignatureMethodModalComponent,
    SearchProfileAddressComponent,
    SearchProfileDetailsComponent,
    SearchProfileInfoComponent,
    SearchProfileLandlordDistrictsComponent,
    SearchProfilePropertyDetailsComponent,
    ColorPickerComponent,
    PageSizeSelectionComponent,
    AddressFormComponent,
    MessageComponent,
    LogoComponent,
    ListNavigationComponent,
    HightlightedBgComponent,
    FileUploadComponent,
    WizardStepDirective,
    WizardProgressComponent,
    WizardComponent,
    WizardFooterComponent,
    CommentsComponent,
    FooterComponent,
    SocialsComponent,
    HeaderComponent,
    NoContentComponent,
    MatchControlValidator,
    HintComponent,
    PaginationComponent,
    AttachmentsListComponent,
    AttachmentItemComponent,
    AttachmentPreviewComponent,
    AttachmentItemV2Component,
    AttachmentPreviewV2Component,
    AttachmentsListV2Component,
    AttachmentsComponent,
    CalendarComponent,
    ToastContainerComponent,
    AdBlockInfoComponent,
    InvoicePaymentMethodComponent,
    SetPasswordFormComponent,
    LoadingSpinnerComponent,
    CookieBannerComponent,
    CustomCookieSettingsModalComponent,
    VerifyEmailBannerComponent,
    PerformanceWarningBannerComponent,
    InfoBoxComponent,
    AppClickAreaDirective,
    AppInputDirective,
    AvatarComponent,
    ListControlsComponent,
    TableHeaderComponent,
    EmbeddedLinkComponent,
    MessengerComponent,
    ChatComponent,
    ChatContactItemComponent,
    ChatContactListComponent,
    ChatTextMessageComponent,
    ChatAttachmentComponent,
    ChatCreateModalComponent,
    ChatSettingsModalComponent,
    PropertyBannerComponent,
    PropertyParkingSpaceComponent,
    NewUpdateBannerComponent,
    MessageTemplatesComponent,
    MessageTemplateFormComponent,
    ProfileDkTwoComponent,
    GlobalCustomQuestionsComponent,
    ConfirmReasonComponent,
    AppointmentSelectionComponent,
    PropertyInterestComponent,
    HeadingComponent,
    UpdateModalComponent,
    HeaderV2Component,
    FooterV2Component,
    TenantProfileFormComponent,
    KnockoutCriteriaComponent,
    FieldsetDisabledComponent,
    LockableDataComponent,
    CheckComponent,
    CheckIntermediateComponent,
    CheckboxComponent,
    DateComponent,
    DropdownMultiselectComponent,
    DropdownSelectComponent,
    FlatSelectComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    FormFieldErrorComponent,
    FormFieldInfoComponent,
    FormFieldLangPickerComponent,
    ScoreCircleComponent,
    SimpleTextInputComponent,
    ConversationRestrictionConfigFormComponent,
    ConversationGeneralConfigFormComponent,
    SlideToggleComponent,
    ItemCheckComponent,
    ListNavigationV2Component,
    NavigationItemV2Component,
    PasswordComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ScoreCircleComponent,
    SelectComponent,
    SimpleTextInputComponent,
    SlideToggleComponent,
    SliderComponent,
    TimeComponent,
    InternationalPhoneComponent,
    MultiselectComponent,
    RangeSliderComponent,
    ModalFooterComponent,
    ModalContentComponent,
    ConfirmationDialogComponent,
    RejectionDialogComponent,
    ModalComponent,
    ModalV2Component,
    AppointmentsModalComponent,
    ConfirmReasonModalComponent,
    AttachCustomQuestionModalComponent,
    AvailableCustomQuestionComponent,
    CustomQuestionComponent,
    CustomQuestionDetailsComponent,
    CustomQuestionPreviewModalComponent,
    CustomQuestionFormComponent,
    CustomQuestionsFieldComponent,
    CustomQuestionsFormComponent,
    HierarchicalQuestionFormComponent,
    HierarchicalRootQuestionFormComponent,
    CustomQuestionsModalContentComponent,
    AvailableHierarchicalQuestionComponent,
    HierarchicalQuestionsDisplayRootComponent,
    HierarchicalQuestionsDisplayQuestionComponent,
    HierarchicalQuestionSelectionComponent,
    HierarchicalQuestionInputComponent,
    EditAttachmentComponent,
    TextFieldComponent,
    ImageComponent,
    BadgeComponent,
    CardComponent,
    ButtonComponent,
    BreadcrumbsComponent,
    MultiSelectGroupedDropdownComponent,
    SalesLegalCheckboxesPreviewComponent,
    InfoCollapseComponent,
    ApplicationConfirmationComponent,
    InfiniteScrollComponent,
    GroupedDistrictsDetailComponent,
    GroupedCityMapComponent,
    InformationBoxComponent,
    LocaleFlagComponent,
    QuotaInfoBadgeComponent,
    MultiSelectDropdownV2Component,
    SingleSelectDropdownComponent,
    TextEditorComponent,
    LoadMoreComponent,
    MultiSelectGroupedIntermediateDropdownComponent,
    SingleSelectDropdownStepperComponent,
    MultiSelectDropdownStepperComponent,
    DataTableCellDirective,
    DataTableHeaderDirective,
    DataTableComponent,
    DataTableCellComponent,
    BasicTableComponent,
    QuotaTableComponent,
    NavigationComponent,
    NavigationDrawerComponent,
    NavigationDrawerItemComponent,
    NavigationDrawerListComponent,
    NavigationUserProfileComponent,
    SmartInputFieldSetComponent,
    NoDataDisclaimerComponent,
    StatusPipelineBaseComponent,
    StatusInfoComponent,
    HeaderMobileComponent,
    ApplicationStatusPipelineComponent,
    ProjectStatusPipelineComponent,
    LandlordInfoComponent,
    SideSheetComponent,
    SideSheetContentDirective,
    SwitchComponent,
    MapComponent,
    SideModalComponent,
    SideModalContentDirective,
    TooltipComponent,
    TooltipDirective,
    TabNavigationComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SocialLoginComponent,
    FileUploadInputComponent,
    LangPickerComponent,
    SegmentComponent,
    LabelListComponent,
    LabelListTagComponent,
    GetAssignableContactTagsPipe,
    GetAssignableSystemTagsPipe,
    GetAssignablePropertyTagsPipe,
    TagComponent,
    FunnelComponent,
    PropertyCardComponent,
    PortalCardComponent,
    ProjectCardComponent,
    ServiceCardComponent,
    PropertyGroupCardComponent,
    SelectionCardComponent,
    RegisterCardComponent,
    AutoCompleteFieldComponent,
    LocationSearchFieldComponent,
    PhoneInputComponent,
    QuotaButtonComponent,
    QuotaStylePipe,
    QuotaIconPipe,
    QuotaTranslationPipe,
    QuotaNumberPipe,
    ElevationDirective,
    FocusFormErrorDirective,
    AutofocusDirective
  ],
  exports: [
    AutofocusDirective,
    CityAutocompleteComponent,
    DigitalContractSignerComponent,
    MobileIdTutorialComponent,
    SearchProfileAddressComponent,
    SearchProfileDetailsComponent,
    SearchProfileInfoComponent,
    SearchProfileLandlordDistrictsComponent,
    SearchProfilePropertyDetailsComponent,
    ColorPickerComponent,
    PageSizeSelectionComponent,
    AddressFormComponent,
    MessageComponent,
    LogoComponent,
    ListNavigationComponent,
    HightlightedBgComponent,
    WizardStepDirective,
    WizardProgressComponent,
    WizardComponent,
    WizardFooterComponent,
    CommentsComponent,
    FooterComponent,
    SocialsComponent,
    HeaderComponent,
    NoContentComponent,
    MatchControlValidator,
    HintComponent,
    PaginationComponent,
    AttachmentsListComponent,
    AttachmentItemComponent,
    AttachmentPreviewComponent,
    AttachmentItemV2Component,
    AttachmentPreviewV2Component,
    AttachmentsListV2Component,
    AttachmentsComponent,
    CalendarComponent,
    ToastContainerComponent,
    AdBlockInfoComponent,
    InvoicePaymentMethodComponent,
    SetPasswordFormComponent,
    LoadingSpinnerComponent,
    CookieBannerComponent,
    CustomCookieSettingsModalComponent,
    VerifyEmailBannerComponent,
    PerformanceWarningBannerComponent,
    InfoBoxComponent,
    AppClickAreaDirective,
    AppInputDirective,
    AvatarComponent,
    EditAttachmentComponent,
    ListControlsComponent,
    TableHeaderComponent,
    EmbeddedLinkComponent,
    MessengerComponent,
    ChatComponent,
    ChatContactItemComponent,
    ChatContactListComponent,
    ChatTextMessageComponent,
    ChatAttachmentComponent,
    ChatCreateModalComponent,
    ChatSettingsModalComponent,
    PropertyBannerComponent,
    PropertyParkingSpaceComponent,
    NewUpdateBannerComponent,
    MessageTemplatesComponent,
    MessageTemplateFormComponent,
    ProfileDkTwoComponent,
    GlobalCustomQuestionsComponent,
    ConfirmReasonComponent,
    AppointmentSelectionComponent,
    PropertyInterestComponent,
    HeadingComponent,
    UpdateModalComponent,
    HeaderV2Component,
    FooterV2Component,
    TenantProfileFormComponent,
    KnockoutCriteriaComponent,
    FieldsetDisabledComponent,
    LockableDataComponent,
    CheckComponent,
    CheckIntermediateComponent,
    CheckboxComponent,
    DateComponent,
    DropdownMultiselectComponent,
    DropdownSelectComponent,
    FlatSelectComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    FormFieldErrorComponent,
    FormFieldInfoComponent,
    FormFieldLangPickerComponent,
    ScoreCircleComponent,
    SimpleTextInputComponent,
    ConversationRestrictionConfigFormComponent,
    ConversationGeneralConfigFormComponent,
    SlideToggleComponent,
    ItemCheckComponent,
    ListNavigationV2Component,
    NavigationItemV2Component,
    PasswordComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ScoreCircleComponent,
    SelectComponent,
    SimpleTextInputComponent,
    SlideToggleComponent,
    SliderComponent,
    TimeComponent,
    InternationalPhoneComponent,
    MultiselectComponent,
    RangeSliderComponent,
    ModalFooterComponent,
    ModalContentComponent,
    ConfirmationDialogComponent,
    RejectionDialogComponent,
    ModalComponent,
    ModalV2Component,
    ChatCreateModalComponent,
    AppointmentsModalComponent,
    ConfirmReasonModalComponent,
    MultiSelectDropdownStepperComponent,
    MultiSelectGroupedIntermediateDropdownComponent,
    SingleSelectDropdownStepperComponent,
    ButtonComponent,
    CardComponent,
    TextFieldComponent,
    ImageComponent,
    BadgeComponent,
    BreadcrumbsComponent,
    MultiSelectGroupedDropdownComponent,
    SalesLegalCheckboxesPreviewComponent,
    InfoCollapseComponent,
    ApplicationConfirmationComponent,
    InfiniteScrollComponent,
    GroupedDistrictsDetailComponent,
    GroupedCityMapComponent,
    InformationBoxComponent,
    LocaleFlagComponent,
    QuotaInfoBadgeComponent,
    MultiSelectDropdownV2Component,
    SingleSelectDropdownComponent,
    TextEditorComponent,
    LoadMoreComponent,
    DataTableCellDirective,
    DataTableHeaderDirective,
    DataTableComponent,
    DataTableCellComponent,
    BasicTableComponent,
    QuotaTableComponent,
    NavigationComponent,
    NavigationDrawerComponent,
    NavigationDrawerItemComponent,
    NavigationDrawerListComponent,
    NavigationUserProfileComponent,
    SmartInputFieldSetComponent,
    NoDataDisclaimerComponent,
    StatusPipelineBaseComponent,
    StatusInfoComponent,
    HeaderMobileComponent,
    ApplicationStatusPipelineComponent,
    ProjectStatusPipelineComponent,
    LandlordInfoComponent,
    SideSheetComponent,
    SideSheetContentDirective,
    SwitchComponent,
    MapComponent,
    SideModalComponent,
    SideModalContentDirective,
    TooltipComponent,
    TooltipDirective,
    TabNavigationComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SocialLoginComponent,
    FileUploadInputComponent,
    LangPickerComponent,
    SegmentComponent,
    LabelListComponent,
    LabelListTagComponent,
    GetAssignableContactTagsPipe,
    GetAssignableSystemTagsPipe,
    GetAssignablePropertyTagsPipe,
    TagComponent,
    FunnelComponent,
    PropertyCardComponent,
    PortalCardComponent,
    ProjectCardComponent,
    ServiceCardComponent,
    PropertyGroupCardComponent,
    SelectionCardComponent,
    RegisterCardComponent,
    AutoCompleteFieldComponent,
    LocationSearchFieldComponent,
    PhoneInputComponent,
    QuotaButtonComponent,
    QuotaStylePipe,
    QuotaIconPipe,
    QuotaTranslationPipe,
    QuotaNumberPipe,
    ElevationDirective,
    FocusFormErrorDirective
  ],
  providers: [
    ModalService,
    ToastService,
    MessengerFilterService,
    MessengerNotificationService,
    KeyValuePipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class ComponentsModule {
  public static forRoot(
    config: ComponentsModuleConfig = {}
  ): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
      providers: [
        {
          provide: GOOGLE_MAPS_API_CONFIG,
          useValue: config.googleApiConfig || {}
        },
        DateTimeService,
        GeoService,
        MessengerNotificationService
      ]
    };
  }
}
