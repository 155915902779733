import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';
import { ThemeService } from '../../../infrastructure/theme';

@Component({
  selector: 'app-application-confirmation',
  templateUrl: './application-confirmation.component.html',
  styleUrls: ['./application-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslateModule]
})
export class ApplicationConfirmationComponent {
  private themeService = inject(ThemeService);

  readonly header = input<string>(undefined);
  readonly description = input<string>(undefined);
  readonly buttonText = input<string>(undefined);
  readonly showButton = input(true);
  readonly buttonClicked = output();

  public get logo(): string {
    return (
      this.themeService?.getActiveTheme?.logo?.url ||
      '/assets/images/logos/logo-immomio-main.svg'
    );
  }
}
