import { Component, input } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  imports: [NgClass, NgTemplateOutlet]
})
export class HeadingComponent {
  readonly level = input<1 | 2 | 3 | 4>(undefined);
  readonly styleType = input<100 | 200>(undefined);

  private baseClass = 'heading';

  private _styleTypeClass(): string {
    const styleType = this.styleType();
    return styleType && `${this.baseClass}--${styleType}`;
  }

  private _makeIterative(obj: any): [any] | [] {
    return obj ? [obj] : [];
  }

  public classString(): string {
    return [
      this.baseClass,
      ...this._makeIterative(this._styleTypeClass())
    ].join(' ');
  }
}
