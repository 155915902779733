import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../../components';

@Component({
  selector: 'app-downgrade-modal',
  templateUrl: './downgrade-modal.component.html',
  styleUrls: ['./downgrade-modal.component.scss'],
  imports: [ComponentsModule, TranslateModule]
})
export class DowngradeModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
