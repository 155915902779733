<form [formGroup]="form">
  <app-form-field>
    <app-form-field-label>{{
      'PORTAL_NAME_L' | translate
    }}</app-form-field-label>
    <input
      appInput
      type="text"
      required
      [placeholder]="'TYPE_PORTAL_NAME_L' | translate"
      formControlName="name"
      class="form-control mb10"
    />
  </app-form-field>
  <ng-container formGroupName="properties">
    <app-form-field>
      <app-form-field-label>{{
        portalConfig().userNameLabel | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        required
        [placeholder]="portalConfig().userNameLabel | translate"
        formControlName="username"
        class="form-control mb10"
      />
    </app-form-field>
    <app-form-field>
      <app-form-field-label>{{
        portalConfig().passwordLabel | translate
      }}</app-form-field-label>
      <input
        appInput
        type="password"
        required
        autocomplete="new-password"
        [placeholder]="portalConfig().passwordLabel | translate"
        formControlName="password"
        class="form-control mb10"
      />
    </app-form-field>
  </ng-container>
</form>
