export enum ResidentMailActionType {
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  INVITE_PARTNER = 'INVITE_PARTNER'
}

export enum ActionStatusString {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NONE = 'NONE'
}
