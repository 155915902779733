import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-email-banner',
  templateUrl: './verify-email-banner.component.html',
  styleUrls: ['./verify-email-banner.component.scss'],
  imports: [TranslateModule]
})
export class VerifyEmailBannerComponent {
  public readonly emailResent = input(false);
  public readonly emailVerified = input(false);

  public readonly resend = output<void>();

  public onResend() {
    this.resend.emit();
  }
}
