<div class="banner__container">
  <div class="banner__message-container d-flex">
    @if (!emailResent() && !emailVerified()) {
      <span class="banner__message d-flex"
        >{{ 'user.verify_email_message' | translate }}&nbsp;</span
      >
    }
    @if (!emailResent() && !emailVerified()) {
      <span class="banner__resend-link d-flex" (click)="onResend()">{{
        'user.resend_verification_email_a' | translate
      }}</span>
    }
    @if (emailResent() && !emailVerified()) {
      <span class="banner__message d-flex">{{
        'user.confirm_email_message' | translate
      }}</span>
    }
    @if (emailVerified()) {
      <span class="banner__message d-flex">{{
        'user.email_verified_message' | translate
      }}</span>
    }
  </div>
</div>
