import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  Input,
  input
} from '@angular/core';
import { coerceBooleanProperty } from '../../utils';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  private elementRef = inject(ElementRef);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private _appAutofocus = true;

  readonly forceAutofocus = input(false);

  @Input()
  set appAutofocus(value: boolean) {
    this._appAutofocus = coerceBooleanProperty(value);
  }

  ngAfterViewInit() {
    if (this._appAutofocus) {
      const el = this.elementRef.nativeElement as HTMLInputElement;

      if ((el && !el.value) || this.forceAutofocus()) {
        el.focus();
        this.changeDetectorRef.detectChanges();
      }
    }
  }
}
