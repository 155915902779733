import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input
} from '@angular/core';

import { PropertyType } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SCREEN_LARGE_MIN } from '../../../config';
import {
  buildBEMClassNamesByGivenBaseClassAndFlags,
  isTouchScreen
} from '../../../utils';
import {
  CurrentStatusConfig,
  PipelineSteps
} from './status-pipeline-base.config';

@Component({
  selector: 'app-status-pipeline',
  templateUrl: './status-pipeline-base.component.html',
  styleUrls: ['./status-pipeline-base.component.scss'],
  imports: [NgbTooltip, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusPipelineBaseComponent {
  readonly pageView = input(false);
  readonly steps = input<PipelineSteps>(undefined);
  readonly currentStatus = input<CurrentStatusConfig>(undefined);
  readonly propertyType = input<PropertyType>(PropertyType.FLAT);

  readonly pipelineSteps = computed(() => {
    const {
      stepId: currentStatusStepId,
      tooltip: currentStatusTooltip,
      label: currentStatusLabel,
      showAsFullscreen: currentStatusShowAsFullscreen
    } = this.currentStatus().pipeline;

    return this.steps().map((step, stepId) => {
      const isActive = stepId === currentStatusStepId;
      return {
        ...step,
        isActive,
        label: isActive && currentStatusLabel ? currentStatusLabel : step.label,
        hasPassed:
          currentStatusShowAsFullscreen || stepId < currentStatusStepId,
        tooltip: isActive ? currentStatusTooltip : step.label
      };
    });
  });

  public baseClass = 'status-pipeline-base';

  public get narrowScreen(): boolean {
    return !this.pageView() || window.innerWidth < SCREEN_LARGE_MIN;
  }

  public get tooltipOpenDelay(): number {
    return isTouchScreen() ? 0 : 1000;
  }

  public get tooltipCloseDelay(): number {
    return isTouchScreen() ? 0 : 300;
  }

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      'page-view': this.pageView()
    });
  }

  public getStepListClassName(hidden: boolean): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__step-list`,
      {
        hidden
      }
    );
  }

  public getStepItemClassName(
    first: boolean,
    last: boolean,
    active: boolean,
    passed: boolean
  ): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__step-item`,
      {
        first,
        last,
        active,
        passed
      }
    );
  }

  public getFullscreenStatusClassName(
    hide: boolean,
    isTenant: boolean
  ): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__fullscreen-status`,
      {
        'color-grey': hide,
        'color-green': isTenant
      }
    );
  }

  public getStepWrapperClassName(first: boolean, last: boolean): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__step-wrapper`,
      {
        first,
        last
      }
    );
  }
}
