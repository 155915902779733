import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { of } from 'rxjs';

import { Apollo } from 'apollo-angular';

import { getResponseValidator } from '@ui/legacy-lib';
import {
  portalCredentialsQuery,
  PortalCredentialsQueryResult
} from '../queries';
import * as fromActions from './portals.actions';

@Injectable()
export class PortalsEffects {
  private actions$ = inject(Actions);
  private apollo = inject(Apollo);

  portalFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FETCH_PORTALS),
      map((action: fromActions.FetchPortals) => action),
      switchMap(action =>
        this.apollo
          .query({
            query: portalCredentialsQuery,
            variables: {
              filter: { size: 50, page: 0, sort: 'NAME,ASC' },
              id: action.id
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<PortalCredentialsQueryResult>()),
            map(
              result =>
                new fromActions.FetchPortalsSuccess(
                  result.data.portalCredentials.nodes
                )
            ),
            catchError(err => of(new fromActions.FetchPortalsFail(err)))
          )
      )
    )
  );
}
