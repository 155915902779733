import { Directive, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel
} from '@angular/forms';

/**
 * NoopValueAccessorDirective is used when we want to direct form control from parent component to child component,
 * without the need of implementing ControlValueAccessor for the child component.
 *
 * usage: add to hostDirectives array of the component.
 */

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoopValueAccessorDirective
    }
  ]
})
export class NoopValueAccessorDirective implements ControlValueAccessor {
  /* eslint-disable @typescript-eslint/no-empty-function */
  writeValue(_: unknown) {}

  registerOnChange(_: unknown) {}

  registerOnTouched(_: unknown) {}

  /* eslint-enable @typescript-eslint/no-empty-function */
}

/**
 * injectNgControl function is used to inject NgControl instance assigned to the host component
 *  check {@link DatePickerComponent} for example
 */

export function injectNgControl() {
  const ngControl = inject(NgControl, { self: true, optional: true });

  if (!ngControl) throw new Error('No control provided!');

  if (
    ngControl instanceof FormControlDirective ||
    ngControl instanceof FormControlName ||
    ngControl instanceof NgModel
  ) {
    return ngControl;
  }

  throw new Error(
    'Control not instance of FormControlDirective, FormControlName or NgModel!'
  );
}
