import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from '../../../form/controls/select/select.component';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';

@Component({
  selector: 'app-page-size-selection',
  templateUrl: './page-size-selection.component.html',
  styleUrls: ['./page-size-selection.component.scss'],
  imports: [
    FormFieldComponent,
    FormFieldLabelComponent,
    SelectComponent,
    FormsModule,
    TranslateModule
  ]
})
export class PageSizeSelectionComponent {
  readonly pageSize = input<number>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly forceDisplay = input<boolean>(undefined);
  readonly selectableSizes = input<number[]>(undefined);

  readonly sizeChange = output<number>();

  onSizeChange(pageNumber: number) {
    this.sizeChange.emit(pageNumber);
  }
}
