import { Action } from '@ngrx/store';

import { Prioset } from '@ui/shared/models';

export const UPDATE_PRIOSET = '[Priosets] Update Prioset';
export const UPDATE_PRIOSET_SUCCESS = '[Priosets] Update Prioset Success';
export const UPDATE_PRIOSET_FAIL = '[Priosets] Update Prioset Fail';

export const CLEAR_LATEST_PRIOSET_SAVED =
  '[Priosets] Clear Latest Prioset Saved';

export class UpdatePrioset implements Action {
  readonly type = UPDATE_PRIOSET;
  constructor(public prioset: Prioset) {}
}

export class UpdatePriosetSuccess implements Action {
  readonly type = UPDATE_PRIOSET_SUCCESS;
  constructor(public prioset: Prioset) {}
}

export class UpdatePriosetFail implements Action {
  readonly type = UPDATE_PRIOSET_FAIL;
  constructor(public error: string) {}
}

export class ClearLatestSavedPrioset implements Action {
  readonly type = CLEAR_LATEST_PRIOSET_SAVED;
}

export type PriosetAction =
  | UpdatePrioset
  | UpdatePriosetSuccess
  | UpdatePriosetFail
  | ClearLatestSavedPrioset;
