<div class="container messenger">
  <div
    class="messenger-container d-flex"
    [ngClass]="{ 'mobile-chat': isMobile, 'tablet-chat': isTablet }"
    [@slide]="(isMobile || isTablet) && activePane"
  >
    <app-chat-contact-list
      [class.isDesktop]="!isMobile && !isTablet"
      [totalMessageCount]="totalMessageCount$ | async"
      [conversationList]="contactList$ | async"
      [conversationListPage]="contactListPage$ | async"
      [contactListActionState]="contactListActionState$ | async"
      [messagesActionState]="messagesActionState()"
      [activeConversation]="activeConversation"
      [isLandlord]="isLandlord"
      [isPropertySearcher]="isPropertySearcher"
      [isAgent]="isLandlord && isAgent"
      [customerSettings]="customerSettings()"
      [agentsFilter]="agentsFilter()"
      [loadArchivedConversationsToggle]="loadArchivedConversationsToggle"
      [conversationDetails]="conversationDetails()"
      [searchedProperty]="searchedProperty$ | async"
      (selectConversation)="onSelectConversation($event)"
      (reloadMessenger)="onReloadMessenger()"
      (openChatSettingsModal)="onOpenSettings()"
      (addConversation)="onAddConversation()"
      (archivedConversations)="loadArchivedConversations()"
      (activeConversations)="loadActiveConversations()"
      (removePropertyFilter)="removePropertyFilter()"
    ></app-chat-contact-list>
    <app-chat
      [sender]="sender()"
      [canAnswer]="canAnswer()"
      [messagesActionState]="messagesActionState()"
      [messages]="messages$ | async"
      [messagesAttach]="messagesAttach$ | async"
      [isLandlord]="isLandlord"
      [isPropertySearcher]="isPropertySearcher"
      [activeConversation]="activeConversation"
      [conversationDetails]="conversationDetails()"
      [isMobile]="isMobile"
      [isTablet]="isTablet"
      [templates]="template$ | async"
      [hasOldMessages]="(conversationMessagesPagination$ | async)?.hasNext"
      [allowAttachmentUpload]="isApplication()"
      [showMessageTemplates]="isApplication() && isLandlord"
      (setActivePane)="onSetActivePane($event)"
      (sendMessage)="onSendMessage($event)"
      (fetchOldMessages)="onFetchOldMessages()"
      (download)="onDownloadDocument($event)"
      (preview)="onPreviewDocumentInNewTab($event)"
      (createMessageAsTemplate)="onCreateMessageAsTemplate($event)"
      (scrollBottom)="onScrollBottom()"
      (calcHeight)="onCalcHeight()"
    ></app-chat>
    <div class="d-flex info-container">
      @if (isMobile || isTablet) {
        <div class="mobile-chat-nav d-flex ios-padding-left ios-padding-right">
          <app-button
            class="mobile-nav-item"
            (clickEvent)="onSetActivePane(paneTypes.CHAT)"
            [iconLeft]="'arrow-left'"
            [type]="'light-bordered'"
          >
            <span class="pl5">{{ 'messenger.return_a' | translate }}</span>
          </app-button>
        </div>
      }
      <div class="side-info" [ngClass]="sender().toLowerCase()">
        <ng-content select="[side-info]"></ng-content>
      </div>
    </div>
  </div>
</div>
