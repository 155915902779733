import {
  Component,
  ChangeDetectionStrategy,
  input,
  output
} from '@angular/core';

import { Attachment } from '@ui/shared/models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass]
})
export class AttachmentPreviewComponent {
  public readonly imgSrc = input<string>(undefined);
  public readonly isDocument = input(false);
  public readonly attachment = input<Attachment>(undefined);

  public readonly preview = output<Attachment>();
  public readonly download = output<Attachment>();

  public previewOpen = false;

  public togglePreview() {
    this.previewOpen = !this.previewOpen;
  }

  public openPreview() {
    this.previewOpen = true;
  }

  public closePreview() {
    this.previewOpen = false;
  }

  public isPdf(attachment: Attachment) {
    return (
      attachment.type === 'PDF' ||
      (attachment.title && attachment.title.toLowerCase().endsWith('.pdf'))
    );
  }

  public clickOnPdf() {
    const attachment = this.attachment();
    if (attachment.url) {
      this.preview.emit(attachment);
    }
  }

  public clickOnImage() {
    const attachment = this.attachment();
    if (attachment.url) {
      this.download.emit(attachment);
    }
  }
}
