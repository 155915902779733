<app-form-field class="inline">
  <app-form-field-label [showRequiredMarker]="true">{{
    'general.city_or_zipcode_l' | translate
  }}</app-form-field-label>
  <app-auto-complete-field
    [selector$]="citiesSelector$"
    [inputWatcher]="cityGroup?.get('name')?.valueChanges"
    [readonly]="disableCityEdit()"
    [loading]="isLoading$ | async"
    (onSelect)="onSelect($event)"
    (onInputChange)="onNewInput($event)"
  >
    <input
      appInput
      type="text"
      autocomplete="none"
      [placeholder]="'forms.type_city_or_zip' | translate"
      [formControl]="cityGroup.controls.name"
      [readonly]="disableCityEdit()"
      class="form-control"
      [ngClass]="{ 'cursor-default': disableCityEdit() }"
    />
    <ng-template let-item>{{
      item.state && item.name ? item.name + ', ' + item.state : item.name
    }}</ng-template>
  </app-auto-complete-field>
</app-form-field>
