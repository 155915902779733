import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, OnChanges, input } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Gallery,
  GalleryComponent as NgGalleryComponent,
  GalleryConfig,
  GalleryItem,
  GalleryState,
  ImageItem,
  ImageSize,
  ThumbnailsPosition
} from 'ng-gallery';

import { Attachment } from '@ui/shared/models';

import { AsyncPipe, NgStyle } from '@angular/common';
import { ImageSizePipe } from '../../pipes';
import { mobileBreakingPoints } from '../../components/legacy/messenger/model';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  providers: [ImageSizePipe],
  imports: [NgGalleryComponent, NgStyle, AsyncPipe]
})
export class GalleryComponent implements OnChanges {
  private gallery = inject(Gallery);
  private imageSizePipe = inject(ImageSizePipe);
  private breakpointObserver = inject(BreakpointObserver);

  public items: GalleryItem[];
  public galleryConfig$: Observable<GalleryConfig>;
  public styleObject: Record<string, string> = {};

  readonly attachments = input<Attachment[]>([]);

  public ngOnChanges() {
    this.items = this.attachments().map(attachment => {
      return new ImageItem({
        src: attachment?.url,
        thumb: this.imageSizePipe.transform(attachment, 'S')
      });
    });

    // Get a blurry background image
    this.styleObject['width'] = '100%';
    this.styleObject['height'] = '100%';
    this.styleObject['filter'] = 'blur(8px)';

    if (this.items && this.items[0]?.data?.src) {
      this.styleObject['background-image'] =
        'url(' + String(this.items[0]?.data?.src) + ')';
    }

    this.galleryConfig$ = this.breakpointObserver
      .observe(mobileBreakingPoints)
      .pipe(
        map(res => {
          if (res.matches) {
            return {
              thumb: false,
              dots: true
            };
          }
          return {
            thumbPosition: ThumbnailsPosition.Bottom,
            thumbWidth: 80,
            thumbHeight: 80,
            thumb: true,
            dots: false
          };
        })
      );

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);
  }

  // Change blurry bg image when gallery moves to another picture
  changeBackgroundImage(galleryState: GalleryState) {
    if (this.items) {
      this.styleObject['background-image'] =
        'url(' + String(this.items[galleryState.currIndex]?.data?.src) + ')';
    }
  }
}
