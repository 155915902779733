<div>
  {{
    districtGroup().groupedDistrictsName
      ? districtGroup().groupedDistrictsName + ': '
      : ''
  }}
  @for (
    district of districtGroup().districts
      | slice
        : 0
        : (showAllCityParts ? districtGroup().districts.length : splitLength);
    track district.name;
    let i = $index
  ) {
    <span
      >{{ district.name }}
      @if (i < districtGroup()?.districts?.length - 1) {
        ,
      }
    </span>
  }
  @if (districtGroup().districts.length > splitLength) {
    <p
      (click)="toggleShowAllCityParts()"
      class="mt-2 search-profile__districts__show-more"
    >
      {{
        (showAllCityParts
          ? 'search.show_less_a'
          : 'search_profile.load_more_city_parts_l'
        ) | translate: { count: districtGroup().districts.length - splitLength }
      }}
    </p>
  }
</div>
