import { Component, input } from '@angular/core';
import { PropertyApplicationStatus } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BANNER_CONFIGS } from './property-banner.config';

@Component({
  selector: 'app-property-banner',
  templateUrl: './property-banner.component.html',
  styleUrls: ['./property-banner.component.scss'],
  imports: [NgbTooltip, NgClass, TranslateModule]
})
export class PropertyBannerComponent {
  readonly applicantStatus = input<string>(undefined);
  readonly showExplanation = input(false);

  get bannerInfo() {
    return BANNER_CONFIGS[
      this.applicantStatus() || PropertyApplicationStatus.NEW_MATCH
    ];
  }
}
