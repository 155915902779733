import { Directive, inject, TemplateRef, input } from '@angular/core';

@Directive({
  selector: '[appSideModalContent]'
})
export class SideModalContentDirective {
  template = inject<TemplateRef<any>>(TemplateRef);

  readonly name = input<string>(undefined);
  readonly navName = input<string>(undefined);
}
