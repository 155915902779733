import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { RootQuestionContainerModel, ContactTag } from '@ui/shared/models';
import { FormsModule } from '@angular/forms';
import { CheckComponent } from '../../form/controls/check/check.component';
import { HierarchicalQuestionsDisplayRootComponent } from '../hierarchical-questions-display-root/hierarchical-questions-display-root.component';

@Component({
  selector: 'app-available-hierarchical-question',
  templateUrl: './available-hierarchical-question.component.html',
  styleUrls: ['./available-hierarchical-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HierarchicalQuestionsDisplayRootComponent,
    CheckComponent,
    FormsModule
  ]
})
export class AvailableHierarchicalQuestionComponent {
  readonly hierarchicalQuestion = input<RootQuestionContainerModel>(undefined);
  readonly tags = input<ContactTag[]>(undefined);
  readonly selectedChange = output<boolean>();
  public selected = false;

  public onSelectedChange(value: boolean) {
    this.selectedChange.emit(value);
  }
}
