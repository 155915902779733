<ng-template #menuContent>
  <div class="context-menu__content" (click)="handleInsideClick($event)">
    <ng-content select="[menu-content]"></ng-content>
  </div>
</ng-template>

<!-- Tooltip Menu Button Variant -->
<div
  [class.w-100]="buttonUseFullContainerSize()"
  [class.h-100]="buttonUseFullContainerSize()"
  [ngbPopover]="menuContent"
  [placement]="[tooltipPlacement(), 'auto']"
  [popoverClass]="container() === 'body' ? 'context-menu' : undefined"
  [autoClose]="closeOnItemClick()"
  [container]="container()"
  #popover="ngbPopover"
  (document:click)="popover.close()"
  (document:touch)="popover.close()"
  (click)="$event.stopPropagation()"
>
  <div
    #customButton
    class="context-menu__custom-button"
    (click)="openContextMenu($event, content)"
  >
    <ng-content select="[menu-button]"></ng-content>
  </div>

  @if (!customButton.children.length) {
    <app-button
      class="context-menu__button"
      [type]="ButtonTypeEnum.CONTEXT_MENU"
      [iconLeft]="buttonMenuIcon()"
      [elevation]="buttonElevation()"
      [ghost]="ghostButton()"
      [disabled]="disabled()"
      [borderRadius]="borderRadius()"
      [useFullContainerSize]="buttonUseFullContainerSize()"
      [zeroPadding]="zeroPadding()"
      [disableHover]="disableHover()"
      (click)="openContextMenu($event, content)"
      [ngbTooltip]="tooltip() | translate"
      [placement]="tooltipPlacement()"
      [tooltipClass]="'context-menu__tooltip'"
      [openDelay]="1000"
    >
    </app-button>
  }
</div>

<!-- Side Nav Menu Variant -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header mt-5">
    <div
      class="d-flex flex-nowrap flex-row align-items-center w-100"
      [ngClass]="
        this.sideNavMenuTitle()
          ? 'justify-content-between'
          : 'justify-content-end'
      "
    >
      @if (this.sideNavMenuTitle()) {
        <span class="title-s ms-2"> {{ sideNavMenuTitle() }} </span>
      }
      <app-button
        class="context-menu__side-nav-close-button"
        [type]="ButtonTypeEnum.CONTEXT_MENU"
        [iconLeft]="IconTypeEnum.Close"
        [ghost]="true"
        (click)="closeContextMenu()"
      >
      </app-button>
    </div>
  </div>
  <div class="offcanvas-body">
    <div
      appClickDetector
      [enabled]="sideNavOpened"
      (insideClick)="closeOnItemClick() && closeContextMenu()"
    >
      <ng-container *ngTemplateOutlet="menuContent"></ng-container>
    </div>
  </div>
</ng-template>
