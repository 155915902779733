<form [formGroup]="form">
  <app-form-field>
    <app-form-field-label>{{
      'PORTAL_NAME_L' | translate
    }}</app-form-field-label>
    <input
      appInput
      type="text"
      required
      [placeholder]="'TYPE_PORTAL_NAME_L' | translate"
      formControlName="name"
      class="form-control mb10"
    />
  </app-form-field>
  <ng-container formGroupName="properties">
    <app-form-field>
      <app-form-field-label>{{
        portalConfig().userNameLabel | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        required
        [placeholder]="portalConfig().userNameLabel | translate"
        formControlName="username"
        class="form-control mb10"
      />
    </app-form-field>
    <app-form-field>
      <app-form-field-label>{{
        portalConfig().passwordLabel | translate
      }}</app-form-field-label>
      <input
        appInput
        type="password"
        required
        autocomplete="new-password"
        [placeholder]="portalConfig().passwordLabel | translate"
        formControlName="password"
        class="form-control mb10"
      />
    </app-form-field>
    <span class="ftp-settings-toggler app-link" (click)="toggleFtpSettings()">{{
      'general.advanced_configuration' | translate
    }}</span>
    @if (showFtpSettings) {
      <app-form-field>
        <app-form-field-label>{{
          'general.ftp_host_l' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="text"
          required
          [placeholder]="'forms.type_ftp_host' | translate"
          formControlName="host"
          class="form-control mb10"
        />
      </app-form-field>
      <app-form-field>
        <app-form-field-label>{{
          'general.type_ftp_port' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="number"
          required
          [placeholder]="'forms.type_ftp_port' | translate"
          formControlName="port"
          class="form-control mb10"
        />
      </app-form-field>
      <app-form-field>
        <app-form-field-label>{{
          'general.ftp_type_l' | translate
        }}</app-form-field-label>
        <app-select
          [placeholder]="'forms.select_ftp_type' | translate"
          [items]="ftpTypes"
          formControlName="type"
        ></app-select>
      </app-form-field>
      <div class="check-ftp-connection-wrapper d-flex">
        <app-button
          [disabled]="checkingFtp"
          (clickEvent)="checkFtpConnection()"
          [type]="'light-bordered'"
          >{{ 'general.check_ftp_connection_a' | translate }}</app-button
        >
        <div class="status-wrapper">
          @if (!checkingFtp && validFtpConnection) {
            <img src="/assets/images/icons/icon-checkbox-green.svg" />
          }
          @if (checkingFtp) {
            <app-loading-spinner
              class="loading-indicator"
            ></app-loading-spinner>
          }
          @if (!checkingFtp && invalidFtpConnection) {
            <span class="invalid-ftp-connection-message">{{
              'errors.invalid_ftp_connection' | translate
            }}</span>
          }
        </div>
      </div>
    }
  </ng-container>
</form>
