import { createSelector } from '@ngrx/store';

import { getPropertySearcherState } from '../reducers';
import * as fromPropertySearcherReducer from './property-searcher.reducers';

export const getPropertySearcherUsersData = createSelector(
  getPropertySearcherState,
  fromPropertySearcherReducer.getPropertySearcherUserData
);

export const getPropertySearcherLoading = createSelector(
  getPropertySearcherState,
  fromPropertySearcherReducer.getPropertySearcherLoading
);

export const getPropertySearcherLoaded = createSelector(
  getPropertySearcherState,
  fromPropertySearcherReducer.getPropertySearcherLoaded
);

export const getPropertySearcherError = createSelector(
  getPropertySearcherState,
  fromPropertySearcherReducer.getError
);

export const getPropertySearcherpagination = createSelector(
  getPropertySearcherState,
  fromPropertySearcherReducer.getpagination
);
