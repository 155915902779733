import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  input,
  output
} from '@angular/core';

import { combineLatest, skip } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';

import { AnimationEvent } from '@angular/animations';
import { LetDirective } from '@ngrx/component';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { fadeAnimation, FadeAnimationStateEnum } from '../../../utils';
import { NavigationService } from './navigation.service';
import { NavigationAlignment } from './navigation.model';
import { NavigationDrawerComponent } from './navigation-drawer/navigation-drawer.component';

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NavigationService],
  imports: [
    NgTemplateOutlet,
    NavigationDrawerComponent,
    LetDirective,
    AsyncPipe
  ]
})
export class NavigationComponent implements OnInit, OnDestroy, OnChanges {
  navigation = inject(NavigationService);

  /**
   * Activates dark mode.
   */
  readonly dark = input<boolean>(undefined);

  /**
   * Defines whether the whole navigation should always overlay the main content instead of displacing it.
   */
  readonly overlay = input<boolean>(undefined);

  /**
   * Defines the screen side the navigation should appear at.
   */
  readonly alignment = input<NavigationAlignment>(undefined);

  /**
   * Defines whether the main navigation should appear narrow initially.
   */
  readonly narrow = input<boolean>(undefined);
  readonly narrowManualChange = output<boolean>();

  /**
   * Defines whether the main navigation should be open initially.
   */
  readonly open = input<boolean>(undefined);
  readonly openChange = output<boolean>();
  readonly closedNavigationAnimationEnded = output<void>();

  /**
   * All navigation items starting from root level with nested children.
   */
  readonly navigationItems = input<NavigationItem[]>(undefined);

  public get backdropVisible$() {
    return combineLatest(
      [
        this.navigation.open$,
        this.navigation.overlay$,
        this.navigation.mobileView$
      ],
      (open, overlay, mobileView) => open && (overlay || mobileView)
    );
  }

  private _closeOnOutsideClick: boolean;
  private _closeFloatingSubMenuOnOutsideClick: boolean;
  private _clickedInside = false;

  @HostListener('click')
  public clickInside() {
    this._clickedInside = true;
  }

  @HostListener('document:click')
  public clickout() {
    if (!this._clickedInside) {
      if (this._closeOnOutsideClick) {
        this.navigation.close();
      } else if (this._closeFloatingSubMenuOnOutsideClick) {
        this.navigation.closeFloatingSubMenu();
      }
    }
    this._clickedInside = false;
  }

  public ngOnInit() {
    const {
      dark: darkInput,
      overlay: overlayInput,
      alignment: alignmentInput,
      narrow: narrowInput,
      open: openInput,
      navigationItems: navigationItemsInput
    } = this;
    const dark = darkInput();
    const overlay = overlayInput();
    const alignment = alignmentInput();
    const narrow = narrowInput();
    const open = openInput();
    const navigationItems = navigationItemsInput();

    this.navigation.init({
      dark,
      overlay,
      alignment,
      narrow,
      open,
      navigationItems
    });

    this.navigation.open$.pipe(untilDestroyed(this)).subscribe(open => {
      this.openChange.emit(open);
    });

    this.navigation.openFloatingSubMenu$
      .pipe(untilDestroyed(this))
      .subscribe(
        openFloatingSubMenu =>
          (this._closeFloatingSubMenuOnOutsideClick = openFloatingSubMenu)
      );

    this.navigation.narrowManualChange$
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(narrow => this.narrowManualChange.emit(narrow));

    combineLatest([
      this.navigation.open$,
      this.navigation.overlay$,
      this.navigation.mobileView$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([open, overlay, mobileView]) => {
        this._closeOnOutsideClick = open && (overlay || mobileView);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.navigation.initialised) return;

    if (changes.open) {
      if (this.open()) {
        this.navigation.open();
      } else {
        this.navigation.close();
      }
    }

    if (changes.navigationItems) {
      this.navigation.setNavigationItems(this.navigationItems());
    }

    if (changes.dark) {
      this.navigation.setDark(this.dark());
    }

    if (changes.overlay) {
      this.navigation.setOverlay(this.overlay());
    }

    if (changes.alignment) {
      this.navigation.setAlignment(this.alignment());
    }

    if (changes.narrow) {
      this.navigation.setNarrow(this.narrow());
    }
  }

  public ngOnDestroy() {
    this.navigation.complete();
  }

  public onBackdropClick(): void {
    this.navigation.close();
  }

  public fadeDone(e: AnimationEvent) {
    if (e.toState === FadeAnimationStateEnum.HIDE) {
      this.closedNavigationAnimationEnded.emit();
    }
  }
}
