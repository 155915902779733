<div class="row col-12">
  <p class="default-s confirm-reason__message">
    {{ 'confirm_reason_modal.select_reason_l' | translate }}
  </p>
  <div class="confirm-reason__reason-list">
    <app-radio-group
      class="ms-auto"
      [isUnselectable]="false"
      [(ngModel)]="reasonTypeModel"
      (ngModelChange)="onReasonTypeModelChange($event)"
      [ngModelOptions]="{ standalone: true }"
    >
      @for (reason of reasons(); track reason; let i = $index) {
        <app-radio-button [value]="reason.value" class="me-3">
          {{ reason.name | translate }}
        </app-radio-button>
      }
    </app-radio-group>
  </div>
</div>

@if (showText) {
  <div class="mt20 row col-12">
    <app-form-field>
      <app-form-field-label
        >{{ 'confirm_reason_modal.other_reason_l' | translate }}
      </app-form-field-label>
      <textarea
        appInput
        class="form-control"
        type="text"
        max="500"
        required
        (ngModelChange)="onOtherReasonTextChange($event)"
        [(ngModel)]="otherReasonText"
      >
      </textarea>
    </app-form-field>
  </div>
}
