import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  OnInit,
  output,
  viewChild
} from '@angular/core';

import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  ConditionId,
  Constants,
  ContactTag,
  CustomQuestion,
  DropdownStepperParent,
  getWbsOptions,
  HousingPermissionType,
  NameValue,
  Prioset,
  RootQuestionContainerModel,
  StateOfGermany,
  TimeInterval
} from '@ui/shared/models';

import {
  deleteHierarchicalQuestionDialogConfig,
  removeCustomQuestionFromPrioSetDialogConfig
} from '../../../config';
import { ButtonComponent } from '../../atoms/button/button.component';
import { HierarchicalQuestionsDisplayRootComponent } from '../custom-questions/hierarchical-questions-display-root/hierarchical-questions-display-root.component';
import { CustomQuestionComponent } from '../custom-questions/custom-question/custom-question.component';
import { SelectComponent } from '../form/controls/select/select.component';
import { SliderComponent } from '../form/controls/slider/slider.component';
import { MultiSelectDropdownStepperComponent } from '../../atoms/multi-select-dropdown-stepper/multi-select-dropdown-stepper.component';
import { FlatSelectComponent } from '../form/controls/flat-select/flat-select.component';
import { DropdownMultiselectComponent } from '../form/controls/dropdown-multiselect/dropdown-multiselect.component';
import { HintComponent } from '../hint/hint.component';
import { AppInputDirective } from '../form/controls/input/input.directive';
import { FormFieldLabelComponent } from '../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';
import { FieldsetDisabledComponent } from '../fieldset-disabled/fieldset-disabled.component';
import { TenantProfileFormService } from '../../../services/tenant-profile-form.service';
import {
  FormControlErrorsDirective,
  FormSubmitDirective
} from '../../../directives';
import { ModalService } from '../modal';
import { AttachCustomQuestionModalComponent } from '../custom-questions/attach-custom-question-modal/attach-custom-question-modal.component';
import { KnockoutCriteriaComponent } from './components/knockout-criteria/knockout-criteria.component';

@UntilDestroy()
@Component({
  selector: 'app-tenant-profile-form',
  templateUrl: './tenant-profile-form.component.html',
  styleUrls: ['./tenant-profile-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TenantProfileFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TenantProfileFormComponent),
      multi: true
    },
    TenantProfileFormService
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FieldsetDisabledComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective,
    HintComponent,
    DropdownMultiselectComponent,
    FlatSelectComponent,
    MultiSelectDropdownStepperComponent,
    KnockoutCriteriaComponent,
    SliderComponent,
    SelectComponent,
    CustomQuestionComponent,
    HierarchicalQuestionsDisplayRootComponent,
    ButtonComponent,
    TranslateModule,
    FormControlErrorsDirective,
    FormSubmitDirective
  ]
})
export class TenantProfileFormComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  private translateService = inject(TranslateService);
  private store = inject(Store);
  private modalService = inject(ModalService);
  private tenantProfileFormService = inject(TenantProfileFormService);

  readonly customQuestions = input<CustomQuestion[]>(undefined);
  readonly hierarchicalQuestions =
    input<RootQuestionContainerModel[]>(undefined);
  readonly constants = input<Constants>(undefined);
  readonly hasCustomQuestionsAccess = input<boolean>(undefined);
  readonly readonly = input<boolean>(undefined);
  readonly tags = input<ContactTag[]>(undefined);
  readonly changeOnlyByTemplateAllowed = input<boolean>(undefined);

  readonly previewCustomQuestion = output<CustomQuestion>();
  readonly wbsSettingChanged = output<boolean>();

  public minSalaryOptions: { name: string; value: number }[];
  public maxSalaryOptions: { name: string; value: number }[];
  public conditionId = ConditionId;
  public conditionIds: NameValue<ConditionId>[];

  public WBS_STEPPER_OPTIONS: DropdownStepperParent<HousingPermissionType>[] =
    [];

  public yesNoOptions = [
    { name: 'general.yes_l', value: true },
    { name: 'general.no_l', value: false, disabled: false }
  ];

  public timeIntervalOptions = [
    { name: 'general.month_l', value: TimeInterval.MONTH },
    { name: 'general.year_l', value: TimeInterval.YEAR }
  ];

  private MIN_SALARIES = [1, 2, 3];
  private MAX_SALARIES = [3, 4, 5, 6, 7, 8, 9, 10];

  private form = viewChild(FormGroupDirective);

  public get ageLowerBound() {
    return this.profileForm.get('data.age.value.lowerBound');
  }

  public get ageUpperBound() {
    return this.profileForm.get('data.age.value.upperBound');
  }

  public get conditionsFormArray() {
    return this.tenantProfileFormService.conditionsFormArray;
  }

  public get housingConditionForm() {
    return this.conditionsFormArray.at(
      this.tenantProfileFormService.housingConditionFormIndex
    );
  }

  public get attachedCustomQuestions() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.tenantProfileFormService.customQuestionsForm.value
      .map(({ id }) =>
        this.customQuestions().find(customQuestion => customQuestion.id === id)
      )
      .filter(customQuestion => !!customQuestion);
  }

  public get attachedHierarchicalQuestionsForm() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.tenantProfileFormService.hierarchicalQuestionsForm.value
      .map(({ id }) => this.hierarchicalQuestions().find(hq => hq.id === id))
      .filter(hq => !!hq);
  }

  public get profileForm() {
    return this.tenantProfileFormService.profileForm;
  }

  public get availableCustomQuestions(): CustomQuestion[] {
    const ids: string[] = (
      this.tenantProfileFormService.customQuestionsForm
        .value as CustomQuestion[]
    ).map(({ id }) => id);

    return this.customQuestions().filter(({ id }) => !ids.includes(id));
  }

  public get availableHierarchicalQuestions(): RootQuestionContainerModel[] {
    const ids: string[] = (
      this.tenantProfileFormService.hierarchicalQuestionsForm
        .value as RootQuestionContainerModel[]
    ).map(({ id }) => id);

    return this.hierarchicalQuestions().filter(({ id }) => !ids.includes(id));
  }

  public get editableConditionsControl() {
    return this.profileForm.get('data.editableConditions') as FormControl;
  }

  public isConditionDisabled(conditionId: ConditionId): boolean {
    return this.tenantProfileFormService.isConditionDisabled(conditionId);
  }

  /* eslint-disable @typescript-eslint/ban-types */
  private onChange: Function = () => null;
  private onTouch: Function = () => null;

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  writeValue(value: Prioset): void {
    this.tenantProfileFormService.init(
      this.readonly(),
      this.hasCustomQuestionsAccess()
    );
    if (value) this.tenantProfileFormService.patchValues(value);
  }

  public ngOnInit() {
    this.minSalaryOptions = this.getSalaryOptions(this.MIN_SALARIES);
    this.maxSalaryOptions = this.getSalaryOptions(this.MAX_SALARIES);

    this.ageLowerBound.valueChanges
      .pipe(debounceTime(50), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: number) => {
        if (this.ageUpperBound.value <= value) {
          this.ageUpperBound.patchValue(value + 1);
        }
      });

    this.profileForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.onChange(value);
        this.onTouch();
      });

    this.conditionIds = Object.values(ConditionId).map(conditionId => ({
      name: this.translateService.instant(conditionId),
      value: conditionId
    }));

    this.WBS_STEPPER_OPTIONS = getWbsOptions(
      this.constants().germanyStates as NameValue<StateOfGermany>[]
    );
  }

  public ngAfterViewInit() {
    this.housingConditionForm
      .get('value')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.housingConditionForm
          .get('data.amountPeopleUpperBound')
          .updateValueAndValidity();
        this.housingConditionForm
          .get('data.amountPeopleLowerBound')
          .updateValueAndValidity();
      });
  }

  public getKnockoutControl(formGroupName: string) {
    return this.profileForm.get(
      `data.${formGroupName}.knockout`
    ) as FormControl;
  }

  public getCustomQuestionKnockoutControl(index: number) {
    return this.tenantProfileFormService.customQuestionsForm
      .at(index)
      ?.get('knockout') as FormControl;
  }

  public getConditionKnockoutControl(index: number) {
    return this.conditionsFormArray.at(index).get('knockout') as FormControl;
  }

  public onPreviewCustomQuestion(customQuestion: CustomQuestion) {
    this.previewCustomQuestion.emit(customQuestion);
  }

  public onDeleteCustomQuestion(id: string) {
    this.modalService
      .openConfirmation({
        data: { ...removeCustomQuestionFromPrioSetDialogConfig }
      })
      .onClose()
      .subscribe(() => {
        this.tenantProfileFormService.removeCustomQuestion(id);
      });
  }

  public onDeleteHierarchicalQuestion({ id }: RootQuestionContainerModel) {
    this.modalService
      .openConfirmation({ data: { ...deleteHierarchicalQuestionDialogConfig } })
      .onClose()
      .subscribe(() => {
        this.tenantProfileFormService.removeHierarchicalQuestion(id);
      });
  }

  public onCustomQuestionAdd() {
    const data = {
      customQuestions: this.availableCustomQuestions,
      hierarchicalQuestions: this.availableHierarchicalQuestions,
      tags: this.tags()
    };

    this.modalService
      .open(AttachCustomQuestionModalComponent, { data })
      .onClose()
      .subscribe(
        ({
          selectedHierarchicalQuestions,
          selectedCustomQuestions
        }: {
          selectedHierarchicalQuestions: string[];
          selectedCustomQuestions: string[];
        }) => {
          if (this.selectedCustomQuestions(selectedCustomQuestions).length) {
            this.tenantProfileFormService.addNewCustomQuestions(
              selectedCustomQuestions
            );
          }

          if (
            this.selectedHierarchicalQuestions(selectedHierarchicalQuestions)
              .length
          ) {
            this.tenantProfileFormService.addNewHierarchicalQuestions(
              selectedHierarchicalQuestions
            );
          }
        }
      );
  }

  public getCustomQuestionFormGroup(i: number): FormGroup {
    return this.tenantProfileFormService.customQuestionsForm.at(i) as FormGroup;
  }

  public getHierarchicalQuestionFormGroup(i: number): FormGroup {
    return this.tenantProfileFormService.hierarchicalQuestionsForm.at(
      i
    ) as FormGroup;
  }

  public onWBSValueChange(value: boolean): void {
    this.wbsSettingChanged.emit(value);
  }

  public validate() {
    return this.profileForm.valid || this.profileForm.disabled
      ? null
      : { missingFields: true };
  }

  private getSalaryOptions(values: number[]) {
    return values.map(value => ({
      value,
      name: this.translateService.instant('property.multiple_of_cold_rent_l', {
        value
      }) as string
    }));
  }

  private selectedCustomQuestions(ids: string[]) {
    return this.availableCustomQuestions.filter(({ id }) => ids.includes(id));
  }

  private selectedHierarchicalQuestions(ids: string[]) {
    return this.availableHierarchicalQuestions.filter(({ id }) =>
      ids.includes(id)
    );
  }

  public failedContinue() {
    this.form().onSubmit(null);
  }
}
