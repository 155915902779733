<div class="funnel">
  <div
    class="funnel-all"
    [class.funnel-all--active]="allSelected"
    (click)="selectValue(null)"
  >
    <div class="funnel-all__label semibold">
      {{ 'FILTER_ALL_L' | translate }}
    </div>
    <app-badge [color]="badgeColor.LIGHT_BLUE" [fontBold]="true">
      @if (!loading()) {
        {{ total() }}
      } @else {
        <div class="skeleton"></div>
      }
    </app-badge>
  </div>

  <div class="funnel-list-container">
    <ul
      class="funnel-list"
      [ngClass]="{ 'fade-left': fadeLeft(), 'fade-right': fadeRight() }"
      #funnelContainer
      (scroll)="onHorizontalScroll()"
    >
      @for (item of items(); track item.label) {
        <li
          class="funnel-list__item"
          [class.funnel-list__item--active]="item?.active && !allSelected"
          [class.funnel-list__item--disabled]="item?.disabled"
          (click)="selectValue(item)"
        >
          <div class="funnel-list__item-label semibold">
            {{ item?.label | translate }}
          </div>
          <app-badge [color]="badgeColor.LIGHT_BLUE" [fontBold]="true">
            @if (!loading()) {
              {{ item?.count }}
            } @else {
              <div class="skeleton"></div>
            }
          </app-badge>
        </li>
      }
    </ul>
  </div>
</div>
