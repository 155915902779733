import { Component } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '@ui/legacy-lib';
import { footerConfig } from '../../config';

@Component({
  selector: 'app-sidebar-not-authenticated',
  templateUrl: './sidebar-not-authenticated.component.html',
  styleUrls: ['./sidebar-not-authenticated.component.scss'],
  imports: [RouterOutlet, FooterComponent]
})
export class SidebarNotAuthenticatedComponent {
  public footerLinks = footerConfig.links;
}
