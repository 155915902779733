import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { CardComponent } from '../../../atoms/card/card.component';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  imports: [
    CardComponent,
    SvgIconComponent,
    ImageComponent,
    NgClass,
    ButtonComponent,
    TranslateModule
  ]
})
export class ServiceCardComponent {
  readonly showNewMarker = input<boolean>(undefined);
  readonly headline = input<string>(undefined);
  readonly description = input<string>(undefined);
  readonly copyTextLabel = input<string>(undefined);
  readonly copyText = input<string>(undefined);
  readonly copyTextDescriptionTitle = input<string>(undefined);
  readonly copyTextDescription = input<string>(undefined);

  readonly price = input<string>(undefined);
  readonly buttonText = input<string>(undefined);
  readonly logoUrl = input<string>(undefined);
  readonly copyEvent = output<string>();
  readonly buttonEvent = output<Event>();

  public baseClass = 'service-card';

  public copy() {
    this.copyEvent.emit(this.copyText());
  }

  public buttonPress(event: Event) {
    this.buttonEvent.emit(event);
  }
}
