<div class="calendar">
  <div class="calendar__header">
    <span class="calendar__month-name"
      >{{ getCurrentMonthName() }} {{ getCurrentYear() }}</span
    >
    <div class="calendar__navigation">
      <i
        class="icon icon--chevron-left"
        (click)="onPreviousMonth()"
        [ngClass]="{ disabled: disablePreviousMonth() }"
      ></i>
      <i
        class="icon icon--chevron-right"
        (click)="onNextMonth()"
        [ngClass]="{ disabled: disableNextMonth() }"
      ></i>
    </div>
  </div>
  <div class="calendar__content">
    <div class="calendar__week-days-row">
      @for (dayLetter of daysLetters; track dayLetter; let i = $index) {
        <span class="calendar__week-day" [ngClass]="{ weekend: i >= 5 }">
          {{ dayLetter }}</span
        >
      }
    </div>
    <div class="calendar__days-table">
      @for (row of daysRows; track row; let rowIndex = $index) {
        <div
          class="calendar__days-row"
          [ngClass]="{ highlight: highlightRow(row) }"
        >
          @for (day of row; track day; let i = $index) {
            <span
              class="calendar__day"
              [ngClass]="{
                'previous-month': day.isPreviousMonth,
                'next-month': day.isNextMonth,
                selected: day.selected,
                today: day.isToday
              }"
              (click)="onSelect(day)"
            >
              {{ day.day }}
              @if (day.marked && !day.selected) {
                <span
                  class="calendar__day-mark"
                  [ngClass]="{ 'before-today': day.isBeforeToday }"
                ></span>
              }
            </span>
          }
        </div>
      }
    </div>
  </div>
</div>
