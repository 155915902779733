import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';

import { CustomQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { CustomQuestionDetailsComponent } from '../custom-question-details/custom-question-details.component';
import { ContextMenuItemComponent } from '../../../molecules/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../../molecules/context-menu/context-menu.component';

@Component({
  selector: 'app-custom-question',
  templateUrl: './custom-question.component.html',
  styleUrls: ['./custom-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ContextMenuComponent,
    ContextMenuItemComponent,
    CustomQuestionDetailsComponent,
    TranslateModule
  ]
})
export class CustomQuestionComponent {
  readonly showPreview = input(true);
  readonly customQuestion = input<CustomQuestion>(undefined);
  readonly delete = output<string>();
  readonly preview = output<CustomQuestion>();

  onPreview() {
    this.preview.emit(this.customQuestion());
  }

  onDelete() {
    this.delete.emit(this.customQuestion().id);
  }
}
