<app-modal>
  <div class="modal-m">
    <app-modal-content [formGroup]="formGroup">
      <div class="modal-body">
        <p class="title-m">
          {{
            (link()
              ? 'homepage_module.portal_edit_l'
              : 'homepage_module.portal_create_l'
            ) | translate
          }}
        </p>
        <p class="default-s">
          {{
            (link()
              ? 'homepage_module.portal_edit_description_l'
              : 'homepage_module.portal_create_description_l'
            ) | translate
          }}
        </p>

        <div class="my-3">
          <app-form-field>
            <app-form-field-label>{{
              'homepage_module.portal_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              type="text"
              required
              formControlName="name"
              class="form-control mb10"
            />
          </app-form-field>
        </div>

        <div class="mb-3">
          <app-form-field>
            <app-form-field-label>{{
              'homepage_module.portal_type_l' | translate
            }}</app-form-field-label>
            <app-dropdown-select
              [items]="homepageModuleTypes"
              formControlName="type"
              required
            >
              <ng-template let-item>{{ item.name | translate }}</ng-template>
            </app-dropdown-select>
          </app-form-field>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer">
        <app-button
          class="order-2 me-0"
          [disabled]="formGroup.invalid || formGroup.pristine"
          (clickEvent)="save()"
        >
          {{
            (link()
              ? 'homepage_module.portal_edit_a'
              : 'homepage_module.portal_create_a'
            ) | translate
          }}
        </app-button>
        <app-button
          [type]="'light-bordered'"
          class="order-1 me-2"
          (clickEvent)="dismiss()"
          >{{ 'CANCEL_A' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
