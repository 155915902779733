<div #content>
  <ng-content></ng-content>
</div>

@if (content.children.length === 0) {
  <div>
    @for (attachment of attachments(); track attachment; let i = $index) {
      <app-attachment-item-v2
        [attachment]="attachment"
        [index]="i"
        [isDocument]="isDocument()"
        [showDownload]="showDownload()"
        [disableDownload]="disableDownload()"
        [blockDownload]="blockDownload()"
        [showRemove]="showRemove()"
        [orderable]="orderable()"
        [editable]="editable()"
        (remove)="onRemove($event)"
        (download)="onDownload($event)"
        (preview)="onPreview($event)"
        (moveUp)="onMoveUp($event)"
        (moveDown)="onMoveDown($event)"
      >
      </app-attachment-item-v2>
    }
  </div>
}
