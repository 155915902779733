import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
  model,
  output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconTypeEnum } from '@ui/shared/models';

import { ButtonComponent } from '../../atoms/button/button.component';
import { ButtonTypeEnum } from '../../atoms/button';

export interface SegmentItem {
  id?: string;
  label?: string;
  icon?: IconTypeEnum;
}

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true
    }
  ],
  imports: [ButtonComponent]
})
export class SegmentComponent {
  readonly segments = input<SegmentItem[]>([]);
  readonly selectedSegment = model<SegmentItem>(null);
  readonly selectedSegmentChange = output<SegmentItem>();

  private _value: SegmentItem;

  get value(): SegmentItem {
    return this._value;
  }

  set value(newValue: SegmentItem) {
    this._value = newValue;
    this.selectedSegmentChange.emit(this._value);
    this.onChange(this._value);
  }

  writeValue(value: SegmentItem) {
    this._value = value;
  }

  private onChange = (value: unknown) => value;
  private onTouch = (value: unknown) => value;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  selectSegment(segment: SegmentItem): void {
    if (segment.id !== this.selectedSegment().id) {
      this.value = segment;
      this.selectedSegment.set(segment);
    }
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
