export const DEFAULT_ERROR_MESSAGES = {
  required: 'forms.errors.field_required',
  email: 'forms.errors.value_does_not_match_email',
  pattern: 'forms.errors.value_does_not_match_pattern',
  minlengthPassword: 'forms.errors.password_too_short',
  minlength: 'forms.errors.value_too_short',
  maxlength: 'forms.errors.value_too_long',
  matchControl: 'forms.errors.value_does_not_match',
  min: 'forms.errors.value_too_small',
  max: 'forms.errors.value_too_big',
  invalidCredentials: 'forms.errors.invalid_credentials',
  invalidDate: 'forms.errors.invalid_date',
  invalidUrl: 'forms.errors.invalid_url',
  invalidIban: 'forms.errors.invalid_iban',
  invalidTaxId: 'forms.errors.invalid_tax_id',
  iban: 'forms.errors.invalid_iban',
  dateRangeStart: 'forms.errors.start_before_to_date',
  dateRangeTo: 'forms.errors.to_after_start_date',
  tooEarlyDate: 'forms.errors.date_too_early',
  requiredBefore: 'forms.errors.date_too_early',
  tooLateDate: 'forms.errors.date_too_late',
  requiredAfter: 'forms.errors.date_too_late',
  notInteger: 'forms.errors.value_is_not_integer',
  invalidZipCode: 'forms.errors.value_is_not_zip_code',
  inviteEmailEqual: 'forms.errors.email_is_already_added',
  forbiddenCharacters: 'forms.errors.forbidden_characters',
  lessThan: 'forms.errors.value_too_big',
  biggerThan: 'forms.errors.value_too_small',
  alreadyUsed: 'forms.errors.value_already_used',
  isDuplicateEmail: 'forms.errors.value_is_duplicate_email',
  isDuplicatePhone: 'forms.errors.value_is_duplicate_phone',
  listIsEmpty: 'forms.errors.list_is_empty',
  alreadyApplied: 'forms.errors.email_already_applied',
  alreadyRegistered: 'forms.errors.email_already_registered',
  unknownError: 'forms.errors.unknown_error',
  duplicateValue: 'forms.errors.duplicate_value',
  duplicatePacketName: 'forms.errors.packet-name.exists',
  dateInPast: 'forms.errors.date_in_past',
  dateInPresentOrToday: 'forms.errors.date_in_present',
  internationalPhone: 'forms.errors.international_phone',
  alreadyExistsInArray: 'forms.errors.already_exists_in_array'
};

export const getErrorMessage = (error: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return DEFAULT_ERROR_MESSAGES[error] || '';
};
