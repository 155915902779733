import { Component, OnInit, input, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CheckComponent } from '../../../legacy/form/controls/check/check.component';
import { ImageComponent } from '../../../atoms/image/image.component';
import { CardComponent } from '../../../atoms/card/card.component';
import { Elevation, ElevationType } from '../../../../directives';
import { CardBorderStyleEnum } from '../../../atoms/card';

@Component({
  selector: 'app-portal-card',
  templateUrl: './portal-card.component.html',
  styleUrls: ['./portal-card.component.scss'],
  imports: [
    CardComponent,
    ImageComponent,
    NgbTooltip,
    CheckComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class PortalCardComponent implements OnInit {
  readonly identification = input<any>(undefined);
  readonly imgUrl = input<string>(undefined);
  readonly title = input<string>(undefined);
  readonly deselectDisabledText = input<string>(undefined);
  readonly elevation = input<ElevationType>(Elevation.ZERO);

  // behaviour control inputs
  readonly selectable = input(true);
  readonly deselectable = input(true);
  readonly disabled = input(false);
  readonly selected = input(false);

  readonly toggleEvent = output<any>();

  public cardBorderStyle = CardBorderStyleEnum.DEFAULT;
  public cardBorderRadius = true;

  public checkControl = new FormControl({
    value: this.selected(),
    disabled: this.isDisabled
  });

  public ngOnInit() {
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    this.checkControl.patchValue(this.selected());
    this.checkControl.valueChanges.subscribe(() =>
      this.toggleEvent.emit(this.identification())
    );
    /* eslint-enable @typescript-eslint/no-unsafe-call */
  }

  public onBoxClick() {
    if (this.isDisabled) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.checkControl.patchValue(!this.isSelected);
  }

  public get isSelected() {
    return !!this.checkControl?.value;
  }

  public get isDisabled() {
    return (
      this.disabled() ||
      (!this.isSelected && !this.selectable()) ||
      (this.isSelected && !this.deselectable())
    );
  }

  public get showDeselectionDisabledTooltip() {
    return (
      this.isSelected && !this.deselectable() && this.deselectDisabledText()
    );
  }
}
