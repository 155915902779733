import { Component, input } from '@angular/core';

import { CustomQuestion } from '@ui/shared/models';
import { FormsModule } from '@angular/forms';
import { CustomQuestionDetailsComponent } from '../custom-question-details/custom-question-details.component';
import { CheckComponent } from '../../form/controls/check/check.component';

@Component({
  selector: 'app-available-custom-question',
  templateUrl: './available-custom-question.component.html',
  styleUrls: ['./available-custom-question.component.scss'],
  imports: [CheckComponent, FormsModule, CustomQuestionDetailsComponent]
})
export class AvailableCustomQuestionComponent {
  readonly customQuestion = input<CustomQuestion>(undefined);
  readonly selected = input(false);
}
