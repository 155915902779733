import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  input
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { first } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '../../legacy/loading-spinner/loading-spinner.component';
import { ImageComponent } from '../../atoms/image/image.component';
import { SvgService } from '../../../services/svg.service';
import { StatusInfoImageReplaceConfig } from './status-info.model';

@Component({
  selector: 'app-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
  imports: [ImageComponent, LoadingSpinnerComponent, TranslateModule]
})
export class StatusInfoComponent {
  private svgService = inject(SvgService);

  private changeDetectorRef = inject(ChangeDetectorRef);

  public svgXML: SafeHtml;

  readonly loading = input(false);
  readonly subject = input<string>(undefined);
  readonly imageSrc = input<string>(undefined);
  readonly imageSize = input<string>(undefined);
  readonly title = input<string>(undefined);

  @Input() set whitelabelSvg(value: StatusInfoImageReplaceConfig) {
    if (value) {
      this.svgService
        .loadSvgAsXML(value.imageSrc)
        .pipe(first())
        .subscribe(svg => {
          this.svgXML = this.svgService.replaceColorAndFinalize(
            svg,
            value.colorHex,
            value.replaceVar
          );
          this.changeDetectorRef.detectChanges();
        });
    }
  }
}
