<footer class="footer__container d-flex flex-column">
  <div class="footer__section">
    <ul class="footer__items d-flex flex-column">
      @for (link of links(); track link.name) {
        <div class="footer__item mb10">
          <a class="footer__link" href="{{ link.url }}" target="_blank">{{
            link.name | translate
          }}</a>
        </div>
      }
    </ul>
  </div>

  <div class="footer__section mt10">
    <app-socials></app-socials>
  </div>

  <div class="footer__section mt20">
    <span class="footer__text">&copy; {{ currentYear() }} Immomio GmbH</span>
  </div>
</footer>
