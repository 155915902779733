import { HttpHeaders } from '@angular/common/http';

import { Store } from '@ngrx/store';

import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import {
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  StoreObject
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { AuthTokenService } from '../authentication/auth-token.service';
import { LocalStorageService } from '../storage';
import { Go } from '../base-state/router';
import {
  ApolloHttpConfig,
  ExtendedGraphQLError,
  ExtendedNetworkError
} from './interfaces';

const UNAUTHORIZED_STATUS_CODE = 401;

export class ApolloFactory {
  public static getApolloConfig(
    httpLink: HttpLink,
    authTokenService: AuthTokenService,
    store: Store,
    httpConfig: ApolloHttpConfig,
    localStorage: LocalStorageService
  ) {
    const { uri, authHeaderName } = httpConfig;
    const http = httpLink.create({ uri });

    const auth = setContext((_, { headers }) => {
      headers = headers || new HttpHeaders();

      const token = authTokenService.getToken().access_token;

      if (!token) {
        return {};
      }

      return {
        headers: (headers as HttpHeaders).append(authHeaderName, token)
      };
    });

    const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
      const invalidRealmError = (graphQLErrors || []).find(
        (error: ExtendedGraphQLError) => error?.message === 'INVALID_REALM_L'
      );

      const graphQLUnauthorizedError = (graphQLErrors || []).find(
        (error: ExtendedGraphQLError) =>
          error &&
          error.extra &&
          Array.isArray(error.extra) &&
          error.extra.some(err => err.status === UNAUTHORIZED_STATUS_CODE)
      );

      const networkUnauthorizedError =
        networkError &&
        (networkError as ExtendedNetworkError).statusCode ===
          UNAUTHORIZED_STATUS_CODE;

      if (
        graphQLUnauthorizedError ||
        networkUnauthorizedError ||
        invalidRealmError
      ) {
        this.handleLogout(store, authTokenService, localStorage);
      }
    });

    return {
      link: from([auth, errorMiddleware, http]),
      cache: new InMemoryCache({
        dataIdFromObject: (object: Readonly<StoreObject>) =>
          defaultDataIdFromObject(object),
        addTypename: false
      })
    };
  }

  public static getApolloResidentConfig(
    httpLink: HttpLink,
    authTokenService: AuthTokenService,
    store: Store,
    httpConfig: ApolloHttpConfig
  ) {
    const { uri } = httpConfig;
    const http = httpLink.create({ uri });

    // later we might need the token for API-RS, at the moment we don't
    const auth = setContext((_, { headers }) => {
      headers = headers || new HttpHeaders();
      return {
        headers: headers as HttpHeaders
      };
    });

    const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
      const graphQLUnauthorizedError = (graphQLErrors || []).find(
        (error: ExtendedGraphQLError) =>
          error &&
          error.extra &&
          Array.isArray(error.extra) &&
          error.extra.some(err => err.status === UNAUTHORIZED_STATUS_CODE)
      );

      const networkUnauthorizedError =
        networkError &&
        (networkError as ExtendedNetworkError).statusCode ===
          UNAUTHORIZED_STATUS_CODE;

      if (graphQLUnauthorizedError || networkUnauthorizedError) {
        console.error('Not authorized');
      }
    });

    return {
      link: from([auth, errorMiddleware, http]),
      cache: new InMemoryCache({
        dataIdFromObject: (object: Readonly<StoreObject>) =>
          defaultDataIdFromObject(object),
        addTypename: false
      })
    };
  }

  public static getApolloBackendTunnelConfig(
    httpLink: HttpLink,
    authTokenService: AuthTokenService,
    store: Store,
    httpConfig: ApolloHttpConfig,
    customerIdent: string,
    localStorage: LocalStorageService
  ) {
    const { uri } = httpConfig;
    const http = httpLink.create({ uri });

    const auth = setContext(() => {
      const token = authTokenService.getToken().access_token;
      if (!token) {
        return {};
      }
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CUSTOMER-IDENT': customerIdent
        }
      };
    });

    const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
      const graphQLUnauthorizedError = (graphQLErrors || []).find(
        (error: ExtendedGraphQLError) =>
          error &&
          error.extra &&
          Array.isArray(error.extra) &&
          error.extra.some(err => err.status === UNAUTHORIZED_STATUS_CODE)
      );

      const networkUnauthorizedError =
        networkError &&
        (networkError as ExtendedNetworkError).statusCode ===
          UNAUTHORIZED_STATUS_CODE;

      if (graphQLUnauthorizedError || networkUnauthorizedError) {
        this.handleLogout(store, authTokenService, localStorage);
      }
    });

    return {
      link: from([auth, errorMiddleware, http]),
      cache: new InMemoryCache({
        dataIdFromObject: (object: Readonly<StoreObject>) =>
          defaultDataIdFromObject(object),
        addTypename: false
      })
    };
  }

  private static handleLogout(
    store: Store,
    auth: AuthTokenService,
    localStorage: LocalStorageService
  ) {
    this.clearStorage(auth, localStorage);
    store.dispatch(new Go({ path: ['login'] }));
  }

  private static clearStorage(
    auth: AuthTokenService,
    localStorage: LocalStorageService
  ) {
    localStorage.removeItems('nonce', 'state', 'clientId', 'realm', 'baseUrl');
    auth.removeToken();
  }
}
