<div class="subscription-form__content">
  @if (!hasActiveProduct()) {
    <div class="new-user-message-container">
      <p class="new-user-message">
        {{ 'FIRST_TIME_VISITOR_SUBSCRIPTION_INFORMATION_L' | translate }}
      </p>
      <p class="first-payment-information">
        {{ 'FIRST_TIME_VISITOR_PAYMENT_INFORMATION_L' | translate }}
      </p>
    </div>
  }
  <form [formGroup]="form()">
    <div class="row">
      <div class="col-12 payment-details__container">
        <app-payment-details-lib
          [totalPrice]="totalPrice"
          [totalPriceNet]="totalPriceNet"
          [totalNextPrice]="totalNextPrice"
          [totalNextPriceNet]="totalNextPriceNet"
          [currency]="currency()"
          [products]="products()"
          [recalculatingPrice]="recalculatingPrice()"
          [paymentMethod]="paymentMethod()"
          formControlName="product"
          [isAdmin]="isAdmin()"
          [form]="paymentDetailsForm"
          [totalPricePreDiscount]="totalPricePreDiscount"
          [totalPriceNetPreDiscount]="totalPriceNetPreDiscount"
          [totalNextPricePreDiscount]="totalNextPricePreDiscount"
          [totalNextPriceNetPreDiscount]="totalNextPriceNetPreDiscount"
          [isYearlySelected]="isYearlySelected"
          [isNextYearlySelected]="isNextYearlySelected"
          [nextProductSubscriptionPeriod]="nextProductSubscriptionPeriod"
          [currentSubscriptionPeriod]="productSubscriptionPeriod"
          [renewDate]="dueDate"
          [hasActiveProduct]="hasActiveProduct()"
          [isTrial]="isTrial()"
          (productChange)="onProductChange($event)"
        >
        </app-payment-details-lib>
      </div>
      <div class="col-12 col-lg-8 subscription-details__container">
        <app-subscription-details
          [addons]="addons()"
          [customerAddons]="customerProduct()?.addons"
          [dueDate]="dueDate"
          [currency]="currency()"
          [recalculatingPrice]="recalculatingPrice()"
          formControlName="addons"
          [isAdmin]="isAdmin()"
          [disabled]="addonsControl.disabled"
          [addonDiscounts]="addonDiscountsFilled"
          [digitalContractQuotaDiscounts]="digitalContractQuotaDiscounts()"
          [digitalContractQuotaPackages]="digitalContractQuotaPackages()"
          [objectContingentQuotaDiscounts]="digitalContractQuotaDiscounts()"
          [objectContingentQuotaPackages]="objectContingentQuotaPackages()"
          [isYearlySelected]="isYearlySelected"
          (discountChange)="onDiscountChange($event)"
          (saveQuotaDiscounts)="onSaveQuotaDiscounts($event)"
          (saveObjectContingentQuotaDiscounts)="
            onSaveObjectContingentQuotaDiscounts($event)
          "
        >
        </app-subscription-details>
        <span class="default-s ms-2">{{
          'subscription.consulting_price_l' | translate
        }}</span>
      </div>
      <div class="col-12 col-lg-4 free-features__container">
        <app-free-features-listing>
          <app-button
            (clickEvent)="onUpdate()"
            [disabled]="disableSubmit"
            [loading]="saving()"
          >
            @if (hasActiveProduct() && !saving()) {
              <span>{{ 'UPDATE_SUBSCRIPTION_A' | translate }}</span>
            }
            @if (!hasActiveProduct() && !saving()) {
              <span>{{ 'START_FREE_TRIAL_A' | translate }}</span>
            }
            @if (saving()) {
              <span>{{ 'UPDATING_SUBSCRIPTION_L' | translate }}...</span>
            }
          </app-button>
        </app-free-features-listing>
      </div>
    </div>
  </form>
</div>
