import { createSelector } from '@ngrx/store';

import { getProductsState } from '../reducers';
import { getLandlord } from '../../screens/landlord/+state/landlords/landlords.selectors';
import * as fromSubscriptionsReducer from './products.reducers';

export const getLandlordProducts = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getLandlordProducts
);

export const getLandlordProduct = createSelector(
  getLandlordProducts,
  getLandlord,
  (products, landlord) => products?.[landlord?.customer?.id || 'new']
);

export const getSelectedProductId = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getSelectedProductId
);

export const getDefaultProductId = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getDefaultProductId
);

export const getLandlordProductsActionState = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getLandlordProductsActionState
);

export const getDefaultProducts = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getDefaultProducts
);
export const getDefaultProductActionState = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getDefaultProductsActionState
);

export const getDefaultProduct = createSelector(
  getDefaultProducts,
  getDefaultProductId,
  (products, defaultProductId) =>
    products.find(p => p.product.id === defaultProductId)
);

export const getDefaultProductAddons = createSelector(
  getDefaultProduct,
  product => product.addons
);

export const getRecalculatingPricesActionState = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getRecalculatingPricesActionState
);

export const getProductId = createSelector(
  getDefaultProductId,
  getSelectedProductId,
  (defaultId, selectedId) => selectedId || defaultId
);

export const getMapOfValidAddonDiscounts = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getMapOfValidAddonDiscounts
);

export const getSelectedProduct = createSelector(
  getDefaultProducts,
  getProductId,
  (products, selectedId) => {
    return products.find(p => p.product.id === selectedId);
  }
);

export const getAddonDiscounts = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getAddonDiscounts
);

export const getDigitalContractQuotaDiscounts = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getDigitalContractQuotaDiscounts
);

export const getDigitalContractQuotaPackages = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getDigitalContractQuotaPackages
);

export const getObjectContingentQuotaDiscounts = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getObjectContingentQuotaDiscounts
);

export const getObjectContingentQuotaPackages = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getObjectContingentQuotaPackages
);

export const getSubscriptionActionState = createSelector(
  getProductsState,
  fromSubscriptionsReducer.getSubscriptionActionState
);
