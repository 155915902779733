<div class="card custom-question__container">
  <div
    class="d-flex align-items-center justify-content-between default-s mb12 custom-question__header"
  >
    <div>{{ customQuestion()?.title }}</div>
    <div class="custom-question__check">
      <app-check [ngModel]="selected()"></app-check>
    </div>
  </div>

  <app-custom-question-details
    [customQuestion]="customQuestion()"
  ></app-custom-question-details>
</div>
