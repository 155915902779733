import { Component, forwardRef, OnInit, input, viewChild } from '@angular/core';

import { NgControl, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@Component({
  selector: 'app-item-check',
  templateUrl: './item-check.component.html',
  styleUrls: ['./item-check.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemCheckComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => ItemCheckComponent)
    }
  ],
  imports: [FormsModule]
})
export class ItemCheckComponent extends BaseControl<boolean> implements OnInit {
  readonly label = input<string>(undefined);
  readonly innerHTML = input<string>(undefined);
  readonly showCheckbox = input<boolean>(undefined);
  readonly ngControl = viewChild(NgControl);

  ngOnInit() {
    this.ngControl().statusChanges.subscribe(() => this.stateChanges.next());
  }

  toggleValue() {
    if (this.disabled) return;

    this.value = !this.value;
  }
}
