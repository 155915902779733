import { Component, input, output } from '@angular/core';

import { LandlordUser, PropertySearcherUser } from '@ui/shared/models';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { AvatarComponent } from '../avatar/avatar.component';
import { LogoComponent } from '../logo/logo.component';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ElevationDirective } from '../../../directives';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    ButtonComponent,
    LogoComponent,
    AvatarComponent,
    ImageSizePipe,
    ElevationDirective
  ]
})
export class HeaderComponent {
  readonly user = input<LandlordUser | PropertySearcherUser>(undefined);
  readonly navigationOpen = input<boolean>(undefined);
  readonly profileSettingsOpen = input<boolean>(undefined);
  readonly navigationToggleClick = output<Event>();
  readonly profileSettingsToggleClick = output<Event>();

  public onNavigationToggleClick(event: Event) {
    this.navigationToggleClick.emit(event);
  }

  public onProfileSettingsToggleClick(event: Event) {
    this.profileSettingsToggleClick.emit(event);
  }
}
