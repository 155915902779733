import { Breakpoints } from '@angular/cdk/layout';
import {
  Address,
  AgentInfo,
  Attachment,
  CommonTicketData,
  ContactType,
  ConversationRestrictionConfig,
  CustomerSettings,
  DkApprovalLevel,
  DKLevelCustomerSettings,
  Pagination,
  Profile,
  Property,
  PropertyMatchBean,
  PropertySearcher,
  PropertySearcherBase
} from '@ui/shared/models';
import { ConversationSenderTypes, ConversationSourceTypes } from './enum';

export const POLLING_TIME_LL = 300000;
export const POLLING_TIME_PS = 300000;

export const POLLING_TIME_NEW_MESSAGES_LL = 5000;
export const POLLING_TIME_NEW_MESSAGES_PS = 15000;

// TODO: do not use the built in breakpoints of Angular's CDK
//  because these breakpoints are based on the Material Design specification
//  and our layout currently relies on the measurements specified by Bootstrap
//  – so we have a mismatch between the breakpoints used in JS & CSS at the moment.
//  => https://immomio.atlassian.net/browse/ART-376
export const mobileBreakingPoints = [Breakpoints.XSmall, Breakpoints.Small];
export const handheldBreakingPoints = [
  Breakpoints.HandsetLandscape,
  Breakpoints.HandsetPortrait,
  Breakpoints.TabletPortrait,
  Breakpoints.TabletLandscape
];
export const desktopBreakingPoints = [
  Breakpoints.Medium,
  Breakpoints.Large,
  Breakpoints.XLarge
];

export enum ConversationMessagesCSSCLass {
  SINGLE = 'single',
  FIRST = 'grouped-first',
  CONTENT = 'grouped-content',
  LAST = 'grouped-last'
}

export interface ConversationResponse {
  searchConversation?: Conversations;
  searchByPropertyId?: Conversations;
}

export interface SendMessageInNewConversationResponse {
  sendMessageInNewConversation?: ConversationMessage;
}

export interface SendMessageResponse {
  sendMessage?: ConversationMessage;
}

export interface FindUnreadMessagesResponse {
  findUnreadMessages: ConversationMessages;
}

export interface CountUnreadResponse {
  countUnread: {
    messages: number;
  };
}

export interface ParticipatedAgentsResponse {
  loadParticipatedAgents: AgentInfo[];
}

export interface ConversationInput extends MessengerFilter {
  conversationId?: string;
  dkLevelCustomerSettings?: DKLevelCustomerSettings;
}

export interface Conversations {
  nodes: Conversation[];
  page: Pagination;
}

export interface Conversation {
  id?: string;
  lastMessageDate?: string;
  lastMessageText?: string;
  unreadMessages?: number;
  subject?: string;
  mainConversationPartner?: ConversationSender;
  otherParticipants?: number;
  titleImage?: string;
  labels?: ConversationLabel[];
  applicationId?: string;
  ticketId?: string;
}

export type ConversationSender = {
  id: string;
  firstname: string;
  name: string;
  portrait: string;
  dkApprovalLevel: DkApprovalLevel;
  type: ConversationSenderTypes;
};

export enum ConversationLabel {
  APPLICATION = 'APPLICATION',
  DAMAGE = 'DAMAGE',
  CONCERN = 'CONCERN',
  TICKET_STATUS_OPEN = 'TICKET_STATUS_OPEN',
  TICKET_STATUS_IN_PROGRESS = 'TICKET_STATUS_IN_PROGRESS',
  TICKET_STATUS_CLOSED = 'TICKET_STATUS_CLOSED',
  TICKET_STATUS_WAITING_FOR_OTHERS = 'TICKET_STATUS_WAITING_FOR_OTHERS',
  TICKET_STATUS_CANCELLED = 'TICKET_STATUS_CANCELLED'
}

export interface BlockInput {
  conversationId: string;
  blocked: boolean;
}

export interface ConversationMessageResponse {
  searchConversationMessagesByConversationId: ConversationMessages;
}

export type ConversationMessagesAndDetailsResponse =
  ConversationMessageResponse & {
    conversationDetails: ConversationDetails;
  };

export interface ConversationMessages {
  nodes: ConversationMessage[];
  page: Pagination;
}

export interface SendMessageInput {
  conversationId?: string;
  message?: string;
  applicationId?: string;
  attachments?: Blob[];
  propertyInfo?: ConversationPropertyBean;
  dkLevelCustomerSettings?: DKLevelCustomerSettings;
}

export interface BulkSendMessageInput extends SendMessageInput {
  recipientApplicationIds?: string[];
}

export interface ConversationMessageInput {
  conversationId?: string;
  page?: number;
  customerSettings?: CustomerSettings;
}

export interface ConversationMessage {
  id?: string;
  messageSent?: string;
  updated?: string;
  message?: string;
  sender?: ConversationParticipant;
  source?: ConversationSourceTypes;
  agentInfo?: AgentInfo;
  profile?: Profile;
  conversationId?: string;
  read?: boolean;
  showSeparator?: boolean;
  attachments?: Attachment[];
}

export type ConversationParticipant = {
  firstname: string;
  name: string;
  portrait: string;
  dkApprovalLevel: DkApprovalLevel;
  type: ContactType;
};

export type ConversationDetails = {
  id: string;
  participants: Profile[];
  type: ConversationType;
  applicationDetails?: PropertySearcherBase;
  ticketDetails?: CommonTicketData;
  propertyDetailsPS?: PropertyMatchBean;
  propertyDetailsLL?: Property;
  userAllowedToSendMessage?: boolean;
  blocked?: boolean;
};

export enum ConversationType {
  APPLICATION = 'APPLICATION',
  TICKET = 'TICKET'
}

export interface ConversationPropertyBean {
  id?: string;
  name?: string;
  titleImage?: Attachment;
  address?: Address;
  basePrice?: number;
  size?: number;
  rooms?: number;
  customerLogo?: string;
  customerName?: string;
  externalId?: string;
}

export interface PropertyConversationConfig {
  conversationConfig: ConversationRestrictionConfig;
  propertyId: string;
}

export interface ConversationApplication {
  id?: string;
  created?: Date;
  score?: number;
  marked?: boolean;
  status?: string;
  archived?: boolean;
  applicationBlocked?: boolean;
}

export enum PaneType {
  CONTACT_LIST = 'CONTACT_LIST',
  CHAT = 'CHAT',
  INFO = 'INFO',
  CHAT_INFO = 'CHAT_INFO'
}

export interface MessengerFilter {
  propertyId?: string;
  agents?: string[];
  search?: string;
  page?: number;
  size?: number;
  conversationExists?: string;
  archivedByCustomerOnly?: boolean;
}

export interface CheckforConversationInterface {
  propertySearcher: PropertySearcher;
  property: Property;
  existingConversationId: string;
}

export interface MessageTemplate {
  id?: string;
  title?: string;
  content?: string;
  attachments?: Blob | Attachment;
}

export interface MessageTemplates {
  nodes: MessageTemplate[];
  page: Pagination;
}

export interface LoadParsedTemplate {
  application: string;
  page: number;
}

export interface LoadTemplateResponse {
  loadTemplate: MessageTemplates;
}

export interface LoadParsedTemplatesResponse {
  loadParsedTemplates: MessageTemplates;
}

export interface CreateTemplateResponse {
  createTemplate: MessageTemplate;
}

export interface UpdateTemplateResponse {
  updateTemplate: MessageTemplate;
}

export interface MessageTemplateSubstitution {
  substitutionTag: string;
  label: string;
}
