import { Component, forwardRef, OnInit, input, viewChild } from '@angular/core';

import { NgControl, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgClass } from '@angular/common';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@UntilDestroy()
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ],
  imports: [FormsModule, NgClass]
})
export class CheckboxComponent extends BaseControl<boolean> implements OnInit {
  readonly label = input<string>(undefined);
  readonly innerHTML = input<string>(undefined);
  readonly hideActiveValue = input(false);
  readonly buttonDesign = input(false);

  readonly showLargerCheckboxes = input(false);

  readonly ngControl = viewChild(NgControl);

  ngOnInit() {
    this.ngControl()
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  toggleValue() {
    if (this.disabled) return;

    this.value = !this.value;
  }
}
