import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output
} from '@angular/core';

import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChangePasswordPayload } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';
import { PasswordComponent } from '../form/controls/password/password.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';
import { matchControlValidatorFactory } from '../form/controls/validation';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    PasswordComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class SetPasswordFormComponent {
  private formBuilder = inject(FormBuilder);

  readonly ctaButtonLabel = input<string>(undefined);
  readonly isSaving = input<boolean>(undefined);
  readonly submitPassword = output<ChangePasswordPayload>();

  public formData: ChangePasswordPayload = {
    password: '',
    confirmedPassword: ''
  };

  public form = this.formBuilder.group({
    password: ['', matchControlValidatorFactory('confirmedPassword', true)],
    confirmedPassword: ['', matchControlValidatorFactory('password', true)]
  });

  public setPassword(): void {
    this.submitPassword.emit(this.form.value as ChangePasswordPayload);
  }
}
